import { getPetName } from '../helpers/petHelpers'
import { toast } from 'react-toastify'
import { DateTime, Settings } from 'luxon'
import useAuthStore from '../store/useAuthStore'

export const alertSafeZone = (message) => {
    const now = DateTime.now().setZone(useAuthStore.getState().timezone.value).toLocaleString(DateTime.TIME_SIMPLE)
    const petName = getPetName(message.iccid)
    if (message.outOfZone) {
        console.log('out of zone message', message)
        toast.error(`${now}: ${petName} is out of all safe zones`, {
            icon: ({theme, type}) => <i className="fa-solid fa-triangle-exclamation"/>,
            theme: 'colored',
            containerId: 'main',
        });
    } else {
        console.log('in zone message', message)
        toast.success(`${now}: ${petName} is back in zone ${message.occupiedZone}`, {
            icon: ({theme, type}) => <i className="fa-solid fa-triangle-exclamation"/>,
            theme: 'colored',
            containerId: 'main',
        });
    }
}

export const alertDeviceUpdate = (message) => {
    const petName = getPetName(message.iccid)
    const now = DateTime.now().setZone(useAuthStore.getState().timezone.value).toLocaleString(DateTime.TIME_SIMPLE)
    switch (message.deviceUpdate?.subType) {
        case 'DeviceIsSleeping':
            toast.info(`${now}: ${petName}'s Gibi is sleeping`, {
                icon: ({theme, type}) => <i className="fa-solid fa-bed"/>,
                theme: 'colored',
                containerId: 'main',
            });
            break
        case 'DeviceAwoke':
            toast.info(`${now}: ${petName}'s Gibi is awake`, {
                icon: ({theme, type}) => <i className="fa-solid fa-sun"/>,
                theme: 'colored',
                containerId: 'main',
            });
            break
        case 'batteryAlert':
            toast.error(`${now}: ${petName}'s Gibi has a low battery`, {
                icon: ({theme, type}) => <i className="fa-solid fa-battery-quarter"/>,
                theme: 'colored',
                containerId: 'main',
            });
            break
        case 'beaconInRange':
            toast.info(`${now}: ${petName}'s is in a power saving zone`, {
                icon: ({theme, type}) => <i className="fa-solid fa-triangle-exclamation"/>,
                theme: 'colored',
                containerId: 'main',
            });
            break
        default:
            break
    }
}

export const alertCommandResponse = (message) => {
    const petName = getPetName(message.iccid)
    const now = DateTime.now().setZone(useAuthStore.getState().timezone.value).toLocaleString(DateTime.TIME_SIMPLE)
    switch (message.commandResponse?.command) {
        case 'wakeResponse':
            const reason = message.setting
            let wakeReason = ''
            if (reason === 1) {
                wakeReason = 'sleep canceled due to power cycle'
            } else if (reason === 2) {
                wakeReason = 'one time sleep is over'
            } else if (reason === 3) {
                wakeReason = 'daily sleep is over'
            } else if (reason === 4) {
                wakeReason = 'sleep canceled due to low battery'
            }
            toast.info(`${now}: ${petName}'s Gibi is awake: ${wakeReason}`, {
                icon: ({theme, type}) => <i className="fa-regular fa-sun"/>,
                theme: 'colored',
                containerId: 'main',
            });
            break
        default:
            break
    }
}

export const alertFind = (message) => {
    const petName = getPetName(message.iccid)
    const now = DateTime.now().setZone(useAuthStore.getState().timezone.value).toLocaleString(DateTime.TIME_SIMPLE)
    toast.success(`${now}: ${petName} is found!`, {
        icon: ({theme, type}) => <i className="fa-solid fa-map-pin"/>,
        theme: 'colored',
        autoClose: false,
        containerId: 'main',
    });
}

