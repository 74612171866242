import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { PetContainer } from "./PetContainer/PetContainer";
import usePetsDevicesStore from "../store/usePetsDevicesStore";
import useUIStore from '../store/useUIStore'

function DashboardAccordion(props) {
  const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
  const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar);
  const petMap = usePetsDevicesStore((s) => s.pets);
  const [width, setWidth] = useState(window.innerWidth);
  return (
    <div>     
      <Accordion flush >
        {Object.keys(petMap)
          .map((petId) => {
            // if (petMap[petId].assignDevice.length) {
            const iccid = petMap[petId]?.assignDevice[0]?.iccid;
            return (
              <PetContainer
                key={petId}
                pet={petMap[petId]}
                iccid={iccid}
                width={width}
                isOpenSidebar={isOpenSidebar}
              />
            );
            // }
          })
          .filter((n) => n)}
      </Accordion>
    </div>
  );
}

export default DashboardAccordion;
