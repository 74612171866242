import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import classnames from 'classnames'
import '../Date.js'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import logo from '../../assets/local-image/gibi-logo.png'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import useAuthStore from '../../store/useAuthStore'
import {
    addPetDetails,
    fileUploadAWS,
    getAllSpecies,
    getBreedType,
    getDeviceList,
    getIconColors,
    ImageUpload,
    updatePetDetails,
} from '../../helpers/petHelpers'

import ModalWrapper from '../../components/ModalWrapper/ModalWrapper.jsx'

const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL
const gender = [
    {value: 'Female', label: 'Female'},
    {value: 'Male', label: 'Male'},
]
const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            borderColor: '#fff!important',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        }
    },
}

const myComponentStyle = {

    fontFamily: 'FontAwesome',
    content: '\f111',
    position: 'absolute',
    left: '15px',
    color: 'red',
    fontSize: '16px',

}

let file_require = 1 //true
const schema = yup
    .object()
    .shape(
        {
            petName: yup
                .string()
                .min(3, 'Pet name must be at least 3 characters')
                .max(20, 'Pet name must be at most 20 characters.')
                .matches(/^[aA-zZ 0-9-\s]+$/, 'Pet Name can contain alphanumeric characters & hyphens(-)')
                .required('Pet name is required.'),
            petWeight: yup
                .string()
                .min(1, 'Pet weight must be at least 1 digit.')
                .max(3, 'Pet weight must be at most 3 digit.')
                .matches(/^[0-9]+$/, 'Must be only numeric characters'),
            // .required("Pet weight is required."),
            birth_date: yup.mixed().required('Birth date is required.'),
            species: yup.string().required('Please select or enter Pet Type'),
            breed: yup.string().required('Please select or enter Breed'),
            gender: yup.string().required('Gender is required'),
            iconColor: yup.string().required('Icon color is required.'),

            microchip: yup.string().when('microchip', (value) => {
                if (value) {
                    return (
                        yup
                            .string()
                            // .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
                            .min(9, 'Microchip must be minimum 9 in digits.')
                            .max(15, 'Microchip must be maximum 15 in digits.')
                            .test(
                                'length',
                                'Microchips are 9-,10- or 15-digits.',
                                (value) => {
                                    if (value.length > 10 && value.length < 15) {
                                        return false
                                    }
                                    else {
                                        return true
                                    }
                                }
                            )
                    )
                }
                else {
                    return yup
                        .string()
                        .transform((value, originalValue) => {
                            // Convert empty values to null
                            if (!value) {
                                return null
                            }
                            return originalValue
                        })
                        .nullable()
                        .optional()
                }
            }),

            pet_photo: yup
                .mixed()

                .test(
                    'fileType',
                    'Only the following formats are accepted: .jpeg, .jpg,.png',
                    (values) => {
                        if (values.length !== 0 && file_require === 3) {
                            return (
                                values &&
                                (values[0].type === 'image/jpeg' || values[0].type === 'image/png')
                            )
                        }
                        else {
                            return true
                        }
                    }
                )
                .test('fileSize', 'File too big, can\'t exceed 3MB', (value) => {
                    if (value.length !== 0 && file_require === 3) {
                        return value[0].size <= 3000000
                    }
                    else {
                        return true
                    }
                }),
        },
        [['microchip', 'microchip']]
    )
    .required()

const MyPetModal = (props) => {
    const {
        showAddNewPet,
        setShowAddNewPet,
        updateLoader,
        editPetDetail,
        form_type,
        setform_type,
        petId,
        auxToFetch,
        setAuxToFetch,
        setEditPetDetail,
        toastConfig,
    } = props
    const [userToken, setuserToken] = useState(useAuthStore((s) => s.token))
    const [isPetNew, setIsPetNew] = useState(false)
    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        setError,
        clearErrors,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    })

    const [selectBirthdate, setSelectBirthdate] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileType, setSelectedFileType] = useState(null)
    const [species, setspecies] = React.useState([])
    const [breed, setBreed] = React.useState([])
    // const [breedData, setBreedData] = React.useState([]);
    const [isBreedNew, setIsBreedNew] = useState(false)
    const [iconColor, setIconColor] = useState([])
    const [isColorNew, setIsColorNew] = useState(false)
    const [deviceList, setDeviceList] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [nodalButtonName, setModalButtonName] = useState('Save')
    const [unChnagefile, setUnChnagefile] = useState('logo')
    const [petBreed, setPetBreed] = useState(0)
    const [modalTitle, setModalTitle] = useState('Add New Pet')

    /*------Image Cropping--------*/
    const [showModal, setShowModal] = useState(false)
    const [selectedModal, setSelectedModal] = useState('React Avatar Editor')
    const [image, setImage] = useState(null)
    const [croppedImg, setCroppedImg] = useState(null)
    /*------Image Cropping--------*/


    const fetchBreedData = async (specie_type) => {
        let data = {species_type: specie_type}
        setValue('breed', '')

        const response = await getBreedType(data, userToken)

        if (response.status) {
            let breed_type = response.breed_list

            setBreed(
                breed_type.map((item) => ({
                    value: item._id,
                    label: item.breed_name,
                }))
            )
            // setBreedData(
            //   breed_type.map((item) => ({
            //     value: item._id,
            //     label: item.breed_name,
            //   }))
            // );
        }
    }

    const selectSpecies = (val) => {
        fetchBreedData(val)
        setValue('species', val)
        setValue('breed', '')

    }

    const selectGender = (val) => {
        setValue('gender', val)
    }
    const selectBreed = (val) => {
        setValue('breed', val)
    }
    const setbirth_date = (val) => {
        let dateControl = ''
        if (val !== '') {
            let date = new Date(val),
                mnth = ('0' + (date.getMonth() + 1)).slice(-2),
                day = ('0' + date.getDate()).slice(-2)
            dateControl = [date.getFullYear(), mnth, day].join('-')
        }
        else {
            let date = new Date(),
                mnth = ('0' + (date.getMonth() + 1)).slice(-2),
                day = ('0' + date.getDate()).slice(-2)
            dateControl = [date.getFullYear(), mnth, day].join('-')
        }
        setValue('birth_date', dateControl)
    }

    const selectIconColor = (val) => {
        setValue('iconColor', val)
    }
    const selectDevice = (val) => {
        setValue('device', val)
    }
    const handleChange = (
        newValue
        // newValue: OnChangeValue<ColourOption, false>,
        // actionMeta: ActionMeta<ColourOption>
    ) => {
        newValue.__isNew__ === true ? setIsBreedNew(true) : setIsBreedNew(false)

        selectBreed(newValue.value)
        // console.log(`action: ${actionMeta.action}`);
        console.groupEnd()
    }
    const handleChangePetType = (newValue) => {
        newValue.__isNew__ === true ? setIsPetNew(true) : setIsPetNew(false)

        selectSpecies(newValue.value)

        console.groupEnd()
    }

    const handleInputChangePetType = (inputValue, breedData) => {
        // console.group('Input Changed');
        // console.log(inputValue);
        // console.log(inputValue.value);
        // selectBreed(inputValue.value);
        // console.log(`action: ${breedData.action}`);
        // console.groupEnd();
    }
    const handleInputChange = (inputValue, breedData) => {
        // console.group('Input Changed');
        // console.log(inputValue);
        // console.log(inputValue.value);
        // selectBreed(inputValue.value);
        // console.log(`action: ${breedData.action}`);
        // console.groupEnd();
    }
    const handleColorChange = (
        newValue
        // newValue: OnChangeValue<ColourOption, false>,
        // actionMeta: ActionMeta<ColourOption>
    ) => {
        newValue.__isNew__ === true ? setIsColorNew(true) : setIsColorNew(false)
        console.group('Value Changed')

        selectIconColor(newValue.value)
        // console.log(`action: ${actionMeta.action}`);
        console.groupEnd()
    }
    const handleColorInputChange = (inputValue, breedData) => {
        // console.group('Input Changed');
        // console.log(inputValue);
        // console.log(inputValue.value);
        // selectBreed(inputValue.value);
        // console.log(`action: ${breedData.action}`);
        // console.groupEnd();
    }

    const fetchSpeciesData = async () => {
        const response = await getAllSpecies(userToken)

        if (response.status) {
            let specie_type = response.data

            setspecies(
                specie_type.map((item) => ({
                    value: item._id,
                    label: item.species_name,
                }))
            )
        }
    }
    const fetchIconColor = async (type) => {
        let data = {petID: petId}
        const response = await getIconColors(type, data, userToken)
        if (response.status) {
            let iconColorData = response.data
            setIconColor(
                iconColorData.map((item) => ({
                    value: item._id,
                    label: item.iconColorName,
                    colorCode: item.colorCode,
                }))
            )
        }
    }
    useEffect(() => {
        fetchSpeciesData() //Fetch  pets type
        fetchAllDevices(userToken) // Fetch device assigned
        setIsSubmitting(false)
        fetchIconColor('add') // Fetch Icon colors
    }, [auxToFetch])
    const [editColorCode, setEditColorCode] = useState('#FF8000')
    useEffect(() => {
        if (form_type === 'edit') {
            if (JSON.stringify(editPetDetail) !== '{}') {
                console.log(editPetDetail)
                file_require = 2 //For Update
                setValue('petName', editPetDetail.petName)
                setValue('petWeight', editPetDetail.petWeight)
                setValue('species', editPetDetail.species?._id)
                editPetDetail.assignDevice.length > 0
                    ? setValue('device', editPetDetail.assignDevice[0]?._id)
                    : setValue('device', '')

                fetchBreedData(editPetDetail.species?._id)
                setValue('breed', editPetDetail.breed?._id)
                setPetBreed(editPetDetail.breed?._id)
                setValue('gender', editPetDetail.gender)
                setValue('microchip', editPetDetail.microchip)
                setUnChnagefile(editPetDetail.petImage)
                setSelectedFile(editPetDetail.imageURL)
                setSelectedFileType(null)
                setEditColorCode(editPetDetail?.iconColorName[0]?.colorCode)
                setIsPetNew(false)
                setIsBreedNew(false)
                let dateControl = ''

                if (editPetDetail.birthDate != '') {
                    let date = new Date(editPetDetail.birthDate),
                        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
                        day = ('0' + date.getDate()).slice(-2)
                    dateControl = [date.getFullYear(), mnth, day].join('-')
                }
                else {
                    let date = new Date(),
                        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
                        day = ('0' + date.getDate()).slice(-2)
                    dateControl = [date.getFullYear(), mnth, day].join('-')
                }
                setSelectBirthdate(dateControl)
                setbirth_date(dateControl)
                setModalTitle('Edit Pet')
                setModalButtonName('Update')
                setShowAddNewPet(!showAddNewPet)
                fetchIconColor('edit')
                setValue('iconColor', editPetDetail.iconColor)
            }
        }
    }, [editPetDetail])

    const [fileName, setFileName] = useState('Click to Upload Photo')
    const fileUpdate = (image) => {
        // const image = e.target.files[0];

        setFileName(image.name)
        setSelectedFile((imageFile) => image)
        file_require = 3
        let image_type = image.type
        let fileExt = image_type.split('/').pop()
        setSelectedFileType(fileExt)
    }

    useEffect(() => {
        console.log(croppedImg)
        console.log('FILE')
        if (croppedImg != null) {
            fileUpdate(croppedImg)
        }
    }, [croppedImg])


    const fetchAllDevices = async (userToken) => {
        const response = await getDeviceList(userToken)

        if (response.status) {
            let deviceData = response.result
            const list = deviceData.map((item) => {
                if (item.val.petId === null) {
                    let deviceName = item.val.deviceName
                    return {value: item.val._id, label: deviceName}
                }
                else {
                    let deviceName = item.val.deviceName + ' -- Tracker Assigned'
                    return {value: item.val._id, label: deviceName}
                }
            })
            list.unshift({value: 'none', label: 'No tracker assigned'})
            setDeviceList(list)
        }
    }

    const formSubmit = async (data) => {
        setIsSubmitting(true)
        data.isBreedNew = isBreedNew
        data.isPetNew = isPetNew
        data.isColorNew = isColorNew
        let ms = Date.now()
        let fileKey = useAuthStore.getState()._id + '-' + ms + '.' + selectedFileType
        let fileType = selectedFileType
        //Pet Image Upload:
        // The File Key is a unique identifier generated for each file that is uploaded to the AWS Image upload function.
        // This key is used to both get the file from the upload function and pass it to the function for processing. Once the file is processed,
        //  the AWS Image upload function returns an AWS URL where the image is stored in an AWS Bucket..

        if (fileType != null) {
            const pet_data = {...data, fileKey}
            const response = await ImageUpload(fileKey, fileType)
            if (response != false) {
                const aws_url = response.data
                const imageResponse = await fileUploadAWS(
                    aws_url,
                    selectedFile,
                )
                if (imageResponse.status) {
                    insert_data(form_type, pet_data, userToken)
                }
                else {
                    console.log('Failed upload file to AWS')
                }
            }
            else {
                console.log('Failed to get aws URL')
            }
        }
        else {
            fileKey = unChnagefile
            let pet_data = {...data, fileKey}
            console.log(pet_data)
            insert_data(form_type, pet_data, userToken)
        }
        updateLoader(true)
    }

    const insert_data = async (form_type, petData, userToken) => {
        if (form_type === 'add') {
            const result = await addPetDetails(userToken, petData)
            if (result.status) {
                setShowAddNewPet(!showAddNewPet)
                updateLoader(false)
                setAuxToFetch(!auxToFetch)
                setIsSubmitting(false)
                toast.success(result.message, {...toastConfig, containerId: 'api'})
                reset()
                closeModal()
            }
            else {
                updateLoader(false)
                setIsSubmitting(false)
                if (result.errorType === 'petName') {
                    setError(
                        'petName',
                        {type: 'custom', message: result.message},
                        {shouldFocus: true}
                    )
                }
                else if (result.errorType === 'microchip') {
                    setError(
                        'microchip',
                        {type: 'custom', message: result.message},
                        {shouldFocus: true}
                    )
                    clearErrors('petName')
                }
                else {
                    clearErrors('petName')
                    clearErrors('microchip')
                }
                toast.error(result.message, {...toastConfig, containerId: 'api'})
            }
        }
        else {
            // update petDetails
            const result = await updatePetDetails(userToken, petId, petData)
            // console.log(result);
            if (result.status) {
                toast.success(result.message, {...toastConfig, containerId: 'api'})
                setShowAddNewPet(!showAddNewPet)
                setAuxToFetch(!auxToFetch)
                reset()
                closeModal()
            }
            else {
                if (result.errorType === 'petName') {
                    setError(
                        'petName',
                        {type: 'custom', message: result.message},
                        {shouldFocus: true}
                    )
                }
                else if (result.errorType === 'microchip') {
                    setError(
                        'microchip',
                        {type: 'custom', message: result.message},
                        {shouldFocus: true}
                    )
                    clearErrors('petName')
                }
                else {
                    clearErrors('petName')
                    clearErrors('microchip')
                }
                toast.error(result.message, {...toastConfig, containerId: 'api'})
            }

            updateLoader(false)
            setIsSubmitting(false)
        }
    }

    const actionButton = (
        <button
            type="submit"
            className="btn btn-outline-primary"
            data-bs-dismiss="modal"
            disabled={isSubmitting}
        >
            {isSubmitting === true && (
                <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )}
            {nodalButtonName}
        </button>
    )
    const handleFileSelect = async (e) => {
        try {
            e.preventDefault()
            let files

            if (e.dataTransfer) {
                files = e.dataTransfer.files
            }
            else if (e.target) {
                files = e.target.files
            }

            const reader = new FileReader()

            if (!reader) {
                return
            }

            reader.onload = () => {
                console.log(reader.result)
                setImage(reader.result?.toString())
                e.target.value = null
                setShowModal(true)
            }

            reader.readAsDataURL(files[0])
        } catch (error) {
            console.log(error)
        }
    }
    const petFileSection = (
        <div className="row mb-3 d-flex align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-4 col-12">
                {selectedFile && (
                    <div className="dog-bio me-3">
                        {selectedFileType && (
                            <img src={URL.createObjectURL(selectedFile)} alt="pet-iamge"/>
                        )}
                        {!selectedFileType && <img src={selectedFile} alt="pet-iamge"/>}
                    </div>
                )}
                {!selectedFile && (
                    <div className="dog-bio me-3">
                        <img src={logo} alt="pet-iamge"/>
                    </div>
                )}
                <label for="actual-btnss">Pet Photo </label>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-8 col-12">
                <input
                    type="file"
                    id="actual-btn"
                    {...register('pet_photo')}
                    onChange={(e) => {
                        // fileUpdate(e);
                        handleFileSelect(e)
                    }}
                />

                {errors.pet_photo && (
                    <p className="errors">{errors.pet_photo.message}</p>
                )}
            </div>


            {showModal && <ModalWrapper
                showModal={showModal}
                modal={selectedModal}
                imgURL={image}
                onSaveHandler={(i) => {
                    setCroppedImg(i)
                }}
                onModalClose={() => {
                    setShowModal(false)
                    setImage(null)
                }}
            />
            }

        </div>
    )
    const petNameSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <label>Pet Name</label>
                <div className="input-group white">
          <span className="input-group-text">
            <i className="bx bxs-dog"></i>
          </span>
                    <input
                        type="text"
                        className={'form-control' + (errors.petName ? ' is-invalid' : '')}
                        {...register('petName')}
                        placeholder="Pet Name"
                    />
                </div>
                {errors.petName && (
                    <div className="errors">{errors.petName?.message}</div>
                )}
            </div>
        </div>
    )
    const petWeightSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <label>Pet Weight (lbs)</label>
                <div className="input-group white">
          <span className="input-group-text">
          <i className="fa fa-weight-scale" aria-hidden="true"></i>
          </span>
                    <input
                        type="text"
                        className={'form-control' + (errors.petWeight ? ' is-invalid' : '')}
                        {...register('petWeight')}
                        placeholder="Pet Weight"
                    />
                </div>
                {errors.petWeight && (
                    <div className="errors">{errors.petWeight?.message}</div>
                )}
            </div>
        </div>
    )
    const birthDateSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <label>Birth Date</label>
                <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </span>
                    <Controller
                        name="birth_date"
                        control={control}
                        // render={({ field }) => (
                        render={({field: {onChange, value}}) => (
                            <Flatpickr
                                id="birth_date"
                                defaultValue={selectBirthdate}
                                options={{
                                    altInput: true,
                                    altFormat: 'F j, Y',
                                    dateFormat: 'Y-m-d',
                                    maxDate: new Date(),
                                    disableMobile: true,
                                    altInputClass: classnames('form-control flatpickr-input ', {
                                        'is-invalid': !!errors.birth_date,
                                    }),
                                }}
                                placeholder="Birth Date"
                                // value={birth_date.find((c) => c.value === value)}
                                onChange={(val) => {
                                    onChange(val)
                                    setbirth_date(val)
                                }}
                            />
                        )}
                    />
                </div>
                <p className="errors">
                    {errors.birth_date?.message || errors.birth_date?.label.message}{' '}
                </p>
            </div>
        </div>
    )

    const petTypeSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group species">
                <label>Pet Type</label>
                <div className="custom-dropdown">
                    <Controller
                        name="species"
                        control={control}
                        // render={() => (
                        render={({field: {value}}) => (
                            <CreatableSelect
                                // isClearable
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                onChange={handleChangePetType}
                                onInputChange={handleInputChangePetType}
                                options={species}
                                value={species.find((c) => c.value === value)}
                            />
                        )}
                    />
                    <p className="errors">
                        {errors.species?.message || errors.species?.label.message}
                    </p>
                </div>
            </div>
        </div>
    )

    const breedSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group species">
                <label>Breed </label>
                <Controller
                    name="breed"
                    control={control}
                    // render={() => (
                    render={({field: {value}}) => (

                        <CreatableSelect
                            // isClearable
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            onChange={handleChange}
                            onInputChange={handleInputChange}
                            options={breed}
                            value={breed.find((c) => c.value === value || '')}

                        />
                    )}
                />

                <p className="errors">
                    {errors.breed?.message || errors.breed?.label.message}
                </p>
            </div>
        </div>
    )

    const genderSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group gender">
                <label>Gender</label>
                <div className="custom-dropdown">
                    <Controller
                        name="gender"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <Select
                                options={gender}
                                styles={customStyles}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                value={gender.find((c) => c.value === value)}
                                onChange={(val) => {
                                    onChange(val.value)
                                    selectGender(val.value)
                                }}
                                placeholder={<div className="Select Gender">Select Gender</div>}
                            />
                        )}
                    />
                </div>
                <p className="errors">
                    {errors.gender?.message || errors.gender?.label.message}
                </p>
            </div>
        </div>
    )

    const iconColorSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <label>Icon Color</label>

                {/* <div className="custom-dropdown icon-color" style={{myComponentStyle}}> */}
                <div className="custom-dropdown set-dropdown-icon">
                    <i className="fa fa-circle adjust-icon" aria-hidden="true" style={{color: editColorCode}}></i>
                    <Controller
                        name="iconColor"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <Select
                                options={iconColor}
                                styles={customStyles}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                value={iconColor.find((c) => c.value === value)}
                                // value="USA"
                                onChange={(val) => {
                                    // console.log(val)
                                    onChange(val.value)
                                    selectIconColor(val.value)
                                    setEditColorCode(val.colorCode)
                                }}
                                placeholder={
                                    <div className="Select Species">Select Icon color</div>
                                }
                            />
                        )}
                    />
                    <p className="errors">
                        {errors.iconColor?.message || errors.iconColor?.label.message}
                    </p>
                </div>
            </div>
        </div>
    )

    const microChipSection = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group">
                <label>Microchip</label>
                <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-microchip" aria-hidden="true"></i>
          </span>
                    <input
                        type="text"
                        className={'form-control' + (errors.microchip ? ' is-invalid' : '')}
                        {...register('microchip')}
                        placeholder="Microchip"
                    />
                </div>
            </div>
            {errors.microchip && (
                <div className="errors">{errors.microchip?.message}</div>
            )}
        </div>
    )

    const assignDevice = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group custom-dropdown mb-3 device-icon">
                <label>Assigned Gibi Tracker</label>
                <Controller
                    name="device"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const _val = value ? value : 'none'
                        return <Select
                            maxMenuHeight={150}
                            styles={customStyles}
                            isClearable={false}
                            isSearchable={false}
                            value={deviceList.find((c) => c.value === _val)}
                            // value="USA"
                            onChange={(val) => {
                                onChange(val.value)
                                selectDevice(val.value)
                            }}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            id="assigned1"
                            options={deviceList}
                            placeholder={<div className="Select">Select Tracker</div>}
                        />
                    }}
                />
            </div>
        </div>
    )
    const closeModal = () => {
        setValue('petName', '')
        setValue('species', '')
        setValue('device', '')
        setValue('breed', '')
        setPetBreed('')
        setValue('gender', '')
        setValue('microchip', '')
        setSelectBirthdate(null)
        setSelectedFile(null)
        setModalTitle('Add New Pet')
        setModalButtonName('Save')
        setValue('birth_date', null)
        setValue('iconColor', '')
    }
    return (
        <div>
            <Modal
                className="add-new-pet"
                size="lg"
                show={showAddNewPet}
                onHide={() => {
                    reset()
                    closeModal()
                    setShowAddNewPet(false)
                    setEditPetDetail({})
                }}
                aria-labelledby="modal-sizes-title-lg"
                centered
                backdrop="static"
            >
                <form
                    method="POST"
                    onSubmit={handleSubmit(formSubmit)}
                    encType="multipart/form-data"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-sizes-title-lg">{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="my-pets">
                        <div className="px-5">
                            {/* {petFileSection} */}

                            <div className="row mb-3 d-flex align-items-center">
                                <div className="col-xl-2 col-lg-2 col-md-4 col-12">
                                    {selectedFile && (
                                        <div className="dog-bio me-3">
                                            {selectedFileType && (
                                                <img src={URL.createObjectURL(selectedFile)} alt="pet-iamge"/>
                                            )}
                                            {!selectedFileType && <img src={selectedFile} alt="pet-iamge"/>}
                                        </div>
                                    )}
                                    {!selectedFile && (
                                        <div className="dog-bio me-3">
                                            <img src={logo} alt="pet-iamge"/>
                                        </div>
                                    )}
                                    <label for="actual-btnss">Pet Photo </label>
                                </div>
                                <div className="col-xl-10 col-lg-10 col-md-8 col-12">
                                    <input
                                        type="file"
                                        id="actual-btn"
                                        {...register('pet_photo')}
                                        onChange={(e) => {
                                            // fileUpdate(e);
                                            handleFileSelect(e)
                                        }}
                                    />

                                    {errors.pet_photo && (
                                        <p className="errors">{errors.pet_photo.message}</p>
                                    )}
                                </div>


                                {showModal && <ModalWrapper
                                    showModal={showModal}
                                    modal={selectedModal}
                                    imgURL={image}
                                    onSaveHandler={(i) => {
                                        setCroppedImg(i)
                                    }}
                                    onModalClose={() => {
                                        setShowModal(false)
                                        setImage(null)
                                    }}
                                />
                                }

                            </div>
                            <div className="row">
                                {petNameSection}
                                {birthDateSection}
                                {petTypeSection}
                                {breedSection}
                                {genderSection}
                                {petWeightSection}
                                {microChipSection}
                                {assignDevice}
                                {iconColorSection}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>{actionButton}</Modal.Footer>
                </form>
            </Modal>


        </div>
    )
};

export default MyPetModal;
