import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import "../Date.js";
import logo from "../../assets/local-image/gibi-logo.png";
import {
  getAllSpecies,
  getBreedType,
  getAllIconColors,
  ImageUpload,
  fileUploadAWS,
} from "../../helpers/petHelpers";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper.jsx";


const today = new Date();
const gender = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
];

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      cursor: "pointer",
      padding: "5px",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#139dcc ",
        color: "#fff",
      },
    };
  },
};

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const validateImageType = (value) => {
  if (value) {
    let type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_FORMATS.includes(type);
  }
};
var file_require = 1; //true
const schema = yup
  .object()
  .shape(
    {
      pet_name: yup
        .string()
        .min(3, "Pet name must be at least 3 characters.")
        .max(20, "Pet name must be at most 20 characters.")
        .matches(/^[aA-zZ 0-9-\s]+$/, "Pet Name can contain alphanumeric characters & hyphens(-)")
        .required("Pet name is required."),
        birth_date: yup.mixed().required("Birth date is required."),
        species: yup.string().required("Please select or enter Pet Type"),
        breed: yup.string().required("Please select or enter Breed"),
        gender: yup.string().required("Gender is required"),
        pet_weight: yup
          .string()
          .min(1, "Pet weight must be at least 1 digit.")
          .max(3, "Pet weight must be at most 3 digit.")
          .matches(/^[0-9]+$/, "Must be only numeric characters"),
          // .required("Pet weight is required."),
      // iconColor: yup.string().required("Icon color is required."),

      micro_chip: yup.string().when("micro_chip", (value) => {
        if (value) {
          return (
            yup
              .string()
              // .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
              .min(9, "Microchips are 9-,10- or 15-digits.")
              .max(15, "Microchips are 9-,10- or 15-digits.")
              .test(
                "length",
                "Microchips are 9-,10- or 15-digits.",
                (value) => {
                  if (value.length > 10 && value.length < 15) {
                    return false;
                  } else {
                    return true;
                  }
                }
              )
          );
        } else {
          return yup
            .string()
            .transform((value, originalValue) => {
              // Convert empty values to null
              if (!value) {
                return null;
              }
              return originalValue;
            })
            .nullable()
            .optional();
        }
      }),

      picture: yup
        .mixed()
        .test(
          "fileType",
          "Only the following formats are accepted: .jpeg, .jpg,.png",
          (value) => {
            if (value.length != 0) {
              return (
                (value && value[0].type === "image/jpeg") ||
                value[0].type === "image/png"
              );
            } else {
              return true;
            }
          }
        )
        .test("fileSize", "File too big, can't exceed 3MB", (value) => {
          if (value.length != 0) {
            return value[0].size <= 3000000;
          } else {
            return true;
          }
        }),
    },
    [["micro_chip", "micro_chip"]]
  )
  .required();

const Petinfo = (props) => {
  const {
    updateStep,
    petInfo,
    updatesetPetInfo,
    setSelectedFile,
    updateSelectedFile,
    updateLoader,
    width
  } = props;
  // console.log(petInfo);
  // console.log(updatesetPetInfo);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: petInfo,
  });

  const [unChnagefile, setUnChnagefile] = useState("logo");
  const [species, setspecies] = React.useState([]);
  const [iconColor, setIconColor] = React.useState([]);
  const [breed, setBreed] = React.useState([]);
  const [selectedPetFile, setSelectedPetFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectBirthdate, setSelectBirthdate] = useState(null);
  const [petBreed, setPetBreed] = useState(0);
  const [isBreedNew, setIsBreedNew] = useState(false);
  const [isPetNew, setIsPetNew] = useState(false);
  const [isColorNew, setIsColorNew] = useState(false);
  const [fileName, setFileName] = useState("Click to Upload Photo");
  /*------Image Cropping--------*/
  const [showModal, setShowModal] = useState(false);
	const [selectedModal, setSelectedModal] = useState("React Avatar Editor");
	const [image, setImage] = useState(null);
	const [croppedImg, setCroppedImg] = useState(null);
  /*------Image Cropping--------*/

  const fileUpdate = (image) => {
    // const image = e.target.files[0];
    setFileName(image.name);
    updateSelectedFile(image);
    // setSelectedPetFile(e.target.files[0])
    setSelectedPetFile((imageFile) => image);

    let image_type = image.type;
    var fileExt = image_type.split("/").pop();
    setSelectedFileType(fileExt);
  };

  const fetchSpeciesData = async () => {
    const response = await getAllSpecies();
    if (response.status) {
      let specie_type = response.data;

      setspecies(
        specie_type.map((item) => ({
          value: item._id,
          label: item.species_name,
        }))
      );
      updateLoader(false);
      // setBreed([]);
      // setValue("breed", "");
      if (Object.keys(petInfo).length != 0) {
        fetchBreedData(petInfo.species);
      }
    }
  };
  useEffect(() => {
    if (Object.keys(petInfo).length != 0) {
      // fetchBreedData(petInfo.species);

      setSelectBirthdate(petInfo.birth_date);

      setValue("birth_date", selectBirthdate);
    }
  }, [selectBirthdate]);
  const fetchBreedData = async (specie_type) => {
    // updateLoader(true);
    const data = { species_type: specie_type };
    console.log(data);
    const response = await getBreedType(data);
    console.log(response);
    if (response.status) {
      let breed_type = response.breed_list;

      setBreed(
        breed_type.map((item) => ({ value: item._id, label: item.breed_name }))
      );
      updateLoader(false);
    }
  };
  const fetchIconColor = async () => {
    const response = await getAllIconColors();
    if (response.status) {
      let iconColorData = response.data;

      setIconColor(
        iconColorData.map((item) => ({
          value: item._id,
          label: item.iconColorName,
        }))
      );
    }
  };

  useEffect(() => {
    updateLoader(true);
    fetchSpeciesData();
    fetchIconColor();
  }, []);

  const formSubmit = async (data) => {
    data.isBreedNew = isBreedNew;
    data.isPetNew = isPetNew;
    data.isColorNew = isColorNew;

    let ms = Date.now();
    let fileKey = "file-" + ms + "." + selectedFileType;
    let fileType = selectedFileType;

    if (fileType != null) {
      const pet_data = { ...data, fileKey };
      const response = await ImageUpload(fileKey, fileType);
      if (response != false) {
        const aws_url = response.data;
        const imageResponse = await fileUploadAWS(aws_url, selectedPetFile);
        if (imageResponse.status) {
          updatesetPetInfo(pet_data);
          updateStep(4);
        } else {
          console.log("Failed upload file to AWS");
        }
      } else {
        console.log("Failed to get aws URL");
      }

      // let image_send_url =REACT_APP_BE_URL+"/api/v1/imagePut?key="+fileKey+"&fileType="+fileType;
      // var pet_data =  {...data, fileKey}

      // axios(image_send_url).then(response => {
      //   // Getting the url from response
      //      const aws_url = response.data;

      //      axios({
      //          method: "PUT",
      //          url: aws_url,
      //          data: selectedPetFile,
      //      })
      //      .then(res => {
      //        console.log(res);
      //        console.log(res.status);
      //        if(res.status===200){
      //       //  insert_data(method,url,pet_data,userToken)
      //       updatesetPetInfo(pet_data);
      //       updateStep(4);
      //        }

      //          })
      //          .catch(err => {
      //              // this.setState({
      //              //     error: "Error Occured while uploading the file",
      //              //     uploadSuccess: undefined
      //              // });
      //          });
      //  });
    } else {
      fileKey = unChnagefile;
      let pet_data = { ...data, fileKey };
      console.log(pet_data);
      updatesetPetInfo(pet_data);
      updateStep(4);
    }
    // updatesetPetInfo(pet_data);
    // updateStep(4);
  };

  const previous_step = () => {
    updateStep(2);
  };

  const selectSpecies = (val) => {
    fetchBreedData(val);

    setValue("species", val);
  };
  const selectIconColor = (val) => {
    setValue("iconColor", val);
  };
  const selectGender = (val) => {
    setValue("gender", val);
  };
  const selectBreed = (val) => {
    setValue("breed", val);
  };
  const setbirth_date = (val) => {
    var dateControl = "";
    if (val != "") {
      var date = new Date(val),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      dateControl = [date.getFullYear(), mnth, day].join("-");
    } else {
      var date = new Date(),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      dateControl = [date.getFullYear(), mnth, day].join("-");
    }

    setValue("birth_date", dateControl);
  };

  const handleChange = (newValue) => {
    newValue.__isNew__ === true ? setIsBreedNew(true) : setIsBreedNew(false);
    console.group("Value Changed");

    selectBreed(newValue.value);

    console.groupEnd();
  };
  const handleInputChange = (inputValue, breedData) => {

  };
  const handleChangePetType = (newValue) => {
    newValue.__isNew__ === true ? setIsPetNew(true) : setIsPetNew(false);
    console.group("Value Changed");

    selectSpecies(newValue.value);

    console.groupEnd();
  };
  const handleInputChangePetType = (inputValue, breedData) => {

  };

  const handleColorChange = (
    newValue
    // newValue: OnChangeValue<ColourOption, false>,
    // actionMeta: ActionMeta<ColourOption>
  ) => {
    newValue.__isNew__ === true ? setIsColorNew(true) : setIsColorNew(false);
    console.group("Value Changed");

    selectIconColor(newValue.value);
    // console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  const handleColorInputChange = (inputValue, breedData) => {

  };

  useEffect(()=>{
    console.log(croppedImg);
    console.log("FILE");
    if(croppedImg!=null){
      fileUpdate(croppedImg);
    }
  },[croppedImg])

  //Pet Image Upload:
// The File Key is a unique identifier generated for each file that is uploaded to the AWS Image upload function.
// This key is used to both get the file from the upload function and pass it to the function for processing. Once the file is processed,
//  the AWS Image upload function returns an AWS URL where the image is stored in an AWS Bucket..

	const handleFileSelect = async (e) => {
		try {
			e.preventDefault();
			let files;

			if (e.dataTransfer) {
				files = e.dataTransfer.files;
			} else if (e.target) {
				files = e.target.files;
			}

			const reader = new FileReader();

			if (!reader) return;

			reader.onload = () => {
				console.log(reader.result);
				setImage(reader.result?.toString());
				e.target.value = null;
				setShowModal(true);
			};

			reader.readAsDataURL(files[0]);
		} catch (error) {
			console.log(error);
		}
	};
  return (
    <>
      <div className={"login-holder d-flex align-items-center " + (width < 768 ? "h-100" : "")}>
        <div className={"login-container " + (width < 768 ? "h-100" : "")}>
          <div className="logo">
            <img src={require("../../images/logo-white_.png")} alt="gibi" />
          </div>
          <h3 className="panel-title text-center text-white">Pet Info</h3>
          <form
            method="POST"
            onSubmit={handleSubmit(formSubmit)}
            encType="multipart/form-data"
          >
            <div className="row mb-3 d-flex align-items-center mx-2">
              <div className="col-xl-2 col-lg-2 col-md-2 col-2">
                {/* {selectedPetFile && (

                <div className="dog-bio me-3">

                  {selectedFileType&&(
                  <img src={URL.createObjectURL(selectedPetFile)} alt="pet-iamge"/>

                  )}
                  {!selectedFileType &&(

                  <img src={selectedPetFile} alt="pet-iamge" />
                  )}
                </div>
                )} */}
                {/* { !selectedPetFile &&(

                <div className="dog-bio me-3">
                  <img src={logo} alt="pet-iamge"/>
                </div>
                )
                } */}
                {/* <label for="actual-btnss"  className="text-white">Pet Photo </label> */}
              </div>
              {/* <div className='col-xl-10 col-lg-10 col-md-10 col-10'>
              <input type="file" id="actual-btn" {...register("pet_photo")}  onChange={(e) =>{fileUpdate(e)}} hidden/>
                  <label for="actual-btn" className="filestyle form-control white" >{fileName} </label>
              </div> */}
            </div>
            <div className="row">
              <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 offset-sm-12 col-12">
                <div className="form-group position-relative custom-shadow p-2">
                  <div className="d-flex justify-content-center align-items-center">
                    {selectedPetFile && (
                      <div className="dog-bio me-3">
                        <img src={URL.createObjectURL(selectedPetFile)} />
                      </div>
                    )}
                    {!selectedPetFile && (
                      <div className="dog-bio me-3">
                        <img src={logo} />
                      </div>
                    )}

                    <input
                      type="file"
                      id="actual-btn"
                      {...register("picture")}
                      onChange={(e) => {
                        // fileUpdate(e);
                        handleFileSelect(e)
                      }}
                    />
                  </div>
                  {errors.picture && (
                    <p className="errors">{errors.picture.message}</p>
                  )}
                </div>
                {showModal && <ModalWrapper
                showModal={showModal}
                modal={selectedModal}
                imgURL={image}
                onSaveHandler={setCroppedImg}
                onModalClose={() => {
                  setShowModal(false);
                  setImage(null);
                }}
              />
              }
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <label className="text-white">Pet Name</label>
                  <div className="input-group white">
                    <span className="input-group-text">
                      <i className="bx bxs-dog"></i>
                    </span>
                    <input
                      type="text"
                      className={
                        "form-control" + (errors.pet_name ? " is-invalid" : "")
                      }
                      {...register("pet_name")}
                      placeholder="Enter Pet Name"
                    />
                    {errors.pet_name && (
                      <div className="invalid-feedback">
                        {errors.pet_name?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group birth-date">
                  <label className="text-white">Birth Date</label>
                  <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </span>

                    <Controller
                      name="birth_date"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Flatpickr
                          value={selectBirthdate}
                          id="birth_date"
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                            maxDate: new Date(),
                            disableMobile: true,
                            altInputClass: classnames(
                              "form-control flatpickr-input",
                              {
                                "is-invalid": !!errors.birth_date,
                              }
                            ),
                          }}
                          placeholder="Select Birth Date"
                          onChange={(val) => {
                            onChange(val);
                            console.log(val);
                            setbirth_date(val);
                          }}
                        />
                      )}
                    />
                  </div>
                  <p className="errors">
                    {errors.birth_date?.message ||
                      errors.birth_date?.label.message}{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group species">
                  <label className="text-white"> Pet Type</label>
                  <div className="custom-dropdown">
                    <Controller
                      name="species"
                      control={control}
                      render={({ field: { value } }) => (
                        <CreatableSelect
                          onChange={handleChangePetType}
                          onInputChange={handleInputChangePetType}
                          options={species}
                          value={species.find((c) => c.value === value)}
                        />
                      )}
                    />
                    <p className="errors">
                      {errors.species?.message || errors.species?.label.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <label className="text-white"> Breed</label>
                  <div className="custom-dropdown species">
                    <Controller
                      name="breed"
                      control={control}
                      render={({ field: { value } }) => (
                        <CreatableSelect
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          options={breed}
                          value={breed.find((c) => c.value === value)}
                        />
                      )}
                    />

                    <p className="errors">
                      {errors.breed?.message || errors.breed?.label.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <label className="text-white"> Gender</label>
                  <div className="custom-dropdown gender">
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={gender}
                          styles={customStyles}
                          className="react-dropdown"
                          classNamePrefix="dropdown"
                          value={gender.find((c) => c.value === value)}
                          // value="USA"
                          onChange={(val) => {
                            onChange(val.value);
                            selectGender(val.value);
                          }}
                          placeholder={
                            <div className="Select Gender">Select Gender</div>
                          }
                        />
                      )}
                    />

                    <p className="errors">
                      {errors.gender?.message || errors.gender?.label.message}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <label className="text-white">Icon Color</label>

                  <div className="custom-dropdown icon-color">
                    <Controller
                      name="iconColor"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={iconColor}
                          styles={customStyles}
                          className="react-dropdown"
                          classNamePrefix="dropdown"
                          value={iconColor.find((c) => c.value === value)}
                          onChange={(val) => {
                            onChange(val.value);
                            selectIconColor(val.value);
                          }}
                          placeholder={
                            <div className="Select Species">
                              Select Icon color
                            </div>
                          }
                        />
                      )}
                    />
                    <p className="errors">
                      {errors.iconColor?.message ||
                        errors.iconColor?.label.message}
                    </p>
                  </div>
                </div>
              </div> */}
               <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <label className="text-white">Pet Weight (lbs)</label>
                  <div className="input-group white">
                    <span className="input-group-text">
                    <i className="fa fa-weight-scale" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      className={
                        "form-control" + (errors.pet_weight ? " is-invalid" : "")
                      }
                      {...register("pet_weight")}
                      placeholder="Enter Pet Weight"
                    />
                    {errors.pet_weight && (
                      <div className="invalid-feedback">
                        {errors.pet_weight?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <label className="text-white">Microchip</label>
                  <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-microchip" aria-hidden="true"></i>

                    </span>

                    <input
                      type="text"
                      className={
                        "form-control" +
                        (errors.micro_chip ? " is-invalid" : "")
                      }
                      {...register("micro_chip")}
                      placeholder="Microchip"
                    />
                    {errors.micro_chip && (
                      <div className="invalid-feedback">
                        {errors.micro_chip?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ul className="list-inline w-100 d-flex align-items-center mb-0">
              <li className="list-inline-item m-0 w-50 pe-2">
                <button
                  type="button"
                  className="btn btn-outline-primary white w-100"
                  onClick={previous_step}
                >
                  Previous
                </button>
              </li>
              <li className="list-inline-item m-0 w-50 ps-2">
                <button
                  type="submit"
                  className="btn btn-outline-primary white w-100"
                >
                  Next
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
};
export default Petinfo;
