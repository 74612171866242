import { React, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import { saveIconColor } from "../../../helpers/adminHelper/settigHelper";
import { toastConfig } from "../adminConfig";

const schema = yup
  .object()
  .shape({
    iconColorName: yup
      .string()
      .min(3, "Icon Color must be at least 3 characters.")
      .max(30, "Icon Color must be at most 30 characters.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Icon Color is required."),
  })
  .required();

function AddIconColorModal(props) {
  const toHex = require("colornames");
  const { adminToken, showAlert, setShowAlert, auxToFetch, setAuxToFetch } =
    props;
  const [colorCodes, setColorCodes] = useState("");
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });

  const addNewIconColor = async (data, onClose) => {
    const response = await saveIconColor(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      reset();
      setAuxToFetch(!auxToFetch);
      setShowAlert(false);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setError(
        "iconColorName",
        { type: "custom", message: response.message },
        { shouldFocus: true }
      );
    }
  };
  const saveColor = (data) => {
    let addColor = { ...data, status: "active", colorCode: colorCodes };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to add ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                addNewIconColor(addColor, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const setColorCode = (code) => {
    let colorCode = code.target.value;
    let result = colorCode.toLowerCase().trim();

    colorCode = toHex(result);
    colorCode != undefined ? setColorCodes(colorCode) : setColorCodes("");
    let Ccode = colorCode != undefined ? colorCode : "";
    setValue("iconColorCode", Ccode);
  };
  return (
    <div>
      {" "}
      <Modal
        size="md"
        show={showAlert}
        onHide={() => {
          setShowAlert(false);
          reset();
        }}
        aria-labelledby="modal-sizes-title-lg"
        centered
      >
        <form onSubmit={handleSubmit(saveColor)}>
          <Modal.Header closeButton>
            <Modal.Title id="modal-sizes-title-lg">Icon Color</Modal.Title>
          </Modal.Header>
          <Modal.Body className="scrollbar text-align-justify">
            <div className="my-pets">
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  {" "}
                  Color Name
                </label>
                <input
                  type="text"
                  className={
                    "form-control " +
                    (errors.iconColorName ? " is-invalid" : "")
                  }
                  {...register("iconColorName")}
                  onChange={(e) => {
                    setColorCode(e);
                  }}
                  placeholder="Enter Color Name"
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  {" "}
                  Color Code
                </label>
                <input
                  type="text"
                  className={
                    "form-control " +
                    (errors.iconColorName ? " is-invalid" : "")
                  }
                  {...register("iconColorCode")}
                  placeholder="Enter Color Code"
                />
              </div>
              <p className="errors">
                {errors.iconColorName?.message ||
                  errors.iconColorName?.label.message}
              </p>
              <p className="errors">
                {errors.iconColorNameError?.message ||
                  errors.iconColorNameError?.label.message}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-outline-primary">
              {" "}
              Save{" "}
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                reset();
                setShowAlert(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default AddIconColorModal;
