import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import {toast} from 'react-toastify';
import useAuthStore from '../../store/useAuthStore';
import {confirmAlert} from 'react-confirm-alert';
import {updateAddress} from '../../helpers/accountHelper'
import {CityStateLookupRequest} from '../../helpers/registerHelper'
import { toastConfig } from '../Admin/adminConfig'

const country = [
    {value: 'USA', label: 'USA'},
    {value: 'Canada', label: 'Canada'},
];


const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        };
    },
};

const schema = yup
    .object()
    .shape({
        country: yup.mixed()
            .required()
            .oneOf(['USA', 'Canada'])
            .label('Selected Country'),

        // COMMON FIELD VALIDATION
        // first_name: yup
        //     .string()
        //     .min(3, 'First name must be at least 3 characters.')
        //     .max(20, 'First name must be at most 20 characters.')
        //     .required('First name is required.'),
        // last_name: yup
        //     .string()
        //     .min(3, 'Last name must be at least 3 characters.')
        //     .max(20, 'Last name must be at most 20 characters.')
        //     .required('Last Name is required.'),

        // CANADA FIELD VALIDATION

        address1: yup.string()
            .when('country', {
                is: 'Canada',
                then: yup.string()
                    .required('Address is required.'),
            }),
        address2: yup.string()
            .when('country', {
                is: 'Canada',
                then: yup.string()
                    .required('Address is required.'),
            }),
        address3: yup.string()
            .when('country', {
                is: 'Canada',
                then: yup.string()
                    .required('Address is required.'),
            }),
        province: yup.string()
            .when('country', {
                is: 'Canada',
                then: yup.string()
                    .required('Province is required.'),
            }),
        canada_city: yup.string()
            .when('country', {
                is: 'Canada',
                then: yup.string()
                    .required('City is required.'),
            }),
        postal_code: yup.string()
            .when('country', {
                is: 'Canada',
                then: yup.string()
                    .required('Postal code is required.'),
            }),

        // USA FIELD VALIDATION

        zip_code: yup.string()
            .when('country', {
                is: 'USA',
                then: yup.string()
                    .required('Zip code is a required field')
                    .typeError('Zip code can only be a number')
                    .test('len', 'Zip code needs to be excatly 5 digits', val => val.toString().length === 5)
            }),
        street_addr: yup.string()
            .when('country', {
                is: 'USA',
                then: yup.string()
                    .min(3, 'Street address must be at least 5 characters.')
                    .max(20, 'Street address must be at most 20 characters.')
                    .required('Street address is required.'),
            }),
        usa_city: yup.string()
            .when('country', {
                is: 'USA',
                then: yup.string()
                    .min(3, 'City  must be at least 3 characters.')
                    .max(20, 'City must be at most 20 characters.')
                    .required('City is required.'),
            }),
        state: yup.string()
            .when('country', {
                is: 'USA',
                then: yup.string()
                    .required('State is required '),
            }),


        // }),

    })
    .required();

const UserBilling = ({updateStep, userBillInfo,  setShowAddGibi,toastConfig,setAuxToFetch,auxToFetch}) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userToken = useAuthStore((s) => s.token)

    useEffect(() => {
        if (userToken === '' || userToken === null) {
            // navigate('/login');
        }

        setCountry_As(userBillInfo?.homeAddress?.country);

    }, []);

    const {
        handleSubmit,
        register,
        getValues,
        setFocus,
        setError,
        setValue,
        control,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: userBillInfo.homeAddress
    });

const updateUserAddress = async(onClose,data)=>{
  const accountId = userBillInfo._id
  // const userBillInfoID = userBillInfo._id;
// console.log(data);
// console.log(accountId);

  const updateResult = await updateAddress(userToken,accountId,data);
  if (updateResult.status) {
      toast.success("Address updated successfully.",{...toastConfig, containerId: 'api'})
      setAuxToFetch(!auxToFetch)
      onClose();
      setShowAddGibi(false);
    }
    else {
      toast.error("Address failed to updated successfully.",{...toastConfig, containerId: 'api'})
      onClose();
    }
    setIsSubmitting(false);
}

const cityStateLookUp = async () => {
  let status = true;
  let country = getValues("country");
  let zip_id = 0;
  if (country === "USA") {
    let zip_code = getValues("zip_code");
    let State_data = getValues("state");
    let State = State_data.value;
    const param = { zip_code: zip_code, zip_id: zip_id };
    if (zip_code != "") {
      let city = getValues("city");
      const response = await CityStateLookupRequest(param);
      let ZipCode = response.result.ZipCode[0];
      let zipCodeStatus = "Error" in ZipCode ? true : false;

      if (zipCodeStatus === false) {
        let CityName = response.result.ZipCode[0].City[0];
        let StateName = response.result.ZipCode[0].State[0];

        if (CityName !== city) {
          setError(
            "usa_city",
            {
              type: "custom",
              message: "City Name Is not correct as per the zipcode",
            },
            { shouldFocus: true }
          );
          setValue("usa_city", CityName);
        }
        if (StateName != State) {
          // alert("State Name Is not correct as per the zipcode");
        }
      } else {
        setError(
          "zip_code",
          {
            type: "custom",
            message: "ZipCode is invalid",
          },
          { shouldFocus: true }
        );
        status=false;
      }
    }
  } else {
    let postal_code = getValues("postal_code");
    if (postal_code != "") {
      let canada_city = getValues("canada_city");
      const param = { zip_code: postal_code, zip_id: 0 };
      const response = await CityStateLookupRequest(param);
      let ZipCode = response.result.ZipCode[0];
      let zipCodeStatus = "Error" in ZipCode ? true : false;
      if (zipCodeStatus === false) {
        let CityName = response.result.ZipCode[0].City[0];
        let StateName = response.result.ZipCode[0].State[0];

        if (CityName !== canada_city) {
          // setError(
          //   "canada_city",
          //   {
          //     type: "custom",
          //     message: "City Name Is not correct as per the zipcode",
          //   },
          //   { shouldFocus: true }
          // );
          setValue("canada_city", CityName);
        }
      } else {
        setError(
          "postal_code",
          {
            type: "custom",
            message: "Postal code is invalid",
          },
          { shouldFocus: true }
        );
        status=false;
      }
    }
  }
  return status;
};


    const formSubmit = async(data) => {
      setIsSubmitting(true);
      const status = await  cityStateLookUp();
      if(status){
          setIsSubmitting(true);

        confirmAlert({
          customUI: ({onClose}) => {
              return (
                  <div className="custom-ui">
                      <div className="fa-3x">
                          <i className="fa-solid fa-paw fa-bounce"></i>
                      </div>
                      <h3>Are you sure want to change ?</h3>
                       <button className="btn btn-outline-primary min-width-120"
                              onClick={() => {
                                updateUserAddress(onClose,data);
                              }}
                      >
                          Yes
                      </button>
                      <button className="btn btn-outline-primary min-width-120" onClick={() => {
                          setIsSubmitting(false);
                          onClose();
                      }}>No
                      </button>
                  </div>
              );
          },
          closeOnClickOutside: false,
      });
    }
    setIsSubmitting(false);
    };

    const [Selected_Country, setCountry_As] = useState(null);

    const selectCountry = (value) => {
        setCountry_As(value);
        // console.log(value);
    };

const CountryDropdownField = (
                              <div className="col-xl-8 col-lg-8 col-md-8 col-12 offset-sm-2">
                                <div className="form-group">
                                  <label className="form-label text-dark">Select Country :</label>
                                  <div className="custom-dropdown country">
                                    <Controller
                                      name="country"
                                      control={control}
                                      render={({ field: { onChange, value } }) => (
                                        <Select
                                          options={country}
                                          styles={customStyles}
                                          className="react-dropdown"
                                          classNamePrefix="dropdown"
                                          value={country?.find((c) => c.value === value)}
                                          // value="USA"
                                          onChange={(val) => {
                                            onChange(val.value);
                                            selectCountry(val.value);
                                          }}
                                          placeholder={<div className="Select country">Select country</div>}
                                        />
                                      )}
                                    />
                                    <p className="errors">
                                      {errors.country?.message || errors.country?.label.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );

const commanAddressField=(
  <>
  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
          <label className="form-label text-dark">First Name :</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
              <input type="text" className={
                  'form-control' + (errors.first_name ? ' is-invalid' : '')
              }
                     {...register('first_name')}
                     placeholder="First Name"/>
          </div>
          {errors.first_name && (
              <div className="errors">
                  {errors.first_name?.message}
              </div>
          )}
      </div>
  </div>

  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
          <label className="form-label text-dark">Last Name :</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
              <input type="text" className={
                  'form-control' + (errors.last_name ? ' is-invalid' : '')
              }
                     {...register('last_name')}
                     placeholder="Last Name"/>
          </div>
          {errors.last_name && (
              <div className="errors">
                  {errors.last_name?.message}
              </div>
          )}
      </div>
  </div>


  {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
          <label className="form-label text-dark">Company Name :</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="bx bxs-building" aria-hidden="true"></i>
            </span>
              <input type="text" className={
                  'form-control' +
                  (errors.company_name ? ' is-invalid' : '')
              }
                     {...register('company_name')}
                     placeholder="Company Name"/>
          </div>
          {errors.company_name && (
              <div className="errors">
                  {errors.company_name?.message}
              </div>
          )}
      </div>
  </div> */}

</>
)
const selectedCountryUSA = (
  <>
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Street Address :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={
              "form-control" + (errors.street_addr ? " is-invalid" : "")
            }
            {...register("street_addr")}
            placeholder="Street Address*"
          />
        </div>
        {errors.company_name && (
          <div className="errors">{errors.street_addr?.message}</div>
        )}
      </div>
    </div>

    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Street Address 2 :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.apartment ? " is-invalid" : "")}
            {...register("apartment")}
            placeholder="Street Address 2*"
          />
        </div>
        {errors.apartment && (
          <div className="errors">{errors.apartment?.message}</div>
        )}
      </div>
    </div>

    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">City :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.usa_city ? " is-invalid" : "")}
            {...register("usa_city")}
            placeholder="City*"
          />
        </div>
        {errors.usa_city && (
          <div className="errors">{errors.usa_city?.message}</div>
        )}
      </div>
    </div>

    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">State :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.state ? " is-invalid" : "")}
            {...register("state")}
            placeholder="State*"
          />
        </div>
        {errors.state && <div className="errors">{errors.state?.message}</div>}
      </div>
    </div>

    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Zipcode :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.zip_code ? " is-invalid" : "")}
            {...register("zip_code")}
            placeholder="Zipcode*"
          />
        </div>
        {errors.zip_code && (
          <div className="errors">{errors.zip_code?.message}</div>
        )}
      </div>
    </div>
  </>
);
const selectedCountryCanada = (
  <>
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Address1 :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.address1 ? " is-invalid" : "")}
            {...register("address1")}
            placeholder="Address1*"
          />
        </div>
        {errors.address1 && (
          <div className="errors">{errors.address1?.message}</div>
        )}
      </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Address2 :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.address2 ? " is-invalid" : "")}
            {...register("address2")}
            placeholder="Address2*"
          />
        </div>
        {errors.address1 && (
          <div className="errors">{errors.address2?.message}</div>
        )}
      </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Address3 :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.address3 ? " is-invalid" : "")}
            {...register("address3")}
            placeholder="Address3*"
          />
        </div>
        {errors.address3 && (
          <div className="errors">{errors.address3?.message}</div>
        )}
      </div>
    </div>

    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Province :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.province ? " is-invalid" : "")}
            {...register("province")}
            placeholder="Province"
          />
        </div>
        {errors.province && (
          <div className="errors">{errors.province?.message}</div>
        )}
      </div>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">City :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={
              "form-control" + (errors.canada_city ? " is-invalid" : "")
            }
            {...register("canada_city")}
            placeholder="City"
          />
        </div>
        {errors.canada_city && (
          <div className="errors">{errors.canada_city?.message}</div>
        )}
      </div>
    </div>

    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Postal Code :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={
              "form-control" + (errors.postal_code ? " is-invalid" : "")
            }
            {...register("postal_code")}
            placeholder="Postal Code"
          />
        </div>
        {errors.postal_code && (
          <div className="errors">{errors.postal_code?.message}</div>
        )}
      </div>
    </div>
  </>
);

const actionButtons =(
  <ul className="list-inline w-100 d-flex align-items-center mb-0 border-top p-2">
  <li className="list-inline-item m-0 pe-2 ms-auto">
    <button
      type="submit"
      className="btn btn-outline-primary "
      disabled={isSubmitting}
    >
      {isSubmitting === true && (
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        // <span className="spinner-grow spinner-grow-sm"></span>
      )}
      Update
    </button>
  </li>
  <li className="list-inline-item m-0 ps-2">
    <button
      type="button"
      onClick={() => {
        setShowAddGibi(false);
      }}
      className="btn btn-outline-primary "
    >
      Cancel
    </button>
  </li>
</ul>
)
    return (
      <>
        <form method="POST" onSubmit={handleSubmit(formSubmit)}>
          <div className="p-3">
            <div className="row">
              {CountryDropdownField}

              {/* {(Selected_Country === "USA" ||Selected_Country === "Canada") && <>{commanAddressField}</>} */}
              {Selected_Country === "USA" && <>{selectedCountryUSA}</>}
              {Selected_Country === "Canada" && <>{selectedCountryCanada}</>}
            </div>
          </div>
         {actionButtons}
        </form>
      </>
    );
};

export default UserBilling;
