import create from 'zustand';
const chicago = {lat: 41.939292, lng: -87.650396}
// const pune = {lat: 18.5204, lng: 73.8567}

const MAP_ZOOM_DEFAULT = 18

let store = (set) => ({
    googleMap: null,
    markers: [],
    mapCenter: chicago,
    mapZoom: MAP_ZOOM_DEFAULT,
    highlightedFeature: null,
    blockFitBounds: false,
    setGoogleMap: (map) =>
        set((state) => {
            return {
                ...state,
                googleMap: map,
            }
        }),
    setMarkers: (list) =>
        set((state) => {
            return {
                ...state,
                markers: list,
            }
        }),
    setMultiMarkers: (iccid) =>
        set((state) => {
            return {
                ...state,
                iccid: iccid,
            }
        }),
    setMapCenter: (center) =>
        set((state) => {
            return {
                ...state,
                mapCenter: center,
            }
        }),
    setMapZoom: (zoom) =>
        set((state) => {
            return {
                ...state,
                mapZoom: zoom,
            }
        }),
    setHighlightedFeature: (loc) =>
        set((state) => {
            return {
                ...state,
                highlightedFeature: loc,
            }
        }),
    setBlockFitBounds: (b) =>
        set((state) => {
            return {
                ...state,
                blockFitBounds: b,
            }
        }),
});

const useMapStore = create(store);

export default useMapStore;
