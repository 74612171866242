import React, { useEffect, useState } from "react";
import DeviceCheck from "./DeviceCheck";
import DeviceSubscription from "./DeviceSubscription";
import DeviceBilling from "./DeviceBilling";
import DeviceConfirm from "./DeviceConfirm";
import DevicePaymentSuccess from "./DevicePaymentSuccess";
import DevicePaymentFailed from "./DevicePaymentFailed";
import useAuthStore from "../../../store/useAuthStore";

export default function AddDeviceForm({
  updateLoader,
  updateDeviceModal,
  updateFetch,
}) {
  const [step, setStep] = useState(1);
  const [registerInfo, setRegisterInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const [petInfo, setPetInfo] = useState({});
  const [planInfo, setPlanInfo] = useState({});
  const [billInfo, setBillInfo] = useState({});
  const [homeAddress, setHomeAddress] = useState({});
  const userToken = useAuthStore((s) => s.token);

  const updateStep = (no) => {
    setStep(no);
  };

  const updatesetRegisterInfo = (data) => {
    setRegisterInfo(data);
  };
  const updatesetPetInfo = (data) => {
    setPetInfo(data);
  };
  const updatesetPlanInfo = (data) => {
    setPlanInfo(data);
  };
  const updatesetBillInfo = (data) => {
    setBillInfo(data);
  };
  const updatehomeAddress = (data) => {
    setHomeAddress(data);
  };
  const updatesetUserInfo = (data) => {
    setUserInfo(data);
  };

  const nextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const previousStep = () => {
    setStep((prevState) => prevState - 1);
  };

  switch (step) {
    case 1:
      const deviceCheckProps = {
        userToken: userToken,
        updateStep: updateStep,
        registerInfo: registerInfo,
        updatesetRegisterInfo: updatesetRegisterInfo,
        updateLoader: updateLoader,
      };
      return <DeviceCheck {...deviceCheckProps} />;

    case 2:
      const deviceSupscritionProps = {
        userToken: userToken,
        updateStep: updateStep,
        planInfo: planInfo,
        updatesetPlanInfo: updatesetPlanInfo,
        updatesetBillInfo: updatesetBillInfo,
        updatesetUserInfo: updatesetUserInfo,
        updatehomeAddress:updatehomeAddress,
        updateLoader: updateLoader,
      };
      return <DeviceSubscription {...deviceSupscritionProps} />;
    case 3:
      const deviceBillingProps = {
        userToken: userToken,
        updateStep: updateStep,
        billInfo: billInfo,
        updatesetBillInfo: updatesetBillInfo,
        homeAddress:homeAddress,
        updateLoader: updateLoader,
        userInfo,
      };
      return <DeviceBilling {...deviceBillingProps} />;

    case 4:
      const DeviceConfirmProps = {
        userToken: userToken,
        updateStep: updateStep,
        registerInfo: registerInfo,
        planInfo: planInfo,
        billInfo: billInfo,
        userInfo: userInfo,
        updatesetPlanInfo: updatesetPlanInfo,
        updateLoader: updateLoader,
        updateDeviceModal: updateDeviceModal,
        updateFetch: updateFetch,
      };
      return <DeviceConfirm {...DeviceConfirmProps} />;

    case 5:
      const devicePaymentSuccessProps = {
        updateStep: updateStep,
        updateLoader: updateLoader,
      };
      return <DevicePaymentSuccess {...devicePaymentSuccessProps} />;
    case 6:
      const devicePaymentFailedProps = {
        updateStep: updateStep,
        updateLoader: updateLoader,
      };
      return <DevicePaymentFailed {...devicePaymentFailedProps} />;

    default:
      return <></>;
  }
}
