import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { adminLogin } from '../../helpers/adminHelper/loginHelper'
import { toastConfig } from './adminConfig'
import useAdminAuthStore from '../../store/useAdminAuthStore'

const schema = yup
    .object()
    .shape({
        email: yup
            .string()
            .matches(/^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/, 'Invalid Email address.')
            .required('Email address is required.'),
        password: yup
            .string()
            .min(6, 'Password must be at least 6 characters.')
            .max(20, 'Password must be at most 20 characters.')
            .required('Password is required.'),
    })
    .required()

const AdminLogin = ({updateLoader}) => {
    const navigate = useNavigate()
    const {
        handleSubmit,
        register,
        control,
        setValue,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        // defaultValues: registerInfo
    })
    const token = useAdminAuthStore((s) => s.token)
    const setToken = useAdminAuthStore((state) => state.setToken)
    const formSubmit = async (data) => {
        setIsSubmitting(true)
        const input = {email: data.email, password: data.password}
        const response = await adminLogin(input)
        if (response.status === true) {
            // localStorage.setItem("adminToken", response.token);
            localStorage.setItem('admin_details', JSON.stringify(response.userData))
            // toast.success(response.message, {...toastConfig, containerId: 'api'})
            setToken(response.token, response.userDetails.isSuperUser)
            setTimeout(() => {
                navigate('/user-list')
            }, 1000)

        }
        else {
            toast.error('Login Failed', {...toastConfig, containerId: 'api'})
        }
        setIsSubmitting(false)
    }

    const [isSubmitting, setIsSubmitting] = useState(false)
    const email = (
        <div className="form-group">
            <div className="input-group white">
        <span className="input-group-text">
          <i className="fa fa-user" aria-hidden="true"></i>
        </span>
                <input
                    type="email"
                    className={'form-control' + (errors.email ? ' is-invalid' : '')}
                    {...register('email')}
                    placeholder="Email"
                    autoComplete="off"
                />
            </div>
            <p className="errors">{errors.email?.message || errors.label?.message}</p>
        </div>
    )

    const password = (
        <div className="form-group">
            <div className="input-group white">
        <span className="input-group-text">
          <i className="fa fa-lock" aria-hidden="true"></i>
        </span>

                <input
                    type="password"
                    className={'form-control ' + (errors.password ? ' is-invalid' : '')}
                    {...register('password')}
                    placeholder="Password"
                    autoComplete="off"
                />
            </div>
            <p className="errors">
                {errors.password?.message || errors.password?.label.message}
            </p>
        </div>
    )

    const actionBtn = (
        <div className="list-inline-item m-0 w-50 ps-2 d-flex m-auto">
            <button
                type="submit"
                className="btn btn-outline-primary white w-100"
                disabled={isSubmitting}
            >
                {isSubmitting && (
                    <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                Login
            </button>
        </div>
    )
    return (
        <>
            <div className="container-fluid admin-login">
                <div className="row">
                    <div className="col-xxl-4 col-xl-6 col-lg-6 offset-xxl-4 offset-xl-3 offset-lg-3 p-0">
                        <div className="login-holder d-flex align-items-center">
                            <div className="login-container">
                                <div className="logo">
                                    <img src={require('../../images/logo-white.png')} alt="gibi"/>
                                </div>
                                <h3 className="panel-title text-center text-white">
                                    Admin Login
                                </h3>
                                <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                                    {email}
                                    {password}
                                    <div className="form-group my-4">{actionBtn}</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminLogin
