import { React, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAdminAuthStore from "../../store/useAdminAuthStore";
import { toastConfig } from "./adminConfig";
import { sendDeviceCommand } from '../SettingsModal/helpers/commandHelpers'
const AdminHeader = ({ pageTitle, isOpenSidebar }) => {
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [otaIccid, setOtaIccid] = useState('')
  const navigate = useNavigate();

  const adminToken =  useAdminAuthStore((s) => s.token)

  const clearToken = useAdminAuthStore((s) => s.clearToken);
  const logout = () => {
    clearToken();
    // toast.success("Logout Successful..!", {...toastConfig, containerId: 'api'});
    setAuxToFetch(!auxToFetch);
    window.location.reload();
  };

  useEffect(() => {
    if (adminToken === "" || adminToken === null) {
      navigate("/admin-login");
    }
  }, [auxToFetch]);

  const handleChange = (e) => setOtaIccid(e.target.value)
  const sendUpgrade = async () => await sendDeviceCommand({iccid: otaIccid, command:'ota'}, adminToken)

  const upgradeFirmware = (
      <div style={{color: 'white', display: 'flex', marginLeft: '3rem'}}>
        <div>
          <div className="input-group white">
            <input className="form-control min-width-120" value={otaIccid} onChange={handleChange}/>
          </div>
        </div>
        <div style={{marginLeft: '1rem'}}>
          <button
              className="btn btn-outline-primary min-width-120 white w-100"
              disabled={otaIccid.length < 19}
              onClick={sendUpgrade}>
            Send Ota Upgrade
          </button>
        </div>
      </div>
  )

  return (
    <>
      <div className={"page-heading " + (isOpenSidebar !== true ? "show" : "")}>
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-2">
              <h2>{pageTitle}</h2>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-7">
              {upgradeFirmware}
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3">
              <div className="dropdown float-right">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span>Admin </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout()}>
                         Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminHeader;
