import React, { useState } from "react";
import { Modal } from "react-bootstrap";
// import Video from "../../../assets/SafeDemoVideo.mp4";

function ErrorDeviceModal({ previewSafe, setPreviewSafe, errorDevices }) {
  const [showInstructions, setShowInstructions] = useState(false);
  return (
    <Modal
      show={previewSafe}
      onHide={() => {
        setPreviewSafe(false);
        setShowInstructions(false);
      }}
      aria-labelledby="modal-sizes-title-lg"
      centered
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-lg">GibiTracker Status</Modal.Title>
      </Modal.Header>
      <Modal.Body className="scrollbar">

        <p className="me-auto fw-bold instruction-title">
          There seems to have been an error during device registration.
          Please try again by selecting the Gibi Tracker tab on the side menu, then click on the ACTIVATE button.
          <br/>
          You may also contact us at support@gibitechnologies.com or 1-844-GETGIBI (1-844-438-4424)
        </p>
        <div className="row">
        {Object.values(errorDevices).map((deviceID) => {
          return (
            <>
            {/* <div className="row"> */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                    <p><span className="fw-bold">Tracker ID : </span>{deviceID}</p>
                </div>

            </>
          );
        })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            setShowInstructions(false);
            setPreviewSafe(false);
          }}
          data-bs-dismiss="modal"
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorDeviceModal;
