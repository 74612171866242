import { React, useState, useEffect } from "react";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DataTable from "react-data-table-component";
import { getSubscriptionPlanList } from "../../helpers/deviceHelper";

function SubscriptionPlan({
  updateLoader,
  updateLoaderMessage,
  updateSidebar,
  isOpenSidebar,
}) {
  const columns = [
    {
      name: "Plan Name",
      selector: (row) => row.productName,
      sortable: true,
    },
    {
      name: "Plan Validity",
      selector: (row) => row.interval_count + " " + row.interval,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {/* {row.status==='active'?<span style={{color:'green'}}>Active</span>:<span style={{color:'red'}}>InActive</span>} */}
          <span style={{ color: "green" }}>Active</span>
        </div>
      ),
    },
  ];

  const [auxToFetch, setAuxToFetch] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);

  const [pageTitle, setPageTitle] = useState("Subscription Plan");

  useEffect(() => {
    fetchAllPlans();
  }, [auxToFetch]);

  const fetchAllPlans = async () => {
    updateLoader(true);
    updateLoaderMessage("Please wait..");

    const response = await getSubscriptionPlanList();
    if (response.status) {
      let result = response.products;
      setPlanDetails(result);

      updateLoader(false);
    } else {
      updateLoader(false);
    }
  };

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <AdminSidebar
          isOpenSidebar={isOpenSidebar}
          updateSidebar={updateSidebar}
        />
        <main className="page-content ">
          <AdminHeader pageTitle={pageTitle} isOpenSidebar={isOpenSidebar} />

          <main
            className={
              "content-holder " + (isOpenSidebar != true ? "show" : "")
            }
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="main-wrapper">
                    <div className="view-structure">
                      <ul
                        className="nav nav-tabs border-0 float-end mb-2"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="gridview-tab"
                            data-bs-toggle="tab"
                            href="#gridview"
                            role="tab"
                            aria-controls="gridview"
                            aria-selected="false"
                          >
                            <div className="icon icon-grid"></div>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link  "
                            id="listview-tab"
                            data-bs-toggle="tab"
                            href="#listview"
                            role="tab"
                            aria-controls="listview"
                            aria-selected="true"
                          >
                            <div className="icon icon-list"></div>
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade p-3 border"
                          id="listview"
                          role="tabpanel"
                          aria-labelledby="listview-tab"
                        >
                          <DataTable
                            className="custom-table"
                            columns={columns}
                            data={planDetails}
                            defaultSortFieldId={1}
                            searchable
                            pagination
                            responsive
                          />
                        </div>
                        <div
                          className="tab-pane fade show active p-3 border"
                          id="gridview"
                          role="tabpanel"
                          aria-labelledby="gridview-tab"
                        >
                          <div className="subscription-plan text-center">
                            <div className="row d-flex justify-content-center">
                              {Object.values(planDetails).map((values) => {                              
                                let status =values.status === "active"? "Deactive": "Active";
                                return (
                                  <div className="col-sm-5">
                                    <div className="subplan mb-3">
                                      <div
                                        className={
                                          "subplan-box p-3 " +
                                          (status !== "Active" ? "" : "active")
                                        }
                                      >
                                        <p className="plan-title mb-5">
                                          {values.productName}
                                        </p>
                                        <p className="plan-price">
                                          ${values.productPrice}
                                        </p>
                                      </div>
                                      <div className="subplan-content p-4">
                                        <ul className="list-group">
                                          <li className="list-group-item">
                                            {values.productDescription}
                                          </li>
                                          <li className="list-group-item">
                                            Total {values.interval}{" "}
                                            {values.interval_count}
                                          </li>
                                        </ul>
                                        {/* <button type="button"  className={"btn btn-primary mb-2 mt-3" } >{status}</button> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
export default SubscriptionPlan;
