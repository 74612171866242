import { React, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import { addNewAlertType } from "../../../helpers/adminHelper/settigHelper";
import { toastConfig } from "../adminConfig";

const schema = yup
  .object()
  .shape({
    alertType: yup
      .string()
      .min(3, "Alert Type must be at least 3 characters.")
      .max(30, "Alert Type must be at most 30 characters.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Alert Type is required."),
    alertCode: yup
      .string()
      .min(3, "Alert Code must be at least 3 characters.")
      .max(30, "Alert Code must be at most 30 characters.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Alert Code is required."),
  })
  .required();

function AddAlertModal(props) {
  const { adminToken, showAlert, setShowAlert, auxToFetch, setAuxToFetch } =
    props;
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });

  const saveNewAlertType = (data, onClose) => {
    const response = addNewAlertType(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      reset();
      setAuxToFetch(!auxToFetch);
      setShowAlert(false);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setError(
        "checkInRateData",
        { type: "custom", message: response.message },
        { shouldFocus: true }
      );
    }
  };

  const saveAlerttype = (data) => {
    let addAlertType = { ...data, status: true };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to add ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                saveNewAlertType(addAlertType, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  return (
    <Modal
      size="md"
      show={showAlert}
      onHide={() => {
        setShowAlert(false);reset();
      }}
      aria-labelledby="modal-sizes-title-lg"
      centered
    >
      <form onSubmit={handleSubmit(saveAlerttype)}>
        <Modal.Header closeButton>
          <Modal.Title id="modal-sizes-title-lg">Alert Type</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollbar text-align-justify">
          <div className="my-pets">
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">

                Alert Type
              </label>
              <input
                type="text"
                className={
                  "form-control " + (errors.alertType ? " is-invalid" : "")
                }
                {...register("alertType")}
                placeholder="Enter Alert Type"
              />
            </div>
            <p className="errors">
              {errors.alertType?.message || errors.alertType?.label.message}
            </p>
            <p className="errors">
              {errors.alertTypeError?.message ||
                errors.alertTypeError?.label.message}
            </p>
          </div>
          <div className="my-pets">
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                Alert Code
              </label>
              <input
                type="text"
                className={
                  "form-control " + (errors.alertCode ? " is-invalid" : "")
                }
                {...register("alertCode")}
                placeholder="Enter Alert Code"
              />
            </div>
            <p className="errors">
              {errors.alertCode?.message ||
                errors.alertCode?.label.message}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-outline-primary">
            Save
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              reset();
              setShowAlert(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default AddAlertModal;
