import React from 'react'
import SafeZone from './SafeZone'

const SafeZonePolygons = (props) => {
    const {
        canDragSafeZone,
        canEditSafeZone,
        zoneList,
        searchZoneList,
        polygonType,
        setPolygonType,
        zoneId,
        setNewSafeZoneGeometry,
    } = props
// console.log(polygonType);
// console.log(zoneList);

const safeZones = polygonType==="old" ?(
    zoneList ? zoneList.map((zone, i) => {
        return <SafeZone zone={zone}
        index={i}
        canEdit={canEditSafeZone && zone._id === zoneId}
        canDrag={canDragSafeZone && zone._id === zoneId}
        setNewSafeZoneGeometry={setNewSafeZoneGeometry}
        searchZoneList={searchZoneList}
        key={`safe-zone=list-${i}`}
        />
    }) : null
    ):(
        searchZoneList ? searchZoneList.map((zone, i) => {
            return <SafeZone zone={zone}
            index={i}
            canEdit={canEditSafeZone }
            canDrag={canDragSafeZone }
            setNewSafeZoneGeometry={setNewSafeZoneGeometry}
            searchZoneList={searchZoneList}
            polygonType={polygonType}
            key={`safe-zone=list-${i}`}
            />
        }) : null
    )
    // console.log(safeZones);

    return (
        <div>
            {safeZones}
        </div>
    )
}

export default SafeZonePolygons
