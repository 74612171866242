import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useForm } from 'react-hook-form'
import { checkPromoCode, makePayment } from '../../helpers/deviceHelper'
import { updatePaymentStatus, userRegisteration, userRegisterationMail } from '../../helpers/registerHelper'
import { confirmCardPayment, createPaymentMethod } from '../../helpers/stripeHelper'

const containerStyles = {
    border: '1px solid #fff',
    padding: '8px 10px 8px 10px',
    borderRadius: '4px',
    width: '100%',
    marginBottom: '15px',
    backgroundColor: '#149dcd',
}

const cardNumberElementOptions = {
    style: {
        base: {
            fontSize: '14px',
            lineHeight: '35px',
        },
    },
    placeholder: 'Card Number',
}
const Confirmation = (props) => {
    const {
        updateStep,
        registerInfo,
        planInfo,
        petInfo,
        billInfo,
        homeAddress,
        selectedFile,
        updateLoader,
        isActive,
        width
    } = props
    const [accountCreatedData, setAccountCreatedData] = React.useState(null)
    useLayoutEffect(() => {
        if (isActive) {
            window.scrollTo(0, 0)
            document.body.style.overflow = 'hidden'
            document.body.style.height = '100%'
        }
        if (!isActive) {
            document.body.style.overflow = 'auto'
            document.body.style.height = 'auto'
        }
    }, [isActive])
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()

    const previous_step = () => {
        updateStep(5)
    }

    const handlePurchase = async (data, promoCode) => {
        try {
            const paymentType = {
                type: 'card',
                card: elements.getElement(CardNumberElement),
            }

            const paymentMethod = await createPaymentMethod(stripe, paymentType)
            const paymentMethodID = paymentMethod.paymentMethod.id
            const email = data.email
            const accountID = data.accountID
            const paymentData = {
                name: data.name,
                email: data.email,
                priceID: data.priceID,
                address: data.address,
                planId: data.planId,
                paymentMethod: paymentMethodID,
                promoCode: promoCode,
                mailerData: data.mailerData,
                paymentProcessType: 0,
                iccid: registerInfo.device_id,
            }

            const responseData = await makePayment(paymentData)
            setPromoCode('')
            if (!responseData.status) {
                updateLoader(false)
                navigate('/payment-fail')
            }
            else {
                const clientSecret = responseData.clientSecret
                const last4 = responseData.last4
                const planId = responseData.planId
                data.mailerData.last4 = last4

                const mailer_info = {
                    email: data.email,
                    mailerData: data.mailerData
                }
                mailer_info.mailerData.iccidId = registerInfo.device_id

                if (clientSecret) {
                    const confrimPaymentData = {
                        clientSecret: clientSecret,
                        paymentMethod: {payment_method: paymentMethodID}
                    }
                    const {error, paymentIntent} = await confirmCardPayment(stripe, confrimPaymentData)
                    // console.log(error)
                    // console.log(paymentIntent)

                    if (error) {
                        console.error(error)
                        updateLoader(false)
                        navigate('/payment-fail')
                    }
                    else if (paymentIntent && paymentIntent.status === 'succeeded') {
                        // console.log('Payment succeeded')
                        // console.log(mailer_info)
                        const planData = {planId: planId}
                        const paymentStatus = await updatePaymentStatus(planData)
                        // console.log(paymentStatus)

                        // const mailerStatus = await sendRegisterationMail(mailer_info)
                        // console.log(mailerStatus)

                        updateLoader(false)
                        updateStep(7)
                        return {'status': true, 'message': 'Payment Success!'}
                    }
                    // else {
                    //     return {'status': false, 'message': 'Payment failed'}
                    // }
                } else {
                    console.error('missing client secret')
                    const planData = {planId: planId}
                    const paymentStatus = await updatePaymentStatus(planData)
                    // console.log(paymentStatus)

                    // const mailerStatus = await sendRegisterationMail(mailer_info)
                    // console.log(mailerStatus)

                    updateLoader(false)
                    updateStep(7)
                    return {'status': true, 'message': 'Payment Success!'}
                }
                updateLoader(false)
            }
        } catch (error) {
            updateLoader(false)
            navigate('/payment-fail')
        }
    }

    const sendRegisterationMail = async (mailerData) => {
        const response = await userRegisterationMail(mailerData)
        // console.log(response)
    }

    const formSubmit = async (data) => {
        const cardNumber = elements.getElement(CardNumberElement)
        const cardDate = elements.getElement(CardExpiryElement)
        const cardCVC = elements.getElement(CardCvcElement)

        const isValidNumber = !(cardNumber._empty || cardNumber._invalid)

        const isValidDate = !(cardDate._empty || cardDate._invalid)

        const isValidCVC = !(cardCVC._empty || cardCVC._invalid)

        if (isValidNumber == true && isValidDate == true && isValidCVC == true) {
            setCardError(false)

            if (!accountCreatedData) {
                const register_data = {
                    accountInfo: registerInfo,
                    petInfo: petInfo,
                    planInfo: planInfo,
                    billInfo: billInfo,
                    homeAddress: homeAddress,
                }
                // console.log(register_data)
                updateLoader(true)
                const response = await userRegisteration(register_data)
                if (response.status) {
                    const result = response.payment_data
                    setAccountCreatedData(result)
                    await handlePurchase(result, promoCode)
                }
            } else {
                await handlePurchase(accountCreatedData, promoCode)
            }
        }
        else {
            setCardError(true)
        }
    }

    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm({
        mode: 'all',
    })
    const [isSubmitting, setIsSubmitting] = useState(true)
    const [isSubmitting1, setIsSubmitting1] = useState(false)
    const [isSubmitting2, setIsSubmitting2] = useState(false)
    const [isCodeBtnValid, setIsCodeBtnValid] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const [codeMessage, setCodeMessage] = useState('')
    const [cardError, setCardError] = useState(false)

    const handleChange1 = (event) => {
        setIsSubmitting1((isSubmitting1) => !isSubmitting1)
    }
    const handleChange2 = (event) => {
        setIsSubmitting2((isSubmitting2) => !isSubmitting2)
    }

    useEffect(() => {
        if (isSubmitting1 === true && isSubmitting2 === true) {
            setIsSubmitting((isSubmitting) => false)
        }
        else {
            setIsSubmitting((isSubmitting) => true)
        }
    }, [isSubmitting1, isSubmitting2])

    const promoCodeCheck = async (value) => {
        setIsCodeBtnValid(false)
        const data = {promoCode: value}
        const response = await checkPromoCode(data)
        // console.log(response)
        if (response.status) {
            const result = response.result.data
            if (result.length > 0) {
                setCodeMessage('\u2713  Coupon Applied Successfully')
                setIsCodeBtnValid(true)
                // console.log(result[0]?.coupon.id)
                setPromoCode(result[0]?.coupon.id)
            }
            else {
                setIsCodeBtnValid(false)
                setPromoCode('')
                setCodeMessage('Your Coupon Code is not valid.')
            }
        }
        else {
        }
    }

    const cardElementOptions = {
        style: {
            base: {
                color: '#000',
                border: '1px solid #ccc',
                fontSize: '15px',
                lineHeight: '30px',
                textAlign: 'center',
                backgroundColor: '#fff',
                '::placeholder': {
                    color: '#87BBFD',
                },
            },
            invalid: {
                color: '#fa755a',
                fontSize: '15px',
            },
        },
    }

    const actionButtons = (
        <ul className="list-inline w-100 d-flex align-items-center mb-0">
            <li className="list-inline-item m-0 w-50 pe-2">
                <button
                    type="button"
                    className="btn btn-outline-primary white w-100"
                    onClick={previous_step}
                >
                    Previous
                </button>
            </li>
            <li className="list-inline-item m-0 w-50 ps-2">
                <button
                    type="submit"
                    className="btn btn-outline-primary white w-100"
                    disabled={isSubmitting}
                >
                    Create Account
                </button>
            </li>
        </ul>
    )

    return (
        <>
            <div className={'login-holder d-flex align-items-center h-100'}>
                <div className={'login-container h-100'}>
                    <div className="logo">
                        <img src={require('../../images/logo-white_.png')} alt="gibi"/>
                    </div>
                    <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                        <h3 className="panel-title text-center text-white">Confirmation</h3>
                        <div className="view-data mb-4">
                            <div className="row mb-3">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label className="caption-label">Tracker ID :</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                    <label className="value-label">
                                        {registerInfo.device_id}
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label className="caption-label">Subscription Plan :</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                    <label className="value-label">
                                        {planInfo.label} $ {planInfo.amount}
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label className="caption-label">Name :</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                    <label className="value-label">
                                        {registerInfo.first_name} {registerInfo.last_name}
                                    </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label className="caption-label">Email :</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                    <label className="value-label">{registerInfo.email}</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label className="caption-label">Mobile :</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                    <label className="value-label">{registerInfo.mobile}</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <label className="caption-label">Billing Address :</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                    {billInfo.street_addr && (
                                        <p className="value-label mb-0">{billInfo.street_addr}</p>
                                    )}
                                    {billInfo.apartment && (
                                        <p className="value-label mb-0">{billInfo.apartment} </p>
                                    )}
                                    {billInfo.address1 && (
                                        <p className="value-label mb-0">{billInfo.address1}, </p>
                                    )}
                                    {billInfo.address2 && (
                                        <p className="value-label mb-0"> {billInfo.address2}, </p>
                                    )}
                                    {billInfo.address3 && (
                                        <p className="value-label mb-0"> {billInfo.address3} </p>
                                    )}
                                    {billInfo.state && (
                                        <p className="value-label mb-0">
                                            {billInfo.usa_city != ''
                                                ? billInfo.usa_city
                                                : billInfo.canada_city}, {billInfo.state}
                                        </p>
                                    )}
                                    {billInfo.zip_code && (
                                        <p className="value-label mb-0"> {billInfo.zip_code} </p>
                                    )}
                                    {billInfo.postal_code && (
                                        <p className="value-label mb-0"> {billInfo.postal_code}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <p className="text-white mb-4 policy-text">
                            By clicking "Create Account," you are creating a Gibi account,
                            purchasing the Subscription Plan selected, providing permission to
                            Gibi Technologies Inc. to charge your credit card for the
                            Subscription Plan selected, and you agree to automatic renewal.
                            Please note that if service is canceled or paused, previous
                            subscription service charges are not refundable.
                        </p>

                        <div style={containerStyles}>
                            {/* <CardElement options={cardNumberElementOptions}/> */}
                            <div className="card-details px-0 border-0 text-white">
                                <p className="fw-bold">Payment Details :</p>
                                <div className="row mb-2">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <label>Card Number :</label>
                                        <CardNumberElement options={cardElementOptions}/>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-xl-6 col-lg-6  col-md-6 col-6">
                                        <label>CVC :</label>
                                        <CardCvcElement options={cardElementOptions}/>
                                    </div>

                                    <div className="col-xl-6 col-lg-6  col-md-6 col-6">
                                        <label>Expiry Date :</label>
                                        <CardExpiryElement options={cardElementOptions}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-8">
                                <input
                                    type="text"
                                    className={
                                        'form-control mb-2' +
                                        (errors.first_name ? ' is-invalid' : '')
                                    }
                                    name="promoCode"
                                    {...register('promoCode')}
                                    placeholder="Enter Your Coupon Code"
                                    onChange={(e) => {
                                        setPromoCode(e.target.value)
                                        if (e.target.value === '') {
                                            setCodeMessage(' ')
                                            setIsCodeBtnValid(false)
                                        }
                                    }}
                                />
                                <div className={isCodeBtnValid === true ? 'text-white' : 'msg-red'}>
                                    {codeMessage}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary white w-100"
                                    onClick={() => {
                                        promoCodeCheck(promoCode)
                                        // console.log(promoCode)
                                    }}
                                    disabled={isCodeBtnValid || !promoCode}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                        <p className="text-white mb-4 policy-text">
                            <div className="form-check form-check-inline custom-check check-change">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="630f2195c0799f4e093cec1d"
                                    name="terms"
                                    value={isSubmitting1}
                                    onChange={handleChange1}
                                />
                                <label
                                    className="form-check-label clearfix text-white"
                                >
                                    I agree to Gibi Technologies Inc’s Terms of Service and Privacy Policy shown at
                                    <Link
                                        to="http://getgibi.com/legal"
                                        target="_blank"
                                        className="text-white text-decoration-none"
                                    > getgibi.com/legal</Link>

                                </label>
                            </div>
                        </p>
                        <p className="text-white mb-4 policy-text">
                            <div className="form-check form-check-inline custom-check check-change">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="630f2195c0799f4e093cec1d"
                                    name="terms"
                                    value={isSubmitting2}
                                    onChange={handleChange2}
                                />
                                <label
                                    className="form-check-label clearfix text-white"
                                >
                                    I agree to receive SMS alerts and notifications to the mobile number provided.
                                </label>
                            </div>
                        </p>
                        {cardError &&
                            <p className="text-white"><i class="fa-solid fa-circle-exclamation"></i> Error: Please enter
                                valid card details</p>}

                        {actionButtons}
                    </form>
                </div>
            </div>
        </>
    )
}
export default Confirmation
