import React, { useEffect, useState } from 'react'

import { Modal } from 'react-bootstrap'
import '../Date.js'
import 'react-confirm-alert/src/react-confirm-alert.css'
import useUIStore from '../../store/useUIStore'
import usePetsDevicesStore from '../../store/usePetsDevicesStore'
import useAuthStore from '../../store/useAuthStore'
import { confirmAndSaveSetting, sendDeviceCommand } from '../SettingsModal/helpers/commandHelpers'
import { AlertsPanel } from './AlertsPanel'
import useGibiConfigStore from '../../store/useGibiConfigStore'
import { toast } from 'react-toastify'
import { toastConfig } from '../Admin/adminConfig'

const AlertsModal = () => {
    const showAlertsModal = useUIStore((s) => s.showAlertsModal)
    const setAlertsModal = useUIStore((s) => s.setAlertsModal)

    const iccid = useUIStore((s) => s.currentIccid)

    const [curIccid, setCurIccid] = useState(iccid)
    const [command, setCommand] = useState('')
    const [commandVal, setCommandVal] = useState(0)
    const [alerts, setAlerts] = useState([])

    const devices = usePetsDevicesStore((s) => s.devices)
    const userToken = useAuthStore((s) => s.token)
    const alertTypes = useGibiConfigStore((s) => s.alertOptions)
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    useEffect(() => {
        setCurIccid(iccid)
        if (iccid) {
            const d = devices[iccid]
            if (d) {
                setAlerts(d.status?.alerts)
            }
        }
    }, [iccid])

    useEffect(() => {
         setSelectedAlerts(alerts);
        // console.log(showAlertsModal);
    }, [alerts])


    const closeModal = () => setAlertsModal(false)

    const update = (key, value) => {
        console.log(key, value)
        switch (key) {
            case 'alerts':
                setCommand('alerts')
                setCommandVal(value)
                break
        }
    }

    const onCancel = () => {
        setAlertsModal(false)
    }

    const onSave = async () => {
        console.log('update alerts', curIccid, command, commandVal)
        setAlertsModal(false)
        const response = await sendDeviceCommand({iccid: curIccid, command, value: commandVal}, userToken)

        if (response.data?.status) {
            // toast.success(response.data.message, {
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     containerId: 'api',
            // })
        }
        else {
            toast.info('Command is in queue. Please wait', {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'api',
            })
        }
    }

    return showAlertsModal ? (
        <Modal
            show={true}
            onHide={onCancel}
            aria-labelledby="modal-sizes-title-lg"
            backdrop="static"
            centered
            className="h-100"
        >
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">Alerts</Modal.Title>
            </Modal.Header>
            <AlertsPanel alerts={alerts} alertTypes={alertTypes} onChange={update} onSave={onSave}  selectedAlerts={selectedAlerts} setSelectedAlerts={setSelectedAlerts}/>
        </Modal>
    ) : null

}
export default AlertsModal
