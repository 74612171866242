import { get, post } from './apiHelper'
import { DateTime, Settings } from 'luxon'
import useAuthStore from '../store/useAuthStore'

export function selectAlertType(alertDate, selectedPet, selectedDeviceID, setAlertsLoading, userToken, setPetAlerts) {
    const selectAlertType = async (data) => {

        var someDate = new Date(alertDate)
        someDate.setDate(someDate.getDate() + 1) //number  of days to add, e.x. 15 days
        var nextDate = someDate.toISOString().substr(0, 10)

        // console.log(selectedPet);
        // console.log(selectedDeviceID);
        const alertType = data.alertCode
        if (selectedPet !== '' && selectedDeviceID !== '' && alertType !== '') {
            setAlertsLoading(true)
            const data = {
                'selectedDate': alertDate,
                'nextDate': nextDate,
                'petId': selectedPet,
                'iccid': selectedDeviceID,
                'alertType': alertType
            }
            const response = await post('/api/v1/findPetAlerts', data, userToken)
            // console.log(response)
            setAlertsLoading(false)
            if (response.data.status === true) {
                // setPetAlerts([{
                //   "petName":val.label,
                //   "petfindOutTime":"1:20 am 05-12-2021",
                // }]);
                let requestData = response.data.result

                setPetAlerts(requestData.map((item) => ({
                    eventType: item.eventType,
                    deviceUpdate: item.deviceUpdate,
                    deviceStatus: item.deviceStatus,
                    createdAt: item.createdAt
                })))

                //     setLoading(false);
            }
            else {

                // setLoading(false);
            }
        }
        else {

        }

    }
    return selectAlertType
}

export const getPetEvents = async (iccid, epoch, userToken) => {
    let response = {}
    try {
        if (!epoch) {
            const today = DateTime.now().setZone(useAuthStore.getState().timezone.value).toISODate()
            response = await get(`/api/v1/events/history/${today}/${iccid}`, userToken)
        }
        else {
            response = await get(`/api/v1/events/history/${epoch}/${iccid}`, userToken)
        }
        if (response.status === 200) {
            return response.data
        }
    } catch (e) {
        console.log('getPetHistory', e)
    }
    return []
}


export const getLastTenEvents = async (iccid, userToken) => {
    if (iccid) {
        const retVal = await get(`/api/v1/events/lastTen/${iccid}`, userToken)
        return retVal.data.response
    }
}

export const getEventName = (m, subType) => {
    let eventName = ''
    switch (subType) {
        case 'powerUp':
            eventName = 'Power On'
            break
        case  'powerDown':
            eventName = 'Power Off'
            break
        case 'fullCharge':
            eventName = 'Full Charge'
            break
        case 'batteryAlert':
            eventName = 'Low Battery Alert'
            break
        case 'find':
            const success = m?.commandResponse?.success
            eventName = success ? `Find Response Success` : `Find Command in Queue`
            break
        case 'BACKGROUND_INTERVAL':
        case 'backgroundInterval':
            eventName = 'Check In Rate Changed'
            break
        case 'sleepResponse':
            eventName = 'Sleep Set'
            break
        case 'wakeResponse':
        case 'DeviceAwoke':
            eventName = m.deviceUpdate.deviceStatus.wakeReason || 'Gibi is Awake'
            break
        case 'otaUpgrade':
            eventName = 'Gibi Firmware Upgrade'
            break
        case 'beaconInRange':
            eventName = 'Beacon in Range'
            break
        case 'beaconOutOfRange':
            eventName = 'Beacon out of Range'
            break
        case 'inCharger':
            eventName = 'Gibi in Charger'
            break
        case 'outOfCharger':
            eventName = 'Gibi out of Charger'
            break
        case 'setLed':
            eventName = `Gibi LED Change Request`
            break
        case 'ledResponse':
            eventName = `Gibi LED Turned ${m.deviceStatus.ledState ? 'On' : 'Off'}`
            break
        case 'bleScanResponse':
            eventName = `Power Save Scan Rate Changed`
            break
        case 'noCellSignalTimerResponse':
            eventName = `No Cell Signal Scan Rate Changed`
            break
        case 'alertsUpdate':
            eventName = 'Alert settings updated'
            break
        case 'ledResponseOn':
            eventName = 'Gibi LED on'
            break
        case 'ledResponseOff':
            eventName = 'Gibi LED off'
            break
        case 'ledResponseAuto':
            eventName = 'Gibi LED Auto off'
            break
        case 'SET_LED':
            eventName = `Gibi LED Command ${m?.commandResponse?.success ? '' : 'Queued'}`
            break
        case 'DeviceIsSleeping':
            eventName = `Gibi has gone to sleep`
            break
        case 'DeviceCommandSent':
            const cmd = getFriendlyCommand(m.deviceUpdate.commandRequest)
            eventName = `${cmd} sent to Gibi`
            break
        default:
            if (m?.eventType === 'COMMAND_RESPONSE') {
                eventName = getCommandNameAndStatus(m)
            }
            else if (m?.eventType === 'SAFE_ZONE') {
                if (!!m.safeZoneEvent?.outOfZone || !!m.outOfZone) {
                    eventName = `Out of Safe Zone`
                }
                else {
                    eventName = 'In Safe Zone'
                }
            }
            else {
                eventName = m.subType
            }
            break
    }
    return eventName
}

export const getFriendlyCommand = (cr) => {
    switch(cr?.cmd) {
        case 'FIND':
            return 'Find'
        case 'BGI':
            return 'Change background interval'
        default:
            return 'Command'
    }
}

export const getCommandNameAndStatus = (m) => {
    const isGood = m.commandResponse.success
    let name
    switch (m.commandResponse.command) {
        case 'BACKGROUND_INTERVAL':
        case 'backgroundInterval':
            name = 'Change Check in Rate'
            break
        case 'SET_BLE_SCAN':
        case 'bleScanResponse':
            name = 'Change Power Saving Rate'
            break
        case 'SET_NSC_TIMER':
        case 'noCellSignalTimerResponse':
            name = 'Change No Cell Signal Sleep'
            break
        case 'SET_MOTION_THRESHOLD':
        case 'motionThresholdResponse':
            name = 'Change Motion Threshold'
            break
        case 'ledResponse':
            name = 'Set LED'
            break
        case 'SLEEP':
            name = 'Configure Sleep'
            break
        case 'FIND':
            name = 'Find Command'
            break
        default:
            name = m.commandResponse.command
            break
    }
    return `${name} ${isGood ? 'Success' : 'Queued'}`
}

export const getEventIcon = (m, subType) => {
    let eventIcon = ''
    switch (subType) {
        case 'backgroundLocation':
            eventIcon = `fa-solid fa-location-dot`
            break
        case 'powerUp':
            eventIcon = 'fa-solid fa-power-off green'
            break
        case  'powerDown':
            eventIcon = 'fa-solid fa-power-off red'
            break
        case 'fullCharge':
            eventIcon = 'fa-solid fa-battery-full'
            break
        case 'batteryAlert':
            eventIcon = 'fa-solid fa-battery-quarter'
            break
        case 'find':
            eventIcon = 'fa-solid fa-magnifying-glass-location'
            break
        case 'BACKGROUND_INTERVAL':
        case 'backgroundInterval':
            eventIcon = 'fa-solid fa-rotate'
            break
        case 'sleepResponse':
            eventIcon = 'fa-solid fa-moon'
            break
        case 'wakeResponse':
            eventIcon = 'fa-solid fa-mug-saucer'
            break
        case 'otaUpgrade':
            eventIcon = 'fa-solid fa-file-code'
            break
        case 'beaconInRange':
            eventIcon = 'fa-solid fa-circle'
            break
        case 'beaconOutOfRange':
            eventIcon = 'fa-regular fa-circle'
            break
        case 'inCharger':
            eventIcon = 'fa-solid fa-bolt'
            break
        case 'outOfCharger':
            eventIcon = 'fa-solid fa-bolt'
            break
        case 'setLed':
            eventIcon = m.deviceStatus.ledState ? 'fa-solid fa-lightbulb' : 'fa-regular fa-lightbulb'
            break
        case 'ledResponse':
            eventIcon = m.deviceStatus.ledState ? 'fa-solid fa-lightbulb' : 'fa-regular fa-lightbulb'
            break
        case 'bleScanResponse':
            eventIcon = 'fa-solid fa-rotate'
            break
        case 'noCellSignalTimerResponse':
            eventIcon = 'fa-solid fa-rotate'
            break
        case 'alertsUpdate':
            eventIcon = 'fa-solid fa-gear'
            break
        case 'DeviceAwoke':
        case 'DeviceIsSleeping':
            eventIcon = 'fa-solid fa-bed'
            break
        case 'DeviceCommandSent':
            eventIcon = `fa-solid fa-check`
            break;
        default:
            if (m?.eventType === 'COMMAND_RESPONSE') {
                eventIcon = m.commandResponse.success ? 'fa-regular fa-thumbs-up' : 'fa-solid fa-clock'
            } else if (m?.eventType === 'SAFE_ZONE') {
                if (!!m.safeZoneEvent?.outOfZone || !!m.outOfZone) {
                    eventIcon = 'fa-solid fa-shield'
                }
                else {
                    eventIcon = 'fa-solid fa-shield-heart'
                }
            }
            else {
                if (m?.eventType) {
                    console.log('missing event icon', m)
                }
                eventIcon = 'fa-solid fa-question'
            }
            break
    }
    return eventIcon
}

export const getEventLabel = (m, subType) => {
    let eventIcon = ''
    switch (subType) {
        case 'powerUp':
            eventIcon = 'Power on'
            break
        case  'powerDown':
            eventIcon = 'Power off'
            break
        case 'fullCharge':
            eventIcon = 'Full charge'
            break
        case 'batteryAlert':
            eventIcon = 'Low battery'
            break
        case 'find':
            eventIcon = 'Find'
            break
        case 'sleepResponse':
            eventIcon = 'Sleep set'
            break
        case 'wakeResponse':
            eventIcon = 'Wake'
            break
        case 'beaconInRange':
            eventIcon = 'Power save on'
            break
        case 'beaconOutOfRange':
            eventIcon = 'Power save off'
            break
        case 'inCharger':
            eventIcon = 'In charger'
            break
        case 'outOfCharger':
            eventIcon = 'Out of charger'
            break
        case 'DeviceIsSleeping':
            eventIcon = 'Sleeping'
            break
    }
    return eventIcon
}

export const getAlertOptions = async (userToken) => {
    try {
        const response = await get(`/api/v1/alertTypes/1`, userToken)
        if (response.data.status === true) {
            return response.data.result
        }
        return []
    } catch (err) {
        console.log('getAlertTypes', err)
        return []
    }
}
