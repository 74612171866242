import { awsFileUpload, get, post, put, remove } from './apiHelper'
import usePetsDevicesStore from '../store/usePetsDevicesStore'

export const getAllPets = (updateLoader, setPetList, setPets) => {
    const fetchAllPets = async (userToken) => {
        updateLoader(true)
        const response = await get('/api/v1/pets', userToken)
        if (response.data.status) {
            // setPetList(response.data.result)
            let petList = response.data.result

            setPetList(petList)
            // setPets(pets.map((item) => ({ value: item._id, label: item.petName })));
            const paidPets = petList.filter((item) => (item.assignDevice.length !== 0)).map((item) => ({
                value: item._id, label: item.petName, iccid: item.assignDevice[0].iccid
            }))
            setPets(paidPets)
            const devices = {}
            const petHistory = []
            const pets = {}
            petList.forEach(p => {
                pets[p._id] = p
                if (p.pet_devices && p.pet_devices.length) {
                    p.pet_devices.forEach(d => {
                        devices[d.iccid] = d
                        // petHistory[d.iccid] = d
                    })
                }
                if (p.petHistory && p.petHistory.length) {
                    p.petHistory.forEach(d => {

                        if (petHistory.hasOwnProperty(d.properties.deviceId)) {
                            petHistory[d.properties.deviceId].push(d)
                            // petHistory[d.properties.deviceId].unshift(d);
                        }
                        else {

                            petHistory[d.properties.deviceId] = [d]
                        }
                    })
                }
            })

            console.log(petHistory)
            updateLoader(false)
            return {devices, pets, petHistory}
        }
        else {
            updateLoader(false)
        }
    }
    return fetchAllPets
}

export function changePetName(alertDate, setSelectedPet, setSelectedDeviceID) {
    // const handlePetNameChange = (val) => {
    //
    //     // console.log(alertDate);
    //
    //     var someDate = new Date(alertDate);
    //     someDate.setDate(someDate.getDate() + 1); //number  of days to add, e.x. 15 days
    //     var dateFormated = someDate.toISOString().substr(0, 10);
    //     // console.log(dateFormated);
    //     setSelectedPet(val.value);
    //     setSelectedDeviceID(val.iccid);
    //     // axios({
    //     //   method: "post",
    //     //   url: REACT_APP_BE_URL+"/api/v1/findPetAlerts",
    //     //   headers: { Authorization: `Bearer ${userToken}` },
    //     //   data:{ "selectedDate":alertDate,"nextDate":dateFormated,"petId":val.value,"iccid":val.iccid}
    //     // }).then(function (response) {
    //     //   console.log(response)
    //     // if(response.data.status === true){
    //     //   // setPetAlerts([{
    //     //   //   "petName":val.label,
    //     //   //   "petfindOutTime":"1:20 am 05-12-2021",
    //     //   // }]);
    //     //   let requestData = response.data.result;
    //
    //     //   setPetAlerts(requestData.map((item) => ({ eventType: item.eventType, deviceUpdate: item.deviceUpdate ,deviceStatus:item.deviceStatus,createdAt:item.createdAt})));
    //     // //     setPetHistory(response.data.result);
    //     // //     setLoading(false);
    //     //   }else{
    //     //       // setPetHistory([]);
    //     //       // setLoading(false);
    //     //   }
    //     // }).catch(function (response) {
    //     //   // setLoading(false);
    //     // });
    //
    //
    // }
}

export const filterPaidPets = (list) => {
    return list.filter((item) => (item.assignDevice.length !== 0)).map((item) => ({
        value: item._id, label: item.petName, iccid: item.assignDevice[0].iccid
    }))
}

export const getLastKnownPosition = async (iccid, userToken) => {
    if (iccid) {
        try {
            const response = await get(`/api/v1/position/lastKnown/${iccid}`, userToken)
            return response.data.data[0]
        } catch (e) {
            console.log(e)
            return null
        }
    }
}

/*
 const response = await get(`/api/v1/pets`, userToken)
        if (response.status === 200) {
            const devices = {}
            const pets = {}
            for (const p of response.data.result) {
                pets[p._id] = p
                if (p.pet_devices && p.pet_devices.length) {
                    for (const device of p.pet_devices) {
                        devices[device.iccid] = device
                        // const lastKnown = await getLastKnownPosition(device.iccid, userToken)
                        // console.log('last known', device.iccid, lastKnown)
                    }
                }
            }
            return {pets, devices}
        }
 */
export const getPets = async (userToken) => {
    try {
        const response = await get(`/api/v1/pets`, userToken)
        if (response.status === 200) {
            const devices = {}
            const pets = {}
            for (const p of response.data.result) {
                pets[p._id] = p
                if (p.pet_devices && p.pet_devices.length) {
                    for (const device of p.pet_devices) {
                        devices[device.iccid] = device
                    }
                }
            }
            return {pets, devices}
        }
    } catch (e) {
        console.log('getPets', e)
    }
    return {pets: undefined, devices: undefined}
}

export const getLastTenLocations = async (iccid, userToken) => {
    if (iccid) {
        const response = await get(`/api/v1/position/lastTen/${iccid}`, userToken)
        return response.data.data
    }
}

export const getAllSpecies = async (userToken) => {
    const response = await get('/api/v1/get_all_species/1', userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}
export const getBreedType = async (data, userToken) => {
    const response = await post('/api/v1/get_breed_type', data, userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}

export const getDeviceList = async (userToken) => {
    const response = await get('/api/v1/new_devices', userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}

export const getIconColors = async (type, data, userToken) => {
    const response = await put('/api/v1/iconColorlists/' + type, data, userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}
export const addPetDetails = async (userToken, data) => {
    const response = await post('/api/v1/pet/', data, userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}

export const updatePetDetails = async (userToken, petId, data) => {
    const response = await put('/api/v1/pet/' + petId, data, userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}

export const removePetDetails = async (userToken, id) => {
    try {
        const response = await remove('/api/v1/pet/' + id, userToken)
        console.log(response)
        if (response.data.status) {
            return response.data
        }
        else {
            return []
        }
    } catch (error) {
        console.log(error)
    }
}

///Image upload to aws
export const ImageUpload = async (fileKey, fileType) => {
    try {
        const response = await get('/api/v1/imagePut?key=' + fileKey + '&fileType=' + fileType)
        console.log(response.status)
        if (response.status) {
            return response
        }
        else {
            return false
        }

    } catch (error) {
        console.log(error)
    }
}
export const fileUploadAWS = async (awsURL, data) => {
    try {
        const response = await awsFileUpload(awsURL, data)
        // console.log(response);
        if (response.status) {
            return response
        }
        else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export const getPetList = async (userToken) => {
    const response = await get('/api/v1/pets', userToken)
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}
export const getAllIconColors = async () => {
    const response = await get('/api/v1/iconColor/1')
    if (response.status) {
        return response.data
    }
    else {
        return []
    }
}

export const getPetName = (iccid) => {
    const pets= usePetsDevicesStore.getState().pets
    const devices = usePetsDevicesStore.getState().devices
    const petId = devices[iccid].petId
    const name = pets[petId]?.petName || pets[petId]?.pet_name || 'Your Pet'
    return name
}
