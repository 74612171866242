import { React, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DataTableSetup from '../DataTableSetup'
import AddTimeZoneModal from './AddTimeZoneModal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { fetchTimeZone, updateTimeZoneStatus, } from '../../../helpers/adminHelper/settigHelper'
import useAdminAuthStore from '../../../store/useAdminAuthStore'
import { toastConfig } from '../adminConfig'

const TimeZone = () => {
    const [auxToFetch, setAuxToFetch] = useState(false)
    const [timeZones, setTimeZones] = useState([])
    const [adminToken, setAdminToken] = useState(useAdminAuthStore((s) => s.token))
    const toastConfig = {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    const [showZone, setShowZone] = useState(false)
    const columns = [
        {
            name: 'Time Zone Name',
            selector: (row) => row.timezone_name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
                <div>
                    {row.status === 'active' ? (
                        <span style={{color: 'green'}}>Active</span>
                    ) : (
                        <span style={{color: 'red'}}>InActive</span>
                    )}
                </div>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            sortable: false,
            cell: (row) => (
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-disabled">Click to change status.</Tooltip>
                        }
                    >
                        <button className="icon-button">
                            <i
                                className={
                                    row.status === 'active' ? 'fa fa-trash' : 'fa fa-undo'
                                }
                                onClick={() => {
                                    deleteID(row._id, row.status)
                                }}
                            >
                            </i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ]

    const fetchAllTimeZone = async () => {
        // Define to get data i.e
        // type=0: all active records
        // type=1: all non-active records
        // type=2: all  records
        const type = 2
        const response = await fetchTimeZone(type, adminToken)
        if (response.status) {
            let zones = response.result
            setTimeZones(zones)
        }
    }

    useEffect(() => {
        fetchAllTimeZone()
    }, [auxToFetch])

    const chnageStatus = async (data, onClose) => {
        const response = await updateTimeZoneStatus(data, adminToken)
        if (response.status) {
            console.log(response.message)
            toast.success(response.message, {...toastConfig, containerId: 'api'})
            onClose()
            setAuxToFetch(!auxToFetch)
        }
        else {
            toast.error(response.message, {...toastConfig, containerId: 'api'})
        }
    }
    const deleteID = (id, status) => {
        console.log({id: id, status: status})
        const statusValue = status === 'active' ? 'In-Active' : 'Active'
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <div className="fa-3x">
                            <i className="fa-solid fa-paw fa-bounce"></i>
                        </div>
                        <h3>Are you sure want to {statusValue} ?</h3>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={() => {
                                const data = {id: id, status: status}
                                chnageStatus(data, onClose)
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={onClose}
                        >
                            No
                        </button>
                    </div>
                )
            },
            closeOnClickOutside: false,
        })
    }
    //-----Search Filter Starts-----//
    const [filterText, setFilterText] = useState('')
    const filteredItems = timeZones.filter(
        (item) =>
            item.timezone_name &&
            item.timezone_name.toLowerCase().includes(filterText.toLowerCase())
    )

    const onFilterData = (e) => {
        setFilterText(e.target.value)
    }
    const onFilterClear = () => {
        setFilterText('')
    }
    //-----Search Filter Ends-----//

    const btnStatus = true
    const BtnContent = () => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="tooltip-disabled">Click to add Time Zone</Tooltip>
                }
            >
                <button
                    className="btn btn-primary mb-2"
                    onClick={() => {
                        setShowZone(true)
                    }}
                >
                    Add Time Zone
                </button>
            </OverlayTrigger>
        )
    }

    const datatableProps = {
        filterText: filterText,
        onFilterData: onFilterData,
        onFilterClear: onFilterClear,
        columns: columns,
        filteredItems: filteredItems,
        btnStatus: btnStatus,
        BtnContent: BtnContent,
    }
    const timeModalProps = {
        showZone: showZone,
        setShowZone: setShowZone,
        auxToFetch: auxToFetch,
        setAuxToFetch: setAuxToFetch,
    }
    return (
        <>
            <DataTableSetup {...datatableProps} />
            <AddTimeZoneModal {...timeModalProps} />
        </>
    )
}
export default TimeZone
