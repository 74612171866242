import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import { makePayment,addNewDevice,checkPromoCode } from "../../../helpers/deviceHelper";
import { createPaymentMethod,confirmCardPayment } from "../../../helpers/stripeHelper";
import useAuthStore from '../../../store/useAuthStore'

const containerStyles = {
    border: '1px solid #fff',
    padding: '8px 10px 8px 10px',
    borderRadius: '4px',
    width: '100%',
    marginBottom: '15px',
    backgroundColor: '#fff',
};
const cardNumberElementOptions = {
    style: {
        base: {
            fontSize: '14px',
            lineHeight: '35px',
        },
    },
    placeholder: 'Card Number',
};
const DeviceConfirm = (props) => {
    const {
      userToken,
      updateStep,
      registerInfo,
      planInfo,
      petInfo,
      billInfo,
      userInfo,
      selectedFile,
      updateLoader,
      updateDeviceModal,
      updateFetch,
    } = props;
    const stripe = useStripe();
    const elements = useElements();
    const previous_step = () => {
        updateStep(3);
    }

    const toastConfig = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };

    const handlePurchase = async (data, promoCode) => {
      try {

        const paymentType = {
          type: "card",
          card: elements.getElement(CardNumberElement),
        };

        const paymentMethod = await createPaymentMethod(stripe, paymentType);
        const paymentMethodID = paymentMethod.paymentMethod.id;
        const paymentData = {
          name: data.name,
          email: data.email,
          priceID: data.priceID,
          address: data.address,
          paymentMethod: paymentMethodID,
          promoCode: promoCode,
          paymentProcessType:1,
            iccid: registerInfo.device_id
        };
        const response = await makePayment(paymentData);
        // console.log(response);
        if (response.status === true) {
          const clientSecret = response.clientSecret;
          if (clientSecret) {
              const confrimPaymentData = {
                  clientSecret: clientSecret,
                  paymentMethod: {payment_method: paymentMethodID},
              }

              const {error, paymentIntent} = await confirmCardPayment(
                  stripe,
                  confrimPaymentData
              )
              // console.log(error);
              // console.log(paymentIntent);

              if (error) {
                  return {status: false, message: 'Payment failed'}
              }
              else if (paymentIntent && paymentIntent.status === 'succeeded') {
                  // console.log("Payment succeeded");
                  return {status: true, message: 'Payment Success!', subscription: response.subscription}
              }
              else {
                  // console.log("Payment failed");
                  return {status: false, message: 'Payment failed'}
              }
          } else {
              return {status: true, message: 'Payment Success!', subscription: response.subscription}
          }
        } else {
          return { status: false, message: "Payment failed!" };
        }
      } catch (error) {
        // console.log(error);
        return { status: false, message: "Payment failed:" + error };
      }
    };

    const formSubmit = async (data) => {
      const cardNumber = elements.getElement(CardNumberElement);
      const cardDate = elements.getElement(CardExpiryElement);
      const cardCVC = elements.getElement(CardCvcElement);

      const isValidNumber = !(cardNumber._empty || cardNumber._invalid);

      const isValidDate = !(cardDate._empty || cardDate._invalid);

      const isValidCVC = !(cardCVC._empty || cardCVC._invalid);

  if (isValidNumber==true && isValidDate==true && isValidCVC==true){
    setCardError(false);
      updateLoader(true);
        const register_data = {
            'account_info': registerInfo,
            'planInfo': planInfo,
            'billInfo': billInfo,
            'userInfo': userInfo,
            'promoCode': promoCode
        };

        let addressDetails;
        if (billInfo.country === 'USA') {
            addressDetails = {
                line1: billInfo.street_addr + '  ' + billInfo.apartment,
                postal_code: billInfo.zip_code,
                city: billInfo.usa_city,
                state: billInfo.state,
                // country: billInfo.country,
                country: 'US'
            }
        }
        else {
            addressDetails = {
                line1: billInfo.street_addr + '  ' + billInfo.apartment,
                postal_code: billInfo.postal_code,
                city: billInfo.canada_city,
                state: billInfo.province,
                // country: billInfo.country,
                country: 'CA',
            }
        }
        const result = {
            'name': `${useAuthStore.getState().firstName} ${useAuthStore.getState().lastName}`,
            'email': useAuthStore.getState().email,
            'priceID': planInfo.priceID,
            'address': addressDetails,
            // "planId":planInfo._id,
        };

        const payment_details = await handlePurchase(result, promoCode);

        if (payment_details.status === false) {
            toast.error(payment_details.message,{...toastConfig, containerId: 'api'});
            updateStep(6);
            updateLoader(false);
        }
        else {
        // Add New Tracker when payment get Success
            const response =await addNewDevice(userToken, {...register_data, subscriptionId: payment_details.subscription.id})
            if(response.status){
                const result = response.payment_data;
                toast.success(response.message,{...toastConfig, containerId: 'api'});
                updateLoader(false);
                updateFetch(true);
                updateStep(5);
            }else{
                toast.success(response.data.message,{...toastConfig, containerId: 'api'});
                updateLoader(false);
                updateStep(6);
            }
        }
      }else{
        setCardError(true);
          }
    };

    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm({
        mode: 'all',

    });
    const cardElementOptions = {
        style: {
            base: {
                color: '#666',
                border: '1px solid #ccc',
                fontSize: '12px',

            },
            invalid: {
                color: '#fa755a',
                fontSize: '12px',
            }
        }
    }
    useEffect(() => {
        const register_data = {
            'account_info': registerInfo,
            'petInfo': petInfo,
            'planInfo': planInfo,
            'billInfo': billInfo,
            'userInfo': userInfo,
            'selectedFile': selectedFile
        };
    }, []);

    const [isSubmitting, setIsSubmitting] = useState(true);
    const [isCodeBtnValid, setIsCodeBtnValid] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [codeMessage, setCodeMessage] = useState('');
    const [cardError, setCardError] = useState(false);
    const handleChange = event => {
        setIsSubmitting(isSubmitting => !isSubmitting);
    };

    const promoCodeCheck = async(value) => {
        setIsCodeBtnValid(false);
        const data={'promoCode': value};
        const response =  await checkPromoCode(data,userToken)
        if(response.status){
            const result = response.result;
            // console.log(result);
            if (result.length > 0) {
                setCodeMessage('\u2713  Coupon Applied Successfully');
                setIsCodeBtnValid(true);
                // console.log(result.data[0].coupon.id);
                setPromoCode(result.data[0].coupon.id);
            }
            else {
                setIsCodeBtnValid(false);
                setPromoCode('');
                setCodeMessage('Your Coupon Code is not valid.');

            }
        }else{

        }
    }


const ViewSction = () => {
  return (
    <div className="view-data mb-3 px-3">
      <div className="row mb-3">
        <div className="col-sm-6 col-12">
          <label className="caption-label">Gibi Tracker ID :</label>
        </div>
        <div className="col-sm-6 col-12">
          <label className="value-label">
            {registerInfo.device_id}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-12">
          <label className="caption-label">Subscription Plan :</label>
        </div>
        <div className="col-sm-6 col-12">
          <label className="value-label">
            {planInfo.label} $ {planInfo.amount}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-12">
          <label className="caption-label">Name :</label>
        </div>
        <div className="col-sm-6 col-12">
          <label className="value-label">
            {userInfo.first_name} {userInfo.last_name}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-12">
          <label className="caption-label">Email :</label>
        </div>
        <div className="col-sm-6 col-12">
          <label className="value-label">{userInfo.email}</label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-12">
          <label className="caption-label">Mobile :</label>
        </div>
        <div className="col-sm-6 col-12">
          <label className="value-label">{userInfo.mobile}</label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-12">
          <label className="caption-label">Billing Address :</label>
        </div>

        <div className="col-sm-6 col-12">
          {billInfo.apartment && (
            <p className="value-label mb-0">{billInfo.street_addr}</p>
          )}
          {billInfo.apartment && (
            <p className="value-label mb-0">{billInfo.apartment} </p>
          )}
          {billInfo.address1 && (
            <p className="value-label mb-0">{billInfo.address1}, </p>
          )}
          {billInfo.address2 && (
            <p className="value-label mb-0"> {billInfo.address2} </p>
          )}
          {billInfo.address3 && (
            <p className="value-label mb-0"> {billInfo.address3} </p>
          )}
          {billInfo.state && (
            <p className="value-label mb-0">
              {billInfo.usa_city != ""
                ? billInfo.usa_city
                : billInfo.canada_city}
              , {billInfo.state}
            </p>
          )}
          {billInfo.zip_code && (
            <p className="value-label mb-0"> {billInfo.zip_code}</p>
          )}
          {billInfo.postal_code && (
            <p className="value-label mb-0"> {billInfo.postal_code}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const actionButtons = (
  <ul className="list-inline w-100 d-flex align-items-center mb-0">
    <li className="list-inline-item m-0 pe-2 ms-auto">
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={previous_step}
      >
        Previous
      </button>
    </li>
    <li className="list-inline-item m-0 ps-2">
      <button
        type="submit"
        className="btn btn-outline-primary"
        disabled={isSubmitting}
      >
        Purchase
      </button>
    </li>
  </ul>
);

    return (
      <>
        <form method="POST" onSubmit={handleSubmit(formSubmit)}>
          <h3 className="panel-title text-center mb-3">Confirm and Payment Info</h3>
          <ViewSction />
          <div style={containerStyles}>
            {/* <CardElement options={cardNumberElementOptions}/> */}
            <div className="card-details mb-2">
              <p className="fw-bold">Enter Credit Card</p>
              <div className="row">
                <div className="col-xl-4 col-lg-4  col-md-4 col-12 mb-2">
                  <label className='mb-0'>Card Number :</label>
                  <CardNumberElement options={cardElementOptions} />
                </div>

                <div className="col-xl-4 col-lg-4  col-md-4 col-12 mb-2">
                  <label className='mb-0'>CVC :</label>
                  <CardCvcElement options={cardElementOptions} />
                </div>

                <div className="col-xl-4 col-lg-4  col-md-4 col-12 mb-2">
                  <label className='mb-0'>Expiration Date :</label>
                  <CardExpiryElement options={cardElementOptions} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-8">
                <input
                  type="text"
                  className={
                    "form-control mb-2" +
                    (errors.first_name ? " is-invalid" : "")
                  }
                  name="promoCode"
                  {...register("promoCode")}
                  placeholder="Enter Your Coupon Code"
                  // onChange={(e) => setPromoCode(e.target.value)}
                  onChange={(e) => {
                    setPromoCode(e.target.value)
                    if(e.target.value===""){
                      setCodeMessage(" ");
                      setIsCodeBtnValid(false)
                    }
                  }}
                />
                <p className={isCodeBtnValid === true ? "msg-green" : "msg-red"}>
                  {codeMessage}
                </p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                      if (promoCode) {
                          promoCodeCheck(promoCode)
                      }
                  }}
                  disabled={isCodeBtnValid || !promoCode}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="form-check form-check-inline custom-check check-change mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="630f2195c0799f4e093cec1d"
              name="terms"
              value={isSubmitting}
              onChange={handleChange}
            />
            <label
              className="form-check-label clearfix "
              for="630f2195c0799f4e093cec1d"
            >
              I acknowledge receipt of the <a href={'https://getgibi.com/legal/terms-of-service/'}>Terms of Service</a> and that
              subscription service fees are not refundable.
            </label>
          </div>
          {cardError && <p className="msg-red">Error: Please enter valid card details</p> }
          {actionButtons}
        </form>
      </>
    );
};
export default DeviceConfirm;
