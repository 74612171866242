import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import {toast, ToastContainer, Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuthStore from '../store/useAuthStore';

const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL;

const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\|]{2,}))$/)


const schema = yup
    .object()
    .shape({
        email: yup
            .string()
            // .matches(/^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/, 'Invalid Email address.')
            .trim()
            .required("Email is required")
            .test(
                "Validate Email",
                'Not a valid email address',
                (value) => {
                    const emails = value.split('|')
                    let valid = true
                    emails.forEach(e => {
                        if (valid) {
                            valid = emailRegex.test(String(e).toLowerCase())
                        }
                    })
                    return valid
                },
            ),
            // .matches(emailRegex,"Invalid email"),
        password: yup
            .string()
            .min(6, 'Password must be at least 6 characters.')
            .max(20, 'Password must be at most 20 characters.')
            .required('Password is required.'),
    })
    .required();

const Login = ({updateLoader}) => {
    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        control,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        // defaultValues: registerInfo
    });

    const formSubmit = async (data) => {
        const {email, password} = data;
        updateLoader(true);
        try {
            const response = await axios({
                method: 'post',
                url: REACT_APP_BE_URL + '/api/v1/auth/local',
                data: {'email': email, 'password': password},
            })
            if (response.status === 200) {
                console.log(response.data);

                // localStorage.setItem('userToken', response.data.token);
                // localStorage.setItem('user_details', JSON.stringify(response.data.userDetails));

                if (!response.data.unpaidDeviceID || response.data.unpaidDeviceID.length === 0) {
                    setTimeout(() => {
                        updateLoader(false);
                        navigate('/dashboard', {state: {
                            data: response.data
                        }
                    });
                        window.location.reload();
                    }, 1000);

                    // toast.success(response.data.message, {
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     containerId: 'api',
                    // });
                }
                else {
                    updateLoader(false);
                    let device_id = response.data.unpaidDeviceID;
                    let repay_link = '/re-payment?di=' + device_id[0];
                    // navigate(repay_link);

                    navigate(repay_link, {state: {
                        data: response.data
                    }
                });
                }
            }
            else {
                toast.error(response.data.message, {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    containerId: 'api',
                });
                updateLoader(false);
            }
        } catch (e) {
            toast.error('Login error', {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'api',
            });
            updateLoader(false);
        }
    }
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

    const emailId = (
        <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
            <div className="form-group">
                <label className="text-white">Email</label>
                <div className="input-group white">
                                                <span className="input-group-text">
                                                  <i className="fa fa-user" aria-hidden="true"/>
                                                </span>
                    <input
                        type="text"
                        className={
                            'form-control white black-text' + (errors.email ? ' is-invalid' : '')
                        }
                        {...register('email')}
                        placeholder="Enter Your Email"
                        autoComplete="off"
                    />
                </div>
                <p className="errors">
                    {errors.email?.message ||errors.label?.message}
                </p>
            </div>
        </div>
    )

    const password = (
        <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
            <div className="form-group">
                <label className="text-white">Password</label>
                <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-lock" aria-hidden="true"
                         onClick={togglePassword}/>
                    </span>
                    <input
                        // type="password"
                        type={showPassword ? 'text' : 'password'}
                        className={
                            'form-control white black-text' + (errors.password ? ' is-invalid' : '')
                        }
                        {...register('password')}
                        placeholder="Enter Your Password"
                        autoComplete="off"
                    />
                    <span className={'input-group-text'}>
                      <i className={`fa ${!showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                         aria-hidden="true" onClick={togglePassword}/>
                    </span>
                </div>
                <p className="errors">
                    {errors.password?.message ||
                        errors.password?.label.message}
                </p>
            </div>
        </div>
    )

    const forgotPassword = (
        <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
            <div className="form-group clearfix">
                <Link
                    to="/forgot-password"
                    target="_blank"
                    className="forgot-pwd"
                >
                    Forgot Password?
                </Link>
            </div>
        </div>
    )

    const createAccountButton = (
        <li className="list-inline-item m-0 pe-2">
            <button
                type="button"
                className="btn btn-outline-primary white w-100"
            >
                <Link
                    to="/user-register"
                    target="_self"
                    className="text-white text-decoration-none"
                >
                    Create Account
                </Link>
            </button>
        </li>
    )

    const loginButton = (
        <li className="list-inline-item m-0 ps-2">
            <button type="submit" className="btn btn-outline-primary white w-100">
                Login
            </button>
        </li>
    )

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4"></div>
            </div>
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0 login-img-holder">
                    <div className="login-bg"></div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0">
                    <div className="login-holder d-flex align-items-center">
                        <div className="login-container">
                            <div className="logo">
                                <img src={require('../images/logo-white_.png')} alt="gibi"/>
                            </div>
                            <h3 className="panel-title text-center text-white">Login</h3>
                            <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                                <div className="row">
                                    {emailId}
                                    {password}
                                </div>
                                {forgotPassword}
                                <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
                                    <div className="form-group my-5">
                                        <ul className="list-inline w-100 d-flex align-items-center">
                                            {createAccountButton}
                                            {loginButton}
                                        </ul>
                                    </div>
                                </div>

                            </form>
                            <p className="version-detials">1.1.2</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
