import {get} from './apiHelper';
import { DateTime, Settings } from 'luxon'
import useAuthStore from '../store/useAuthStore'

export const getPetHistory = async (iccid, selectedDate, userToken) => {
    let response = {}
    try {
        if (!selectedDate) {
            const date = `${DateTime.now().setZone(useAuthStore.getState().timezone.value)/*.toISODate()*/}T00:00+00:00`
            response = await get(`/api/v1/position/history/today/${date}/${iccid}`, userToken)
        }
        else {
            // console.log(selectedDate)
            response = await get(`/api/v1/position/history/${selectedDate}/${iccid}`, userToken)
        }
        if (response.status === 200) {
            return response.data
        }
    } catch (e) {
        console.log('getPetHistory', e)
    }
    return []
};
