import {Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import useAuthStore from '../../../store/useAuthStore';
import usePetsDevicesStore from '../../../store/usePetsDevicesStore';
import {TimeIntervalSelect} from '../components/TimeIntervalSelect';
import { GibiSelect } from '../components/GibiSelect';

const _SettingsForm = (props) => {
    const {
        iccid = '',
        checkInRateOptions = [],
        onChange = () => {},
        extraHeader
    } = props

    const devices = usePetsDevicesStore((s) => s.devices)

    const [curIccid, setCurIccid] = useState(iccid)
    const [curSettings, setCurSettings] = useState({})
    const [curBGI, setCurBGI] = useState(null)

    const userToken = useAuthStore((s) => s.token)

    useEffect(() => {
        if (iccid) {
            const d = devices[iccid]
            if (d) {
                setCurIccid(iccid)
                setCurSettings({...d.status, iccid: d.iccid, name: d.deviceName})
                setCurBGI(d.status.backgroundInterval)
            }
        }
    }, [iccid])

    const changeDevice = (iccid) => {
        setCurIccid(iccid);
        onChange('device', iccid)
    }

    const changeBackgroundInterval = (type, bgi) => {
        setCurBGI(bgi.seconds);
        onChange('bgi', bgi.seconds)
    }

    return (<>
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">Settings</Modal.Title>
                </Modal.Header>
                {extraHeader}
                <Modal.Body className="scrollbar overflow-unset">
                    <div className="row">
                        <div className="col-lg-12">
                            <GibiSelect devices={devices} iccid={curIccid} onChange={changeDevice}/>
                        </div>
                        <div className="col-lg-12">
                            <TimeIntervalSelect label={'Check in Rate'} intervalType={'localRate'} allOptions={checkInRateOptions}
                                                seconds={curBGI} onChange={changeBackgroundInterval}/>
                        </div>
                    </div>
                </Modal.Body>
        </>
    )
}

export const SettingsForm = _SettingsForm
