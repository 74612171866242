import { React, useEffect, useState } from 'react'
import AdminHeader from '../AdminHeader'
import AdminSidebar from '../AdminSidebar'
import AddICCID from './AddICCID'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DataTableSetup from '../DataTableSetup'
import { chnageIccIdStatus, deviceList } from '../../../helpers/adminHelper/deviceHelper'
import useAdminAuthStore from '../../../store/useAdminAuthStore'
import { toastConfig } from '../adminConfig'

const IccIds = (props) => {
    const {updateLoader, updateLoaderMessage, updateSidebar, isOpenSidebar} =
        props
    const [showModal, setShowModal] = useState(false)
    const [ModalData, setModalData] = useState({
        title: 'Add New ICCID\'s',
        btnName: 'Save',
    })
    const [AddExcel, setAddExcel] = useState(true)
    const [iccIdList, setIccIdList] = useState([])
    const [auxToFetch, setAuxToFetch] = useState(false)
    const [adminToken, setAdminToken] = useState(
        useAdminAuthStore((s) => s.token)
    )
    const [pageTitle, setPageTitle] = useState('DEVICES')
    const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL

    const columns = [
        {
            name: 'ICCID',
            selector: (row) => row.iccid,
            sortable: true,
        },
        {
            name: 'MDN',
            selector: (row) => row.mdn,
            sortable: true,
        },
        {
            name: 'IMSI',
            selector: (row) => row.imsi,
            sortable: true,
        },
        {
            name: 'Carrier',
            selector: (row) => row.carrier,
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: (row) =>
                row.createdAt != undefined ? row.createdAt.split('T')[0] : '-',
            sortable: true,
        },

        {
            name: 'Account Holder',
            selector: (row) => row.accountData.length != 0 ? row.accountData[0]?.first_name : '',
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
                <div>
                    {row.status === 'active' ? (
                        <span style={{color: 'green'}}>Active</span>
                    ) : (
                        <span style={{color: 'red'}}>InActive</span>
                    )}
                </div>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            sortable: false,
            cell: (row) => (
                <div>
                    {/* <button className='icon-button me-3' id="productList"><i className="fa fa-eye"></i> </button> */}
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-disabled">Click to change status.</Tooltip>
                        }
                    >
                        <button className="icon-button">
                            <i
                                className={
                                    row.status === 'active' ? 'fa fa-trash' : 'fa fa-undo'
                                }
                                onClick={() => {
                                    deleteID(row._id, row.status)
                                }}
                            >
                                {' '}
                            </i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ]
    useEffect(() => {
        fetchAllIccIds()
    }, [auxToFetch])
    const activeDeactiveStatus = async (onClose, data) => {
        const response = await chnageIccIdStatus(data, adminToken)
        if (response.status) {
            toast.success(response.message, {...toastConfig, containerId: 'api'})
            onClose()
            setAuxToFetch(!auxToFetch)
        }
        else {
            toast.error(response.message, {...toastConfig, containerId: 'api'})
        }
    }

    const deleteID = (id, status) => {
        const statusValue = status === 'active' ? 'In-Active' : 'Active'
        const data = {iccId: id, status: status}
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <div className="fa-3x">
                            <i className="fa-solid fa-paw fa-bounce"></i>
                        </div>
                        <h3>Are you sure want to {statusValue} ?</h3>
                        {/* <p>You want to delete this file?</p> */}
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={() => {
                                activeDeactiveStatus(onClose, data)
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={onClose}
                        >
                            No
                        </button>
                    </div>
                )
            },
            closeOnClickOutside: false,
        })
    }

    const fetchAllIccIds = async () => {
        updateLoader(true)
        updateLoaderMessage('Please wait..')

        const response = await deviceList()
        if (response.status) {
            let result = response.data

            setIccIdList(result)
        }
        updateLoader(false)
    }
    //-----Search Filter Starts-----//
    const [filterText, setFilterText] = useState('')
    const filteredItems = iccIdList.filter(
        (item) =>
            (item.iccid &&
                item.iccid.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.mdn && item.mdn.toLowerCase().includes(filterText.toLowerCase()))
    )

    const onFilterData = (e) => {
        setFilterText(e.target.value)
    }
    const onFilterClear = () => {
        setFilterText('')
    }
    //-----Search Filter Ends-----//

    const btnStatus = true
    const BtnContent = () => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="tooltip-disabled">Click to add new Gibi Tracker.</Tooltip>
                }
            >
                <button
                    className="btn btn-primary mb-2"
                    onClick={() => {
                        setShowModal(true)
                    }}
                >
                    Add New Tracker
                </button>
            </OverlayTrigger>
        )
    }

    const datatableProps = {
        filterText: filterText,
        onFilterData: onFilterData,
        onFilterClear: onFilterClear,
        columns: columns,
        filteredItems: filteredItems,
        btnStatus: btnStatus,
        BtnContent: BtnContent,
    }

    const addIccIdProps = {
        showModal: showModal,
        setShowModal: setShowModal,
        ModalData: ModalData,
        auxToFetch: auxToFetch,
        setAuxToFetch: setAuxToFetch,
    }

    return (
        <>
            <div className="page-wrapper chiller-theme toggled">
                <AdminSidebar
                    isOpenSidebar={isOpenSidebar}
                    updateSidebar={updateSidebar}
                />
                <main className="page-content ">
                    <AdminHeader pageTitle={pageTitle} isOpenSidebar={isOpenSidebar}/>
                    <main
                        className={
                            'content-holder ' + (isOpenSidebar != true ? 'show' : '')
                        }
                    >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-wrapper">
                                        <DataTableSetup {...datatableProps} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AddICCID {...addIccIdProps} />
                </main>
            </div>
        </>
    )
}
export default IccIds
