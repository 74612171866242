import Select from 'react-select';

const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        };
    },
};

export const TimeIntervalSelect = (props) => {
    const {intervalType, allOptions, seconds, onChange, label, disabled} = props

    let typeOptions = allOptions.filter(o => o.checkInRateType === intervalType && o.status)
    typeOptions = typeOptions.sort((a, b) => a.seconds > b.seconds ? 1 : -1)
    const selectOptions =  typeOptions.map((item) => ({value: item._id, label: item.checkRate}));
    const curId = typeOptions.find(o => Number(o.seconds) === Number(seconds))?._id
    const curVal = selectOptions.find(o => o.value === curId)

    const changeTimeInterval = (val) => {
        const update = typeOptions.find(o => o._id === val.value)
        onChange(intervalType, update)
    }

    // console.log('time', intervalType, disabled)

    return (
        <div className="setting-device form-group">
            <label htmlFor="exampleInputEmail1" className="form-label ">
                {label}
            </label>

            <div className="custom-dropdown">
                <Select
                    options={selectOptions}
                    styles={customStyles}
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    value={curVal}
                    onChange={changeTimeInterval}
                    placeholder={
                        <div className="Select Species">
                            Select {label}
                        </div>
                    }
                    isDisabled={disabled}
                />
            </div>
        </div>
    )
}
