import {Accordion} from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import usePetsDevicesStore from '../../../store/usePetsDevicesStore'
import '../PetContainer.css'
import useUIStore from '../../../store/useUIStore'
import { useNavigate } from 'react-router-dom'

export const ContainerHeader = (props) => {
    const {pet, iccid,isOpenSidebar} = props
    const devices = usePetsDevicesStore((s) => s.devices)
    const [inProgress, setInProgress] = useState(false)
    const [inZone, setInZone] = useState('')
    const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)
    const [hasDevice, setHasDevice] = React.useState(false)
    const navigate = useNavigate()

    let petIconColor = pet.iconColorName.length ? pet.iconColorName[0].iconColorName : 'aqua';
    const petStyle = {
        borderColor: petIconColor,
        borderWidth: '3px'
    };

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            if (device.itemId) {
                setHasDevice(true)
            }
            setInProgress(device.status.deviceCommandInProgressStatus !== '')
            setInZone(device.status.occupiedZone)
        }
    }, [devices])

    const subTitle = !iccid ?
        <h6 className="no-device ms-3"><i className="fa-solid fa-triangle-exclamation"></i> ( No Gibi )</h6>
        : inProgress ? <h5 className="command-in-progress ms-3">Gibi command in progress</h5> :
            inZone !== '' ? <div className={'safe-zone-label'}>{`In ${inZone}`}</div> :
                <div className={'safe-zone-label'}>{`Out of all safe zones`}</div>

    const handleClick = () => {
        setIsOpenSidebar(false);
        const path = window.location.pathname
        if (path !== '/dashboard') {
            navigate('/dashboard')
        }
    }

    return (
        <Accordion.Header>
            <div className={"d-flex align-items-center flex-grow-1 " + (isOpenSidebar ? "me-3" : "")}
             onClick={handleClick}
                 style={!hasDevice ? {opacity: 0.5} : {} }
            >
                <div className="dog-bio" style={petStyle}>
                    <img src={pet.imageURL} alt=""/>
                </div>
                {isOpenSidebar===false?
                <div   className="d-flex flex-column ">
                    <div>
                        <h4 className="dog-name ms-3 bottom-4">{pet.petName} </h4>
                    </div>
                    <div>
                        {subTitle}
                    </div>
                </div>
                    :null}
            </div>
        </Accordion.Header>
    )
}
