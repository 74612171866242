import React, {useEffect, useState} from 'react'

import {Modal} from 'react-bootstrap'
// import '../Date.js'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {RepeatSleepForm} from './forms/RepeatSleepForm'
import {SingleSleepForm} from './forms/SingleSleepForm'
import useUIStore from '../../store/useUIStore'
import usePetsDevicesStore from '../../store/usePetsDevicesStore'
import useAuthStore from '../../store/useAuthStore'
import {SelectSleepForm} from './forms/SelectSleepForm'
import {ConfiguredSleepForm} from './forms/ConfiguredSleepForm'
import {convertFormValuesToGibiSettings, convertGibiSettingsToFormValues} from './helpers/sleepHelpers'
import { DateTime, Settings } from 'luxon'
import {ConfirmForm} from './forms/ConfirmForm';
import {sendDeviceCommand} from '../SettingsModal/helpers/commandHelpers';
import {toast} from 'react-toastify';
import {checkIfCommandInProgress} from '../../helpers/commandHelpers';
import './SleepModal.css'

// TODO: needs a state cleanup!!

// sleep modal is in one of 6 states
//
// discover - load the device status to determine the next state
// select - not sleeping, not configured
// single - configure single sleep
// repeat - configure repeat sleep
// ready  - sleep configured/pending
// confirm - confirmation of change is needed
//
const SleepModeModal = (props) => {
    const [sleepState, setSleepState] = useState('discover')
    const [curIccid, setCurIccid] = useState('')
    const [command, setCommand] = useState('')
    const [commandVal, setCommandVal] = useState(0)

    const [canSave, setCanSave] = useState(false)
    const [isCommandInProgress, setIsCommandInProgress] = useState(false)
    const [isBeaconInRange, setIsBeaconInRange] = useState(false)
    const [isInCharger, setIsInCharger] = useState(false)
    const [isSleeping, setIsSleeping] = useState(false)

    const [sleepStartDate, setSleepStartDate] = useState('')
    const [sleepEndDate, setSleepEndDate] = useState('')
    const [sleepStartTime, setSleepStartTime] = useState('00:00')
    const [sleepEndTime, setSleepEndTime] = useState('00:00')
    const [isRepeat, setIsRepeat] = useState(false)
    const [formChange, setFormChange] = useState({})

    const _iccid = useUIStore((s) => s.currentIccid)
    const _devices = usePetsDevicesStore((s) => s.devices)
    const _userToken = useAuthStore((s) => s.token)

    const _isSleepModeModalOpen = useUIStore((s) => s.showSleepModal)
    const setSleepModal = useUIStore((s) => s.setSleepModal)

    useEffect(() => {
        if (curIccid) {
            const s = loadDeviceState(curIccid)
            setSleepState(s)
        }
    }, [])

    useEffect(() => {
        if (_iccid) {
            setCurIccid(_iccid)
            const s = loadDeviceState(_iccid)
            setSleepState(s)
        }
    }, [_iccid])

    useEffect(() => {
        if (isRepeat) {
            if (sleepStartTime !== '00:00' && sleepEndTime !== '00:00') {
                setCanSave(validateTime(sleepStartTime, sleepEndTime))
            }
        }
        else {
            if (sleepStartTime !== '00:00' && sleepEndTime !== '00:00' &&
                sleepStartDate !== '' && sleepEndDate !== '') {
                // todo: validate
                setCanSave(true)
            }
        }
    }, [sleepEndTime, sleepStartTime, sleepEndDate, sleepStartDate])

    useEffect(() => {
        if (formChange) {
            const {type, value} = formChange
            switch (type) {
                case 'startTime':
                    setSleepStartTime(value)
                    break
                case 'endTime':
                    setSleepEndTime(value)
                    break
                case 'startDate':
                    setSleepStartDate(value)
                    break
                case 'endDate':
                    setSleepEndDate(value)
                    break
                default:
                    break
            }
            if (isRepeat) {
                if (isRepeat) {
                    if (sleepEndTime !== '00:00' && sleepStartTime !== '00:00') {
                        setCanSave(validateTime(sleepStartTime, sleepEndTime))
                    }
                }
            }
            else {
                const sav = sleepEndTime !== '00:00' && sleepStartTime !== '00:00' && sleepStartDate !== '' && sleepEndDate !== ''
                setCanSave(sav)
            }
        }
    }, [formChange])

    const validateTime = (start, end) => {
        return DateTime.fromJSDate(start, {zone: useAuthStore.getState().timezone.value}).toMillis() < DateTime.fromJSDate(end).toMillis()
    }

    const loadDeviceState = (iccid) => {
        let sleepState = 'unknown'
        const d = _devices[iccid]
        if (d) {
            setIsCommandInProgress(checkIfCommandInProgress(d.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(d.status.beaconInRange)
            setIsInCharger(d.status.charging)
            sleepState = 'select'
            if (d.status.sleeping) {
                setIsSleeping(true)
                sleepState = 'ready'
            }
            if (d.status.sleepStart) {
                const {startTime, endTime, startDate, endDate} = convertGibiSettingsToFormValues(d.status)
                setIsRepeat(d.status.sleepRepeat)
                setSleepStartDate(startDate)
                setSleepEndDate(endDate)
                setSleepStartTime(startTime)
                setSleepEndTime(endTime)
                sleepState = 'ready'
            }
        }
        return sleepState;
    }

    const unloadDeviceState = () => {
        setCurIccid('')
        setIsCommandInProgress(false)
        setIsBeaconInRange(false)
        setIsInCharger(false)
        setIsSleeping(false)
        setIsRepeat(false)
        setSleepStartDate('')
        setSleepEndDate('')
        setSleepStartTime('00:00')
        setSleepEndTime('00:00')
        setSleepState('discover')
    }

    const closeModal = () => {
        unloadDeviceState()
        setSleepModal(false)
    }

    const updateSetting = (key, value) => {
        switch (key) {
            case 'device':
                setCurIccid(value)
                if (value) {
                    const d = _devices[value]
                    if (d) {
                        setIsCommandInProgress(checkIfCommandInProgress(d.status.deviceCommandInProgressStatus))
                        setIsBeaconInRange(d.status.beaconInRange)
                        setIsInCharger(d.status.charging)
                    }
                }
                break
            case 'repeat':
                setIsRepeat(value === 1 ? true : false)
                break;
            case 'sleep':
                const {
                    startEpoch,
                    duration
                } = convertFormValuesToGibiSettings(sleepStartDate, sleepEndDate, sleepStartTime, sleepEndTime, isRepeat)
                setCommandVal({
                    startTime: startEpoch,
                    duration,
                    repeat: isRepeat,
                })
                setCommand('sleep')
                setSleepState('confirm')
                break
            case 'cancelSleep':
                setCommand('cancelSleep')
                setCommandVal(value)
                setSleepState('confirm')
                break
            case 'configure':
                isRepeat ? setSleepState('repeat') : setSleepState('single')
                break;
            case 'cancelConfig':
                closeModal()
                break;

            default:
                break
        }
    }

    const onCancel = () => {
        closeModal()
    }

    const getConfirmMessage = () => {
        let msg = `missing message ${command}`;
        if (command === 'sleep') {
            msg = 'send sleep command'
        }
        if (command === 'cancelSleep') {
            msg = 'cancel the configured sleep'
        }
        return msg
    }

    const sendCommand = async () => {
        const data = {iccid: curIccid, command, value: commandVal}
        const response = await sendDeviceCommand(data, _userToken)
        closeModal()

        if (response.data?.status) {
            toast.success(response.data.message, {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'api',
            })
        }
        else {
            toast.info('Command is in queue. Please wait', {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                containerId: 'api',
            })
        }
    }

    const cancelSleepButton = (
        <button
            type="button"
            onClick={() => {
                updateSetting('cancelSleep', true);
            }}
            className="btn btn-outline-primary min-width-120"
            id="advanceSettingModalBtn"
            data-dismiss="modal"
            data-target="#settingModal"
            disabled={isCommandInProgress || (isBeaconInRange && !isInCharger) || isSleeping}
        >
            Cancel pending sleep
        </button>
    )

    const cancelSetButton = (
        <button
            type="button"
            onClick={() => {
                updateSetting('cancelConfig', true);
            }}
            className="btn btn-outline-primary min-width-120"
            id="advanceSettingModalBtn"
            data-dismiss="modal"
            data-target="#settingModal"
            disabled={false}
        >
            Cancel
        </button>
    )

    const saveButton = (
        <div>
            <span>
                <button
                    className="btn btn-outline-primary min-width-120"
                    disabled={isCommandInProgress || (isBeaconInRange && !isInCharger) || isSleeping || !canSave}
                    onClick={() => updateSetting('sleep', {
                        sleepStartDate,
                        sleepEndDate,
                        sleepStartTime,
                        sleepEndTime,
                        isRepeat
                    })}
                >
                    Sleep
                </button>
            </span>
        </div>
    )

    const configureButton = (
        <div>
            <span>
                <button
                    className="btn btn-outline-primary min-width-120"
                    disabled={false || isSleeping || (isBeaconInRange && !isInCharger) || isCommandInProgress}
                    onClick={() => updateSetting('configure')}
                >
                    Configure Sleep
                </button>
            </span>
        </div>
    )

    const selectFooter = (
        <Modal.Footer>
            <ul className="list-inline w-100">
                <li className="list-inline-item float-start">
                    {cancelSetButton}
                </li>
                <li className="list-inline-item float-end">
                    {configureButton}
                </li>
            </ul>
        </Modal.Footer>
    )

    const saveFooter = (
        <Modal.Footer>
            <ul className="list-inline w-100">
                <li className="list-inline-item float-start">
                    {cancelSetButton}
                </li>
                <li className="list-inline-item float-end">
                    {saveButton}
                </li>
            </ul>
        </Modal.Footer>
    )

    const readyFooter = (
        <Modal.Footer>
            <ul className="list-inline w-100">
                <li className="list-inline-item float-start">
                    {cancelSetButton}
                </li>
                <li className="list-inline-item float-end">
                    {cancelSleepButton}
                </li>
            </ul>
        </Modal.Footer>
    )

    const sleepingHeader = (
        <div className="warning">
            <Modal.Title id="modal-sizes-title-lg">Cannot change settings. Gibi is Sleeping</Modal.Title>
        </div>
    )

    const commandInProgressHeader = (
        <div className="warning">
            <Modal.Title id="modal-sizes-title-lg">Cannot change settings. A Gibi command is in progress</Modal.Title>
        </div>
    )

    const beaconInRangeHeader = (
        <div className="warning">
            <Modal.Title id="modal-sizes-title-lg">Cannot change settings. Gibi is in range of a beacon.</Modal.Title>
        </div>
    )

    const ssdFn = (v) => {
        setFormChange({type: 'startDate', value: v})
    }

    const sedFn = (v) => {
        setFormChange({type: 'endDate', value: v})
    }

    const sstFn = (v) => {
        setFormChange({type: 'startTime', value: v})
    }

    const setFn = (v) => {
        setFormChange({type: 'endTime', value: v})
    }

    if (sleepState === 'discover' && !curIccid) {
        if (_iccid) {
            setCurIccid(_iccid)
            const s = loadDeviceState(_iccid)
            setSleepState(s)
        }
    }

    const extraHeader = isSleeping ? sleepingHeader : isCommandInProgress ? commandInProgressHeader : (isBeaconInRange && !isInCharger) ? beaconInRangeHeader : null

    let displayedForm = null
    let displayedFooter = null
    switch (sleepState) {
        case 'select':
            displayedForm = <SelectSleepForm
                iccid={curIccid}
                onChange={updateSetting}
                isRepeat={isRepeat}
                devices={_devices}
                extraHeader={extraHeader}
            />
            displayedFooter = selectFooter
            break
        case 'single':
            displayedForm = <SingleSleepForm
                iccid={curIccid}
                onChange={updateSetting}
                startDate={sleepStartDate}
                setStartDate={ssdFn}
                endDate={sleepEndDate}
                setEndDate={sedFn}
                startTime={sleepStartTime}
                setStartTime={sstFn}
                endTime={sleepEndTime}
                setEndTime={setFn}
                extraHeader={extraHeader}
            />
            displayedFooter = saveFooter
            break
        case 'repeat':
            displayedForm = <RepeatSleepForm
                iccid={curIccid}
                onChange={updateSetting}
                startTime={sleepStartTime}
                setStartTime={sstFn}
                endTime={sleepEndTime}
                setEndTime={setFn}
                extraHeader={extraHeader}
            />
            displayedFooter = saveFooter
            break
        case 'ready':
            displayedForm = <ConfiguredSleepForm
                iccid={curIccid}
                cancelSleep={onCancel}
                startDate={sleepStartDate}
                endDate={sleepEndDate}
                startTime={sleepStartTime}
                endTime={sleepEndTime}
                isRepeat={isRepeat}
                extraHeader={extraHeader}
            />
            displayedFooter = readyFooter
            break
        case 'confirm':
            displayedForm =
                <ConfirmForm closeModal={closeModal} sendGibiCommand={sendCommand} message={getConfirmMessage()}/>
            break
        default:
            break
    }

    return _isSleepModeModalOpen ? (
        <Modal
            show={true}
            onHide={closeModal}
            aria-labelledby="modal-sizes-title-lg"
            backdrop="static"
            centered
            className="h-100"
        >
            {displayedForm}
            {displayedFooter}
        </Modal>
    ) : null;

};
export default SleepModeModal;
