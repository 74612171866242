import React, { useCallback, useEffect, useRef, useState } from 'react'
import { OverlayView, Polygon } from '@react-google-maps/api'
import * as turf from '@turf/turf'
import { getCenterOfSafeZone } from '../mapHelpers'

const FILL_COLOR = '#3ccbc9'
const STROKE_COLOR = '#3ccbc9'
const EDIT_FILL_COLOR = '#e98585'
const EDIT_STROKE_COLOR = '#d92424'

const SafeZone = (props) => {
    const {
        canEdit,
        canDrag,
        zone,
        searchZoneList,
        polygonType,
        setPolygonType,
        index,
        setNewSafeZoneGeometry,
    } = props

    // Define refs for Polygon instance and listeners
    const zoneRef = useRef(null)
    const listenersRef = useRef([])
    const [path, setPath] = useState([])

    const [strokeColor, setStrokeColor] = useState(STROKE_COLOR)
    const [fillColor, setFillColor] = useState(FILL_COLOR)

    // console.log("Ploy Search*******************************************");
    // console.log(searchZoneList);
    // console.log(polygonType);

    useEffect(() => {
        if (canDrag) {
            setFillColor(EDIT_FILL_COLOR)
            setStrokeColor(EDIT_STROKE_COLOR)
        } else {
            setFillColor(FILL_COLOR)
            setStrokeColor(STROKE_COLOR)
        }
    }, [canDrag])

    useEffect(() => {
        if (zone) {
            // console.log("ZONE");
            const p = zone.zone_coordinates.slice(0)
            p.push(zone.zone_coordinates[0])
            setPath(p)
        }
    }, [zone])
    useEffect(() => {
        if (searchZoneList.length>0 && polygonType==="new") {
            // console.log("NEW-ZONE");
            const p = searchZoneList[0].zone_coordinates.slice(0)
            p.push(searchZoneList[0].zone_coordinates[0])
            setPath(p)
        }
    }, [searchZoneList])

//     useEffect(() => {
//         if(polygonType==="old"){
// alert(zone)
//             if (zone) {
//                 const p = zone.zone_coordinates.slice(0)
//                 p.push(zone.zone_coordinates[0])
//                 setPath(p)
//             }
//         }else{
//             if (searchZoneList.length>0) {
//                 const p = searchZoneList[0].zone_coordinates.slice(0)
//                 p.push(searchZoneList[0].zone_coordinates[0])
//                 setPath(p)
//             }
//         }
//     }, [polygonType])

    const onEdit = useCallback(() => {
        if (zoneRef.current) {
            const nextPath = zoneRef.current
                .getPath()
                .getArray()
                .map((latLng) => {
                    return {lat: latLng.lat(), lng: latLng.lng()}
                })
            console.log(nextPath)
            setPath(nextPath)
            setNewSafeZoneGeometry(nextPath)
        }
    }, [setPath])

    const onLoadPoly = React.useCallback(
        (polygon) => {
            zoneRef.current = polygon
            const path = polygon.getPaths()
            listenersRef.current.push(
                path.addListener('set_at', onEdit),
                path.addListener('insert_at', onEdit),
                path.addListener('remove_at', onEdit)
            )
        },
        [onEdit]
    )

    const onUnmountPoly = React.useCallback(() => {
        listenersRef.current.forEach((lis) => lis.remove())
        zoneRef.current = null
    }, [])

    let polySettings = {
        strokeColor: strokeColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: fillColor,
        fillOpacity: 0.45,
    }

    const getTextWidth = (text, font) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = font || getComputedStyle(document.body).font
        return context.measureText(text).width
    }

    if (!path.length) {
        return null
    }
    const labelPosition = getCenterOfSafeZone(path)
    const labelOffset = getTextWidth(zone.zone_name) / 2

    const divStyle = {
        color: '#3498db',
        fontSize: '12px',
        marginLeft: `-${labelOffset}px`,
    }

    return (<div key={`safe-zone-${index}`}>
        <Polygon
            editable={canEdit}
            draggable={canDrag}
            paths={path}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoadPoly}
            onUnmount={onUnmountPoly}
            options={polySettings}
            zIndex={400}
        />
        <OverlayView
            position={labelPosition}
            mapPaneName={OverlayView.FLOAT_PANE}
        >
            <div style={divStyle}>
                {zone.zone_name}
            </div>
        </OverlayView>
    </div>)
}

export default SafeZone
