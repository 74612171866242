import create from 'zustand';

let store = (set) => ({
    settingsModalOpen: false,
    currentPet: '',
    currentIccid: '',
    showSleepModal: false,
    showSafeZoneModal: false,
    showAlertsModal: false,
    showAccuracy: false,
    showPowerZones: false,
    isOpenSidebar: true,
    showMenu: false,
    webSocketConnected: false,
    inAccount: false,

    setShowMenu: (v) =>
        set((state) => {
            return {
                ...state,
                showMenu: v,
            }
        }),

    setIsOpenSidebar: (v) =>
        set((state) => {
            return {
                ...state,
                isOpenSidebar: v,
            }
        }),

    setSettingModal: (open) =>
        set((state) => {
            return {
                ...state,
                settingsModalOpen: open,
            }
        }),
    setCurrentPet: (pet) =>
        set((state) => {
            return {
                ...state,
                currentPet: pet,
            }
        }),
    setCurrentIccid: (iccid) =>
        set((state) => {
            return {
                ...state,
                currentIccid: iccid,
            }
        }),
    setSleepModal: (val) =>
        set((state) => {
            return {
                ...state,
                showSleepModal: val,
            }
        }),
    setSafeZoneModal: (val) =>
        set((state) => {
            return {
                ...state,
                showSafeZoneModal: val,
            }
        }),
    setAlertsModal: (val) =>
        set((state) => {
            return {
                ...state,
                showAlertsModal: val,
            }
        }),
    setShowPowerZones: (val) =>
        set((state) => {
            return {
                ...state,
                showPowerZones: val,
            }
        }),
    setShowAccuracy: (val) =>
        set((state) => {
            return {
                ...state,
                showAccuracy: val,
            }
        }),
    setWebsocketConnected: (val) =>
        set((state) => {
            return {
                ...state,
                webSocketConnected: val,
            }
        }),

    toggleInAccount: () =>
        set((state) => {
            return {
                ...state,
                inAccount: !state.inAccount,
            }
        }),
});

const useUIStore = create(store);

export default useUIStore;
