import { React, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select, { createFilter } from "react-select";
import PetBreeds from "../../../assets/PetBreeds.xlsx";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import { toastConfig } from "../adminConfig";
import {
  getPetTypesListActive,
  addBreed,
  uploadBreedExcelFile,
} from "../../../helpers/adminHelper/petHelpers";

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      cursor: "pointer",
      padding: "5px",
      fontSize: "14px",
      borderColor: "#fff!important",
      "&:hover": {
        backgroundColor: "#139dcc ",
        color: "#fff",
      },
    };
  },
};
const excelSchema = yup
  .object()
  .shape({
    excelPetType: yup.string().required("Pet Type is required for Excel."),
    excelFile: yup
      .mixed()
      .test("fileRequired", "Excel file is required", (value) => {
        // file_require = 1;//true
        // if(value.length===0 && file_require===1){
        if (value.length === 0) {
          return false;
        } else {
          return true;
        }
      })
      .test(
        "type",
        "Only the following formats are accepted: .xls and xlsx",
        (value) => {
          if (value.length !== 0) {            
            return (
              value &&
              (value[0].type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                value[0].type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            );
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "File too big, can't exceed 3MB", (value) => {
        if (value.length !== 0) {
          return value[0].size <= 3000000;
        } else {
          return true;
        }
      }),
  })
  .required();

const schema = yup
  .object()
  .shape({
    species_type: yup.string().required("Pet Type is required."),
    breed_name: yup
      .string()
      .min(3, "Breed Name must be at least 3 characters.")
      .max(20, "Breed Name must be at most 20 characters.")
      .required("Breed Name is required."),
  })
  .required();
const AddPetBreed = ({
  showModal,
  setShowModal,
  ModalData,
  auxToFetch,
  setAuxToFetch,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });

  const {
    control: excelControl,
    register: excelRegister,
    handleSubmit: excelSubmit,
    setValue: excelValue,
    reset: excelReset,
    formState: { errors: excelErrors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(excelSchema),
    // defaultValues: planInfo
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadSubmitting, setIsUploadSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [petTypeList, setPetTypeList] = useState([]);
  const [petTypeLists, setPetTypeLists] = useState([]);
  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );
  const exSubmit = async (data) => {
    
    const formData = new FormData();
    formData.append("excelFile", selectedFile);
    formData.append("petType", data.excelPetType);
   
    setIsUploadSubmitting(!isUploadSubmitting);
    const response = await uploadBreedExcelFile(formData,adminToken);

    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});

      setIsUploadSubmitting(false);
      setAuxToFetch(!auxToFetch);
      setShowModal(false);
      excelReset();
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
      //   updateLoader(false);
      setIsUploadSubmitting(false);
    }
  };

  const fileUpdate = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const addBreedType = async (data, onClose) => {
    const response = await addBreed(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
      setShowModal(false);
      setIsSubmitting(false);
      reset();
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
      setError(
        "breed_name_error",
        { type: "manual", message: response.message },
        { shouldFocus: true }
      );
      setIsSubmitting(false);
      onClose();
      reset();
    }
  };

  const singleSubmit = async (data) => {
    
    setIsSubmitting(!isSubmitting);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to add ?</h3>
            {/* <p>You want to delete this file?</p> */}
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                addBreedType(data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                setIsSubmitting(false);
                onClose();
              }}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("ExcelGibi.xlsx").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "ExcelGibi.xlsx";
        alink.click();
      });
    });
  };
  const selectPetType = (val) => {
    setValue("species_type", val);
  };
  const selectPetTypeExcel = (val) => {
    setValue("excelPetType", val);
  };

  const fetchPetTypes = async () => {
    const response = await getPetTypesListActive(adminToken);
    if (response.status) {
      let pettypes = response.data;
      setPetTypeList(
        pettypes.map((item) => ({ value: item._id, label: item.species_name }))
      );
      setPetTypeLists(
        pettypes.map((item) => ({ value: item._id, label: item.species_name }))
      );
    }
  };

  useEffect(() => {
    fetchPetTypes();
  }, []);

  return (
    <>
      <Modal
        className="add-new-gibi"
        show={showModal}
        size="lg"
        onHide={() => setShowModal(false)}
        aria-labelledby="modal-sizes-title-lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>

          <Modal.Title id="modal-sizes-title-lg">

            {ModalData.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-device">
          <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
            <li className="nav-item me-2" role="presentation">
              <a
                className="nav-link active"
                id="single-tab"
                data-bs-toggle="tab"
                href="#singleUpload"
                role="tab"
                aria-controls="single"
                aria-selected="true"
              >
                Single Import
              </a>
            </li>
            <li className="nav-item me-2" role="presentation">
              <a
                className="nav-link"
                id="bulk-tab"
                data-bs-toggle="tab"
                href="#bulkUpload"
                role="tab"
                aria-controls="bulk"
                aria-selected="false"
              >
                Bulk Import
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active p-3 border"
              id="singleUpload"
              role="tabpanel"
              aria-labelledby="single-tab"
            >
              <form onSubmit={handleSubmit(singleSubmit)}>
                <div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group my-pets">
                        <label className="form-label text-dark">
                          Pet Type :
                        </label>
                        <div className="custom-dropdown icon-color">
                          <Controller
                            name="species_type"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={petTypeList}
                                styles={customStyles}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                value={petTypeList.find(
                                  (c) => c.value === value
                                )}
                                // value="USA"
                                onChange={(val) => {
                                  onChange(val.value);
                                  selectPetType(val.value);
                                }}
                                placeholder={
                                  <div className="Select Species">
                                    Select Pet Type
                                  </div>
                                }
                              />
                            )}
                          />
                          <p className="errors">
                            {errors.species_type?.message ||
                              errors.species_type?.label.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group my-pets">
                        <label className="form-label text-dark">
                          Breed Name :
                        </label>
                        <div className="input-group white">
                          <span className="input-group-text">
                            <i className="fa fa-hashtag" aria-hidden="true"></i>
                          </span>
                          <input
                            type="text"
                            {...register("breed_name")}
                            className="form-control"
                            placeholder="Enter Breed Name"
                          />
                        </div>
                        {errors.breed_name && (
                          <p className="errors">{errors.breed_name.message}</p>
                        )}
                        {errors.breed_name_error && (
                          <p className="errors">
                            {errors.breed_name_error.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <ul className="list-inline float-end mb-0 mt-5">
                    <li className="list-inline-item ">
                      <button
                        type="submit"
                        className="btn btn-outline-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting === true && (
                          <div
                            className="spinner-border text-info"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          // <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        {/* <button type="submit" className="btn btn-outline-primary" > */}
                        Save
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          reset();
                          setShowModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade p-3 border"
              id="bulkUpload"
              role="tabpanel"
              aria-labelledby="bulk-tab"
            >
              <form
                onSubmit={excelSubmit(exSubmit)}
                encType="multipart/form-data"
              >
                <div className="file-upload">
                  <div className="mb-3 text-center">
                    <p>
                      Please download the XLS template below and upload with all
                      the necessary information.
                    </p>
                    <a
                      href={PetBreeds}
                      download="BreedExcelTemplate"
                      className="btn btn-outline-primary"
                    >
                      <i className="fa fa-download"></i> Download
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="custom-dropdown my-pets">
                        <Controller
                          name="excelPetType"
                          control={excelControl}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={petTypeLists}
                              styles={customStyles}
                              className="react-dropdown"
                              classNamePrefix="dropdown"
                              value={petTypeLists.find(
                                (c) => c.value === value
                              )}
                              // value="USA"
                              onChange={(val) => {
                                onChange(val.value);
                                selectPetTypeExcel(val.value);
                              }}
                              placeholder={
                                <div className="Select Species">
                                  Select Pet Type
                                </div>
                              }
                            />
                          )}
                        />
                        <p className="errors">

                          {excelErrors.excelPetType?.message ||
                            excelErrors.excelPetType?.label.message}
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group my-pets">
                        <div className="file-control">
                          <input
                            className="filestyle form-control"
                            id="picture"
                            {...excelRegister("excelFile")}
                            type="file"
                            onChange={(e) => {
                              fileUpdate(e);
                            }}
                          />
                        </div>
                        {excelErrors.excelFile && (
                          <p className="errors">
                            {excelErrors.excelFile.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="list-inline float-end mb-0 mt-5">
                  <li className="list-inline-item ">
                    <button
                      type="submit"
                      className="btn btn-outline-primary"
                      disabled={isUploadSubmitting}
                    >
                      {isUploadSubmitting === true && (
                        <div className="spinner-border text-info" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      Upload
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        reset();
                        setShowModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPetBreed;
