import Draggable from 'react-draggable'
import * as React from 'react'
import { Card } from 'react-bootstrap'

const WIDTH = '600px'

const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#42b1d6',
    color: 'white',
}

const titleStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}

export const DraggableBox = (props) => {
    const {
        title,
        subTitle,
        onClose = () => {},
        header,
        iconLeft,
        iconRight,
    } = props
    const width = props.width || WIDTH
    return <Draggable defaultPosition={{x: 50, y: 40}}>
        <Card style={{width}}>
            <Card.Body style={{padding: '6px', background: '#149dcd', borderRadius: '5px'}}>
                <Card.Header style={{padding: '4px', background: '#42b1d6'}}>
                    <div style={headerStyle}>
                        <div style={{cursor: 'pointer'}} onClick={onClose}>
                            <i className="fa-solid fa-xmark"/>
                        </div>
                        <div>
                            {header}
                        </div>
                        <div style={{cursor: 'grab'}}>
                            <i className="fa-solid fa-grip-vertical"/>
                        </div>
                    </div>
                </Card.Header>
                <Card.Title style={{marginLeft: '8px', color: 'white'}}>{title}</Card.Title>
                <hr style={{marginBottom: '8px', marginTop: '-8px'}}/>
                <Card.Subtitle onClick={props.onClick} style={{marginLeft: '8px', color: 'white'}}>
                    <div style={titleStyle}>
                        {iconLeft || null}
                        {subTitle}
                        {iconRight || null}
                    </div>
                </Card.Subtitle>
                <hr style={{marginBottom: '2px', marginTop: '4px'}}/>
                <div>
                    {props.children}
                </div>
            </Card.Body>
        </Card>
    </Draggable>
}
