import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../Header.js";
import Sidebar from "./Sidebar.js";
import "../Date.js";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useAuthStore from "../../store/useAuthStore";
import MyPetModal from "./MyPetModal.js";
import { getPets, removePetDetails } from "../../helpers/petHelpers";
import NoDataFound from "../NoDataFound.js";
import { toastConfig } from '../Admin/adminConfig'
import MenuSidebar from '../MenuSidebar';
import useUIStore from '../../store/useUIStore'

function MyPet({ updateLoader, updateSidebar }) {
  const [userToken, setuserToken] = useState(useAuthStore((s) => s.token));
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add New Pet");
  const [showAddNewPet, setShowAddNewPet] = useState(false);
  const [petInfo, setpetInfo] = useState({});
  const [form_type, setform_type] = useState("add");
  const [petId, setPetId] = useState(0);
  const [editPetDetail, setEditPetDetail] = useState({});

  const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
  const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)
  const toastConfig = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const fetchPetDetails = async (userToken) => {
    updateLoader(true);
    const result = await getPets(userToken);
    // console.log(result);
    if (JSON.stringify(result.pets) !== "{}") {
      setpetInfo(result.pets);
    } else {
      setpetInfo({});
    }
    updateLoader(false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (userToken === "" || userToken === null) {
      navigate("/login");
    }
    // console.log("Refresh...");
    fetchPetDetails(userToken); //Fetch All pets ..
  }, [auxToFetch]);

  const addPet = () => {
    setModalTitle("Add Pet");
    setShowAddNewPet(!showAddNewPet);
    setform_type("add");
    setPetId(0);

  };

  const editPet = (values) => {
    // Edit Pet call
    setPetId(values._id);
    setform_type("edit");
    setEditPetDetail(values);
  };

  const removePet = async (id, onClose) => {
    const response = await removePetDetails(userToken, id);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      setAuxToFetch(!auxToFetch);
      onClose();
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };

  const deletePet = (values) => {
    // Remove Pet
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to remove ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                removePet(values._id, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const addNewPetButton = (
    <div className="card-header d-flex align-items-center justify-content-between">
      <h6 className="mb-0">Pets</h6>
      <OverlayTrigger
        placement="auto"
        overlay={<Tooltip id="tooltip-disabled">Click to add new pet.</Tooltip>}
      >
        <button
          className="btn btn-link p-0 text-dark text-decoration-none font-weight-bold"
          style={{ lineHeight: "14px", fontWeight: "600" }}
          onClick={() => {
            addPet();
          }}
          data-toggle="modal"
          data-target="#addNewPet"
        >
          <span className="me-1">
            <i className="fa fa-plus-circle"></i>
          </span>
          Add New Pet
        </button>
      </OverlayTrigger>
    </div>
  );
  const ActionBtn = (props) => {
    const { values } = props;
    return (
      <div>
        <ul className="list-inline quick-icons">
          <li className="list-inline-item">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id="tooltip-disabled">Click to edit pet.</Tooltip>
              }
            >
              <button
                className="btn btn-link"
                onClick={() => {
                  editPet(values);
                }}
              >
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
            </OverlayTrigger>
          </li>
          <li className="list-inline-item">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id="tooltip-disabled">Click to remove pet.</Tooltip>
              }
            >
              <button
                className="btn btn-link"
                onClick={() => {
                  deletePet(values);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
    );
  };

  const PetView = (props) => {
    const { values, deviceName, brithdate, iconColor,colorCode} = props;
    return (
      <div className={width > 767 ? "col-9 col-sm-9" : ""}>
        {/*{deviceName && (*/}
        {/*  <div className="form-group">*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="form-control  ps-2 fw-bold"*/}
        {/*      value={deviceName}*/}
        {/*      disabled*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className="form-group">
          <label>Pet Name</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="bx bxs-dog"></i>
            </span>
            <input
              type="text"
              className="form-control "
              placeholder="Pet Name"
              value={values.petName}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Birth Date</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-calendar" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control "
              placeholder="Select Birth Date"
              id="birthDates"
              value={brithdate}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Pet Type</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-paw" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control "
              placeholder=""
              id="species"
              value={values.species?.species_name}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Breed</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-paw" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control "
              placeholder=""
              id="breed"
              value={values.breed?.breed_name}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Gender</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-venus-mars" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control "
              placeholder=""
              id="gender"
              value={values.gender}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Pet Weight (lbs)</label>
          <div className="input-group white">
            <span className="input-group-text">
            <i className="fa fa-weight-scale" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control "
              // placeholder="Microchip"
              value={values.petWeight}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Icon Color</label>
          <div className="input-group white">
            <span className="input-group-text">
             {colorCode!="" && <i className="fa fa-circle" aria-hidden="true" style={{color:colorCode}}></i>}
            </span>

            <input
              id="primary_color"
              type="text"
              className="form-control "
              placeholder="Icon Color"
              value={iconColor}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Microchip</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-microchip" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className="form-control "
              placeholder="Microchip"
              value={values.microchip}
              disabled
            />
          </div>
        </div>
      </div>
    );
  };

  const PetMobileView = (props) => {
    const { values, deviceName, brithdate, iconColor, imageURL, i, petStyle } =
      props;
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item>
          <Accordion.Header>
            <div className="dog-bio me-3" style={petStyle}>
              <img src={imageURL} alt="gibi" />
            </div>
            <div>{values.petName} </div>
          </Accordion.Header>
          <Accordion.Body>
            <ActionBtn values={values} />
            <PetView {...props} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  let modalProps = {
    showAddNewPet: showAddNewPet,
    setShowAddNewPet: setShowAddNewPet,
    modalTitle: modalTitle,
    updateLoader: updateLoader,
    editPetDetail: editPetDetail,
    form_type: form_type,
    setform_type: setform_type,
    petId: petId,
    auxToFetch: auxToFetch,
    setAuxToFetch: setAuxToFetch,
    setEditPetDetail: setEditPetDetail,
    toastConfig: toastConfig,
  };
  const [width, setWidth] = useState(window.innerWidth);
  return (
    <>
      <Header />
      <main id="mainContent" className={!isOpenSidebar ? "show" : ""}>
        <MenuSidebar/>

        <div className="container-fluid">
          <div className="row">
            <div className="col-xx-12">
              <div className="card parent mt-2">
                {addNewPetButton}
                <div className="card-body my-pets scrollbar">
                {Object.keys(petInfo).length  != 0 ?  (
                  <div className="row">
                    {Object.values(petInfo).map((values, i) => {
                      // console.log(values);
                      let iconColor =  values.iconColorName.length !== 0 ? values.iconColorName[0].iconColorName : "";
                      let colorCode =  values.iconColorName.length !== 0 ? values.iconColorName[0].colorCode : "";

                      let brithdate = dateFormat(
                        values.birthDate,
                        "dS mmmm yyyy"
                      );
                      let imageURL = values.imageURL;
                      let deviceName =  values.pet_devices.length !== 0 ? values.pet_devices[0].deviceName : "";
                      let petIconColor = iconColor.length ? iconColor : "aqua";
                      const petStyle = {
                        borderColor: petIconColor,
                        // borderWidth: '5px'
                      };
                      const petViewProps = {
                        iconColor,
                        colorCode,
                        brithdate,
                        deviceName,
                        values,
                        imageURL,
                        i,
                        petStyle,
                      };

                      return values ? (
                        <div  className="col-xl-6 col-lg-12 col-md-12 col-12 my-account mt-0 mb-3"  key={values._id} >
                          {width > 767 && (
                            <div className="card h-100">
                              <div className="card-body p-0">
                                <div className="pet-info-container">
                                  <div className="pet-info-inner">
                                    <div className="row">
                                      <div className="col-3 col-sm-3">
                                        <div
                                          className="dog-bio"
                                          style={petStyle}
                                        >
                                          <img src={imageURL} alt="gibi" />
                                        </div>
                                        <ActionBtn values={values} />
                                      </div>
                                      <PetView {...petViewProps} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {width < 767 && <PetMobileView {...petViewProps} />}
                        </div>
                      ) : null;
                    })}
                  </div>
                   ): (
                    <NoDataFound />
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <MyPetModal {...modalProps} />
    </>
  );
}

export default MyPet;
