import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import useAuthStore from "../../../store/useAuthStore";
import UserBilling from "../UserBilling";

function AddressModel(props) {
  const userToken = useAuthStore((s) => s.token);
  const [updateStep, setupdateStep] = useState({});
  const {
    showAddGibi,
    setShowAddGibi,
    updateLoader,
    userBillInfo,
    setUserBillInfo,
    toastConfig,
    setAuxToFetch,
    auxToFetch,
  } = props;

  return (
    <div>
      <Modal
        className="add-new-gibi"
        size="lg"
        show={showAddGibi}
        onHide={() => setShowAddGibi(false)}
        aria-labelledby="modal-sizes-title-lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-sizes-title-lg">
            Update Home Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <UserBilling
            updateStep={updateStep}
            userBillInfo={userBillInfo}
            updateLoader={updateLoader}
            setShowAddGibi={setShowAddGibi}
            toastConfig={toastConfig}
            setAuxToFetch={setAuxToFetch}
            auxToFetch={auxToFetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddressModel;
