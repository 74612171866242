import React from 'react'
import { DateTime, Settings } from 'luxon'
import './Battery.css'
import { getEventIcon } from '../../helpers/alertHelpers'
import usePetsDevicesStore from '../../store/usePetsDevicesStore'
import useAuthStore from '../../store/useAuthStore'
import usePetHistoryStore from '../../store/usePetHistoryStore'
import useMapStore from '../../store/useMapStore'

export const Battery = (props) => {
    const {iccid} = props
    const devices = usePetsDevicesStore((s) => s.devices)
    const lastKnownPositions = usePetHistoryStore((s) => s.lastKnown)
    const setMapCenter = useMapStore((s) => s.setMapCenter)

    const [percent, setPercent] = React.useState('--')
    const [date, setDate] = React.useState('')
    const setHighlightedFeature = useMapStore((s) => s.setHighlightedFeature)

    const center = () => {
        if (lastKnownPositions && lastKnownPositions[iccid]) {
            const feature = lastKnownPositions[iccid]
            const loc = {
                lng: feature.properties.longitude,
                lat: feature.properties.latitude,
            }
            setMapCenter(loc)
        }
    }

    React.useEffect(() => {
        if (devices) {
            const device = devices[iccid]
            if (device) {
                setPercent(device.status.batteryPercent || '--')
                // const batteryDate = DateTime.fromISO(device.status.lastContact, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
                const validLocationDate = lastKnownPositions && lastKnownPositions[iccid] ? DateTime.fromISO(lastKnownPositions[iccid].properties.receivedTime, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) : ''
                setDate(validLocationDate)
            }
        }
    }, [devices])

    const batteryIcon = (
        <div className="batteryContainer">
            <div className="batteryOuter">
                <div id="batteryLevel" style={{width: percent}}></div>
            </div>
            <div className="batteryBump"></div>
        </div>
    )

    const highlightedFeature = lastKnownPositions && lastKnownPositions[iccid] ? lastKnownPositions[iccid] : null

    return (
        <>
            <span className="progress-value d-block text-center white">
                {percent}
            </span>
            {/*{batteryIcon}*/}
            <div className="progress">
                <div className="progress-bar"
                     role="progressbar"
                     style={{width: percent}}
                     aria-valuemin="0"
                     aria-valuemax="100"/>
            </div>
            <div className={'batteryDate'} onClick={center}
                 onMouseEnter={() => setHighlightedFeature(highlightedFeature)}
                 onMouseLeave={() => setHighlightedFeature(null)}>
                {/*<span style={{marginLeft: '8px'}}>{date}</span>*/}
                <i className={'fa-solid fa-location-dot'}/> <span>{date}</span>
            </div>
        </>
    )
}
