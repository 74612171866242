import { React, useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import {
  getAllIconColor,
  removeIconColor,
} from "../../../helpers/adminHelper/settigHelper";
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import DataTableSetup from "../DataTableSetup";
import AddIconColorModal from "./AddIconColorModal";
import { toastConfig } from "../adminConfig";

const IconColor = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [iconColorName, setIconColor] = useState([]);

  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );

  const columns = [
    {
      name: "Color Name",
      selector: (row) => row.iconColorName,
      sortable: true,
    },
    {
      name: "Color Code",
      selector: (row) => row.colorCode,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.status === "active" ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>InActive</span>
          )}
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-disabled">Click to change status.</Tooltip>
            }
          >
            <button className="icon-button">
              <i
                className={
                  row.status === "active" ? "fa fa-trash" : "fa fa-undo"
                }
                onClick={() => {
                  deleteID(row._id, row.status);
                }}
              >
                {" "}
              </i>
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const fetchIconColor = async () => {
    const response = await getAllIconColor(adminToken);
    if (response.status) {
      let colors = response.data;
      setIconColor(colors);
    }
  };

  useEffect(() => {
    fetchIconColor();
  }, [auxToFetch]);

  const deleteColor = async (data, onClose) => {
    const response = await removeIconColor(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };
  const deleteID = (id, status) => {
    const statusValue = status === true ? "In-Active" : "Active";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {statusValue} ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                const data = { id: id, status: status };
                deleteColor(data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };
  //-----Search Filter Starts-----//
  const [filterText, setFilterText] = useState("");
  const filteredItems = iconColorName.filter(
    (item) =>
      item.iconColorName &&
      item.iconColorName.toLowerCase().includes(filterText.toLowerCase())
  );

  const onFilterData = (e) => {
    setFilterText(e.target.value);
  };
  const onFilterClear = () => {
    setFilterText("");
  };
  //-----Search Filter Ends-----//

  const btnStatus = true;
  const BtnContent = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">Click to add Icon Color</Tooltip>
        }
      >
        <button
          className="btn btn-primary mb-2 "
          onClick={() => setShowAlert(true)}
        >
          Add Icon Color
        </button>
      </OverlayTrigger>
    );
  };
  const datatableProps = {
    filterText: filterText,
    onFilterData: onFilterData,
    onFilterClear: onFilterClear,
    columns: columns,
    filteredItems: filteredItems,
    btnStatus: btnStatus,
    BtnContent: BtnContent,
  };
  const modalProps = {
    adminToken: adminToken,
    showAlert: showAlert,
    setShowAlert: setShowAlert,
    auxToFetch: auxToFetch,
    setAuxToFetch: setAuxToFetch,
  };

  return (
    <>
      <DataTableSetup {...datatableProps} />
      <AddIconColorModal {...modalProps} />
    </>
  );
};
export default IconColor;
