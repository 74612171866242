import { React, useState } from "react";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import PetTypes from "./PetTypes";
import PetBreeds from "./PetBreeds";
const AdminPetSettings = ({
  updateLoader,
  updateLoaderMessage,
  updateSidebar,
  isOpenSidebar,
}) => {
  const [pageTitle, setPageTitle] = useState("Pets");
  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <AdminSidebar
          isOpenSidebar={isOpenSidebar}
          updateSidebar={updateSidebar}
        />
        <main className="page-content ">
          <AdminHeader pageTitle={pageTitle} isOpenSidebar={isOpenSidebar} />
          <main
            className={
              "content-holder " + (isOpenSidebar != true ? "show" : "")
            }
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="main-wrapper">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item me-2" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-petType-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pet-types"
                          type="button"
                          role="tab"
                          aria-controls="pills-timefeq"
                          aria-selected="true"
                        >
                          Pet Types
                        </button>
                      </li>

                      <li className="nav-item me-2" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-breed-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pet-breed"
                          type="button"
                          role="tab"
                          aria-controls="pills-alert"
                          aria-selected="false"
                        >
                          Pet Breeds
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pet-types"
                        role="tabpanel"
                        aria-labelledby="pills-timefeq-tab"
                      >
                        <PetTypes />
                      </div>
                      {/* Battery Status */}
                      <div
                        className="tab-pane fade"
                        id="pet-breed"
                        role="tabpanel"
                        aria-labelledby="pills-breed-tab"
                      >
                        <PetBreeds
                          updateLoader={updateLoader}
                          updateLoaderMessage={updateLoaderMessage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
};
export default AdminPetSettings;
