import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

 import AddDeviceForm from "./AddDeviceForm";


function AddNewDeviceModal(props) {
    const {showAddNewGibi,setShowAddNewGibi,updateLoader,updateDeviceModal,updateFetch}=props
  return (
    <div><Modal
    className="add-new-gibi"
    size="lg"
    show={showAddNewGibi}
    onHide={() => setShowAddNewGibi(false)}
    aria-labelledby="modal-sizes-title-lg"
    centered
    backdrop="static"
  >
    <Modal.Header closeButton>
      <Modal.Title id="modal-sizes-title-lg">Add New Gibi Tracker</Modal.Title>
    </Modal.Header>
    <Modal.Body className="my-pets">
      <AddDeviceForm
        updateLoader={updateLoader}
        updateDeviceModal={updateDeviceModal}
        updateFetch={updateFetch}
      />
    </Modal.Body>
  </Modal></div>
  )
}

export default AddNewDeviceModal
