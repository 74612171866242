import React, { useState, useEffect } from "react";
import Header from "../Header.js";
import Sidebar from "./Sidebar.js";
import "../Date.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/local-image/gibi-logo.png";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useAuthStore from "../../store/useAuthStore";
import {
  getDevices,
  removeDeviceDetails,
  activateDevice,
} from "../../helpers/deviceHelper";
import { getPetList } from "../../helpers/petHelpers";
import AssignedModal from "./MyDevice/AssignedModal";
import AddNewDeviceModal from "./MyDevice/AddNewDeviceModal.js";
import { DateTime, Settings } from 'luxon'
import NoDataFound from "../NoDataFound.js";
import MenuSidebar from '../MenuSidebar';
import useUIStore from '../../store/useUIStore'

const MyDevice = (props) => {
  const { updateLoader, updateSidebar } = props;
  const navigate = useNavigate();
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [userToken, setuserToken] = useState(useAuthStore((s) => s.token));
  const [showAddNewGibi, setShowAddNewGibi] = useState(false);
  const [deviceList, setDeviceList] = useState({});
  const [petList, setPetList] = useState([{ value: "pet", label: "Pet" }]);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [updateGibi, setUpdateGibi] = useState(false);
  const updateDeviceModal = (val) => {
    val === false ? setShowAddNewGibi(false) : setShowAddNewGibi(true);
  };
  const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
  const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)
  const updateFetch = (val) => {
    setAuxToFetch(!auxToFetch);
  };
  const [selectPet, setselectPet] = useState("");

  const toastConfig = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const fetchAllDevices = async (userToken) => {
    updateLoader(true);
    const response = await getDevices(userToken);
    if (response.status) {
      // console.log(response.result);
      setDeviceList(response.result);
      updateLoader(false);
    } else {
      updateLoader(false);
    }
  };

  const fetchAllPets = async (userToken) => {
    updateLoader(true);
    const response = await getPetList(userToken);
    console.log();
    if (response.status) {
      let pets = response.result;

      const list = pets.map((item) => {
        if (item.pet_devices.length === 0) {
          let petName = item.petName;
          return { value: item._id, label: petName };
        } else {
          let petName = item.petName + " --Tracker Assigned";
          return { value: item._id, label: petName };
        }
      })

      list.unshift({ value: '', label: 'Unassigned' })

      setPetList();
      updateLoader(false);
    } else {
      updateLoader(false);
    }
  };

  useEffect(() => {
    if (userToken === "" || userToken === null) {
      navigate("/login");
    } else {
      fetchAllDevices(userToken);
      fetchAllPets(userToken);
    }
  }, [auxToFetch]);

  const removeDeviceFunc = async (deletedID, onClose) => {
    const response = await removeDeviceDetails(userToken, deletedID);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };

  const removeDevice = (deletedID) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to remove ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                removeDeviceFunc(deletedID, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const assignDeviceProps = {
    userToken: userToken,
    updateGibi: updateGibi,
    setUpdateGibi: setUpdateGibi,
    petList: petList,
    setselectPet: setselectPet,
    selectPet: selectPet,
    selectedDevice: selectedDevice,
    toastConfig: toastConfig,
    setAuxToFetch: setAuxToFetch,
    auxToFetch: auxToFetch,
  };

  const addNewDeviceModalProps = {
    showAddNewGibi: showAddNewGibi,
    setShowAddNewGibi: setShowAddNewGibi,
    updateLoader: updateLoader,
    updateDeviceModal: updateDeviceModal,
    updateFetch: updateFetch,
  };

  const [width, setWidth] = useState(window.innerWidth);

  const activeProvision = async (iccid) => {
    updateLoader(true);
    const response = await activateDevice(userToken, { iccid });
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});

      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
    updateLoader(false);
  };

  return (
    <>
      <Header />
      <main id="mainContent" className={!isOpenSidebar ? "show" : ""}>

          {/* <Sidebar
            updateSidebar={updateSidebar}
            isOpenSidebar={isOpenSidebar}
            width={width}
          /> */}
       <MenuSidebar/>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card parent mt-2">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="mb-0"> Gibi Trackers</h6>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        Click to add new tracker.
                      </Tooltip>
                    }
                  >
                    <button
                      className="btn btn-link p-0 text-dark text-decoration-none font-weight-bold"
                      style={{ lineHeight: "14px", fontWeight: "600" }}
                      data-toggle="modal"
                      data-target="#addNewDevice"
                      onClick={() => {
                        setShowAddNewGibi(true)}}
                    >
                      <span className="me-1">
                        <i className="fa fa-plus-circle"></i>
                      </span>
                      Add New Gibi Tracker
                    </button>
                  </OverlayTrigger>
                </div>
                <div className="card-body my-device scrollbar">
                  {Object.keys(deviceList).length != 0 ? (
                    <div className="row">
                      {Object.values(deviceList).map((values) => {
                        let pet_data = values.val;
                        let planEndDate = pet_data.planId.plan_end;

                        const ednDate = DateTime.fromISO(planEndDate, {zone: useAuthStore.getState().timezone.value});
                        const diff = Math.floor(ednDate.diffNow().as("day"));

                        let remaningnDays =
                          diff > 0 ? (
                            <span style={{ color: "#139dcc" }}>
                              {" "}
                              {diff} Days Remains.{" "}
                            </span>
                          ) : (
                            <span style={{ color: "#e50f0f" }}>
                              Plan Renews.
                            </span>
                          );

                        // console.log(pet_data.iccid);
                        var mypetBgImage = "";
                        if (pet_data.petId != null) {
                          mypetBgImage = values.imageURL;
                        } else {
                          mypetBgImage = logo;
                        }
                        let planStatus = "pending";
                        if (pet_data.planId != null) {
                          planStatus = pet_data.planId.status;
                        }
                        let errorDevice = false;
                        if (pet_data.imsi === "" || pet_data.mdn === "") {
                          errorDevice = true;
                        } else {
                          errorDevice = false;
                        }

                        return (
                          <div
                            className="col-xl-6 col-lg-6 col-sm-12 my-account mt-0 mb-2"
                            key={pet_data._id}
                          >
                            <div className="card mb-2">
                              <div className="card-body my-device">
                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="dog-bio me-3">
                                      <img src={mypetBgImage} alt="gibi" />

                                      <div className="d-flex justify-content-around align-items-center justify-content-between">
                                        {!errorDevice && (
                                          <OverlayTrigger
                                            placement="auto"
                                            overlay={
                                              <Tooltip id="tooltip-disabled">
                                                Click to edit tracker.
                                              </Tooltip>
                                            }
                                          >
                                            <button
                                              className="btn btn-link px-0 py-2 d-flex align-items-center"
                                              id="edit-2"
                                              onClick={() => {
                                                setSelectedDevice(pet_data._id);
                                                setUpdateGibi(true);
                                              }}
                                            >
                                              <i
                                                className="fa fa-pencil-square-o btn-remove mt-1"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </OverlayTrigger>
                                        )}

                                        <OverlayTrigger
                                          placement="auto"
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              Click to remove tracker.
                                            </Tooltip>
                                          }
                                        >
                                          <button
                                            className="btn btn-link px-0 py-2 d-flex align-items-center"
                                            onClick={() => {
                                              removeDevice(pet_data._id);
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash btn-remove"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </OverlayTrigger>
                                      </div>
                                    </div>

                                    <h4 className="pet-label me-3">
                                      {pet_data.deviceName}
                                      <div className="pet-label text-black-50" style={{fontSize: '12px'}}>
                                        {pet_data.iccid}
                                      </div>
                                    </h4>


                                    {pet_data.petId && !errorDevice && (
                                      <label
                                        className="form-label text-dark d-block label-info-1 ms-auto"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Assigned to {pet_data.petId.petName}
                                      </label>
                                    )}
                                  </div>
                                </div>
                                {/*<div className="float-end">*/}
                                {/*  <p className="plan-validity mb-0">*/}
                                {/*    {remaningnDays}*/}
                                {/*  </p>*/}
                                {/*</div>*/}
                              </div>
                            </div>
                            <div
                              className="note-box d-flex justify-content-around align-items-center"
                              style={{
                                borderColor: errorDevice ? "#e50f0f" : "green",
                              }}
                            >
                              <p className="mb-0">
                                {errorDevice ? (
                                  <>
                                    <i
                                      class="fa-sharp fa-solid fa-circle-exclamation"
                                      style={{ color: "#e50f0f" }}
                                    ></i>{" "}
                                    Note: Gibi Tracker is not active.
                                  </>
                                ) : (
                                  <>
                                    <i
                                      class="fa-solid fa-circle-check"
                                      style={{ color: "green" }}
                                    ></i>{" "}
                                    Gibi Tracker is active.
                                  </>
                                )}
                              </p>
                              {errorDevice ? (
                                <span
                                  onClick={() => {
                                    activeProvision(pet_data.iccid);
                                  }}
                                >
                                  {" "}
                                  Activate
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AssignedModal {...assignDeviceProps} />

      <AddNewDeviceModal {...addNewDeviceModalProps} />
    </>
  );
};
export default MyDevice;
