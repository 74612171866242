import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { updateAccountDetails } from "../../../helpers/accountHelper";
import useAuthStore from "../../../store/useAuthStore";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toastConfig } from '../../Admin/adminConfig'
import useGibiConfigStore from '../../../store/useGibiConfigStore'
function EditAccountInfoModal(props) {
  const userToken = useAuthStore((s) => s.token);

  const {
    userData,
    showEditAccountInfo,
    setShowEditAccountInfo,
    isSubmitting,
    setIsSubmitting,
    toastConfig,
    customStyles,
    setShowAddGibi,
    options,
    setAuxToFetch,
    auxToFetch
  } = props;


  useEffect(() => {
    if (showEditAccountInfo) {
      setValue("account_id", userData._id);
      setValue("first_name", userData.first_name);
      setValue("last_name", userData.last_name);
      setValue("email", userData.email);
      setValue("mobile_no", userData.mobile);
      setValue("timeZone", userData.timeZone._id);
      setValue("smsOptIn", userData.smsOptIn === 'Opt-in' ? true : false);
    }
  }, [showEditAccountInfo]);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    // resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });
  const selectTimeZone = (val) => {
    setValue("timeZone", val);
  };

  const updateAccount = async (onClose, data) => {
    // console.log(data);

    const accountId = data.account_id;
    const updateResult = await updateAccountDetails(userToken, accountId, data);
    if (updateResult.status) {
      toast.success(updateResult.message, {...toastConfig, containerId: 'api'});
      setAuxToFetch(!auxToFetch);
      onClose();
    } else {
      toast.error(updateResult.message, {...toastConfig, containerId: 'api'});
      onClose();
    }
  };

  const formSubmit = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to change ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={async () => {
                  if (data.smsOptIn) {
                      data.smsOptIn = 'Opt-in'
                  } else {
                      data.smsOptIn = 'Opt-out'
                  }
                  await updateAccount(onClose, data)
                  const timeZones = useGibiConfigStore.getState().timezones
                  const curTZ = timeZones.find(t => t._id === data.timeZone)
                  if (curTZ) {
                      useAuthStore.getState().setTimeZone(curTZ)
                  }
                  setShowEditAccountInfo(false)
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                onClose();setIsSubmitting(false);
              }}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const firstNameField = (
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">First Name :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={
              "form-control" + (errors.first_name ? " is-invalid" : "")
            }
            placeholder="Enter name"
            {...register("first_name")}
            disabled
          />
        </div>
        {errors.first_name && (
          <div className="errors">{errors.first_name?.message}</div>
        )}
      </div>
    </div>
  );

  const lastNameField = (
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Last Name :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.last_name ? " is-invalid" : "")}
            placeholder="Enter name"
            {...register("last_name")}
            disabled
          />
        </div>
        {errors.last_name && (
          <div className="errors">{errors.last_name?.message}</div>
        )}
      </div>
    </div>
  );

  const emailField = (
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Email :</label>
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-envelope" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.email ? " is-invalid" : "")}
            placeholder="Enter email"
            {...register("email")}
            disabled
          />
        </div>
        {errors.email && <div className="errors">{errors.email?.message}</div>}
      </div>
    </div>
  );

  const mobileField = (
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group my-pets">
        <label className="form-label text-dark">Mobile No.:</label>
        <div className="input-group white">
          <span className="input-group-text" style={{ fontSize: "20px" }}>
            <i className="fa fa-mobile" aria-hidden="true"></i>
          </span>
          <input
            type="text"
            className={"form-control" + (errors.mobile_no ? " is-invalid" : "")}
            placeholder="Enter mobile"
            {...register("mobile_no")}
          />
        </div>
        {errors.mobile_no && (
          <div className="errors">{errors.mobile_no?.message}</div>
        )}
      </div>
    </div>
  );

  const timeZoneField = (
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group">
        <label className="form-label text-dark">Time Zone</label>
        <div className="custom-dropdown timezone">
          <Controller
            name="timeZone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                options={options}
                styles={customStyles}
                className="react-dropdown"
                classNamePrefix="dropdown"
                value={options.find((c) => c.value === value)}
                // value="USA"
                onChange={(val) => {
                  onChange(val.value);
                  selectTimeZone(val.value);
                }}
                placeholder={
                  <div className="Select Species">Select Time Zone</div>
                }
                menuPosition="fixed"
              />
            )}
          />
          <p className="errors">
            {errors.timeZone?.message || errors.timeZone?.label.message}
          </p>
        </div>
      </div>
    </div>
  );
  const SMSOption = (
    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="form-group">
        <label className="form-label text-dark">SMS Alerts</label>
        <br/>
        <label className="toggle mt-2">
                <input
                    type="checkbox"
                    name="notificationStatus"
                    autoComplete="off"
                    {...register('smsOptIn')}
                    defaultChecked={false}
                />
                <span className="slider"></span>
                <span
                    className="labels"
                    data-on="IN"
                    data-off="OUT"
                ></span>
            </label>
      </div>
    </div>
  );

  const btnField = (
    <ul className="list-inline w-100">
      <li className="list-inline-item float-start">
        <button
          type="button"
          onClick={() => {
            setShowEditAccountInfo(false);
            setShowAddGibi(true);
          }}
          className="btn btn-outline-primary float-start"
        >
          Change Address
        </button>
      </li>
      <li className="list-inline-item float-end">
        <button
          type="submit"
          className="btn btn-outline-primary me-2"
          data-bs-dismiss="modal"
          disabled={isSubmitting}
        >
          {isSubmitting === true && (
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          Update
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          data-bs-dismiss="modal"
          onClick={() => {
            // passReset();
            setShowEditAccountInfo(false);
          }}
        >
          Cancel
        </button>
      </li>
    </ul>
  );

  return (
    <div>
      <Modal
        className="add-new-gibi"
        size="lg"
        show={showEditAccountInfo}
        onHide={() => {
          setShowEditAccountInfo(false);
        }}
        aria-labelledby="modal-sizes-title-lg"
        centered
        backdrop="static"
      >
        <form method="POST" onSubmit={handleSubmit(formSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title id="modal-sizes-title-lg">
              Edit Account Info
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="hidden"
              placeholder="Enter name"
              {...register("account_id")}
            />
            <div className="px-5">
              <div className="row">
                {firstNameField}
                {lastNameField}
                {emailField}
                {mobileField}
                {timeZoneField}
                {SMSOption}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>{btnField}</Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default EditAccountInfoModal;
