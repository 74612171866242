import React from "react";
import { NavLink } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useUIStore from "../store/useUIStore";
import AccountMenuSidebar from "./AccountMenuSidebar";
import DashboardAccordion from "./DashboardAccordion";
import { SafeZoneSection } from "./SafeZone/SafeZoneSection";
import SettingsModals from "./SettingsModal/SettingsModals.js";
import AlertsModal from "./AlertsModal/AlertsModals";
import SleepModeModal from "./SleepModeModal/SleepModeModal";

function MenuSidebar() {
  const isOpenSidebar = useUIStore((s) => s.isOpenSidebar);
  const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar);

  const toggleSidbar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };
  const asideButton = (
    <div className={`sidebar-footer p-2`}>
      <button
        className="btn btn-link toggle-btn d-flex justify-content-center align-items-center"
        onClick={toggleSidbar}
      >
        <img
          src={
            isOpenSidebar === true
              ? require("../images/maximize_.png")
              : require("../images/minimize_.png")
          }
          alt=""
          style={{ height: "25px" }}
        />
      </button>
    </div>
  );

  return (
    <div>
      <aside className={"sidebar scrollbar " + (!isOpenSidebar ? "show" : "")}>
        <DashboardAccordion
          isOpenSidebar={isOpenSidebar}
          setIsOpenSidebar={setIsOpenSidebar}
        />
        <SafeZoneSection />
        <AccountMenuSidebar isOpenSidebar={isOpenSidebar} />
        {asideButton}
      </aside>

      <SettingsModals />

      <SleepModeModal />

      <AlertsModal />
    </div>
  );
}

export default MenuSidebar;
