import axios from 'axios';

export const getCheckInRateOptions = async (userToken) => {
    try {
        const response = await axios({
            method: 'get',
            url: process.env.REACT_APP_BE_URL + '/api/v1/checkInRate/1',
            headers: {Authorization: `Bearer ${userToken}`}
        })
        if (response.data.status) {
            return response.data.result;
        }
        return []
    } catch(err) {
        console.log('getCheckInRateOptions', err);
        return []
    }
}

export const getAlertTypes = async (userToken) => {
    try {
        const response = await axios({
            method: 'get',
            url: process.env.REACT_APP_BE_URL + '/api/v1/alertTypes/1',
            headers: {Authorization: `Bearer ${userToken}`}, // data:{ "iccid":iccid}
        })
        if (response.data.status === true) {
            return response.data.result;
        }
        return []
    } catch(err) {
        console.log('getAlertTypes', err);
        return []
    }
}

