import Select from 'react-select';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {GibiSelect} from '../../SettingsModal/components/GibiSelect';

const repeatOptions = [
    {value: 0, label: 'One Time Only'},
    {value: 1, label: 'Repeat Daily'},
];

const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        };
    },
};

export const SelectSleepForm = (props) => {
    const {onChange, isRepeat, devices, iccid, extraHeader} = props
    const [repeatVal, setRepeatVal] = useState(0)

    useEffect(() => {
        setRepeatVal(isRepeat ? 1 : 0)
    }, [isRepeat])

    const modeAndStatus = <div className="row">
        <div className="col-lg-6">
            <div className=" setting-device form-group">
                <label htmlFor="exampleInputEmail1" className="form-label">Repeat</label>

                <div className="custom-dropdown">
                    <Select
                        options={repeatOptions}
                        styles={customStyles}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        value={repeatOptions.find(o => o.value === repeatVal)}
                        onChange={(val) => onChange('repeat', val.value)}
                        placeholder={
                            <div className="Select Species">Select Sleep Mode</div>
                        }
                    />
                </div>
            </div>
        </div>
    </div>

    return (<>
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">Single Sleep</Modal.Title>
            </Modal.Header>
            {extraHeader}
            <Modal.Body className="scrollbar overflow-unset">
                <div className="row">
                    <div className="col-lg-12">
                        <GibiSelect devices={devices} iccid={iccid} onChange={onChange}/>
                    </div>
                    <div className="col-lg-12">
                        {modeAndStatus}
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}
