import React from 'react'
import useUIStore from '../store/useUIStore'

export const WebSocketIndicator = () => {
    const webSocketConnected = useUIStore(s => s.webSocketConnected)

    const indicator = {
        width: '20px',
        height: '20px',
        // borderRadius: '50%',
        color: webSocketConnected ? 'green' : 'red'
    }

    return <i className="fa-solid fa-circle-dot" style={indicator}/>
}
