import create from 'zustand';

let store = (set) => ({
    lastTen: {},
    events: {},

    loadLastTen: (iccid, positions) => {
        set((state) => {
            return {
                ...state,
                lastTen: {
                    ...state.lastTen,
                    [`${iccid}`] : positions,
                },
            }
        })
    },

    addToLastTen: (iccid, position) => {
        set((state) => {
            const lastTen = state.lastTen[iccid] && Array.isArray(state.lastTen[iccid]) ? state.lastTen[iccid].slice(0) : []
            lastTen.unshift(position);
            if (lastTen.length > 10) {
                lastTen.pop()
            }

            return {
                ...state,
                lastTen: {
                    ...state.lastTen,
                    [`${iccid}`] : lastTen,
                }
            }
        })
    },

    loadEvents: (iccid, events) => {
        set((state) => {
            return {
                ...state,
                events: {
                    ...state.events,
                    [`${iccid}`] : events,
                },
            }
        })
    },
});

const usetEventsStore = create(store);

export default usetEventsStore;
