import { get, post, put } from "../apiHelper";

export const deviceList = async (adminToken) => {
    const response = await get("/api/v1/iccId/2",adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const chnageIccIdStatus = async (data,adminToken) => {
    const response = await post("/api/v1/iccIdStatus",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const addIccIdData = async (data,adminToken) => {
    const response = await post("/api/v1/iccid",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const uploadExcelFile = async (data,adminToken) => {
    const response = await post("/api/v1/uploadExcelFile",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };

  