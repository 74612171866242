import React, { useEffect, useState } from 'react'
import {TimeIntervalSelect} from '../components/TimeIntervalSelect';
import './AdvancedSettings.css'
import { NumberValueSelect } from '../components/NumberValueSelect'

export const SingleSetting = (props) => {
    const {
        label,
        type,
        value,
        onSave,
        options,
        isSleeping,
        isCommandInProgress,
        updateSetting,
        pendingChange,
    } = props

    const [curValue, setCurValue] = useState(value)
    const [curValueChanged, setCurValueChanged] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (pendingChange) {
            setDisabled(pendingChange !== type)
        }
    }, [pendingChange])

    const changeCurValue = (type, val) => {
        setCurValue(val.seconds)
        setCurValueChanged(true)
        updateSetting(type, val.seconds)
    }

    const changeCurNumValue = (type, val) => {
        setCurValue(val)
        setCurValueChanged(true)
        updateSetting(type, val)
    }

    const SaveButton = (props) => {
        const {type, canSave, disabled} = props
        // console.log('save', type, disabled)
        return (
            <div className="col-lg-3">
                <button
                    type="button"
                    className="btn btn-outline-primary min-width-120"
                    disabled={disabled || isSleeping || isCommandInProgress | !canSave}
                    onClick={() => onSave(type, curValue?.seconds || curValue)
                    }
                >
                    Save
                </button>
            </div>
        )
    }

    const timeOrNumber = options ? (
        <TimeIntervalSelect intervalType={type} allOptions={options}
                            seconds={curValue} onChange={changeCurValue} label={label} disabled={disabled}/>
    ) : (
        <NumberValueSelect type={type} min={0} max={40} value={curValue} onChange={changeCurNumValue} label={label} disabled={disabled}/>
    )

    return (
        <div>
            <div className="col-lg-12 panel-container">
                {timeOrNumber}
                <SaveButton type={type} canSave={curValueChanged} disabled={disabled}/>
            </div>
        </div>
    )
}
