import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { sendForgetMail } from "../helpers/registerHelper";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Invalid Email address.")
      .required("Email address is required."),
  })
  .required();

const toastConfig = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function ForgotPassword({ updateLoader }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formSubmit = async(data) => {
    const random_number = Math.floor(Math.random() * 100000) + 1;
    const origin = window.location.origin;
    setIsSubmitting(true);
    // const resetLink = `${origin}/reset-password?email=${encodedEmail}&token=${random_number}`
    const requestData = {
      email: data.email,
      subject: "Reset Password",
      token: random_number,
      origin: origin,
    };
    const response = await sendForgetMail(requestData);
    // console.log(response);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      reset();
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
    setIsSubmitting(false);
  };

  const actionBtn = (
    <button
      type="submit"
      className="btn btn-outline-primary white w-100"
      disabled={isSubmitting}
    >
      {isSubmitting === true && (
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        // <span className="spinner-grow spinner-grow-sm"></span>
      )}
      Send Mail
    </button>
  );
  const emailInput = (
    <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
      <div className="form-group">
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-envelope" aria-hidden="true"></i>
          </span>

          <input
            type="text"
            className={"form-control" + (errors.email ? " is-invalid" : "")}
            placeholder="Enter Your Email"
            {...register("email")}
          />
        </div>
        {errors.email && <div className="errors">{errors.email?.message}</div>}
      </div>
    </div>
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 p-0 login-img-holder">
          <div className="login-bg"></div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 p-0">
          <div className="login-holder d-flex align-items-center">
            <div className="login-container">
              <div className="logo">
                <img src={require("../images/logo-white_.png")} alt="gibi" />
              </div>
              <h3 className="panel-title text-center text-white">
                Forgot Password
              </h3>
              <p className="text-center text-white">
                You can reset your password here.
              </p>
              <form onSubmit={handleSubmit(formSubmit)}>
                <div className="row">
                  {emailInput}
                  <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
                    <div className="form-group my-3">
                      <ul className="list-inline w-100 d-flex align-items-center justify-content-center">
                        <li className="list-inline-item m-0  ps-2">
                          {actionBtn}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
