import React from 'react'
import { NavLink  } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useUIStore from '../store/useUIStore'
function AccountMenuSidebar(props) {
    // const {isOpenSidebar}=props;

    const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
    const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)


  return (

    <ul  className={"nav-list ps-0 "+(!isOpenSidebar  ? 'show' : ' ')}>
    <li style={{borderBottom: '1px solid #139dcc'}}>
      <OverlayTrigger
        placement="auto"
        overlay={ <Tooltip id="tooltip-disabled"> Click to view all Pets. </Tooltip> }
      >
        <NavLink
          to="/my-pet"
          className="nav-link"
          activeclassname="active"
          >
          <i className={  "fa fa-paw " + (!isOpenSidebar ? "show" : "mx-auto")} aria-hidden="true"></i>
          {!isOpenSidebar && <span>Pet Profiles</span>}
        </NavLink>
      </OverlayTrigger>
    </li>
    <li style={{borderBottom: '1px solid #139dcc'}}>
      <OverlayTrigger
        placement="auto"
        overlay={ <Tooltip id="tooltip-disabled"> Click to view Trackers. </Tooltip> }
      >
        <NavLink
          to="/my-device"
          className="nav-link"
          activeclassname="active"
          >
          <img className={`${!isOpenSidebar ? "show" : "mx-auto"}`}
               src={require('../images/deviceVertIcon.png')}
               style={{width:'15px', marginRight:'16px',transform: 'rotateX(180deg)'}}
          />
          {!isOpenSidebar && <span>Trackers</span>}
        </NavLink>
      </OverlayTrigger>
    </li>
    <li style={{borderBottom: '1px solid #139dcc'}}>
      <OverlayTrigger
        placement="auto"
        overlay={
            <Tooltip id="tooltip-disabled">
            Click to view Contacts.
          </Tooltip>
        }
        >
        <NavLink to="/my-contact" className="nav-link" activeclassname="active">
          <i className={ "fa fa-users " + (!isOpenSidebar ? "show" : "mx-auto")}aria-hidden="true" ></i>
          {!isOpenSidebar && <span>Contacts</span>}
        </NavLink>
      </OverlayTrigger>
    </li>
    <li style={{borderBottom: '1px solid #139dcc'}}>
      <OverlayTrigger
        placement="auto"
        overlay={<Tooltip id="tooltip-disabled">Click to view Account & Billing. </Tooltip>}
        >
        <NavLink to="/my-account"className="nav-link" activeclassname="active">
          <i className={"fa fa-file-text " + (!isOpenSidebar ? "show" : "mx-auto")}  aria-hidden="true"></i>
          {!isOpenSidebar && <span> Account & Billing</span> }
        </NavLink>
      </OverlayTrigger>
    </li>

  </ul>
  )

}

export default AccountMenuSidebar
