import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import useAuthStore from '../store/useAuthStore'
import { WebSocketIndicator } from '../webSockets/WebSocketIndicator'
import usePetsDevicesStore from '../store/usePetsDevicesStore'
import MobileTopMenu from '../components/MyAccount/MobileTopMenu'
import MobileDashboardTopMenu from '../components/MobileDashboardTopMenu'
import useUIStore from '../store/useUIStore'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import useZoneStore from '../store/useZoneStore'
import { fetchAllZones, reloadAllStoresAndResetMap } from '../helpers/stateHelper'

function Header() {
    const userToken = useAuthStore(s => s.token)

    const [auxToFetch, setAuxToFetch] = useState(false)
    const [urlName, setUrlName] = useState('')
    // const [isOpenSidebar, setIsOpenSidebar] = useState(true);
    const [headerOpenSidebar, setheaderOpenSidebar] = useState(true)
    const loadPetMap = usePetsDevicesStore((s) => s.loadPetMap)
    const loadDeviceMap = usePetsDevicesStore((s) => s.loadDeviceMap)
    const navigate = useNavigate()
    const clearToken = useAuthStore((s) => s.clearToken)

    const showPowerZones = useUIStore((s) => s.showPowerZones)
    const setShowPowerZones = useUIStore((s) => s.setShowPowerZones)
    const showAccuracy = useUIStore((s) => s.showAccuracy)
    const setShowAccuracy = useUIStore((s) => s.setShowAccuracy)
    const showMenu = useUIStore((s) => s.showMenu)
    const setShowMenu = useUIStore((s) => s.setShowMenu)

    const fetchZoneToggle = useZoneStore((s) => s.fetchZonesToggle)
    const inAccountView = useUIStore(s => s.inAccount)

    const firstName = useAuthStore(s => s.firstName)
    useEffect(() => {
        if (userToken && !inAccountView) {
            fetchAllZones(userToken).then(() => console.log('zones updated'))
        }
    }, [fetchZoneToggle])

    useEffect(() => {
        // initial store population, updates come via websockets
        if (userToken) {
            if (!inAccountView) {
                reloadAllStoresAndResetMap(userToken)
            }
        }
        else {
            logout()
        }

    }, [userToken])

    const logout = () => {
        localStorage.removeItem('userToken')
        localStorage.removeItem('user_details')
        clearToken({})
        loadPetMap({})
        loadDeviceMap({})
        setTimeout(() => {
            setAuxToFetch(!auxToFetch)
            navigate('/login')
            window.location.reload(true)
        }, 500)
    }

    useEffect(() => {
        var last_url_name = window.location.pathname.split('/').pop()

        if (last_url_name.includes('-')) {
            var check = last_url_name.split('-')
            // console.log(check[0]);
            setUrlName((prev) => check[0])
        }
        if (userToken === '' || userToken === null) {
            // navigate('/login');
        }
        else {
        }
    }, [auxToFetch])
    const [width, setWidth] = useState(window.innerWidth)
    const [showMobileView, setShowMobileView] = useState(false)

    const viewMoblieTop = () => {
        setShowMobileView(!showMobileView)
    }
    const [showMobileDashboardView, setShowMobileDashboardView] = useState(false)
    const viewMoblieDashboardTop = () => {
        setShowMobileDashboardView(!showMobileDashboardView)
    }

    const accuracyTip = `${showAccuracy ? 'Hide' : 'Show'} Location Accuracy`
    const toggleAccuracy = (
        <div onClick={() => setShowAccuracy(!showAccuracy)} style={{color: 'white'}}>
            <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip-disabled">{accuracyTip}</Tooltip>}>
                <i className="fa-regular fa-circle-dot"/>
            </OverlayTrigger>
        </div>
    )

    const powerZoneTip = `${showPowerZones ? 'Hide' : 'Show'} Power Zones`
    const togglePowerZones = (
        <div onClick={() => setShowPowerZones(!showPowerZones)} style={{color: 'white'}}>
            <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip-disabled">{powerZoneTip}</Tooltip>}>
                <i
                    className={
                        'fa-solid' +
                        (showPowerZones == 'Hide' ? ' fa-bolt' : ' fa-bolt')
                    }
                />
            </OverlayTrigger>
        </div>
    )

    const headerButtonsStyle = {
        display: 'flex',
        width: '120px',
        justifyContent: 'space-around'
    }

    const headerButtons = (
        <div style={headerButtonsStyle}>
            <WebSocketIndicator/>
            {/*{toggleAccuracy}*/}
            {/*{togglePowerZones}*/}
        </div>
    )

    const toastContainers = <ToastContainer
        autoClose={20000}
        enableMultiContainer
        containerId={'main'}
        newestOnTop={true}
    />
    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-light fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="images/logo.png" alt="" className="img-fluid"/>
                        <img
                            src={require('../images/logo.png')}
                            alt="Gibi"
                            className="img-fluid"
                        />
                    </a>
                    {headerButtons}
                    {toastContainers}
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={() => {
                            setShowMenu(!showMenu)
                            const mediaQuery = window.matchMedia('(max-width: 768px)')
                            // showMenu is true if the menu is being closed
                            if (mediaQuery.matches && showMenu) {
                                navigate('/dashboard')
                            }
                        }}
                    >
                        <i className={(showMenu === true ? 'fa-solid fa-xmark' : 'fa fa-bars')} aria-hidden="true"></i>
                    </button>
                    <div
                        className={'collapse navbar-collapse ' + (showMenu === true ? 'show scrollbar' : 'scrollbar')}
                        id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {width < 767 && (
                                <p className="text-white">Hi, {useAuthStore.getState()?.firstName} </p>
                            )}
                            {/* {width > 767 && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/dashboard"
                                        className="nav-link"
                                        onClick={() => useUIStore.getState().toggleInAccount()}
                                    >
                                        <i className="fa fa-tachometer me-2" aria-hidden="true"></i>
                                        Dashboard
                                    </NavLink>
                                </li>
                            )} */}


                            {/* Mobile View for Dashboard */}
                            {width < 767 && (
                                <li className="nav-item dropdown">
                                    <NavLink
                                        className={'nav-link ' + (urlName === 'my' ? 'active' : '')}
                                        onClick={() => {
                                            useUIStore.getState().toggleInAccount()
                                            viewMoblieDashboardTop()
                                        }}
                                    >
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>
                                        Dashboard
                                    </NavLink>
                                    {showMobileDashboardView == true && <MobileDashboardTopMenu/>}
                                </li>
                            )}

                            <li
                                className="nav-item"
                                onClick={() => {
                                    setheaderOpenSidebar(false)
                                }}
                            >
                                {/* Web View */}
                                {/* {width > 767 && (
                                    <NavLink
                                        to="/my-account"
                                        className={'nav-link ' + (urlName === 'my' ? 'active' : '')}
                                        onClick={() => useUIStore.getState().toggleInAccount()}
                                    >
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>
                                        My Account
                                    </NavLink>
                                )} */}
                            </li>
                            {/* Mobile View */}
                            {width < 767 && (
                                <li className="nav-item dropdown">
                                    <NavLink
                                        className={'nav-link ' + (urlName === 'my' ? 'active' : '')}
                                        onClick={() => viewMoblieTop()}
                                    >
                                        <i className="fa fa-user me-2" aria-hidden="true"></i>
                                        My Account
                                    </NavLink>
                                    {showMobileView === true && <MobileTopMenu/>}
                                </li>
                            )}
                            {width > 767 && (
                                <li className="nav-item dropdown">
                                    <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                        <div className='text-white'>Welcome {firstName} </div>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant=""
                                                id="dropdown-basic"
                                                className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                                            >
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => logout()}>
                                                    Log Out
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </li>
                            )}
                            {width < 767 && (
                                <li className="nav-item dropdown">
                                    <button className="btn btn-primary-outline logout"
                                            onClick={() => logout()}>Logout <i
                                        className="fa-solid fa-arrow-right-from-bracket ms-3"></i></button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Header
