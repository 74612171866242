import axios from 'axios';
import useAuthStore from '../store/useAuthStore'

export const post = async (url, data, userToken) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_BE_URL}${url}`,
            headers: {Authorization: `Bearer ${userToken}`},
            data
        })
        if (response.status === 200) {
            return response;
        }
        return []
    } catch(err) {
        console.log('postWithCredentials', err);
        useAuthStore.getState().setToken(null)
        return []
    }
}

export const get = async (url, userToken) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_BE_URL}${url}`,
            headers: {Authorization: `Bearer ${userToken}`},
        })
        if (response.status === 200) {
            return response
        }
        return []
    } catch(err) {
        console.log('getWithCredentials', err);
        useAuthStore.getState().setToken(null)
        return []
    }
}

export const unsecureGet = async (url) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_BE_URL}${url}`,
        })
        if (response.status === 200) {
            return response
        }
        return []
    } catch(err) {
        console.log('unsecureGet', err);
        return []
    }
}

export const remove = async(url,userToken)=>{
    try {
        const response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BE_URL}${url}`,
            headers: {Authorization: `Bearer ${userToken}`},
        })
        if (response.status === 200) {
            return response
        }
        return []
    } catch(err) {
        console.log('deleteWithCredentials', err);
        useAuthStore.getState().setToken(null)
        return []
    }
}

export const put = async (url, data, userToken) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_BE_URL}${url}`,
            headers: {Authorization: `Bearer ${userToken}`},
            data
        })
        if (response.status === 200) {
            return response;
        }
        return []
    } catch(err) {
        console.log('putWithCredentials', err);
        useAuthStore.getState().setToken(null)
        return []
    }
}

export const awsFileUpload = async (url, data) => {
    try {
        const response = await axios({
            method: 'put',
            url: url,
            // headers: {Authorization: `Bearer ${userToken}`},
            data
        })
        if (response.status === 200) {
            return response;
        }
        return []
    } catch(err) {
        console.log('awsFileUpload Error', err);
        return []
    }
}

export const getUserIPaddress  = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    useAuthStore.getState().setUserIP(res.data.ip);
};
