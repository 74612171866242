import React from 'react'
import { useNavigate } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import useUIStore from '../../store/useUIStore'

function MobileTopMenu() {
    const navigate = useNavigate()
    const setShowMenu = useUIStore((s) => s.setShowMenu)
    return (
        <div>
            <ul className={'nav-list ps-0  mini-sidebar mobile-list'}>
                <li>
                    <OverlayTrigger placement="auto"
                                    overlay={<Tooltip id="tooltip-disabled">Click to view Account & Billing.</Tooltip>}>
                        <div onClick={() => {
                            setShowMenu(false)
                            navigate('/my-account')
                        }} className="nav-link" activeclassname="active">
                            <i className={'fa fa-file-text'} aria-hidden="true"></i> <span> Account & Billing</span>
                        </div>
                    </OverlayTrigger>

                </li>
                <li>
                    <OverlayTrigger placement="auto"
                                    overlay={<Tooltip id="tooltip-disabled">Click to view Pets.</Tooltip>}>
                        <div onClick={() => {
                            setShowMenu(false)
                            navigate('/my-pet')
                        }} className="nav-link" activeclassname="active">
                            <i className={'fa fa-paw'} aria-hidden="true"></i><span>Pets</span>
                        </div>
                    </OverlayTrigger>
                </li>
                <li>
                    <OverlayTrigger placement="auto"
                                    overlay={<Tooltip id="tooltip-disabled">Click to view Trackers.</Tooltip>}>
                        <div onClick={() => {
                            setShowMenu(false)
                            navigate('/my-device')
                        }} className="nav-link" activeclassname="active">
                            <img src={require('../../images/deviceVertIcon.png')}
                                 style={{width:'15px', marginRight:'10px',transform: 'rotateX(180deg)'}}
                            />
                           <span>Gibi Trackers</span>
                        </div>
                    </OverlayTrigger>

                </li>
                <li>
                    <OverlayTrigger placement="auto"
                                    overlay={<Tooltip id="tooltip-disabled">Click to view Contacts.</Tooltip>}>
                        <div onClick={() => {
                            setShowMenu(false)
                            navigate('/my-contact')
                        }} className="nav-link" activeclassname="active">
                            <i className={'fa fa-users'} aria-hidden="true"></i><span>Contacts</span>
                        </div>
                    </OverlayTrigger>
                </li>
            </ul>

        </div>
    )
}

export default MobileTopMenu
