import useAuthStore from '../store/useAuthStore'
import React, { useEffect, useState } from 'react'
import usePetsDevicesStore from '../store/usePetsDevicesStore'
import ReconnectingWebSocket from 'reconnecting-websocket'
import usePetHistoryStore from '../store/usePetHistoryStore'
import useEventStore from '../store/useEventStore'
import { getPets } from '../helpers/petHelpers'
import { alertCommandResponse, alertDeviceUpdate, alertFind, alertSafeZone } from './webSocketHelper'
import { getUserIPaddress } from '../helpers/apiHelper'
import useUIStore from '../store/useUIStore'
import { reloadAllStoresAndResetMap } from '../helpers/stateHelper'
import { toast } from 'react-toastify';

export const WebSocket = () => {
    const [notStarted, setNotStarted] = useState(true)
    const userToken = useAuthStore((s) => s.token)
    const userIP = useAuthStore(s => s.userIP)
    const updateDevice = usePetsDevicesStore((s) => s.updateDeviceStatus)
    const addToLastTenLocations = usePetHistoryStore((s) => s.addToLastTen)
    const addToLastTenEvents = useEventStore((s) => s.addToLastTen)
    const loadPetMap = usePetsDevicesStore((s) => s.loadPetMap)
    const loadDeviceMap = usePetsDevicesStore((s) => s.loadDeviceMap)
    const updateLastKnownPosition = usePetHistoryStore((s) => s.updateLastKnownPosition)

    useEffect(() => {
        getUserIPaddress().then(() => console.log('got ws uuid'))
    }, [])

    const processWebSocketMessage = (message) => {
        if (message.status === 'ok') {
            useUIStore.getState().setWebsocketConnected(true)
            return
        }
        if (message.type === 'echo') {
            useUIStore.getState().setWebsocketConnected(true)
            return
        }

        switch (message.type) {
            case 'POSITION_UPDATE':
                addToLastTenLocations(message.iccid, message.locationReport)
                if (message.locationReport.properties.status === 1) {
                    updateLastKnownPosition(message.iccid, message.locationReport)
                }
                if (message.isFind) {
                    alertFind(message)
                }
                if (message.deviceStatus) {
                    const s = Object.assign({}, message.deviceStatus)
                    updateDevice(message.iccid, s)
                }
                break
            case'COMMAND_RESPONSE':
                addToLastTenEvents(message.iccid, message)
                // make sure we have the latest pet status after a command response
                getPets(userToken).then(({devices, pets}) => {
                    if (devices) {
                        loadDeviceMap(devices)
                    }
                    if (pets) {
                        loadPetMap(pets)
                    }
                })
                alertCommandResponse(message)
                if (message.deviceStatus) {
                    const s = Object.assign({}, message.deviceStatus)
                    updateDevice(message.iccid, s)
                }
                break
            case 'SAFE_ZONE':
                addToLastTenEvents(message.iccid, message)
                alertSafeZone(message)
                if (message.deviceStatus) {
                    const s = Object.assign({}, message.deviceStatus)
                    updateDevice(message.iccid, s)
                }
                break
            case 'DEVICE_UPDATE':
                // const d = devices[message.iccid]
                // if (d) {
                const status = Object.assign({}, message.deviceUpdate.deviceStatus)
                updateDevice(message.iccid, status)
                // }
                if (message.deviceUpdate.subType !== 'DeviceStatusChanged') {
                    addToLastTenEvents(message.iccid, message)
                }
                alertDeviceUpdate(message)
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (userToken && notStarted && userIP !== '') {
            const startWebSocket = (userToken) => {
                const login = () => {
                    const msg = {
                        type: 'login',
                        token: userToken
                    }
                    // ws.current.send(JSON.stringify(msg))
                    rws.send(JSON.stringify(msg))
                }

                // tag url with user ip so we only keep one connection to the client open
                const url = process.env.REACT_APP_WEBSOCKET_URL + `/${userIP}`
                // const socket = new WebSocket(url);
                const rws = new ReconnectingWebSocket(url)

                rws.addEventListener('open', () => {
                    login()
                })

                rws.addEventListener('close', () => {
                    useUIStore.getState().setWebsocketConnected(false)
                })

                rws.addEventListener('error', (error) => {
                    console.log('websocket error', error)
                    useUIStore.getState().setWebsocketConnected(false)
                })

                rws.addEventListener('message', (event) => {
                    let message
                    try {
                        message = event && event.data ? JSON.parse(event.data) : {}
                        if (message.type === 'login' && message.status === 'ok') {
                            reloadAllStoresAndResetMap(userToken)
                            toast.dismiss()
                            useUIStore.getState().setWebsocketConnected(true)
                        }
                        else if (message.type !== 'echo') {
                            console.log('got message', message)
                            processWebSocketMessage(message)
                        }
                    } catch (e) {
                        console.log('web socket', e)
                    }
                })

                return () => {
                    rws.close()
                }
            }

            if (userToken) {
                console.log('websock', userToken)
                startWebSocket(userToken)
                setNotStarted(false)
            }
        }
    }, [userToken, userIP])

    return <div/>
}
