import React from "react";
import { useForm, Controller } from "react-hook-form";

const containerStyles = {
  border: "1px solid #fff",
  padding: "8px 10px 8px 10px",
  borderRadius: "4px",
  width: "100%",
  marginBottom: "15px",
  backgroundColor: "#fff",
};

const DevicePaymentSuccess = ({ updateStep, updateLoader }) => {
  return (
    <>
      <form method="POST">
        <h3 className="panel-title text-center mb-3">Payment Success</h3>
        <div className="view-data mb-3 px-3"></div>
        <div style={containerStyles}>
          <div className="  text-center">
            <p className="fw-bold">Thank you for getting Gibi!</p>
            <p className="fw-bold">
              New Gibi Tracker added to your account.
            </p>
          </div>
        </div>
      </form>
    </>
  );
};
export default DevicePaymentSuccess;
