import { useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import Modal from "../../../components/Modal/Modal";
import axios from 'axios';

const ReactAvatarEditor = ({
	showModal,
	onModalClose,
	imgURL,
	onSaveHandler,
}) => {
	const EditorRef = useRef(null);

	const showCroppedImage = async () => {
		if (EditorRef.current) {
			const img = EditorRef.current.getImage().toDataURL();
			console.log(img);

			const filename = '110.png'; // Replace with your desired filename
			const file = await dataURLtoFile(img, filename);
			console.log(file);
			let fileType = 'png';
			let fileKey='110.png';

// const ImgResponse = await ImageUpload(fileKey, fileType);
// console.log(ImgResponse);

// if (ImgResponse != false) {
// 	const aws_url = ImgResponse.data;
// 	const imageResponse = await fileUploadAWS(
// 	  aws_url,
// 	  file,

// 	);

// 	console.log(imageResponse);
// }

			return file;
		}
	};


	const  dataURLtoFile = async(dataURL, filename) =>{
		const arr = dataURL.split(',')
		const	mime = arr[0].match(/:(.*?);/)[1]
		const	bstr = atob(arr[1])
		let	n = bstr.length
		const	u8arr = new Uint8Array(n)

		while (n--) {
		  u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	  }

	return (
		<Modal
			showModal={showModal}
			onSaveHandler={async () => onSaveHandler(await showCroppedImage())}
			onModalClose={onModalClose}
		>
			<Wrapper className="w-full h-full flex flex-col items-center justify-center flex-column modalBody">
				<AvatarEditor
					ref={EditorRef}
					image={imgURL}
					width={250}
					height={250}
					border={0}
					scale={1.2}
					color={[255, 255, 255, 0.6]}
				/>
				<p className="mt-3" style={{Color:'#000'}}>Drag the Image to center your pet</p>
			</Wrapper>
		</Modal>
	);
};

const Wrapper = styled.div`
	background: #ccc;
	backdrop-filter: blur(2px);
`;

export default ReactAvatarEditor;
