import create from 'zustand';

let store = (set) => ({
    bgiOptions: [],
    alertOptions: [],
    timezones: [],

    loadBgiOptions: (options) => {
        set((state) => {
            return {
                ...state,
                bgiOptions: options,
            }
        })
    },

    loadAlertOptions: (options) => {
        set((state) => {
            return {
                ...state,
                alertOptions: options,
            }
        })
    },

    loadTimezones: (options) => {
        set((state) => {
            return {
                ...state,
                timezones: options,
            }
        })
    },
});

const useGibiConfigStore = create(store);

export default useGibiConfigStore;
