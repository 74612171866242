import React from "react";
import { NavLink } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const AdminSidebar = ({ isOpenSidebar, updateSidebar }) => {
  return (
    <>
      <nav
        id="sidebar"
        className={"sidebar-wrapper " + (!isOpenSidebar ? "show" : "")}
      >
        <div className="sidebar-content ">
          <div className="sidebar-brand">
            <a href="#" className="navbar-brand">
              <img
                src={require("../../images/admin-logo.png")}
                alt="Gibi"
                className="img-fluid"
              />
            </a>
          </div>
          <div
            className={"sidebar-menu " + (isOpenSidebar ? "mini-sidebar" : " ")}
          >
            <ul className="nav">
              <li>
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-disabled">View Dashboard</Tooltip>
                  }
                >
                  <NavLink to="/admin-dashboard" className="nav-link">
                    <span className="icon dashboard"></span>
                    <span className="nav-name">Dashboard</span>
                  </NavLink>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-disabled">View User List</Tooltip>
                  }
                >
                  <NavLink to="/user-list" className="nav-link">
                    <span className="icon user-list"></span>
                    <span className="nav-name">User List</span>
                  </NavLink>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip id="tooltip-disabled">View Tracker</Tooltip>}
                >
                  <NavLink to="/icc-ids" className="nav-link">
                    <span className="icon iccid"></span>
                    <span className="nav-name">Devices</span>
                  </NavLink>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      View Subscription Plans
                    </Tooltip>
                  }
                >
                  <NavLink to="/sub-plan" className="nav-link">
                    <span className="icon sub-plan"></span>
                    <span className="nav-name">Subscription Plans</span>
                  </NavLink>
                </OverlayTrigger>
              </li>

              <li>
                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip id="tooltip-disabled">View Pets</Tooltip>}
                >
                  <NavLink to="/petSettings" className="nav-link">
                    <span className="icon pet-setting"></span>
                    <span className="nav-name">Pets</span>
                  </NavLink>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-disabled">View Settings</Tooltip>
                  }
                >
                  <NavLink to="/settings" className="nav-link">
                    <span className="icon setting"></span>
                    <span className="nav-name">Settings</span>
                  </NavLink>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebar-footer p-3">
          <div>
            <button
              id="show-sidebar"
              className="btn btn-sm border-0 d-flex align-items-center justify-content-center"
              onClick={updateSidebar}
            >
              <img
                src={
                  isOpenSidebar === true
                    ? require("../../images/maximize_.png")
                    : require("../../images/minimize_.png")
                }
                alt=""
                style={{ height: "25px" }}
              />
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};
export default AdminSidebar;
