import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
function UserModal(props) {
    const {showUserList,setShowUserList,viewUserInfo,noPets,location}=props
//     useEffect(() => {
 
//         fetchAllUserDetails();

// }, [showUserList]);

  return (
    <Modal
    size="xl"
    show={showUserList}
    onHide={() => setShowUserList(false)}
    aria-labelledby="modal-sizes-title-lg"
    centered
>
    <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-lg">
            User List
        </Modal.Title>
    </Modal.Header>
    <Modal.Body className='scrollbar text-align-justify'>
        <div className=" row px-5">
            <div className='col-sm-4'>
                <div className="form-group">
                    <label className="form-label">Name : </label>
                    <span className="data-label">{viewUserInfo.first_name}</span>
                </div>
            </div>
            <div className='col-sm-4'>
                <div className="form-group">
                    <label className="form-label">Contact No : </label>
                    <span className="data-label"> {viewUserInfo.mobile}</span>
                </div>
            </div>


            <div className='col-sm-4'>
                <div className="form-group">
                    <label className="form-label">Email : </label>
                    <span className="data-label"> {viewUserInfo.email}</span>
                </div>
            </div>
        </div>
        <div className=" row px-5">

            <div className='col-sm-4'>
                <div className="form-group">
                    <label className="form-label">Time Zone : </label>
                    <span className="data-label"> {location}</span>
                </div>
            </div>
            <div className='col-sm-4'>
                <div className="form-group">
                    <label className="form-label">No Of Pets : </label>
                    <span className="data-label"> {noPets}</span>
                </div>
            </div>
            <div className='col-sm-4'>
                <div className="form-group">
                    <label className="form-label">Location : </label>
                    <span className="data-label">{viewUserInfo.first_name}</span>
                </div>
            </div>
        </div>
    </Modal.Body>
</Modal>
  )
}

export default UserModal