import axios from 'axios';
import {toast} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert';

export const sendDeviceCommand = async (data, userToken) => {
    console.log('sending command', data)

    const url = `${process.env.REACT_APP_BE_URL}/api/v1/setting`
    let response
    try {
        const config = {
            method: 'post',
            url,
            headers: {
                'Accept': 'application/json',
                'Authorization':  `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify(data)
        };
        response = await axios(config)
    } catch (e) {
        response = e
    }
    return response
}

const handleConfirm = async (onClose, data, userToken) => {
    onClose()
    const response = await sendDeviceCommand(data, userToken)

    if (response.data?.status) {
        toast.success(response.data.message, {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            containerId: 'api',
        })
    }
    else {
        toast.info('Command is in queue. Please wait', {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            containerId: 'api',
        })
    }
}

export const confirmAndSaveSetting = (data, onCancel, userToken) => {
    confirmAlert({
        customUI: ({onClose}) => {
            return (
                <div className="custom-ui">
                    <div className="fa-3x">
                        <i className="fa-solid fa-paw fa-bounce"></i>
                    </div>
                    <h3>Are you sure want to update your Gibi?</h3>
                    <button className="btn btn-outline-primary min-width-120"
                            onClick={() => handleConfirm(onClose, data, userToken)}>
                        Yes
                    </button>
                    <button className="btn btn-outline-primary min-width-120" onClick={() => {
                        onClose();
                        onCancel()
                    }}>No</button>
                </div>
            );
        },
        closeOnClickOutside: false,
    });
}
