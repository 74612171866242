import classnames from 'classnames';
import Flatpickr from 'react-flatpickr';
import React from 'react';

export const DateTimePicker = (props) => {
    const {minDate, defaultValue, onChange, type} = props

    const dtpTypes = {
        'startDate': {
            id: 'sleepStartDate',
            placeholder: 'Start Date',
            options: {
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                minDate,
                disableMobile: true,
                altInputClass: classnames(
                    'form-control flatpickr-input ',
                ),
            },
        },
        'endDate': {
            id: 'sleepEndDate',
            placeholder: 'End Date',
            options: {
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                minDate,
                disableMobile: true,
                altInputClass: classnames(
                    'form-control flatpickr-input ',
                ),
            },
        },
        'startTime': {
            id: 'sleepStartTime',
            placeholder: 'Start Time',
            options: {
                noCalendar: true,
                enableTime: true,
                altInput: true,
                dateFormat: 'h:i',
                disableMobile: true,
                altInputClass: classnames(
                    'form-control flatpickr-input ',
                ),
            },
        },
        'endTime': {
            id: 'sleepEndTime',
            placeholder: 'End Time',
            options: {
                noCalendar: true,
                enableTime: true,
                altInput: true,
                dateFormat: 'h:i',
                disableMobile: true,
                altInputClass: classnames(
                    'form-control flatpickr-input ',
                ),
            },
        },
    }

    const config = dtpTypes[type]
    if (config) {
        let def = defaultValue
        if (typeof defaultValue !== 'string') {
            def = defaultValue.toDateString()
        }
        return <Flatpickr
            defaultValue={def}
            id={config.id}
            options={config.options}
            placeholder={config.placeholder}
            onChange={onChange}
        />
    }
    return <div/>
}
