import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import useUIStore from '../../store/useUIStore';
import settings from '../../images/settings_w.png';

export const SettingsButton = (props) => {
    const {iccid} = props

    const setCurrentIccid = useUIStore((s) => s.setCurrentIccid)
    const setSettingModal = useUIStore((s) => s.setSettingModal)

    return (
        <li className="nav-item" role="presentation">
            <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip-disabled">Settings</Tooltip>}>
                <div className="sleep-button"
                        id="slippingModeModalBtn"
                        onClick={() => {
                            setCurrentIccid(iccid)
                            setSettingModal(true)
                        }}
                        >
                    <img src={settings} style={{
                        'height': '18px', 'width': '18px'
                    }}></img>
                </div>
            </OverlayTrigger>
        </li>
    )
}
