import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import find from "../../images/searchpet_w.png";
import React, {useEffect, useState} from 'react';
import {sendDeviceCommand} from "../SettingsModal/helpers/commandHelpers";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import useAuthStore from "../../store/useAuthStore";
import usePetsDevicesStore from '../../store/usePetsDevicesStore';
import {checkIfCommandInProgress} from '../../helpers/commandHelpers';

const handleConfirm = async ( userToken, data) => {
    
    const response = await sendDeviceCommand(data, userToken)

    if (response.data?.status) {
        toast.success(response.data.message, {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            containerId: 'api',
        })
    }
    else {
        toast.info('Command is in queue. Please wait', {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            containerId: 'api',
        })
    }
}

// export const findPet = (userToken, iccid, isSleeping, isCommandInProgress, isBeaconInRange) => {
//     confirmAlert({
//         customUI: ({onClose}) => {
//             const doFind = (<div className="custom-ui">
//                 <div className="fa-3x">
//                     <i className="fa-solid fa-paw fa-bounce"></i>
//                 </div>
//                 <h3>Are you sure want to send a Find?</h3>
//                 <button className="btn btn-outline-primary min-width-120" onClick={() => {
//                     handleConfirm(onClose, userToken, {iccid, command: 'find', value: ''})
//                 }}>
//                     Yes
//                 </button>
//                 <button className="btn btn-outline-primary min-width-120" onClick={onClose}>
//                     No
//                 </button>
//             </div>);

//             const cantHeader = isSleeping ? 'Gibi is Sleeping' : isCommandInProgress ? 'A Gibi command is in progress' : isBeaconInRange ? 'Gibi is in range of a beacon' : ''

//             const cantChange = (<div className="custom-ui">
//                 <div className="fa-3x">
//                     <i className="fa-solid fa-paw fa-bounce"></i>
//                 </div>
//                 <h3>{`Cannot Find Gibi. ${cantHeader}`}</h3>
//                 <button className="btn btn-outline-primary min-width-120" onClick={onClose}>
//                     Ok
//                 </button>
//             </div>);

//             return isSleeping || isBeaconInRange || isCommandInProgress ? cantChange : doFind

//         }, closeOnClickOutside: false,

//     });
// }
export const findPet = (userToken, iccid, isSleeping, isCommandInProgress, isBeaconInRange) => {

        
            const doFind = handleConfirm( userToken, {iccid, command: 'find', value: ''})
                

            const cantHeader = isSleeping ? 'Gibi is Sleeping' : isCommandInProgress ? 'A Gibi command is in progress' : isBeaconInRange ? 'Gibi is in range of a beacon' : ''

            const cantChange = (<div className="custom-ui">
                <div className="fa-3x">
                    <i className="fa-solid fa-paw fa-bounce"></i>
                </div>
                <h3>{`Cannot Find Gibi. ${cantHeader}`}</h3>
                {/* <button className="btn btn-outline-primary min-width-120" onClick={onClose}>
                    Ok
                </button> */}
            </div>);

            return isSleeping || isBeaconInRange || isCommandInProgress ? cantChange : doFind

    


}

export const FindPetControl = (props) => {
    const {iccid} = props
    const userToken = useAuthStore((s) => s.token)
    const devices = usePetsDevicesStore((s) => s.devices)
    const device = devices[iccid]
    const [isSleeping, setIsSleeping] = useState(false)
    const [isCommandInProgress, setIsCommandInProgress] = useState(false)
    const [isBeaconInRange, setIsBeaconInRange] = useState(false)
    const [_iccid, setIccid] = useState(iccid)

    useEffect(() => {
        setIccid(iccid)
    },[iccid])

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            setIsSleeping(device.status.sleeping)
            setIsCommandInProgress(checkIfCommandInProgress(device.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(device.status.beaconInRange)
        }
    }, [_iccid])

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            setIsSleeping(device.status.sleeping)
            setIsCommandInProgress(checkIfCommandInProgress(device.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(device.status.beaconInRange)
        }
    }, [devices])

    return (
        <li className="nav-item" role="presentation">
            <OverlayTrigger placement="auto" overlay={<Tooltip
                id="tooltip-disabled">Find Pet </Tooltip>}>
                <button
                    className="nav-link"
                    type="button"
                    onClick={() => {
                        findPet(userToken, iccid, isSleeping, isCommandInProgress, isBeaconInRange)
                    }}>
                    <img src={find} style={{
                        'height': '18px', 'width': '18px'
                    }}></img>

                </button>
            </OverlayTrigger>
        </li>
    )
}
