import Tooltip from "react-bootstrap/Tooltip";
import ledOn from "../../images/battery.png";
import ledOff from "../../images/battery_w.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React, {useEffect, useState} from 'react';
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import useAuthStore from "../../store/useAuthStore";
import usePetsDevicesStore from "../../store/usePetsDevicesStore";
import {sendDeviceCommand} from "../SettingsModal/helpers/commandHelpers";
import {checkIfCommandInProgress} from '../../helpers/commandHelpers';

const handleConfirm = async (onClose, userToken, data) => {
    onClose()
    const response = await sendDeviceCommand(data, userToken)

    if (response.data?.status) {
        toast.success(response.data.message, {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            containerId: 'api',
        })
    }
    else {
        toast.info('Command is in queue. Please wait', {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            containerId: 'api',
        })
    }
}

const changeLEDStatus = (userToken, iccid, ledState, isSleeping, isCommandInProgress, isBeaconInRange) => {
    const cantHeader = isSleeping ? 'Gibi is Sleeping' : isCommandInProgress ? 'A Gibi command is in progress' : isBeaconInRange ? 'Gibi is in range of a beacon' : ''

    confirmAlert({
        customUI: ({onClose}) => {
            const doChange = (<div className="custom-ui">
                <div className="fa-3x">
                    <i className="fa-solid fa-paw fa-bounce"></i>
                </div>
                <h3>Are you sure want to Change LED Status ?</h3>
                <button className="btn btn-outline-primary min-width-120"

                        onClick={() => {handleConfirm(onClose, userToken, {iccid, command: 'led', value: ledState})}}
                >
                    Yes
                </button>
                <button className="btn btn-outline-primary min-width-120" onClick={onClose}>
                    No
                </button>
            </div>);

            const cantChange = (<div className="custom-ui">
                <div className="fa-3x">
                    <i className="fa-solid fa-paw fa-bounce"></i>
                </div>
                <h3>{`Cannot change LED. ${cantHeader}`}</h3>
                <button className="btn btn-outline-primary min-width-120" onClick={onClose}>
                    Ok
                </button>
            </div>);

            return cantHeader ? cantChange : doChange
        }, closeOnClickOutside: false,
    });
}

export const LedControl = (props) => {
    const {iccid} = props
    const userToken = useAuthStore((s) => s.token)
    const devices = usePetsDevicesStore((s) => s.devices)
    const device = devices[iccid]
    const [buttonState, setButtonState] = useState(0)
    const [isSleeping, setIsSleeping] = useState(false)
    const [isCommandInProgress, setIsCommandInProgress] = useState(false)
    const [isBeaconInRange, setIsBeaconInRange] = useState(false)
    const [_iccid, setIccid] = useState(iccid)

    useEffect(() => {
        setIccid(iccid)
    },[iccid])

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            setButtonState(Number(device.status.ledState))
            setIsSleeping(device.status.sleeping)
            setIsCommandInProgress(checkIfCommandInProgress(device.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(device.status.beaconInRange)
        }
    }, [_iccid])

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            setButtonState(Number(device.status.ledState))
            setIsSleeping(device.status.sleeping)
            setIsCommandInProgress(checkIfCommandInProgress(device.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(device.status.beaconInRange)
        }
    }, [devices])

    return (
        <li className={'nav-item'} role="presentation">
            <OverlayTrigger placement="auto" overlay={<Tooltip
                id="tooltip-led">{`Turn Gibi LED ${buttonState ? 'off' : 'on'}`} </Tooltip>}>
                <div
                    className="sleep-button"
                    onClick={() => {
                        changeLEDStatus(userToken, _iccid, buttonState ? 0 : 1, isSleeping, isCommandInProgress, isBeaconInRange)
                    }}>
                    <img
                        src={buttonState ? ledOn : ledOff}
                        style={{
                            'height': '18px', 'width': '18px'
                        }}></img>
                </div>
            </OverlayTrigger>
        </li>
    )
}
