import React, {useEffect, useState} from 'react';

import {Modal} from 'react-bootstrap';
import '../Date.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {AdvancedSettingForm} from './advancedSettings/AdvancedSettingForm';
import {SettingsForm} from './settings/SettingsForm';
import useUIStore from '../../store/useUIStore';
import usePetsDevicesStore from '../../store/usePetsDevicesStore';
import useAuthStore from '../../store/useAuthStore';
import {confirmAndSaveSetting} from './helpers/commandHelpers';
import {checkIfCommandInProgress} from '../../helpers/commandHelpers';
import useGibiConfigStore from '../../store/useGibiConfigStore' // Import css
import AlertsModal from '../AlertsModal/AlertsModals'

const SettingsModals = (props) => {
    const settingsModalOpen = useUIStore((s) => s.settingsModalOpen)
    const setSettingModal = useUIStore((s) => s.setSettingModal)
    const iccid = useUIStore((s) => s.currentIccid)

    const [curIccid, setCurIccid] = useState(iccid)
    const [isDeviceCommandInProgress, setIsDeviceCommandInProgress] = useState(false)
    const [isBeaconInRange, setIsBeaconInRange] = useState(false)
    const [isInCharger, setIsInCharger] = useState(false)
    const [isSleeping, setIsSleeping] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [command, setCommand] = useState('')
    const [commandVal, setCommandVal] = useState(0)
    const [origDevice, setOrigDevice] = useState(null)

    const [showAdvancedSetting, setShowAdvancedSetting] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);

    const devices = usePetsDevicesStore((s) => s.devices)
    const userToken = useAuthStore((s) => s.token)
    const bgiOptions = useGibiConfigStore((s) => s.bgiOptions)
    const alertOptions = useGibiConfigStore((s) => s.alertOptions)

    useEffect(() => {
        setCurIccid(iccid)
        if (iccid) {
            const d = devices[iccid]
            if (d) {
                setOrigDevice(d)
                setIsDeviceCommandInProgress(checkIfCommandInProgress(d.status.deviceCommandInProgressStatus))
                setIsBeaconInRange(d.status.beaconInRange)
                setIsInCharger(d.status.charging)
                setIsSleeping(Boolean(d.status.sleeping))
            }
        }
    }, [iccid])

    const closeModal = () => {
        setSettingModal(false)
        setCommand('')
        setCommandVal(0)
    }

    const updateSetting = (key, value) => {
        console.log(key, value)
        switch (key) {
            case 'device':
                setCurIccid(value)
                if (value) {
                    const d = devices[value]
                    if (d) {
                        setIsDeviceCommandInProgress(d.status.deviceCommandInProgressStatus !== '')
                        setIsBeaconInRange(d.status.beaconInRange)
                        setIsInCharger(d.status.charging)
                    }
                }
                break
            case 'bgi':
                setCanSave(true)
                setCommand('bgi')
                setCommandVal(value)
                break
            case 'powerSave':
                setCommand('powerSave')
                setCommandVal(value)
                break
            case 'ncs':
                setCommand('ncs')
                setCommandVal(value)
                break
            case 'mt':
                setCommand('mt')
                setCommandVal(value)
                break
            case 'alerts':
                setCommand('alerts')
                setCommandVal(value)
                break
        }
    }

    const onCancel = () => {
       closeModal()
    }

    const saveSetting = async (cmd, cmdVal) => {
        const c = command || cmd
        const v = commandVal || cmdVal
        console.log('update device', curIccid,  c, v)
        closeModal()
        await confirmAndSaveSetting({iccid: curIccid, command: c, value: v}, onCancel, userToken)
        setShowAdvancedSetting(false)
    }
    const setCurrentIccid = useUIStore((s) => s.setCurrentIccid)
    const setAlertsModal = useUIStore((s) => s.setAlertsModal)
    const alertButton = (
        <button
            type="button"
            onClick={() => {
                setCurrentIccid(curIccid)

                setShowAlerts(true);
                setTimeout(() => {
                    closeModal();
                    setAlertsModal(true)
                }, 500);
            }}
            className="btn btn-outline-primary min-width-120"
            id="aletrlBtn"
            data-dismiss="modal"
            data-target="#settingModal"
            disabled={canSave}
        >
            Alerts
        </button>
    )
    const advancedSettingButton = (
        <button
            type="button"
            onClick={() => {
                setShowAdvancedSetting(true);
            }}
            className="btn btn-outline-primary min-width-120"
            id="advanceSettingModalBtn"
            data-dismiss="modal"
            data-target="#settingModal"
            disabled={canSave}
        >
            Advanced Settings
        </button>
    )

    const saveButton = (
        <div>
            <span>
                <button
                    className="btn btn-outline-primary min-width-120"
                    disabled={isDeviceCommandInProgress || (isBeaconInRange && !isInCharger) || isSleeping || !canSave}
                    onClick={saveSetting}
                >
                    Save
                </button>
            </span>
        </div>
    )

    const sleepingHeader = (
        <div className="warning">
            <Modal.Title id="modal-sizes-title-lg">Cannot change settings. Gibi is Sleeping</Modal.Title>
        </div>
    )

    const commandInProgressHeader = (
        <div className="warning">
            <Modal.Title id="modal-sizes-title-lg">Cannot change settings. A Gibi command is in progress</Modal.Title>
        </div>
    )

    const beaconInRangeHeader = (
        <div className="warning">
            <Modal.Title id="modal-sizes-title-lg">Cannot change settings. Gibi is in range of a beacon.</Modal.Title>
        </div>
    )

    const extraHeader = isSleeping ? sleepingHeader : isDeviceCommandInProgress ? commandInProgressHeader : (isBeaconInRange && !isInCharger) ? beaconInRangeHeader : null

    return settingsModalOpen ? (
        <Modal
            show={true}
            onHide={closeModal}
            aria-labelledby="modal-sizes-title-lg"
            backdrop="static"
            centered
            className="h-100"
        >
            <div>
                {showAdvancedSetting ?
                    <AdvancedSettingForm
                        setShowAdvancedSetting={setShowAdvancedSetting}
                        iccid={curIccid}
                        isDisabled={isDeviceCommandInProgress || (isBeaconInRange && !isInCharger) || isSleeping}
                        checkInRateOptions={bgiOptions}
                        alertTypes={alertOptions}
                        onChange={updateSetting}
                        saveSetting={saveSetting}
                        isSleeping={isSleeping}
                        isCommandInProgress={isDeviceCommandInProgress}
                        extraHeader={extraHeader}
                    />
                      :
                    <SettingsForm
                    setShowAdvancedSetting={setShowAdvancedSetting}
                    iccid={curIccid}
                    checkInRateOptions={bgiOptions}
                    onChange={updateSetting}
                    extraHeader={extraHeader}
                />
                    }
                      {showAlerts ? <AlertsModal />  : null}
            </div>
            {!showAdvancedSetting ? <Modal.Footer>
                <ul className="list-inline w-100">
                    <li className="list-inline-item float-start">
                        {alertButton}
                    </li>
                    {/*<li className="list-inline-item float-start">*/}
                    {/*    {advancedSettingButton}*/}
                    {/*</li>*/}
                    <li className="list-inline-item float-end">
                        {saveButton}
                    </li>
                </ul>
            </Modal.Footer> : null}
        </Modal>
    ) : null;


};
export default SettingsModals;
