import Flatpickr from 'react-flatpickr'
import flatpickr from "flatpickr";
import React, { useEffect, useState } from 'react'
import usePetHistoryStore from '../../store/usePetHistoryStore'
import { DateTime, Settings } from 'luxon'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Accordion } from 'react-bootstrap'
import './PetHistory.css'
import { getLastTenLocations } from '../../helpers/petHelpers'
import useAuthStore from '../../store/useAuthStore'
import { getPetHistory } from '../../helpers/historyHelper'
import useMapStore from '../../store/useMapStore'
import { getEventIcon, getEventLabel } from '../../helpers/alertHelpers'
import { DraggableBox } from '../DraggableBox'
import useUIStore from '../../store/useUIStore'
import usePetsDevicesStore from '../../store/usePetsDevicesStore'
import {Collapse} from 'react-collapse';

export const PetHistorySection = (props) => {
    const [historyList, setHistoryList] = useState([])
    const [selectedDate, setSelectedDate] = useState('')
    const [listItems, setListItems] = useState(null)
    const [chooseDate, setChooseDate] = useState(false)

    const [dayHistory, setDayHistory] = useState([])
    const [dayIndex, setDayIndex] = useState(-10)
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [isCollapseOpen, setIsCollapseOpen] = useState(true)

    const userToken = useAuthStore((s) => s.token)

    const allLastTenLocations = usePetHistoryStore((s) => s.lastTen)
    const loadLastTen = usePetHistoryStore((s) => s.loadLastTen)
    const lastKnownPositions = usePetHistoryStore((s) => s.lastKnown)

    const allHistories = usePetHistoryStore((s) => s.history)
    const loadHistory = usePetHistoryStore((s) => s.loadHistory)
    const setMarkers = useMapStore((s) => s.setMarkers)

    const setMapCenter = useMapStore((s) => s.setMapCenter)
    const setHighlightedFeature = useMapStore((s) => s.setHighlightedFeature)

    const iccid = useUIStore(s => s.currentIccid)
    const pets = usePetsDevicesStore(s => s.pets)
    const devices = usePetsDevicesStore(s => s.devices)
    const showHistory = usePetHistoryStore(s => s.showHistory)

    const [pet, setPet] = React.useState(null)

    const setMapMarkers = (m) => {
        if (showHistory) {
            setMarkers(m)
        }
    }

    async function fetchLastTenLocations(iccid) {
        const locations = await getLastTenLocations(iccid, userToken)
        loadLastTen(iccid, locations)
        setHistoryList(locations || [])
    }

    useEffect(() => {
        // populate the stores
        fetchLastTenLocations(iccid).then(() => console.log('mount: last 10 locations loaded'))
    }, [])

    useEffect(() => {
        if (iccid && pets && devices) {
           const device = devices[iccid]
           const newPet = pets[device.itemId]
            setPet(newPet)
        }
    }, [iccid, pets, devices])

    useEffect(() => {
        if (iccid) {
            if (!selectedDate) {
                setHistoryList(allLastTenLocations[iccid] || [])
            }
        }
    }, [allLastTenLocations])

    useEffect(() => {
        if (iccid) {
            if (selectedDate) {
                setHistoryList(allHistories[iccid] || [])
            }
        }
    }, [allHistories])

    useEffect(() => {
        if (historyList && historyList.length) {
            console.log('historyList', historyList)

            // const listItems = generateLocationList(historyList)
            // setListItems(listItems)
            // setMapMarkers(historyList)
            setDayIndex(0)
            setDayHistory(historyList.slice(0, 10))
        } else {
            setDayHistory([])
        }
    }, [historyList])

    useEffect(() => {
        if (dayHistory && dayHistory.length) {
            console.log('dayHistory', dayHistory)
            const listItems = generateLocationList(dayHistory)
            setListItems(listItems)
            setMapMarkers(dayHistory)
            const zone = useAuthStore.getState().timezone.value
            const start = DateTime.fromISO(dayHistory[0].properties?.receivedTime, {zone}).toLocaleString(DateTime.TIME_SIMPLE)
            const end = DateTime.fromISO(dayHistory[dayHistory.length-1].properties?.receivedTime, {zone}).toLocaleString(DateTime.TIME_SIMPLE)
            setStartTime(start)
            setEndTime(end)
        } else {
            setListItems([])
            setMapMarkers([])
            setStartTime('')
            setEndTime('')
        }
    }, [dayHistory])

    useEffect(() => {
        if (iccid) {
            if (!selectedDate) {
                setHistoryList(allLastTenLocations[iccid] || [])
            }
            else {
                setHistoryList(allHistories[iccid] || [])
            }
        }
    }, [selectedDate])

    useEffect(() => {
        if (showHistory) {
            // populate the stores
            fetchLastTenLocations(iccid).then(() => console.log('open: last 10 locations loaded'))
        } else {
            setMarkers(Object.values(lastKnownPositions))
        }
    }, [showHistory])

    const onDaySearch = async (day) => {
        const date = DateTime.fromJSDate(day[0]).toISODate()
        const isoDate = DateTime.fromISO(`${date}T00:00`,{zone: useAuthStore.getState().timezone.value}) // .setZone('UTC')
        const epoch = isoDate.toSeconds()
        setSelectedDate(day[0])
        const locations = await getPetHistory(iccid, epoch, userToken)
        loadHistory(iccid, locations.data || [])
        setChooseDate(false)
    }

    const fp = flatpickr("#calendar", {
        maxDate: new Date(),
        dateFormat: 'l, M j Y',
        disableMobile: true,
        altInputClass : "invisible",
        index: true,
        clickOpens: false,
        onChange: onDaySearch,
    })

    useEffect(() => {
        if (chooseDate) {
            if (fp) {
                if (Array.isArray(fp)) {
                    if (fp.length)
                        fp[0].open()
                } else {
                    fp.open()
                }
            }
        } else {
            if (fp) {
                if (Array.isArray(fp)) {
                    if (fp.length)
                        fp[0].close()
                } else {
                    fp.close()
                }
            }
        }
    }, [chooseDate])

    // console.log('pet history section - show', showHistory)
    if (!pet) return null

    const pageForward = () => {
        if (allHistories[iccid]?.length - dayIndex <= 10) {
            return
        }
        const i = dayIndex + 10
        let remaining = 10
        if (i + 10 > allHistories[iccid].length) {
            remaining = allHistories[iccid].length - i
        }
        setDayIndex(i)
        const h = allHistories[iccid].slice(i, i+remaining)
        setDayHistory(h)
    }

    const pageBackward = () => {
        const i = Math.max(dayIndex - 10, 0)
        setDayIndex(i)
        setDayHistory(allHistories[iccid].slice(i, i+10))
    }

    const onTodaySearch = async () => {
        setSelectedDate(new Date())
        setChooseDate(true)
        const locations = await getPetHistory(iccid, undefined, userToken)
        loadHistory(iccid, locations.data)
    }

    const closeDateSearch = () => {
        setChooseDate(!chooseDate)
        setSelectedDate('')
        setHistoryList(allLastTenLocations[iccid])
    }

    const center = (feature) => {
        const loc = {
            lng: feature.properties.longitude,
            lat: feature.properties.latitude,
        }
        setMapCenter(loc)
    }

    const generateTitle = (pet, selectedDate) => {
        let formattedDate = selectedDate ? DateTime.fromJSDate(selectedDate, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATE_SHORT) : ''
        const dateString = `${formattedDate}`
        const title = `${selectedDate ? dateString : 'Last 10 locations'}`
        return title
    }

    // expects geojson objects
    const generateLocationList = (locations) => {
        return locations && locations.length ? Object.values(locations).map((feature, index) => {
            const dateTime = DateTime.fromISO(feature?.properties?.receivedTime, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
            const isFindResponse = feature.properties.gibiMessageType?.toLowerCase() === 'find'
            const eventLabel = getEventLabel({}, feature.properties.gibiMessageType )
            return (
                <div className={`history-container-col ${!feature.properties.status ? 'invalid-location':''}`}
                    key={`${feature?.properties?.timeStamp}-${index}` || index}
                    onMouseEnter={() => setHighlightedFeature(feature)}
                    onMouseLeave={() => setHighlightedFeature(null)}
                >
                    <li className="history-container-row">
                        <div onClick={() => feature.properties.status ? center(feature) : null}
                             className={'history-list-label'}
                             style={{marginRight: '4px'}}
                        >
                           {index + 1}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                            <div>
                                <h6 className="place-name">{`${feature.properties?.status ? 'Valid' : 'Invalid'} Location`}</h6>
                            </div>
                            <div>
                                {eventLabel ? <div className={'find-tag'}>{eventLabel}</div> : null}
                            </div>
                        </div>
                    </li>
                    <div className="date">
                        {dateTime}
                    </div>
                </div>
            )
        }) : (
            <p className="text-center text-white">
                No Locations Found
            </p>
        )
    }

    const onClose = () => {
        setHistoryList([])
        setSelectedDate('')
        setListItems(null)
        setChooseDate(false)
        setDayHistory([])
        setDayIndex(-10)
        setStartTime('')
        setEndTime('')
        setIsCollapseOpen(true)
        usePetHistoryStore.getState().toggleShowHistory()
    }

    const lastTenLocationsLabel = (
        <div className="label-row last-ten-label" key={`${iccid}-last-ten-label`}>
        </div>
    )

    const datePicker = (
        <div style={{position: 'absolute', zIndex: 200}} id={'calendar'}>
            {/*<Flatpickr*/}
            {/*    className="form-control white"*/}
            {/*    placeholder="Select Date"*/}
            {/*    id="petHistoryDate"*/}
            {/*    value={selectedDate}*/}
            {/*    options={{*/}
            {/*        maxDate: new Date(),*/}
            {/*        dateFormat: 'l, M j Y',*/}
            {/*        disableMobile: true,*/}
            {/*        altInputClass : "invisible",*/}
            {/*        index: true,*/}
            {/*        clickOpens: false,*/}
            {/*    }}*/}
            {/*    onChange={(date) => onDaySearch(date)}*/}
            {/*/>*/}
        </div>
    )

    const calendar = (
        <div style={{position: 'relative'}}>
            <OverlayTrigger
                key={`tooltip-ph-${iccid}-left`}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-ph-${iccid}-left`}>
                        Choose the day
                    </Tooltip>
                }
            >
                <div className={'label-icon-left'} onClick={() => {
                        setChooseDate(true)
                    }
                }>
                    <i className="fa-regular fa-calendar-days" style={{color: 'white'}}/>
                </div>
            </OverlayTrigger>
            {datePicker}
        </div>
    )

    const backControl = (
        <div>
            <i className="fa-solid fa-caret-left large-font"
               style={{color: dayIndex < 10 ? 'gray' : '#42b1d6'}}
               onClick={() => {
                   if (dayIndex > 9) {
                       pageBackward()
                   }
               }}
            />
        </div>
    )

    const forwardControl = (
        <div>
            <i className="fa-solid fa-caret-right large-font"
               style={{color: allHistories[iccid]?.length - dayIndex <= 10 ? 'gray' : '#42b1d6'}}
               onClick={() => {
                   if (allHistories[iccid].length - dayIndex >= 10) {
                       pageForward()
                   }
               }}
            />
        </div>
    )

    const timeHeader = (
        <div className={'label-row timer-header'}>
            {selectedDate ? backControl : null}
            <div>{selectedDate ? startTime : endTime}</div>
            <div className={'small-font'}
                 style={{cursor: selectedDate ? 'pointer': 'default'}}
                 onClick={() => {
                     if(selectedDate) setIsCollapseOpen(!isCollapseOpen)
                 }}
                 >to</div>
            <div>{selectedDate ? endTime: startTime}</div>
            {selectedDate ? forwardControl : null}
        </div>
    )

    const closeDayHistory = (
        <div>
            {selectedDate ? (
                    <div style={{position: 'relative'}}>
                        <OverlayTrigger
                            key={`tooltip-ph-${iccid}-left`}
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-ph-${iccid}-left`}>
                                    Close day history
                                </Tooltip>
                            }
                        >
                            <i className="fa-regular fa-circle-xmark"
                               style={{cursor: 'pointer', marginLeft: '8px'}}
                               onClick={() => {
                                   setSelectedDate('')
                                   setIsCollapseOpen(true)
                               }}
                               />
                        </OverlayTrigger>
                        {datePicker}
                    </div>
                )
                : null}
        </div>
    )
    const body = (
        <div style={{width:'100%'}}>
            <div className="tab-content" id="ph-tabContent">
                <div className="tab-pane fade show active"
                     id="ph-home" role="tabpanel"
                     aria-labelledby="ph-home-tab">
                    {timeHeader}
                    <Collapse isOpened={isCollapseOpen}>
                        <div className="place-list-scroll scrollbar">
                            <ul className="place-list ps-0">
                                {listItems && listItems.length ? listItems :  (
                                        <p className="text-center text-white">
                                            No Locations Found
                                        </p>
                                    )}
                            </ul>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    )

    return showHistory ? (
        <DraggableBox title={pet.petName}
                      subTitle={generateTitle(pet, selectedDate)}
                      header={'Location history'}
                      iconLeft={calendar}
                      iconRight={closeDayHistory}
                      width={'272px'}
                      onClose={onClose}>
                <div className={'history-section'}>
                    {body}
                </div>
        </DraggableBox>
    ) : null
}
