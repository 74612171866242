import {Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import usePetsDevicesStore from '../../../store/usePetsDevicesStore';
import {DateTimePicker} from '../components/DateTimePicker';

const _SingleSleepForm = (props) => {
    const {
        iccid = '',
        onChange = () => {
        },
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        extraHeader,
    } = props


    const devices = usePetsDevicesStore((s) => s.devices)

    const [formChange, setFormChange] = useState({})

    useEffect(() => {
        if (iccid) {
            const d = devices[iccid]
        }
    }, [iccid])

    useEffect(() => {
        if (formChange) {
            const {type, value} = formChange
            switch (type) {
                case 'startDate':
                    setStartDate(value[0])
                    break
                case 'endDate':
                    setEndDate(value[0])
                    break
                case 'startTime':
                    setStartTime(value[0])
                    break
                case 'endTime':
                    setEndTime(value[0])
                    break
            }
        }
    }, [formChange])

    const singleSleepForm = (
        <>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-md-12 col-sm-12">
                    <div className="setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Sleep
                            Starts </label>
                        <DateTimePicker type={'startDate'}
                                        minDate={new Date().toDateString()}
                                        defaultValue={startDate}
                                        onChange={(v) => setFormChange({type: 'startDate', value: v})}/>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-md-12 col-sm-12">
                    <div className=" setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Sleep
                            Ends </label>
                        <DateTimePicker type={'endDate'}
                                        minDate={startDate}
                                        defaultValue={endDate}
                                        onChange={(v) => setFormChange({type: 'endDate', value: v})}/>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-md-12 col-sm-12">
                    <div className="setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Start
                            Time </label>
                        <DateTimePicker type={'startTime'}
                                        minDate={startDate}
                                        defaultValue={startTime}
                                        onChange={(v) => setFormChange({type: 'startTime', value: v})}/>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-md-12 col-sm-12">
                    <div className=" setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">End
                            Time </label>
                        <DateTimePicker type={'endTime'}
                                        minDate={startDate}
                                        defaultValue={endTime}
                                        onChange={(v) => setFormChange({type: 'endTime', value: v})}/>
                    </div>
                </div>
            </div>

        </>
    )

    return (<>
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">Sleep Mode</Modal.Title>
            </Modal.Header>
            {extraHeader}
            <Modal.Body className="scrollbar overflow-unset">
                <div className="row">
                    <div className="col-lg-12">
                        {singleSleepForm}
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}

export const SingleSleepForm = _SingleSleepForm
