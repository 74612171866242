import { React, useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
// import Button from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

import DataTable from 'react-data-table-component';
import axios from "axios";
function Payment({ updateSidebar, isOpenSidebar }) {

    const [pageTitle, setPageTitle] = useState("Payments");
    const [showPaymentList, setShowPaymentList] = useState(false);
    const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL;
    const columns = [
        {
            name: 'User Name',
            selector: row => row.customer_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.customer_email,
            sortable: true,
        },
        {
            name: 'Paid Amount',
            selector: row => row.productPrice_unit_amount,
            sortable: true,
        },
        {
            name: 'Subcription Plan',
            selector: row => row.product_name,
            sortable: true,
        },

        {
            name: 'Date & Time',
            selector: row => row.subscription_current_period_start,
            sortable: true,
        },
        // {
        //     name: 'Action',
        //     selector: row => row.action,
        //     sortable: false,
        //     cell: row => (
        //         <div>
        //             <button className='icon-button me-3' id="productList" onClick={() => setShowPaymentList(true)}><i className="fa fa-eye"></i></button>
        //             <button className='icon-button'><i className="fa fa-trash"></i></button>
        //         </div>
        //     )
        //     // cell: () => <Button variant="danger" data-tag="allowRowEvents" data-action="delete"><FontAwesomeIcon icon={faTrash} /></Button>,
        // },
    ];

    const data = [
        {

            id: 1,
            uname: 'Sara',
            pname: 'Gibi Pet GPS Tracker Master Kit',
            tracker: '53546792332',
            subplan: 'Monthly Plan',
            transactionid: '123456',
            datetime: '2 jan 2022',
            action: '',
        },
        {
            id: 2,
            uname: 'Sara',
            pname: 'Gibi Pet GPS Tracker Master Kit',
            tracker: '53546792332',
            subplan: 'Monthly Plan',
            transactionid: '123456',
            datetime: '2 jan 2022',
            action: '',

        },
        {
            id: 3,
            uname: 'Sara',
            pname: 'Gibi Pet GPS Tracker Master Kit',
            tracker: '53546792332',
            subplan: 'Monthly Plan',
            transactionid: '123456',
            datetime: '2 jan 2022',
            action: '',
        },
        {
            id: 4,
            uname: 'Sara',
            pname: 'Gibi Pet GPS Tracker Master Kit',
            tracker: '53546792332',
            subplan: 'Monthly Plan',
            transactionid: '123456',
            datetime: '2 jan 2022',
            action: '',
        },
        {
            id: 5,
            uname: 'Sara',
            pname: 'Gibi Pet GPS Tracker Master Kit',
            tracker: '53546792332',
            subplan: 'Monthly Plan',
            transactionid: '123456',
            datetime: '2 jan 2022',
            action: '',
        },

    ]
    const [auxToFetch, setAuxToFetch] = useState(false);
    const [adminToken, setAdminToken] = useState(localStorage.getItem("adminToken"));

    const [payment, setPayment] = useState([]);
    const fetchAllPaymentDetails = () => {

        const config = { headers: { Authorization: `Bearer ${adminToken}` } };
        axios.get(REACT_APP_BE_URL + '/api/v1/getSubscriptionPayment', config).then(function (response) {
            if (response.data.status) {
                // setPetList(response.data.result)
         
                let payment = response.data.subscriptions;

                setPayment(payment);
                // setAuxToFetch(!auxToFetch);
                // setPetList(pets.map(( item ) => ({ value: item._id, label: item.petName}))); 
                // updateLoader(false);
            } else {
                // updateLoader(false);
            }
        }
        )
            .catch(console.log);
    }
    useEffect(() => {

        if (adminToken === "" || adminToken === null) {
            //   navigate('/admin-login');
        } else {
            fetchAllPaymentDetails();
            //   fetchAllPets(userToken);   
        }
    }, [auxToFetch]);

    const [filterText, setFilterText] = useState('');
    const filteredItems = payment.filter(
        item => item.customer_name && item.customer_name.toLowerCase().includes(filterText.toLowerCase())
            || item.customer_email && item.customer_email.toLowerCase().includes(filterText.toLowerCase()),
    );

    const onFilterData = (e) => { setFilterText(e.target.value) }
    const onFilterClear = () => { setFilterText(""); }
    //-----Search Filter Ends-----//

    return (
        <>
            <div className="page-wrapper chiller-theme toggled">
                <AdminSidebar isOpenSidebar={isOpenSidebar} updateSidebar={updateSidebar} />
                <main className="page-content ">
                    <AdminHeader pageTitle={pageTitle} isOpenSidebar={isOpenSidebar} />

                    <main className={"content-holder " + (isOpenSidebar != true ? 'show' : '')}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-wrapper">

                                        <div className="card">
                                            <div className="card-body p-0">
                                                <DataTable
                                                    columns={columns}
                                                    data={filteredItems}
                                                    defaultSortFieldId={1}
                                                    searchable
                                                    pagination
                                                    responsive
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                </main>
            </div>

            {/*View User List Modal */}
            <Modal
                size="xl"
                show={showPaymentList}
                onHide={() => setShowPaymentList(false)}
                aria-labelledby="modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">
                        Payment List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='scrollbar text-align-justify'>
                    <div className=" row px-5">
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label className="form-label">Name : </label>
                                <span className="data-label"> Sara</span>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label className="form-label">Product Name : </label>
                                <span className="data-label"> Gibi Pet GPS Tracker Master Kit</span>
                            </div>
                        </div>


                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label className="form-label">Gibi Tracker Id : </label>
                                <span className="data-label"> 53546792332</span>
                            </div>
                        </div>
                    </div>
                    <div className=" row px-5">

                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label className="form-label">Subcription Plan : </label>
                                <span className="data-label"> Monthly Plan</span>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label className="form-label">Transaction Id: </label>
                                <span className="data-label"> 123456</span>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label className="form-label">Date & Time : </label>
                                <span className="data-label"> 2 jan 2022</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Payment;
