import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { FindButton } from '../FindPetControl/FindButton'
import { Battery } from '../Battery/Battery'
import { SleepModeButton } from '../SleepModeModal/SleepModeButton'
import { LedControl } from '../LedControl/LedControl'
import { SettingsButton } from '../SettingsModal/SettingsButton'
import { PetHistorySection } from '../PetHistory/PetHistorySection'
import { ContainerHeader } from './ContainerHeader/ContainerHeader'
import './PetContainer.css'
import { EventsSection } from '../Events/EventSection'
import usePetsDevicesStore from '../../store/usePetHistoryStore'
import usePetHistoryStore from '../../store/usePetHistoryStore'
import { DateTime, Settings } from 'luxon'
import { AlertsButton } from '../AlertsModal/AlertsButton'
import useMapStore from '../../store/useMapStore'
import { PetHistoryButton } from '../PetHistory/PetHistoryButton'
import useAuthStore from '../../store/useAuthStore'

export const PetContainer = (props) => {
    const {pet, iccid,width,isOpenSidebar} = props

    const setMapMarkers = useMapStore((s) => s.setMarkers)
    const lastKnownPositions = usePetHistoryStore((s) => s.lastKnown)
    const lastTenLocations = usePetsDevicesStore((s) => s.lastTen)

    const setMapCenter = useMapStore((s) => s.setMapCenter)
    const setHighlightedFeature = useMapStore((s) => s.setHighlightedFeature)

    const [eventIsOpen, setEventIsOpen] = useState(false)

    const validLocationDate = lastKnownPositions && lastKnownPositions[iccid] ? DateTime.fromISO(lastKnownPositions[iccid].properties.receivedTime, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) : ''
    const historyIsOpen = usePetHistoryStore(s => s.showHistory)

    useEffect(() => {
        if (!historyIsOpen){
            setMapMarkers(Object.values(lastKnownPositions))
        }
        else {
            // setMapMarkers(lastTenLocations[iccid])
        }
    }, [lastTenLocations, lastKnownPositions])

    const center = () => {
        if (lastKnownPositions && lastKnownPositions[iccid]) {
            const feature = lastKnownPositions[iccid]
            const loc = {
                lng: feature.properties.longitude,
                lat: feature.properties.latitude,
            }
            setMapCenter(loc)
        }
    }

    const highlightedFeature = lastKnownPositions && lastKnownPositions[iccid] ? lastKnownPositions[iccid] : null

    const containerHeader = (
        <div
            className="d-flex align-items-center justify-content-between mb-3 ">
            <div>
                <div onClick={() => center()} style={{cursor:'pointer', 'marginRight':'10px'}}>
                    <img src={pet.imageURL} alt="pet-image"
                         className="dog-img me-3"/>
                </div>

                {/*<div className={'batteryDate'}*/}
                {/*     style={{marginLeft: '4px', cursor: 'pointer'}}*/}
                {/*     onClick={center}*/}
                {/*     onMouseEnter={() => setHighlightedFeature(highlightedFeature)}*/}
                {/*     onMouseLeave={() => setHighlightedFeature(null)}*/}
                {/*>*/}
                {/*   <i className={'fa-solid fa-location-dot'}/> <span>{validLocationDate}</span>*/}
                {/*</div>*/}
            </div>
            <div className={'width100'}>
                <FindButton iccid={iccid}/>
                <Battery iccid={iccid}/>
            </div>
        </div>
    )

    const containerControls = (
        <ul className="nav nav-pills mb-3 d-flex justify-space-around"
            id="pills-tab" role="button"
        >
            {/*<SleepModeButton iccid={iccid}/>*/}
            <LedControl iccid={iccid}/>
            {/* <AlertsButton iccid={iccid}/> */}
            <SettingsButton iccid={iccid}/>
            {width>786 && <PetHistoryButton iccid={iccid}/>}
        </ul>
    )

    const handleSectionOpen = (sectionId) => {
        if (sectionId) {
            if ( sectionId.includes('pet-history')) {
                setEventIsOpen(false)
            }
            else {
                setEventIsOpen(true)
            }
        } else {
            setEventIsOpen(false)
        }
    }

    const containerSections = (
        <Accordion onSelect={handleSectionOpen}>
           {/*{width>786 && <PetHistorySection pet={pet} iccid={iccid} isOpen={historyIsOpen}/>}*/}
            <EventsSection pet={pet} iccid={iccid} isOpen={eventIsOpen}/>
        </Accordion>
    )

    return (
        <Accordion.Item eventKey={pet._id}
                        className="sub-accordian-item"
                        key={`${pet._id}-container`}>
            <ContainerHeader pet={pet} iccid={iccid} isOpenSidebar={isOpenSidebar} />
            {iccid&& isOpenSidebar===false &&
            <Accordion.Body style={{border: 'thin solid white', borderRadius: '4px', padding: '4px'}}>
                {containerHeader}
                {containerControls}
                {containerSections}
            </Accordion.Body>
            }
        </Accordion.Item>
    )
}
