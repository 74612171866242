import { Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { zonePost, zonePut } from '../../helpers/zoneHelper'
import useAuthStore from '../../store/useAuthStore'
import useZoneStore from '../../store/useZoneStore'

const create_zoze_schema = yup
    .object()
    .shape({
        zone_name: yup
            .string()
            .min(3, 'Zone name must be at least 3 characters.')
            .max(20, 'Zone name must be at most 20 characters.')
            .required('Zone name is required.'),
    })
    .required()

const SafeZoneModal = (props) => {
    const userToken = useAuthStore((s) => s.token)
    const {
        setShowSearchBox,
        zoneModalData,
        setZoneModalData,
        fetchZones,
        zoneID,
        zoneName,

        // power zone creation
        setCanDragPowerZone,
        newPowerZoneLocation,
        setNewPowerZoneLocation,
        setCircleType,

        // safeZoneCreation
        newSafeZoneGeometry,
        setNewSafeZoneGeometry,
        setCanDragSafeZone,
        setCanEditSafeZone,
        setPolygonType

    } = props
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(create_zoze_schema), // defaultValues: {form_type:true}
    })

    const [zoneFormtype, setZoneFormtype] = useState('')
    const fetchZonesToggle = useZoneStore((s) => s.fetchZonesToggle)
    const setFetchZones = useZoneStore((s) => s.setFetchZones)
    useEffect(() => {
        // console.log(zoneModalData)
        zoneModalData.length !== 0
            ? setZoneFormtype(zoneModalData[0][1])
            : setZoneFormtype('Create')
    }, [zoneModalData])

    useEffect(() => {
        setValue('zone_name', zoneName)
    }, [zoneName])

    const reloadNormalState = () => {
        reset()
        setShowSearchBox(false)
        setCanDragPowerZone(false)
        setCanDragSafeZone(false)
        setCanEditSafeZone(false)
        // fetchZones()
        setFetchZones(!fetchZonesToggle)
        setNewSafeZoneGeometry([])
        setNewPowerZoneLocation({})
        setPolygonType("old");
        setCircleType("old");
    }

    const messageContent = {
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'api',
    }
    const zoneInput = (
        <div className="px-5">
            <div className="form-group my-pets">
                <label className="form-label">Zone Name</label>
                <input
                    id="zone_name"
                    type="text"
                    className={'form-control' + (errors.zone_name ? ' is-invalid' : '')}
                    {...register('zone_name')}
                    placeholder="Zone Name"
                />
                {errors.zone_name && (
                    <div className="errors">{errors.zone_name?.message}</div>
                )}
            </div>
        </div>
    )

    const handleConfirm = async (onClose, data) => {
        // console.log(userToken);
        // console.log(data)
        onClose()

        if (data.submitType === 'create') {
            const response = await zonePost(userToken, data)
            if (response.status) {
                toast.success(response.message, messageContent)
            }
            else {
                toast.error(response.message, messageContent)
            }
            reloadNormalState()
        }
        else {
            const responsePut = await zonePut(userToken, zoneID, data)
            if (responsePut.status) {
                toast.success(responsePut.message, messageContent)
            }
            else {
                // TODO: reload previous data
                toast.error(responsePut.message, messageContent)
            }
            reloadNormalState()
        }
    }

    const closeModal = () => {
        const status = zoneModalData.map((c, i) => {
            if (i === 1) {
                return false
            }
            else {
                return c
            }
        })
        setZoneModalData(status)
    }

    const formSubmit = (data) => {
        const newZone = Object.assign({}, data)
        newZone.zone_coordinates = zoneModalData[0].includes('safe')
            ? newSafeZoneGeometry
            : [newPowerZoneLocation]
        newZone.zone_type = zoneModalData[0].includes('safe') ? 'safe' : 'power'

        const alert_text_value = zoneModalData[0].includes('Create')
            ? 'create'
            : 'update'
        newZone.submitType = alert_text_value

        closeModal()

        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <div className="fa-3x">
                            <i className="fa-solid fa-paw fa-bounce"></i>
                        </div>
                        <h3>Are you sure want {alert_text_value} ?</h3>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={() => {
                                handleConfirm(onClose, newZone)
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={onClose}
                        >
                            No
                        </button>
                    </div>
                )
            },
            closeOnClickOutside: false, // setPets
        })
    }

    return (
        <>
            <Modal
                show={zoneModalData[1]}
                onHide={() => {
                    closeModal()
                }}
                aria-labelledby="modal-sizes-title-lg"
                centered
                backdrop="static"
            >
                <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-sizes-title-lg">
                            {/* {zoneFormtype} {createZoneType}  */}
                            {zoneFormtype} Zone
                            {/* {zoneModalData[0]} Zone */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="scrollbar">{zoneInput}</Modal.Body>
                    <Modal.Footer>
                        <button
                            type="submit"
                            className="btn btn-outline-primary"
                            data-bs-dismiss="modal"
                        >
                            {zoneFormtype}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                                closeModal()
                                reloadNormalState()
                            }}
                            data-bs-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default SafeZoneModal
