import create from "zustand";
import { persist } from "zustand/middleware";

const useAdminAuthStore = create(
  persist(
    (set, get) => ({
      token: "",
      superUser: false,
      setToken: (token, isSuperUser) =>
        set((state) => ({
          ...state,
          token: token,
          superUser: isSuperUser,
        })),
      clearToken: () =>
        set((state) => ({
          ...state,
          token: "",
          superUser: false,
        })),
    }),
    {
      name: "adminAuthToken", // unique name
      getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export default useAdminAuthStore;
