import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "./components/Dashboard.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Login from "./components/Login.js";
import NoDataFound from "./components/NoDataFound.js";
import ForgotPassword from "./components/ForgotPassword.js";
import Welcome from "./components/Registeration/Welcome.js";
import Subscription from "./components/Registeration/Subscription.js";
import Register from "./components/Registeration/Register.js";
import Petinfo from "./components/Registeration/Petinfo.js";
import BillingAddress from "./components/Registeration/BillingAddress.js";
import Message from "./components/Registeration/Message.js";
import Confirmation from "./components/Registeration/Confirmation";
import UserRegister from "./components/UserRegister.js";
import UserForm from "./components/UserForm";
// import RegisterBK from "./components/RegisterBK";
import Sidebar from "./components/MyAccount/Sidebar.js";
/************ MyAccount ***********/
import MyAccount from "./components/MyAccount/MyAccount.js";
import MyPet from "./components/MyAccount/MyPet.js";
import MyDevice from "./components/MyAccount/MyDevice.js";
import MyContact from "./components/MyAccount/MyContact.js";
import AddDeviceForm from "./components/MyAccount/MyDevice/AddDeviceForm";
import DeviceCheck from "./components/MyAccount/MyDevice/DeviceCheck";
import DeviceSubscription from "./components/MyAccount/MyDevice/DeviceSubscription";
import DeviceBilling from "./components/MyAccount/MyDevice/DeviceBilling";
import DeviceConfirm from "./components/MyAccount/MyDevice/DeviceConfirm";

/************ Admin pages import ***********/
import AdminLogin from "./components/Admin/AdminLogin.js";
import AdminHeader from "./components/Admin/AdminHeader";
import AdminDashboard from "./components/Admin/AdminDashboard";
import UserList from "./components/Admin/UserSettings/UserList";
// import ProductList from "./components/Admin/ProductList";
import SubscriptionPlan from "./components/Admin/SubscriptionPlan";
import Payment from "./components/Admin/Payment";
import IccIds from "./components/Admin/DeviceSettings/IccIds";
import AdminSettings from "./components/Admin/AdminSettings";
import AdminPetSettings from "./components/Admin/PetSettings/AdminPetSettings";
import BatteryStatus from "./components/Admin/Settings/BatteryStatus";
import StripePayment from "./components/StripePayment";
import Repayment from "./components/Repayment.js";
import PaymentSuccess from "./components/PaymentPage/PaymentSuccess";
import PaymentFailed from "./components/PaymentPage/PaymentFailed";
import DeviceInactive from "./components/DeviceInactive/DeviceInactive";

import "./css/Style.css";
import "./css/sidebar-nav.css";
import "./css/settings.css";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ResetPassword from "./components/ResetPassword.js";
import TokenInvalid from "./components/TokenInvalid.js";
import { WebSocket } from './webSockets/WebSocket'

const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const STRIPE_SECERT_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;

const stripe = loadStripe(STRIPE_PUBLISH_KEY);

function App() {

  const [isLoaderActive, setLoaderActive] = useState(false);
  const [isLoaderMessage, setLoaderMessage] = useState('Please wait...');

  const updateLoader = (val) => {
    val === true ? setLoaderActive(true) : setLoaderActive(false);
  };
  const updateLoaderMessage = (msg) => {
     setLoaderMessage(msg) ;
  };

  // const [showSidebar, setShowSidebar] = useState(true);

  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const updateSidebar = ( ) => {
    setIsOpenSidebar(!isOpenSidebar) ;
    // val === true ? setIsOpenSidebar(true) : setIsOpenSidebar(false);
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: STRIPE_SECERT_KEY,
  };
  return (
    <div className="wrapper">
      <WebSocket/>
      <LoadingOverlay
        active={isLoaderActive}
        spinner
        text={isLoaderMessage}
        // styles={undefined}
      >
        <Router>
          <Routes>

            {/* <Route path="/" element={
              <Elements stripe={stripe}>

            <UserRegister />
             </Elements>
            } /> */}
            <Route path="/nodata" element={<NoDataFound />} />
            <Route path="/dashboard" element={<Dashboard updateLoader={updateLoader}/>} />
            <Route path="/sidebar" element={<Sidebar updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/my-account" element={<MyAccount updateLoader={updateLoader}  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar} />} />
            <Route path="/my-pet" element={<MyPet updateLoader={updateLoader} updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/my-device" element={
              <Elements stripe={stripe}>

                <MyDevice updateLoader={updateLoader} updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>
              </Elements>
            } />
            <Route path="/my-contact" element={<MyContact updateLoader={updateLoader} updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/header" element={<Header  updateSidebar={updateSidebar} />} />
            <Route path="/footer" element={<Footer />} />
            <Route path="/login" element={<Login updateLoader={updateLoader} />} />
            <Route path="/" element={<Login updateLoader={updateLoader} />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/register" element={<Register />} />
            <Route path="/petinfo" element={<Petinfo />} />
            <Route path="/message" element={<Message />} />
            <Route path="/billing-address" element={<BillingAddress />} />
            <Route path="/confirmation" element={
            <Elements stripe={stripe}>
              <Confirmation />
              </Elements>
            } />

            <Route path="/user-register" element={
              <Elements stripe={stripe}>
              <UserRegister />
             </Elements>
            } />
            <Route path="/forgot-password" element={<ForgotPassword updateLoader={updateLoader}/>} />
             <Route path="/reset-password" element={<ResetPassword  updateLoader={updateLoader}/>} />
            <Route path="/user-form" element={<UserForm />} />
            <Route path="/add-device-form" element={<AddDeviceForm />} />
            <Route path="/device-check" element={<DeviceCheck />} />
            <Route path="/device-subscription" element={<DeviceSubscription />} />
            <Route path="/device-billing" element={<DeviceBilling />} />
            <Route path="/device-confirm" element={
             <Elements stripe={stripe}>

            <DeviceConfirm />
             </Elements>
            } />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/device-Inactive" element={<DeviceInactive />} />
            <Route path="/payment-fail" element={<PaymentFailed />} />
            {/* <Route path="/reg-form" element={<RegisterBK/>} /> */}

            {/* ADMIN PAGE ROUTES */}
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-header" element={<AdminHeader />} />
            <Route path="/admin-dashboard" element={<AdminDashboard  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/user-list" element={<UserList  updateLoader={updateLoader} updateLoaderMessage={updateLoaderMessage}  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            {/* <Route path="/product-list" element={<ProductList />} /> */}
            <Route path="/sub-plan" element={<SubscriptionPlan  updateLoader={updateLoader} updateLoaderMessage={updateLoaderMessage}  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/icc-ids" element={<IccIds  updateLoader={updateLoader} updateLoaderMessage={updateLoaderMessage}  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/payment" element={<Payment  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar} />} />
            <Route path="/settings" element={<AdminSettings  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/petSettings" element={<AdminPetSettings   updateLoader={updateLoader} updateLoaderMessage={updateLoaderMessage}  updateSidebar={updateSidebar} isOpenSidebar={isOpenSidebar}/>} />
            <Route path="/battery-status" element={<BatteryStatus />} />
            <Route path="/tokenInvalid" element={<TokenInvalid />} />

            <Route path="/re-payment" element={
             <Elements stripe={stripe}>
               <Repayment updateLoader={updateLoader}/>
             </Elements>
            } />


            <Route path="/StripePayment" element={
             <Elements stripe={stripe}  options={options} >
              {/* <Confirmation /> */}
               <StripePayment  />
             </Elements>
            }/>

          </Routes>
        </Router>
        <ToastContainer
            enableMultiContainer
            containerId={'api'}
            newestOnTop={true}
            position={'top-center'}
        />
      </LoadingOverlay>
    </div>
  );
}

export default App;
