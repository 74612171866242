import { get, post, put } from "../apiHelper";


export const adminLogin = async (data) => {
  const authLogin = {...data, admin: true}
  const response = await post("/api/v1/auth/local",authLogin);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const changePass = async (userToken, data) => {
  const response = await post("/api/v1/changeUserPass", data, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const updateAccountDetails = async (userToken, accountId, data) => {
  const response = await put("/api/v1/userInfo/" + accountId, data, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const updateAddress = async (userToken, userBillInfoID, data) => {
  const response = await put(
    "/api/v1/billing_info/" + userBillInfoID,
    data,
    userToken
  );
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
