import { React, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import DataTableSetup from "../DataTableSetup";
import {
  getPetTypesList,
  removePetType,
} from "../../../helpers/adminHelper/petHelpers";
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import { toastConfig } from "../adminConfig";
import PetTypeModal from "./PetTypeModal";

const PetTypes = () => {
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );
  const [show, setShow] = useState(false);
  const [petTypes, setPetTypes] = useState([]);
  const [form_type, setform_type] = useState("add");
  const [showEdit, setShowEdit] = useState(false);
  const [speciesName, setSpeciesName] = useState([]);

  const columns = [
    {
      name: "Pet Type",
      selector: (row) => row.species_name,
      sortable: true,
    },

    {
      name: "Created Date",
      // selector: row => row.createdAt.split("T")[0],
      selector: (row) =>
        row.createdAt != undefined ? row.createdAt.split("T")[0] : "-",
      sortable: true,
    },
    {
      name: "Status",
      sortable: true,
      cell: (row) => (
        <div>
          {row.status === "active" ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>InActive</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      cell: (row) => (
        <div>
          <button className="icon-button me-3">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled">Click to Update.</Tooltip>
              }
            >
              <i
                className="fa fa-pencil "
                onClick={() => {
                  updatePeTypeName(row._id, row.species_name, row.status);
                }}
              >
                {" "}
              </i>
            </OverlayTrigger>
          </button>
          <button className="icon-button">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled">Click to Delete.</Tooltip>
              }
            >
              <i
                className={
                  row.status === "active" ? "fa fa-trash" : "fa fa-undo"
                }
                onClick={() => {
                  deleteID(row._id, row.status);
                }}
              >
                {" "}
              </i>
            </OverlayTrigger>
          </button>
        </div>
      ),
    },
  ];

  const updatePeTypeName = (id, species_name, status) => {
    console.log(id, species_name, status);
    if (status === "active") {
      setSpeciesName([id, species_name]);
      setShowEdit(true);
    }
  };
  const fetchAllPetTypes = async () => {
    const response = await getPetTypesList(adminToken);
    if (response.status) {
      let pettypes = response.data;
      setPetTypes(pettypes);
    } else {
    }
  };

  useEffect(() => {
    fetchAllPetTypes();
  }, [auxToFetch]);

  const deletePetType = async (data, onClose) => {
    const response = await removePetType(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };

  const deleteID = (id, status) => {
    console.log({ id: id, status: status });
    const statusValue = status === "active" ? "In-Active" : "Active";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {statusValue} ?</h3>

            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                let data = { id: id, status: status };
                deletePetType(data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };
  //-----Search Filter Starts-----//
  const [filterText, setFilterText] = useState("");
  const filteredItems = petTypes.filter(
    (item) =>
      item.species_name &&
      item.species_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const onFilterData = (e) => {
    setFilterText(e.target.value);
  };
  const onFilterClear = () => {
    setFilterText("");
  };
  //-----Search Filter Ends-----//
  const btnStatus = true;
  const BtnContent = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-disabled">Click to add Pet Type</Tooltip>}
      >
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            setShow(true);
            setform_type("add");
          }}
        >
          Add Pet Type
        </button>
      </OverlayTrigger>
    );
  };
  const datatableProps = {
    filterText: filterText,
    onFilterData: onFilterData,
    onFilterClear: onFilterClear,
    columns: columns,
    filteredItems: filteredItems,
    btnStatus: btnStatus,
    BtnContent: BtnContent,
  };

  const petModalProps = {
    adminToken: adminToken,
    show: show,
    setShow: setShow,
    setAuxToFetch: setAuxToFetch,
    auxToFetch: auxToFetch,
    form_type: form_type,
    setform_type: setform_type,
    showEdit: showEdit,
    setShowEdit: setShowEdit,
    speciesName: speciesName,
  };
  return (
    <>
      <DataTableSetup {...datatableProps} />
      <PetTypeModal {...petModalProps} />
    </>
  );
};
export default PetTypes;
