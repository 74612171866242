import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAuthStore from "../../../store/useAuthStore";
import { changePass } from "../../../helpers/accountHelper";
import { toastConfig } from '../../Admin/adminConfig'

const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL;

function ChangePassModal(props) {
  const {
    showChangePassword,
    setShowChangePassword,
    showOldPassword,
    toggleOldPassword,
    showNewPassword,
    toggleNewPassword,
    isSubmitting,
    toastConfig,
    setAuxToFetch,
    auxToFetch,
    setIsSubmitting,
  } = props;
  const userToken = useAuthStore((s) => s.token);
  const passSchema = yup
    .object()
    .shape({
      oldPass: yup.string().required("Old Password is required."),
      newPass: yup
        .string()
        .min(6, "New Password must be at least 6 characters.")
        .max(20, "New Password must be at most 20 characters.")
        .required("New Password is required."),
    })
    .required();
  const {
    // control:PassControl,
    register: passRegister,
    handleSubmit: handlePassSubmit,
    // setValue,
    reset: passReset,
    setError,
    formState: { errors: passError },
  } = useForm({
    mode: "all",
    resolver: yupResolver(passSchema),
    // defaultValues: {form_type:true}
  });

  const changePassData = async (onClose, data) => {
    const resultPass = await changePass(userToken, data);
    if (resultPass.status) {
      toast.success(resultPass.message, {...toastConfig, containerId: 'api'});
      onClose();
      passReset();
      setShowChangePassword(false);
      setIsSubmitting(false);
    } else {
      toast.error(resultPass.message, {...toastConfig, containerId: 'api'});
      setIsSubmitting(false);
    }
  };

  const changePassword = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to change ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                changePassData(onClose, data);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                // setIsSubmitting(false);
                onClose();
              }}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const OldPasswordField = (
    <div className="form-group my-pets">
      <label for="exampleInputEmail1" className="form-label">
        Old Password{" "}
      </label>
      <div className="input-group white">
        <span className="input-group-text">
          <i className="fa fa-lock"></i>
        </span>
        <input
          type={showOldPassword ? "text" : "password"}
          className={"form-control" + (passError.oldPass ? " is-invalid" : "")}
          {...passRegister("oldPass")}
          placeholder="Enter old password "
        />
        <span className="input-group-text">
          <i
            className={`fa ${!showOldPassword ? "fa-eye-slash" : "fa-eye"}`}
            aria-hidden="true"
            onClick={toggleOldPassword}
          ></i>
        </span>
      </div>
      <p className="errors">
        {" "}
        {passError.oldPass?.message || passError.oldPass?.label.message}{" "}
      </p>
      <p className="errors">
        {" "}
        {passError.oldPassError?.message ||
          passError.oldPassError?.label.message}{" "}
      </p>
    </div>
  );

  const NewPasswordField = (
    <div className="form-group my-pets">
      <label for="exampleInputEmail1" className="form-label">
        New Password{" "}
      </label>
      <div className="input-group white">
        <span className="input-group-text">
          <i className="fa fa-lock"></i>
        </span>
        <input
          type={showNewPassword ? "text" : "password"}
          className={"form-control" + (passError.newPass ? " is-invalid" : "")}
          {...passRegister("newPass")}
          placeholder="Enter new password "
        />
        <span className="input-group-text">
          <i
            className={`fa ${!showNewPassword ? "fa-eye-slash" : "fa-eye"}`}
            aria-hidden="true"
            onClick={toggleNewPassword}
          ></i>
        </span>
      </div>
      <p className="errors">
        {" "}
        {passError.newPass?.message || passError.newPass?.label.message}{" "}
      </p>
    </div>
  );

  const modalBtnField = (
    <ul className="list-inline float-end mb-0">
      <li className="list-inline-item ">
        <button
          type="submit"
          className="btn btn-outline-primary"
          disabled={isSubmitting}
        >
          {isSubmitting === true && (
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          Update
        </button>
      </li>
      <li className="list-inline-item">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            passReset();
            setShowChangePassword(false);
          }}
        >
          Cancel
        </button>
      </li>
    </ul>
  );

  return (
    <div>
      <Modal
        className="add-new-gibi"
        size="sl"
        show={showChangePassword}
        onHide={() => setShowChangePassword(false)}
        aria-labelledby="modal-sizes-title-lg"
        centered
        backdrop="static"
      >
        <form method="POST" onSubmit={handlePassSubmit(changePassword)}>
          <Modal.Header closeButton>
            <Modal.Title id="modal-sizes-title-lg">
              {" "}
              Change Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="scrollbar text-align-justify">
            {OldPasswordField}
            {NewPasswordField}
            {modalBtnField}
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
}

export default ChangePassModal;
