import React, { useState } from "react";
import UserForm from "./UserForm";
import LoadingOverlay from 'react-loading-overlay';
const UserRegister = () => {

const [isActive,setActive]=useState(false);

const updateLoader = (val) => {
  val === true?setActive(true):setActive(false);
};

    return (
      <>
        <div>
          <LoadingOverlay active={isActive} spinner text="Please wait...">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0 login-img-holder">
                  <div className="login-bg"></div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0">
                  <UserForm updateLoader={updateLoader} isActive={isActive} />
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </>
    );


};

export default UserRegister;
