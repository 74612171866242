import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Video from "../../../assets/SafeDemoVideo.mp4";

function HelperSafeZoneModal({ previewSafe, setPreviewSafe }) {
  const [showInstructions, setShowInstructions] = useState(false);
  return (
    <Modal
      show={previewSafe}
      onHide={() => {
        setPreviewSafe(false);
        setShowInstructions(false);
      }}
      aria-labelledby="modal-sizes-title-lg"
      centered
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-lg">
        Instruction to creating safe zone
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scrollbar">
        <video controls autoPlay width="100%" className="mb-2">
          <source src={Video} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <p
          className="me-auto fw-bold mb-0 instruction-title text-decoration-underline"
          onClick={() => {
            setShowInstructions(!showInstructions);
          }}
        >
          View Safe Zone Instructions
        </p>
        {showInstructions && (
          <div className="instruction mt-2">
            <p className="text-justify">
              <b> To CREATE- </b>Click on "Create Safe Zone" then click on Enter
              Location to redirect to your location. Then, Click on the map to
              create an actual zone by moving the dots. After creating desired
              zone, click on the orange button namely "create safe zone" button
              next to search location, then a pop-up for a safe zone name will
              be appearing, give a name and hit the save button .Thus Safe zone
              gets created.
            </p>
            <p className="text-justify">
              <b>To EDIT-</b> Click on Edit icon, Safe Zone will appear in RED
              to make Editable. You may then click and drag corners or sides to
              modify. After editing zone completed, click on the orange button
              namely "Update safe zone" button next to search location, then a
              pop-up for an update safe zone name will be appearing, Then click
              on update.
            </p>
            <p className="text-justify">
              <b>To DELETE- </b>Click on Delete icon and Confirm delete.
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            setShowInstructions(false);
            setPreviewSafe(false);
          }}
          data-bs-dismiss="modal"
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default HelperSafeZoneModal;
