import React, { useEffect, useState } from "react";
import Header from "../Header.js";
import Sidebar from "./Sidebar.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../NoDataFound.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useAuthStore from "../../store/useAuthStore";
import {
  getAllContacts,
  removeContact,
  getPets,
} from "../../helpers/contactHelper";
import ContactModal from "./ContactModal";
import { toastConfig } from '../Admin/adminConfig'
import MenuSidebar from '../MenuSidebar'; 
import useUIStore from '../../store/useUIStore'

// function MyContact({ updateLoader, updateSidebar, isOpenSidebar }) {
  const MyContact = ({ updateLoader, updateSidebar }) => {
  const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
  const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)
  const toastConfig = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [auxToFetch, setAuxToFetch] = useState(false);
  const [showAddNewContact, setShowAddNewContact] = useState(false);
  const [petInfo, setpetInfo] = useState({});
  const [petContact, setpetContact] = useState({});
  const [petContactID, setpetContactID] = useState({});
  const [form_type, setform_type] = useState("add");
  const [modalTitle, setModalTitle] = useState("Add New Contact");
  const [userToken, setuserToken] = useState(useAuthStore((s) => s.token));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [btnName, setBtnName] = useState("Save");
  const [contactInfo, setContactInfo] = useState({});
  const [checkDisable,setCheckDisable]=useState(true);
  const [origSms, setOrigSms] = useState('')
  const navigate = useNavigate();

  const fetchPet = async () => {
    const response = await getPets(userToken);
    if (response.status) {
      setpetInfo(response.result);
    } else {
      setpetInfo({});
    }
  };

  const fetchContactList = async (userToken) => {
    updateLoader(true);
    const contacts = await getAllContacts(userToken);
    if (contacts.status) {
      setpetContact(contacts.result);
    } else {
      setpetContact({});
    }
    updateLoader(false);
  };

  useEffect(() => {
    if (userToken === "" || userToken === null) {
      navigate("/login");
    } else {
      fetchPet(userToken);
      fetchContactList(userToken);
      setIsSubmitting(false);
    }
  }, [auxToFetch]);

  const addNewContact = () => {
    setModalTitle("Add New Contact");
    setform_type("add");
    // reset();
    setBtnName("Save");
    setShowAddNewContact(true);
  };

  const editContact = (data) => {
    // console.log(data);

    setpetContactID(data._id);
    setModalTitle("Edit Contact");
    setform_type("edit");
    setBtnName("Update");
    setContactInfo(data);
    data.mobile_no===""||data.mobile_no===null ?setCheckDisable(true):setCheckDisable(false);
    // console.log(contactInfo);
  };

  const removeContactDetails = async (onClose, deletedID) => {
    const reesponse = await removeContact(userToken, deletedID);
    if (reesponse.status) {
      toast.success(reesponse.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.success(reesponse.message, {...toastConfig, containerId: 'api'});
    }
  };
  const removePetContact = (values) => {
    // console.log(values);
    const deletedID = values._id;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to remove ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                removeContactDetails(onClose, deletedID);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const contactProps = {
    showAddNewContact: showAddNewContact,
    setShowAddNewContact: setShowAddNewContact,
    petInfo: petInfo,
    isSubmitting: isSubmitting,
    setIsSubmitting: setIsSubmitting,
    form_type: form_type,
    setform_type:setform_type,
    petContactID: petContactID,
    updateLoader: updateLoader,
    modalTitle: modalTitle,
    btnName: btnName,
    userToken: userToken,
    toastConfig: toastConfig,
    setAuxToFetch: setAuxToFetch,
    auxToFetch: auxToFetch,
    contactInfo: contactInfo,
    checkDisable,
    setCheckDisable,
    origSms,
  };
  const [width, setWidth] = useState(window.innerWidth);
  return (
    <>
      <Header />
      <main id="mainContent" className={!isOpenSidebar ? "show" : ""}>
      <MenuSidebar/>
        {/* {
          <Sidebar
            updateSidebar={updateSidebar}
            isOpenSidebar={isOpenSidebar}
            width={width}
          />
        } */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card parent mt-2">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="mb-0"> Contacts</h6>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        Click to add new contact.
                      </Tooltip>
                    }
                  >
                    <button
                      className="btn btn-link p-0 text-dark text-decoration-none font-weight-bold"
                      style={{ lineHeight: "14px", fontWeight: "600" }}
                      data-toggle="modal"
                      data-target="#addNewDevice"
                      onClick={() => {
                        addNewContact();
                      }}
                    >
                      <span className="me-1"><i className="fa fa-plus-circle"></i></span>
                      Add New Contact
                    </button>
                  </OverlayTrigger>
                </div>
                <div className="card-body my-device scrollbar">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 my-account mt-0">
                      <div className="card h-100">
                        <div className="card-body p-0 scrollbar" style={{'overflowY':'auto'}}>
                          {petContact != "" ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Contact Person</th>
                                  {/* <th scope="col">Email</th> */}
                                  <th scope="col">Mobile</th>
                                  <th scope="col">Pet assign</th>
                                  <th scope="col">SMS Opt-in</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.values(petContact).map((values) => {
                                  // console.log(values);
                                  function extractValue(arr, prop) {
                                    // extract value from property
                                    let extractedValue = arr.map(
                                      (item) => item[prop]
                                    );
                                    return extractedValue;
                                  }

                                  let pet_list = values.pet_list;
                                  const result = extractValue( pet_list,"petName").join(",");

                                  return (
                                    <tr key={values._id}>
                                      <th scope="row">
                                        {values.first_name} {values.last_name}
                                      </th>
                                      {/* <td>{values.email}</td> */}
                                      <td>{values.mobile_no}</td>
                                      <td> {result}</td>
                                      <td> {values.smsOptIn}</td>
                                      <td>
                                        <OverlayTrigger
                                          placement="auto"
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              Click to edit contact.
                                            </Tooltip>
                                          }
                                        >
                                          <button className="btn btn-link">
                                            <i
                                              className="fa fa-pencil-square-o"
                                              aria-hidden="true"
                                              onClick={() => {
                                                // values.smsOptIn = values.smsOptIn === 'Opt-in' ? true : false
                                                setOrigSms(values.smsOptIn)
                                                editContact(Object.assign({}, values));
                                              }}
                                            ></i>
                                          </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="auto"
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              Click to remove contact.
                                            </Tooltip>
                                          }
                                        >
                                          <button className="btn btn-link">
                                            <i
                                              className="fa fa-trash btn-remove"
                                              aria-hidden="true"
                                              onClick={() => {
                                                removePetContact(values);
                                              }}
                                            ></i>
                                          </button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <NoDataFound />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ContactModal {...contactProps} />
    </>
  );
}

export default MyContact;
