import { get, post, put } from "../apiHelper";

export const getAllcheckInRate = async (adminToken) => {
    const response = await get("/api/v1/checkInRate/2",adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };

export const updateCheckInRateStatus = async (data,adminToken) => {
    const response = await post("/api/v1/checkInRateStatus",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const addNewCheckInRate = async (data,adminToken) => {
    const response = await post("/api/v1/checkInRateStatus",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };

  export const getAllAlertTypes = async (adminToken) => {
    const response = await get("/api/v1/alertTypes/2",adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
  
  export const addNewAlertType = async (data,adminToken) => {
      const response = await post("/api/v1/alertTypes",data,adminToken);
      if (response.status) {
      return response.data;
    } else {
        return [];
    }
};
export const removeAlertType = async (data,adminToken) => {
    const response = await post("/api/v1/updateStatusAlertTypes",data,adminToken);
    if (response.status) {
        return response.data;
    } else {
        return [];
    }
};

export const getAllIconColor = async (adminToken) => {
  const response = await get("/api/v1/iconColor/2",adminToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const removeIconColor = async (data,adminToken) => {
    const response = await post("/api/v1/updateStatusAlertTypes",data,adminToken);
    if (response.status) {
        return response.data;
    } else {
        return [];
    }
};
export const saveIconColor = async (data,adminToken) => {
    const response = await post("/api/v1/iconColor",data,adminToken);
    if (response.status) {
        return response.data;
    } else {
        return [];
    }
};
export const fetchTimeZone = async (type,adminToken) => {
    const response = await get(`/api/v1/get_all_timezone/${type}`,adminToken);
    if (response.status) {
        return response.data;
    } else {
        return [];
    }
};


export const updateTimeZoneStatus = async (data,adminToken) => {
  const response = await post("/api/v1/updateStatusTimeZone",data,adminToken);
  if (response.status) {
      return response.data;
  } else {
      return [];
  }
};



export const addNewTimeZone = async (data,adminToken) => {
  const response = await post("/api/v1/timezone",data,adminToken);
  if (response.status) {
  return response.data;
} else {
    return [];
}
};