import {Modal} from 'react-bootstrap';
import React from 'react';
import { DateTime, Settings } from 'luxon'
import useAuthStore from '../../../store/useAuthStore'

const _ConfiguredSleepForm = (props) => {
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        isRepeat,
        extraHeader,
    } = props

    if (!startDate) {
        return null
    }
    const zone = useAuthStore.getState().timezone.value
    const startTimeFormatted = DateTime.fromJSDate(startTime, {zone}).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    const endTimeFormatted = DateTime.fromJSDate(endTime, {zone}).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    const startDateFormatted = DateTime.fromJSDate(startDate, {zone}).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    const endDateFormatted = DateTime.fromJSDate(endDate, {zone}).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)

    const configuredSleepForm = (
        <div>
            {!isRepeat ? <div className="row">
                <div className="col-lg-6">
                    <div className="setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Sleep
                            Starts </label>
                        <div style={{marginLeft: '16px'}}>
                            {startDateFormatted}
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className=" setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Sleep
                            Ends </label>
                        <div style={{marginLeft: '16px'}}>
                            {endDateFormatted}
                        </div>
                    </div>
                </div>

            </div> : null}

            {isRepeat ? (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="setting-device form-group">
                            <label className="form-label">Daily Sleep Setting</label>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="row">
                <div className="col-lg-6">
                    <div className="setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">Start
                            Time </label>
                        <div style={{marginLeft: '16px'}}>
                            {startTimeFormatted}
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className=" setting-device form-group">
                        <label htmlFor="exampleInputEmail1" className="form-label">End
                            Time </label>
                        <div style={{marginLeft: '16px'}}>
                            {endTimeFormatted}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

    return (<>
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">Sleep is configured</Modal.Title>
            </Modal.Header>
            {extraHeader}
            <Modal.Body className="scrollbar overflow-unset">
                <div className="row">
                    <div className="col-lg-12">
                        {configuredSleepForm}
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}

export const ConfiguredSleepForm = _ConfiguredSleepForm
