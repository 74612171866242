import { React, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {  toast } from 'react-toastify';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {addNewCheckInRate} from "../../../helpers/adminHelper/settigHelper"
import useAdminAuthStore from "../../../store/useAdminAuthStore";


import { toastConfig } from "../adminConfig";
const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {

        cursor: "pointer",
        padding: "5px",
        fontSize: "14px",
        borderColor: "#fff!important",
        "&:hover": {
          backgroundColor: "#139dcc ",
          color: "#fff",
        },
      };
    },
  };
const schema = yup
  .object()
  .shape({
    checkInRateData: yup
      .number()
      .typeError('Check In Rate must be a number')
      .positive("Check In Rate must be more than 0")
      .integer("Check In Rate must be more than 0")
      .min(1, "Check In Rate must be at least 1 numbers.")
      .max(100, "Check In Rate must be upto 100 numbers.")
    //   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Check In Rate is required."),

    checkInRateType: yup.string()
    .required("Check In Rate Type is required"),

  })
  .required();



function AddTimeModal(props) {
    const {showFrequency,setShowFrequency,auxToFetch,setAuxToFetch,}=props
    const [adminToken, setAdminToken] = useState(
        useAdminAuthStore((s) => s.token)
      );
    const {
        control,
        register,
        handleSubmit,
        setValue,
        setError,
        reset,
        formState: { errors },
      } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        // defaultValues: {form_type:true}
      });

      const [checkInRateTypeList, setcheckInRateTypeList] = useState([
        { value: "localRate", label: "Check in Rate" },
        { value: "powerSaveRate", label: "Power Save Scan Rate" },
        { value: "noCellularRate", label: "No Cellular Scan Rate" },
    ]);
    const [selectRate, setselectRate] = useState("");

const addCheckInRate=async(data,onClose)=>{
const response = await addNewCheckInRate(data,adminToken)
if(response.status){
    toast.success(response.message, {...toastConfig, containerId: 'api'});
    onClose();
    reset();
    setAuxToFetch(!auxToFetch);
    setShowFrequency(false);
}else{
    toast.error(response.message, {...toastConfig, containerId: 'api'});
    onClose();
    setError("checkInRateData", { type:"custom",message: response.message},{ shouldFocus: true });
 }

}

    const saveCheckInRate=(data)=>{

    confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <div className="fa-3x">
          <i className="fa-solid fa-paw fa-bounce"  ></i>
          </div>
          <h3>Are you sure want to add ?</h3>
          <button className="btn btn-outline-primary min-width-120"
            onClick={() => {

                addCheckInRate(data,onClose)

            }}
          >
            Yes
          </button>
            <button className="btn btn-outline-primary min-width-120" onClick={onClose}>No</button>
        </div>
      );
    },
    closeOnClickOutside: false,

    });
    }

  return (
    <Modal
                size="md"
                show={showFrequency}
                onHide={() => setShowFrequency(false)}
                aria-labelledby="modal-sizes-title-lg"
                centered
            >
            <form onSubmit={handleSubmit(saveCheckInRate)}>
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">
                        Check In Rate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='scrollbar text-align-justify'>


                        <div className="form-group mb-0 assign-value-1 my-pets">
                            <div className="custom-dropdown mb-3">
                                <label for="exampleInputEmail1" className="form-label"> Check In Rate Type</label>
                                <Controller
                                        name="checkInRateType"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                        <Select
                                        maxMenuHeight={150}
                                        styles={customStyles}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={checkInRateTypeList.find((c) => c.value === value)}
                                        // value="USA"
                                        onChange={(val) => {onChange(val.value)
                                        setselectRate(val.value);
                                        console.log(setselectRate);
                                        }}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        id="assigned1"
                                        options={checkInRateTypeList}
                                        placeholder={
                                        <div className="Select">Select Check In Rate Type</div>
                                        }
                                        />
                                        )}
                                        />
                            </div>
                            <p className="errors">{" "} {errors.checkInRateType?.message || errors.checkInRateType?.label.message}{" "} </p>
                          </div>
                          <div className=" my-pets">
                          <div className="mb-3 ">
                            <label for="exampleInputEmail1" className="form-label"> Check In Rate</label>
                            <input
                                type="number"
                                min="1"
                                className={"form-control " + (errors.checkInRateData ? " is-invalid" : "")}
                                {...register("checkInRateData")}
                                placeholder="Enter Check In Rate" />
                        </div>
                        <p className="errors">{" "} {errors.checkInRateData?.message || errors.checkInRateData?.label.message}{" "} </p>
                        </div>

                </Modal.Body>
                <Modal.Footer>
                    <button  type="submit"  className="btn btn-outline-primary"> Save </button>
                    <button type="button" className="btn btn-outline-primary" onClick={()=>{reset();setShowFrequency(false)}}>
                                    Cancel
                                </button>
                </Modal.Footer>
                </form>
            </Modal>
  )
}

export default AddTimeModal
