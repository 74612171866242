import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import usePetsDevicesStore from '../../../store/usePetsDevicesStore';
import {AlertsPanel} from '../../AlertsModal/AlertsPanel';
import { SingleSetting } from './SingleSetting'
import './AdvancedSettings.css'


export const AdvancedSettingForm = (props) => {
    const {
        setShowAdvancedSetting,
        iccid,
        onChange,
        checkInRateOptions,
        alertTypes,
        saveSetting,
        isSleeping,
        isCommandInProgress,
        extraHeader
    } = props

    const devices = usePetsDevicesStore((s) => s.devices)
    const device = devices[iccid]

    const [powerSaveRate, setPowerSaveRate] = useState(device?.status?.powerSavingsScanRate)
    const [noCellRate, setNoCellRate] = useState(device?.status?.noCellularScanRate)
    const [mtValue, setMtValue] = useState(device?.status?.mt)
    const [pendingChange, setPendingChange] = useState('')

    const onSave = (key, value) => {
        saveSetting(key, value)
    }

    const closeModal = () => {
        setPendingChange('')
        setShowAdvancedSetting(false)
    }

    const updateSetting = (type, val) => {
        setPendingChange(type)
        onChange(type, val)
    }

    return (
        <>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title id="modal-sizes-title-lg">
                    Advance Settings
                </Modal.Title>
            </Modal.Header>
            {extraHeader}
            <Modal.Body>
                <div className="advanced-settings-container">
                    <SingleSetting label={'Power save scan rate'}
                                   type={'powerSaveRate'}
                                   value={powerSaveRate}
                                   options={checkInRateOptions}
                                   onSave={onSave}
                                   isSleeping={isSleeping}
                                   isCommandInProgress={isCommandInProgress}
                                   updateSetting={updateSetting}
                                   pendingChange={pendingChange}
                    />
                    <SingleSetting label={'No cell signal sleep'}
                                   type={'noCellularRate'}
                                   value={noCellRate}
                                   options={checkInRateOptions}
                                   onSave={onSave}
                                   isSleeping={isSleeping}
                                   isCommandInProgress={isCommandInProgress}
                                   updateSetting={updateSetting}
                                   pendingChange={pendingChange}
                    />
                    <SingleSetting label={'Motion threshold'}
                                   type={'mt'}
                                   value={mtValue}
                                   onSave={onSave}
                                   isSleeping={isSleeping}
                                   isCommandInProgress={isCommandInProgress}
                                   updateSetting={updateSetting}
                                   pendingChange={pendingChange}
                    />

                </div>
            </Modal.Body>
        </>
    )
}
