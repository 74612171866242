import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getSubscriptionPlanList } from '../../helpers/deviceHelper'

const schema = yup
    .object()
    .shape({
        plan: yup.string().required('Please select plan '),
    })
    .required()

const Subscription = ({
                          updateStep,
                          planInfo,
                          updatesetPlanInfo,
                          updateLoader,
                          width
                      }) => {
    // console.log(planInfo);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: planInfo,
    })

    const [plan_list, setPlanList] = React.useState([])

    const [selectRadio, setSelectRadio] = React.useState('')
    const formSubmit = (data) => {
        const selected_plan = plan_list.filter(function (item) {
            return item.value === data.plan
        })

        updatesetPlanInfo(selected_plan[0])
        updateStep(5)
    }

    const previous_step = () => {
        updateStep(3)
    }

    const fetchPlanData = async () => {
        const response = await getSubscriptionPlanList()
        if (response.status) {
            let plan_list = response.products

            setPlanList(
                plan_list.map((item) => ({
                    value: item.priceID,
                    label: item.productName,
                    amount: item.productPrice,
                    priceID: item.priceID,
                    productID: item.productID,
                    description: item.productDescription,
                    no_months: 1, //item.intervalCount,
                    intervalCount: item.interval_count,
                    interval: item.interval,
                    currency: item.currency,
                }))
            )
            updateLoader(false)
        }
    }

    useEffect(() => {
        updateLoader(true)
        fetchPlanData()

        if (Object.keys(planInfo).length !== 0) {
            setSelectRadio(planInfo.value)
        }
    }, [])

    return (
        <>

            <div className={'login-holder d-flex align-items-center ' + (width < 768 ? 'h-100' : '')}>
                <div className={'login-container ' + (width < 768 ? 'h-100' : '')}>
                    <div className="logo">
                        <img src={require('../../images/logo-white_.png')} alt="gibi"/>
                    </div>
                  { !plan_list.length ? null :
                      <>
                    <h3 className="panel-title text-center text-white">
                        Subscription Plan
                    </h3>
                    <p className="text-white text-center mb-4">
                        Please select a subscription plan. Subscription fees will be
                        automatically renewed and charged to your credit card based on the plan
                        selected. For example, if you choose monthly, your credit card will
                        be automatically charged monthly. Please note that subscription
                        service fees are not refundable per the Terms of Service.

                    </p>
                    <p className="text-white text-center mb-4">
                        To pause or cancel your account, please contact us support@gibitechnologies.com or 1-844-GETGIBI
                        (1-844-438-4424).
                    </p>
                    <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                        {Object.values(plan_list).map((key) => {
                            console.log(key.label)
                            let str = key.label.replace(/([A-Z])/g, ' $1').trim()
                            const capitalizeFirst = (str) => {
                                return str.charAt(0).toUpperCase() + str.slice(1)
                            }

                            return (
                                <div className="form-group" key={key.value}>
                                    <div className="form-check custom-check white">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            // name="plan"
                                            {...register('plan')}
                                            id={key.value}
                                            value={key.value}
                                            onClick={() => {
                                                setSelectRadio(key.value)
                                                console.log(key.value)
                                            }}
                                            checked={key.value === selectRadio}
                                            // checked
                                        />
                                        <label
                                            className="form-check-label clearfix"
                                            htmlFor={key.value}
                                        >
                                            <span className="plan-name">{capitalizeFirst(str)}</span>
                                            <span className="plan-price"> $ {key.amount}</span>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}

                        <p style={{color: 'red'}}>{errors.plan && 'Plan is required'}</p>

                        <ul className="list-inline w-100 d-flex align-items-center mb-0">
                            <li className="list-inline-item m-0 w-50 pe-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary white w-100"
                                    onClick={previous_step}
                                >
                                    Previous
                                </button>
                            </li>
                            <li className="list-inline-item m-0 w-50 ps-2">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary white w-100"
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </form>
                    </>
                  }
                </div>
            </div>
        </>
    )
}

export default Subscription
