import React from "react";

const Welcome = ({ width }) => {
  return (
    <>
    <div className={"login-holder d-flex align-items-center " + (width < 991 ? "h-100" : "")}>
        <div className={"login-container " + (width < 991 ? "h-100" : "")}>

      <h3 className="panel-title text-center text-white">
        Thanks for getting Gibi!
      </h3>
      <p className="text-white mb-1">
        Thanks for getting Gibi to help keep your pet safe!
      </p>
      <p className="text-white mb-1">
        Let's get your Gibi tracker oriented with GPS satellites in your area by
        doing the steps below.
      </p>
      <ol className="list-group list-group-numbered mb-3">
        <li className="list-group-item bg-transparent border-0 text-white pt-0">
          If you haven’t already, please charge your Gibi tracker. When the
          button light is green, it is fully charged.
        </li>
        <li className="list-group-item bg-transparent border-0 text-white pt-0">
          When the Gibi tracker is fully charged, slide it out of the charger.
        </li>
        <li className="list-group-item bg-transparent border-0 text-white pt-0">
          Unplug the charger. (This is important)
        </li>
        <li className="list-group-item bg-transparent border-0 text-white pt-0">
          Take the Gibi tracker outdoors where there is clear line of sight to
          the sky.
        </li>
        <li className="list-group-item bg-transparent border-0 text-white pt-0">
          Wait 15-30 minutes until a pawprint shows on the map. When you see a
          paw print on the map, your Gibi is oriented.
        </li>
        <li className="list-group-item bg-transparent border-0 text-white pt-0">
          Click “Get Started” below to see the map.
        </li>
      </ol>
      <p className="text-white mb-1">
        To learn more about how Gibi works, check out our FAQ at
        <a
          href="http://www.getgibi.com/FAQ"
          className="text-white text-decoration-none ms-2"
          target="_blank"
          >
          getgibi.com/FAQ
        </a>
      </p>
      <p className="text-white mb-1">For any support, contact us :</p>
      <p className="text-white mb-1">
        at
        <a
          href="mailto:support@gibitechnologies.com"
          className="text-white mx-2"
          target="_blank"
          >
          support@gibitechnologies.com
        </a>
        or
        <a href="tel:1-844-GETGIBI" className="text-white ms-2" target="_blank">
          1-844-GETGIBI
        </a>
      </p>
      <p className="text-white mb-0">
         <a href="tel:(1-844-438-4424)" className="text-white" target="_blank">
          (1-844-438-4424)
        </a>
      </p>
      <div className="form-group mt-3 mb-0 text-center">
      <a href="/login"className="btn btn-outline-primary white">
                  Get Started
        </a>
      </div>
          </div>
          </div>

    </>
  );
};
export default Welcome;
