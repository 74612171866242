import { React, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
function BatteryStatus() {
    const [showStatus, setShowStatus] = useState(false);

    const columns = [
        {
            name: 'Time',
            selector: row => row.time,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },

        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            cell: row => (
                <div>
                    <button className='icon-button'><i className="fa fa-trash"></i></button>
                </div>
            )
        },
    ];

    const data = [
        {
            id: 1,
            time: '05 Minutes',
            date: '10 jan, 2022',
            action: '',
        },
        {
            id: 2,
            time: '45 Minutes',
            date: '27 apr, 2022',
            action: '',
        },
        {
            id: 3,
            time: '57 Minutes',
            date: '04 feb, 2022',
            action: '',
        },
        {
            id: 4,
            time: '35 Minutes',
            date: '23 july, 2022',
            action: '',
        },
        {
            id: 5,
            time: '59 Minutes',
            date: '10 jun, 2022',
            action: '',
        },

    ]

    return (


        <>

            <button className="btn btn-primary mb-2 float-end" onClick={() => setShowStatus(true)}>Add</button>

            <DataTable
                className='custom-table'
                columns={columns}
                data={data}
                defaultSortFieldId={1}
                searchable
                pagination
                responsive
            />

            <Modal
                size="md"
                show={showStatus}
                onHide={() => setShowStatus(false)}
                aria-labelledby="modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">
                        Time Signal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='scrollbar text-align-justify'>
                    <form>
                        <div className="mb-3">
                            <label for="exampleInputEmail1" className="form-label">Add Time</label>
                            <input type="text" className="form-control" id="exampleInputtext" placeholder="Text here" />
                        </div>
                        <button className="btn btn-primary mt-3 float-end">Add</button>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}
export default BatteryStatus;