import { get, post, put } from "../apiHelper";

export const getPetTypesList = async (adminToken) => {
    const response = await get("/api/v1/get_all_species/2",adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const getPetTypesListActive = async (adminToken) => {
    const response = await get("/api/v1/get_all_species/1",adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const savePetType = async (data,adminToken) => {
    const response = await post("/api/v1/species",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const updatePetType = async (data,petTypeID,adminToken) => {
    const response = await put("/api/v1/species/"+petTypeID,data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const removePetType = async (data,adminToken) => {
    const response = await post("/api/v1/updateStatusPetTypes",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const removePetBreed = async (data,adminToken) => {
    const response = await post("/api/v1/updateStatusBreed",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const addBreed = async (data,adminToken) => {
    const response = await post("/api/v1/add_breed",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
export const uploadBreedExcelFile = async (data,adminToken) => {
    const response = await post("/api/v1/uploadBreedExcelFile",data,adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };

  export const getBreedList = async (adminToken) => {
    const response = await get("/api/v1/get_all_breed",adminToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
  