import { React, useState, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import AdminSidebar from "../AdminSidebar";
import DataTableSetup from "../DataTableSetup";
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import { userLists } from "../../../helpers/adminHelper/userHelper";
import UserModal from "./UserModal";
function UserList(props) {
  const {
    updateLoader,
    updateLoaderMessage,
    updateSidebar,
    isOpenSidebar,
  }=props
  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );
  const [showUserList, setShowUserList] = useState(false);
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageTitle, setPageTitle] = useState("User List");
  const [viewUserInfo, setViewUserInfo] = useState("User List");
  const [noPets, setNoPets] = useState(0);
  const [location, setlocation] = useState("");

  const viewUser = (id) => {
    console.log(id);
    // let UserInfo = userList.filter((value) => value._id === id);
    // setViewUserInfo(UserInfo[0]);
    // console.log(viewUserInfo);
    // setNoPets(UserInfo[0].pet_info.length);
    // const locations =
    //   UserInfo[0].timezones.length != 0
    //     ? UserInfo[0].timezones[0].timezone_name
    //     : "";
    // setlocation(locations);
    // setShowUserList(true);
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => row.first_name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Time Zone",
      selector: (row) =>''
        // row.timezones.length != 0 ? row.timezones[0].timezone_name : "",
      // sortable: true,
    },
    // {
    //     name: 'No. of Pets',
    //     selector: row => row.pet_info,
    //     sortable: true,
    // },
    // {
    //     name: 'Status',
    //     selector: row =>{row.status==='active'?<span style={{color:'green'}}>Active</span>:<span style={{color:'red'}}>InActive</span>} ,
    //     sortable: true,
    // },
    {
      name: "Created Date",
      selector: (row) =>
        row.createdAt != undefined ? row.createdAt.split("T")[0] : "-",
      sortable: true,
    },
    // {
    //     name: 'Update At',
    //     selector: row => row.updatedAt,
    //     selector: row => row.createdAt!=undefined ? row.createdAt.split("T")[0] : "-" ,
    //     sortable: true,
    // },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      cell: (row) => (
        <div>
          <button
            className="icon-button me-3"
            id="productList"
            onClick={() => viewUser(row._id)}
          >
            <i className="fa fa-eye"></i>
          </button>
          <button className="icon-button">
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const fetchAllUserDetails = async () => {
    updateLoader(true);
    updateLoaderMessage("Please wait..");
    const response = await userLists(adminToken);
    console.log(response);
    if (response.status) {
      let userList = response.result;
      setUserList(userList);
    }
    updateLoader(false);
  };

  useEffect(() => {
    fetchAllUserDetails();
  }, [auxToFetch]);

  const [filterText, setFilterText] = useState("");
  const filteredItems = userList.filter(
    (item) =>
      (item.first_name &&
        item.first_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onFilterData = (e) => {
    setFilterText(e.target.value);
  };
  const onFilterClear = () => {
    setFilterText("");
  };
  //-----Search Filter Ends-----//
  const datatableProps = {
    filterText: filterText,
    onFilterData: onFilterData,
    onFilterClear: onFilterClear,
    columns: columns,
    filteredItems: filteredItems,
    btnStatus:false
  };

  const userModalProps = {
    showUserList: showUserList,
    setShowUserList: setShowUserList,
    viewUserInfo: viewUserInfo,
    noPets: noPets,
    location: location,
  };
  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <AdminSidebar
          isOpenSidebar={isOpenSidebar}
          updateSidebar={updateSidebar}
        />
        <main className="page-content ">
          <AdminHeader pageTitle={pageTitle} isOpenSidebar={isOpenSidebar} />
          <main
            className={  "content-holder " + (isOpenSidebar !== true ? "show" : "") }
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="main-wrapper">
                    <DataTableSetup {...datatableProps} />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </main>
      </div>

      <UserModal {...userModalProps} />
    </>
  );
}
export default UserList;
