import create from 'zustand';
import { getPetHistory } from '../helpers/historyHelper'

let store = (set) => ({
    lastTen: {},
    history: {},
    lastKnown: {},
    showHistory: false,

    toggleShowHistory: () => {
        set((state) => {
            return {
                ...state,
                showHistory: !state.showHistory
            }
        })
    },

    loadLastTen: (iccid, positions) => {
        set((state) => {
            return {
                ...state,
                lastTen: {
                    ...state.lastTen,
                    [`${iccid}`] : positions,
                },
            }
        })
    },

    addToLastTen: (iccid, position) => {
        set((state) => {
            const lastTen = state.lastTen[iccid] && Array.isArray(state.lastTen[iccid])  ? state.lastTen[iccid].slice(0) : []
            lastTen.unshift(position);
            if (lastTen.length > 10) {
                lastTen.pop()
            }

            return {
                ...state,
                lastTen: {
                    ...state.lastTen,
                    [`${iccid}`] : lastTen,
                }
            }
        })
    },

    loadHistory: (iccid, positions) => {
        set((state) => {
            const rev = positions?.reverse()
            return {
                ...state,
                history: {
                    ...state.history,
                    [`${iccid}`] :rev,
                },
            }
        })
    },

    updateLastKnownPosition: (iccid, position) => {
        set((state) => {
            return {
                ...state,
                lastKnown: {
                    ...state.lastKnown,
                    [`${iccid}`] : position,
                }
            }
        })
    },

});

const usePetHistoryStore = create(store);

export default usePetHistoryStore;
