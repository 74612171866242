import React from 'react'
import PowerZone from './PowerZone'
import useUIStore from '../../../store/useUIStore'

const PowerZoneCircles = (props) => {
    const {
        canDragPowerZone,
        zoneList,
        zoneId,
        setNewPowerZoneLocation,
        searchPowerList,
        circleType
    } = props


    const showPowerZones = useUIStore((s) => s.showPowerZones)

    const setShowPowerZones = useUIStore((s) => s.setShowPowerZones)
// console.log(showPowerZones);
// console.log(circleType);
// console.log(zoneList);
    const powerZones = circleType==="old" ?(
        zoneList ? zoneList.map((zone, i) => {
        return <PowerZone
                    zone={zone}
                    index={i}
                    canDrag={canDragPowerZone && zone._id === zoneId}
                    key={`power-zone-list-${i}`}
                    setNewPowerZoneLocation={setNewPowerZoneLocation}
                    searchPowerList={searchPowerList}
                    circleType={circleType}
        />
    }) : null
    ):(
        searchPowerList? searchPowerList.map((zone, i) => {
            setShowPowerZones(true);
            return <PowerZone
                        zone={zone}
                        index={i}
                        canDrag={canDragPowerZone }
                        key={`power-zone-list-${i}`}
                        setNewPowerZoneLocation={setNewPowerZoneLocation}
                        searchPowerList={searchPowerList}
                        circleType={circleType}
            />
        }) : null
    )

    return (
        <div>
            {
            showPowerZones &&(
            <>{powerZones}</>
            )
            }
        </div>
    )
}

export default PowerZoneCircles
