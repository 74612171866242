import create from 'zustand';

let store = (set) => ({
    devices: {},
    pets: {},
    updateDeviceStatus: (id, status) =>
        set((state) => {
            const update = Object.assign({}, state.devices)
            update[id].status = status
            return {
                ...state,
                devices: update,
            }
        }),
    loadDeviceMap: (devices) =>
        set((state) => {
            return {
                ...state,
                devices,
            }
        }),
    addPet: (id, pet) =>
        set((state) => {
            const update = Object.assign({}, state.pets)
            update[id] = pet
            return {
                ...state,
                pets: update,
            }
        }),
    loadPetMap: (pets) =>
        set((state) => {
            return {
                ...state,
                pets,
            }
        }),
});

const usePetsDevicesStore = create(store);

export default usePetsDevicesStore;
