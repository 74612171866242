import NumericInput from 'react-numeric-input'

export const NumberValueSelect = (props) => {
    const {type, min, max, value, onChange, label, disabled} = props

    console.log('num', type, disabled)

    return (
        <div className="setting-device form-group">
            <label htmlFor="exampleInputEmail1" className="form-label">
                {label}
            </label>

            <div className="custom-dropdown">
                <NumericInput
                    style={{
                        input: {
                            padding: '8px',
                            borderRadius: '4px',
                            width: '120px',
                        }
                    }}
                    min={min}
                    max={max}
                    value={value}
                    onChange={(val) => onChange(type, val)}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
