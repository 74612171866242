import { get, post,remove } from "./apiHelper";


  export const createPaymentMethod =async (stripe,data) => {
    // const stripe = useStripe();
    const paymentMethod = await stripe.createPaymentMethod({
        type: data.type,
        card: data.card,
      });
      return paymentMethod;
  };
  export const confirmCardPayment =async (stripe,data) => {
      try {
          let val = {}
          val = await stripe.confirmCardPayment(data.clientSecret, data.paymentMethod);
          if (val.error && val.error.payment_intent && val.error.payment_intent.status === 'succeeded') {
              val = {paymentIntent: val.error.payment_intent}
          }
          return val
      } catch (e) {
          if (e.error?.paymentIntent?.status === 'succeeded') {
              return {paymentIntent: e.error.payment_intent}
          } else {
              return {error: e}
          }
      }
  };
