import React, { useEffect, useState } from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe, } from '@stripe/react-stripe-js'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useAuthStore from '../store/useAuthStore'
import { getUserPaymentData, updatePaymentStatus } from '../helpers/registerHelper'
import { checkPromoCode, makePayment } from '../helpers/deviceHelper'
import { confirmCardPayment, createPaymentMethod } from '../helpers/stripeHelper'

const containerStyles = {
    border: '1px solid #fff',
    padding: '8px 10px 8px 10px',
    borderRadius: '4px',
    width: '100%',
    marginBottom: '15px',
    backgroundColor: '#149dcd',
}


const Repayment = ({updateStep, updateLoader}) => {
    const location = useLocation()
    const userToken = useAuthStore((state) => state.token)
    const setToken = useAuthStore((state) => state.setToken)
    const navigate = useNavigate()

    if (location.state?.data) {
        setToken(location.state?.data?.token, location.state?.data?.adminRole)
    }
    else if (!userToken) {
        navigate('/login')
    }
    console.log(process.env.REACT_APP_STRIPE_PUBLISH_KEY)
    console.log(process.env)
    const stripe = useStripe()
    const elements = useElements()
    const [userDetails, setUserDetails] = useState([])
    const [planDetails, setUserPlanDetails] = useState([])
    const [address, setAddress] = useState([])
    const [accountDetails, setAccountDetails] = useState([])
    const [searchParams] = useSearchParams()
    // http://localhost:3000/re-payment?di=dXNlcjAzQHlvcG1haWwuY29t&token=123654
    var deviceID = searchParams.get('di')

    const fetchPaymentDeta = async () => {
        const data = {deviceID: deviceID}

        let userTokens = location.state?.data?.token
        const response = await getUserPaymentData(data, userTokens)
        if (response.status) {
            let res = response.result
            setUserDetails(res[0])
            setUserPlanDetails(res[0].planDetails[0])
            setAddress(res[0].address[0])
            setAccountDetails(res[0].accountDetails[0])
        }
        else {
            setUserDetails([])
        }
    }
    useEffect(() => {
        fetchPaymentDeta()
    }, [])

    const handlePurchase = async (data, promoCode) => {
        try {
            const paymentType = {
                type: 'card',
                card: elements.getElement(CardNumberElement),
            }
            const paymentMethod = await createPaymentMethod(stripe, paymentType)
            const paymentMethodID = paymentMethod.paymentMethod.id
            updateLoader(true)
            const paymentData = {
                name: data.name,
                email: data.email,
                priceID: data.priceID,
                address: data.address,
                planId: data.planId,
                paymentMethod: paymentMethodID,
                promoCode: promoCode,
                paymentProcessType: 0,
            }

            // console.log(paymentData);

            const responseData = await makePayment(paymentData)
            // console.log(responseData);

            if (!responseData.status) {
                navigate('/payment-fail')
            }
            else {
                const clientSecret = responseData.clientSecret
                const last4 = responseData.last4
                const planId = responseData.planId
                // console.log(clientSecret);
                // console.log(last4);
                //  data.mailerData.last4 = last4;

                //  const mailer_info = {
                //    email: sendData.email,
                //    mailerData:sendData.mailerData
                //  }
                //  console.log(mailer_info);

                if (clientSecret) {
                  const confrimPaymentData = {
                    clientSecret: clientSecret,
                    paymentMethod: {payment_method: paymentMethodID}
                  }
                  const {error, paymentIntent} = await confirmCardPayment(stripe, confrimPaymentData)
                  // console.log(error);
                  // console.log(paymentIntent);

                  if (error) {
                    // console.error(error);
                    // alert(error)
                    navigate('/payment-fail')

                  }
                  else if (paymentIntent && paymentIntent.status === 'succeeded') {
                    // console.log("Payment succeeded");
                    //  console.log(mailer_info);
                    //  const mailerStatus = await sendRegisterationMail(mailer_info);
                    //  console.log(mailerStatus);
                    const planData = {planId: planId}
                    const paymentStatus = await updatePaymentStatus(planData)
                    // console.log(paymentStatus);
                    if (paymentStatus) {
                      updateLoader(false)
                      navigate('/payment-success')
                    }
                    else {
                      navigate('/payment-fail')
                    }

                  }
                  else {
                    // console.log("Payment failed");
                    navigate('/payment-fail')
                  }
                } else {
                  const planData = {planId: planId}
                  const paymentStatus = await updatePaymentStatus(planData)
                  updateLoader(false)
                  navigate('/payment-success')
                }
                updateLoader(false)
            }
        } catch (error) {
            alert('Payment failed :' + error)
        }
    }

    // const sendRegisterationMail = async (mailerData) => {
    //
    //   const response = await userRegisterationMail(mailerData);
    //   // if (response.status) {
    //     console.log(response);
    //   // }
    // }

    const formSubmit = async (data) => {

        let addressDetails
        if (address.country === 'USA') {
            addressDetails = {
                line1: address.street_addr + '  ' + address.apartment,
                postal_code: address.zip_code,
                city: address.usa_city,
                state: address.state,
                country: 'US',
            }
        }
        else {
            addressDetails = {
                line1: address.street_addr + '  ' + address.apartment,
                postal_code: address.postal_code,
                city: address.canada_city,
                state: address.province,
                country: 'CA',
            }
        }

        var result = {
            name: accountDetails.first_name,
            email: accountDetails.email,
            priceID: planDetails.stripePriceID,
            address: addressDetails,
            planId: planDetails._id,
        }

        handlePurchase(result, promoCode)
    }
    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm({
        mode: 'all',
    })
    const [isSubmitting, setIsSubmitting] = useState(true)
    const [isCodeBtnValid, setIsCodeBtnValid] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const [codeMessage, setCodeMessage] = useState('')
    const handleChange = (event) => {
        setIsSubmitting((isSubmitting) => !isSubmitting)
    }

    const cardElementOptions = {
        style: {
            base: {
                color: '#000',
                border: '1px solid #ccc',
                fontSize: '15px',
                lineHeight: '30px',
                textAlign: 'center',
                backgroundColor: '#fff',
                '::placeholder': {
                    color: '#87BBFD',
                },
            },
            invalid: {
                color: '#fa755a',
                fontSize: '15px',
            },
        },
    }

    const promoCodeCheck = async (value) => {
        setIsCodeBtnValid(false)
        const data = {promoCode: value}
        const response = await checkPromoCode(data)
        if (response.status) {
            const result = response.result.data
            if (result.length > 0) {
                setCodeMessage('\u2713  Coupon Applied Successfully')
                setIsCodeBtnValid(true)
                setPromoCode(result.data[0].coupon.id)
            }
            else {
                setIsCodeBtnValid(false)
                setPromoCode('')
                setCodeMessage('Your Coupon Code is not valid.')
            }
        }
        else {
        }
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0 login-img-holder">
                        <div className="login-bg h-100"></div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0">
                        <div className="login-holder d-flex align-items-center h-100">
                            <div className="login-container h-100">
                                <div className="logo">
                                    <img src={require('../images/logo-white_.png')} alt="gibi"/>
                                </div>
                                <>
                                    <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                                        <h3 className="panel-title text-center text-white">
                                            Confirmation
                                        </h3>
                                        <div className="view-data mb-4">
                                            <div className="row mb-3">
                                                <div className="col-sm-4 col-12">
                                                    <label className="caption-label">Tracker ID :</label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <label className="value-label">
                                                        {userDetails.deviceName}{' '}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4 col-12">
                                                    <label className="caption-label">
                                                        Subscription Plan:
                                                    </label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <label className="value-label">
                                                        {planDetails.plan_type} - $ {planDetails.plan_amount}
                                                    </label>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4 col-12">
                                                    <label className="caption-label">Name :</label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <label className="value-label">
                                                        {accountDetails.first_name}
                                                        {accountDetails.last_name}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4 col-12">
                                                    <label className="caption-label">Email :</label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <label className="value-label">
                                                        {accountDetails.email}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4 col-12">
                                                    <label className="caption-label">Mobile :</label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <label className="value-label">
                                                        {accountDetails.mobile}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4 col-12">
                                                    <label className="caption-label">
                                                        Billing Address :
                                                    </label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    {address.street_addr && (
                                                        <p className="value-label mb-0">
                                                            {address.street_addr}
                                                        </p>
                                                    )}
                                                    {address.apartment && (
                                                        <p className="value-label mb-0">
                                                            {address.apartment}
                                                        </p>
                                                    )}
                                                    {address.address1 && (
                                                        <p className="value-label mb-0">
                                                            {address.address1}
                                                        </p>
                                                    )}
                                                    {address.address2 && (
                                                        <p className="value-label mb-0">
                                                            {address.address2}
                                                        </p>
                                                    )}
                                                    {address.address3 && (
                                                        <p className="value-label mb-0">
                                                            {address.address3}
                                                        </p>
                                                    )}
                                                    {address.state && (
                                                        <p className="value-label mb-0">
                                                            {address.usa_city != ''
                                                                ? address.usa_city
                                                                : address.canada_city}
                                                            , {address.state}
                                                        </p>
                                                    )}
                                                    {address.zip_code && (
                                                        <p className="value-label mb-0">
                                                            {address.zip_code}
                                                        </p>
                                                    )}
                                                    {address.postal_code && (
                                                        <p className="value-label mb-0">
                                                            {address.postal_code}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={containerStyles}>
                                            <div className="card-details px-0 border-0 text-white">
                                                <p className="fw-bold">
                                                    Payment failed. Please re-enter payment details.{' '}
                                                </p>
                                                <div className="row mb-2">
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                        <label>Card Number :</label>
                                                        <CardNumberElement options={cardElementOptions}/>
                                                    </div>
                                                </div>
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-xl-6 col-lg-6  col-md-6 col-6">
                                                        <label>CVC :</label>
                                                        <CardCvcElement options={cardElementOptions}/>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6  col-md-6 col-6">
                                                        <label>Expiry Date :</label>
                                                        <CardExpiryElement options={cardElementOptions}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-8">
                                                <input
                                                    type="text"
                                                    className={
                                                        'form-control mb-2' +
                                                        (errors.first_name ? ' is-invalid' : '')
                                                    }
                                                    name="promoCode"
                                                    {...register('promoCode')}
                                                    placeholder="Enter Your Coupon Code"
                                                    onChange={(e) => setPromoCode(e.target.value)}
                                                />
                                                <div
                                                    className={
                                                        isCodeBtnValid === true ? 'text-white' : 'msg-red'
                                                    }
                                                >
                                                    {codeMessage}
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary white w-100"
                                                    onClick={() => {
                                                        promoCodeCheck(promoCode)
                                                    }}
                                                    disabled={isCodeBtnValid || !promoCode}
                                                >Apply
                                                </button>
                                            </div>
                                        </div>
                                        <p className="text-white mb-4 policy-text">
                                            <div className="form-check form-check-inline custom-check check-change">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="630f2195c0799f4e093cec1d"
                                                    name="terms"
                                                    value={isSubmitting}
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    className="form-check-label clearfix text-white"
                                                    htmlFor="630f2195c0799f4e093cec1d"
                                                >
                                                    I have read and agree to Terms of Service, the Terms
                                                    of Use, and Privacy Policy of Gibi Technologies Inc
                                                </label>
                                            </div>
                                        </p>

                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary white w-25"
                                                disabled={isSubmitting}
                                            > Pay
                                            </button>
                                        </div>
                                    </form>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Repayment
