import { React, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import {
  getAllcheckInRate,
  updateCheckInRateStatus,
} from "../../../helpers/adminHelper/settigHelper";
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import DataTableSetup from "../DataTableSetup";
import AddTimeModal from "./AddTimeModal";
import { toastConfig } from "../adminConfig";

const TimeFrequency = () => {
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );
  const [showFrequency, setShowFrequency] = useState(false);
  const [checkInRate, setCheckInRate] = useState([]);

  const columns = [
    {
      name: "Check In Rate",
      selector: (row) => row.checkRate,
      sortable: true,
    },
    {
      name: " Check In Rate Type",
      selector: (row) =>
        row.checkInRateType === "powerSaveRate"
          ? "Power Save Rate"
          : row.checkInRateType === "noCellularRate"
          ? "No Cellular Scan Rate"
          : "Local Check in Rate",
      sortable: true,
    },

    {
      name: "Status",
      // selector: row => row.status === true ? "Active" : "Deactive",
      sortable: true,
      cell: (row) => (
        <div>
          {row.status === true ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>InActive</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-disabled">Click to change status.</Tooltip>
            }
          >
            <button className="icon-button">
              <i
                className={
                  row.status === true ? "fa fa-trash" : "fa fa-undo"
                }
                onClick={() => {
                  deleteID(row._id, row.status);
                }}
              >
                {" "}
              </i>
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
    // {
    //     name: 'Action',
    //     selector: row => row.action,
    //     sortable: false,
    //     cell: row => (
    //         <div>
    //             {/* <button className='icon-button me-3' id="productList" onClick={() => setShowUserList(true)}><i className="fa fa-eye"></i></button> */}
    //             <button className='icon-button'><i className="fa fa-trash"></i></button>
    //         </div>
    //     )
    // },
  ];

  const fetchAllCheckInRate = async () => {
    const response = await getAllcheckInRate(adminToken);
    if (response.status) {
      let checkInRate = response.result;
      setCheckInRate(checkInRate);
    }
  };

  useEffect(() => {
    fetchAllCheckInRate();
  }, [auxToFetch]);

  const removeCheckInRate = async (data, onClose) => {
    const response = await updateCheckInRateStatus(data, adminToken);
    if (response.status) {
      console.log(response.message);
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };
  const deleteID = (id, status) => {
    console.log({ id: id, status: status });
    const statusValue = status === true ? "In-Active" : "Active";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {statusValue} ?</h3>
            {/* <p>You want to delete this file?</p> */}
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                const data = { id: id, status: status };
                removeCheckInRate(data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  //-----Search Filter Starts-----//
  const [filterText, setFilterText] = useState("");
  const filteredItems = checkInRate.filter(
    (item) =>
      (item.checkRate &&
        item.checkRate.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.checkInRateType &&
        item.checkInRateType.toLowerCase().includes(filterText.toLowerCase()))
  );

  const onFilterData = (e) => {
    setFilterText(e.target.value);
  };
  const onFilterClear = () => {
    setFilterText("");
  };
  //-----Search Filter Ends-----//
  const btnStatus = true;
  const BtnContent = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">Click to add Time Frequency</Tooltip>
        }
      >
        <button
          className="btn btn-primary mb-2 "
          onClick={() => {
            setShowFrequency(true);
          }}
        >
          Add Time Frequency
        </button>
      </OverlayTrigger>
    );
  };
  const datatableProps = {
    filterText: filterText,
    onFilterData: onFilterData,
    onFilterClear: onFilterClear,
    columns: columns,
    filteredItems: filteredItems,
    btnStatus: btnStatus,
    BtnContent: BtnContent,
  };
  const timeModalProps = {
    showFrequency: showFrequency,
    setShowFrequency: setShowFrequency,
    auxToFetch: auxToFetch,
    setAuxToFetch: setAuxToFetch,
  };
  return (
    <>
      <DataTableSetup {...datatableProps} />
      <AddTimeModal {...timeModalProps} />
    </>
  );
};
export default TimeFrequency;
