import React, { useState, createContext, useContext } from "react";
const Message = ({ updateStep }) => {
  // const user = useContext(UserContext);
  // console.log(user);
  const previousStep = () => {
    updateStep(1);
  };
  const nextStep = () => {
    updateStep(3);
  };
  const StepButtons = () => (
    <>
      <ul className="list-inline w-100 d-flex align-items-center mb-0">
        <li className="list-inline-item m-0 w-50 pe-2">
          <button
            type="button"
            className="btn btn-outline-primary white w-100"
            onClick={previousStep}
          >
            Previous
          </button>
        </li>
        <li className="list-inline-item m-0 w-50 ps-2">
          <button
            type="button"
            className="btn btn-outline-primary white w-100"
            onClick={nextStep}
          >
            Next
          </button>
        </li>
      </ul>
    </>
  );
  return (
    <>
      <div className="login-holder d-flex align-items-center">
        <div className="login-container">
          <div className="logo">
            <img src={require("../../images/logo-white.png")} alt="gibi" />
          </div>

          <div className="form-group">
            <div className="msg">
              <p className="text-white">
                By creating a Gibi Account, you agree to Gibi Technologies
                Inc.’s Terms of Use, Terms of Service, and Privacy Policy.
              </p>
              <p className="text-white">
                See{" "}
                <a href="#" className="text-white">
                  getgibi.com/legal
                </a>
              </p>
            </div>
          </div>
          <StepButtons />
        </div>
      </div>
    </>
  );
};
export default Message;
