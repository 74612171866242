import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React, {useEffect, useState} from 'react';
import useAuthStore from '../../store/useAuthStore';
import usePetsDevicesStore from '../../store/usePetsDevicesStore';
import {checkIfCommandInProgress} from '../../helpers/commandHelpers';
import {findPet} from './FindPetControl';
import './Find.css'

export const FindButton = (props) => {
    const {iccid} = props
    const userToken = useAuthStore((s) => s.token)
    const devices = usePetsDevicesStore((s) => s.devices)
    const device = devices[iccid]
    const [isSleeping, setIsSleeping] = useState(false)
    const [isCommandInProgress, setIsCommandInProgress] = useState(false)
    const [isBeaconInRange, setIsBeaconInRange] = useState(false)
    const [isInCharger, setIsInCharger] = useState(false)
    const [_iccid, setIccid] = useState(iccid)

    useEffect(() => {
        setIccid(iccid)
    }, [iccid])

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            setIsSleeping(device.status.sleeping)
            setIsCommandInProgress(checkIfCommandInProgress(device.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(device.status.beaconInRange)
            setIsInCharger(device.status.charging)
        }
    }, [_iccid])

    useEffect(() => {
        const device = devices[iccid]
        if (device) {
            setIsSleeping(device.status.sleeping)
            setIsCommandInProgress(checkIfCommandInProgress(device.status.deviceCommandInProgressStatus))
            setIsBeaconInRange(device.status.beaconInRange)
            setIsInCharger(device.status.charging)
        }
    }, [devices])

    const disabled = isCommandInProgress || (isBeaconInRange && !isInCharger) || isSleeping
    return (
        <div className="nav-item width100" role="presentation">
            <button className="btn btn-sm find-btn me-2 mb-2 width100 activeButton"
                    style={{paddingLeft:"12px"}}
                    onClick={() => {
                        findPet(userToken, iccid, isSleeping, isCommandInProgress, isBeaconInRange)
                    }}
                    disabled={disabled}
            >

                    <span className="me-2">
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </span>
                Find
            </button>
        </div>
    )
}
