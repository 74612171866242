import React, { useState } from "react";
import Register from "./Registeration/Register";
import Message from "./Registeration/Message";
import Petinfo from "./Registeration/Petinfo";
import Subscription from "./Registeration/Subscription";
import BillingAddress from "./Registeration/BillingAddress";
import Confirmation from "./Registeration/Confirmation";
import Welcome from "./Registeration/Welcome";
import UserAddress from "./Registeration/UserAddress";

export default function UserForm({ updateLoader,isActive }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [step, setStep] = useState(1);
  const [registerInfo, setRegisterInfo] = useState({});

  const [petInfo, setPetInfo] = useState({});
  const [planInfo, setPlanInfo] = useState({});
  const [billInfo, setBillInfo] = useState({});
  const [homeAddress, setHomeAddress] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const updateStep = (no) => {
    setStep(no);
  };

  const updatesetRegisterInfo = (data) => {
    setRegisterInfo(data);
  };
  const updatesetPetInfo = (data) => {
    setPetInfo(data);
  };
  const updatesetPlanInfo = (data) => {
    setPlanInfo(data);
  };
  const updatesetBillInfo = (data) => {
    setBillInfo(data);
  };
  const updatesetHomeAddress = (data) => {
    setHomeAddress(data);
  };

  const updateSelectedFile = (data) => {
    setSelectedFile(data);
  };

  switch (step) {
    case 1:
      const registerProps = {
        updateStep: updateStep,
        registerInfo: registerInfo,
        updatesetRegisterInfo: updatesetRegisterInfo,
        updateLoader: updateLoader,
        width
      };
      return <Register {...registerProps} />;

    // case 2:
    //   const messageProps = {
    //     updateStep: updateStep,
    //   };
    //   return <Message {...messageProps} />;
    case 2:
      const userAddress = {
        updateStep: updateStep,
        homeAddress: homeAddress,
        updatesetHomeAddress: updatesetHomeAddress,
        updateLoader: updateLoader,
      };
      return <UserAddress {...userAddress} />;

    case 3:
      const petInfoProps = {
        updateStep: updateStep,
        petInfo: petInfo,
        updatesetPetInfo: updatesetPetInfo,
        selectedFile: selectedFile,
        updateSelectedFile: updateSelectedFile,
        updateLoader: updateLoader,
        width
      };
      return <Petinfo {...petInfoProps} />;


    case 4:
      const subscriptionProps = {
        updateStep: updateStep,
        planInfo: planInfo,
        updatesetPlanInfo: updatesetPlanInfo,
        updateLoader: updateLoader,
        width
      };
      return <Subscription {...subscriptionProps} />;

    case 5:
      const BillingAddressProps = {
        updateStep: updateStep,
        billInfo: billInfo,
        homeAddress: homeAddress,
        updatesetBillInfo: updatesetBillInfo,
        updateLoader: updateLoader,
      };

      return <BillingAddress {...BillingAddressProps} registerInfo={registerInfo}/>;

    case 6:
      const confirmationProops = {
        updateStep: updateStep,
        homeAddress:homeAddress,
        billInfo: billInfo,
        planInfo: planInfo,
        petInfo: petInfo,
        registerInfo: registerInfo,
        selectedFile: selectedFile,
        updateLoader: updateLoader,
        isActive:isActive,
        width
      };
      return <Confirmation {...confirmationProops} />;
    case 7:
      return <Welcome width={width}/>;
    default:
      return <Welcome />;
  }
}
