import React, { useContext, useEffect, useRef, useState, } from 'react'
import { GoogleMap, useGoogleMap } from '@react-google-maps/api'
import useMapStore from '../../store/useMapStore'
import usePolygonStore from '../../store/usePolygonStore'
import Markers from './Marker/Markers'
import SafeZonePolygons from './SafeZonePloygons/SafeZonePolygons'
import SafeZoneSearchBox from '../../components/SafeZone/SafeZoneSearchBox'
import UserContext from '../../contextApi/UserContext'
import PowerZoneCircles from './Circle/PowerZoneCircles'
import { getCenterOfSafeZone, getPlaceholderSafeZone } from './mapHelpers'
import { LocationHighlight } from './Circle/LocationHighlight'
import ReferencePoint from './Circle/ReferencePoint'
import useZoneStore from '../../store/useZoneStore'
import { PetHistorySection } from '../PetHistory/PetHistorySection'

const containerStyle = {
    width: '100%',
    height: '92vh',
}

export const referencePoint = [-87.64094, 41.90796]

export const GibiMap = (props) => {
    const mapCenter = useMapStore((s) => s.mapCenter)
    const setMapCenter = useMapStore((s) => s.setMapCenter)
    const setGoogleMap = useMapStore((s) => s.setGoogleMap)
    const mapInstance = useMapStore((s) => s.googleMap)
    const setMapZoom = useMapStore((s) => s.setMapZoom)
    const mapZoom = useMapStore((s) => s.mapZoom)

    const setFetchZonesToggle = useZoneStore((s) => s.setFetchZones)
    const fetchZonesToggle = useZoneStore((s) => s.fetchZonesToggle)
    const showSearchBox = useZoneStore((s) => s.showSearchBox)
    const setShowSearchBox = useZoneStore((s) => s.setShowSearchBox)
    const zoneCoords = useZoneStore((s) => s.zoneCoords)
    const setZoneCoords = useZoneStore((s) => s.setZoneCoords)
    const newPowerZoneLocation = useZoneStore((s) => s.newPowerZoneLocation)
    const setNewPowerZoneLocation = useZoneStore((s) => s.setNewPowerZoneLocation)
    const buttonType = useZoneStore((s) => s.buttonType)
    const canDragSafeZone = useZoneStore((s) => s.canDragSafeZone)
    const setCanDragSafeZone = useZoneStore((s) => s.setCanDragSafeZone)
    const canEditSafeZone = useZoneStore((s) => s.canEditSafeZone)
    const setCanEditSafeZone = useZoneStore((s) => s.setCanEditSafeZone)
    const safeZoneList = useZoneStore((s) => s.safeZoneList)
    const setSafeZoneList = useZoneStore((s) => s.setSafeZoneList)
    const newSafeZoneGeometry = useZoneStore((s) => s.newSafeZoneGeometry)
    const setNewSafeZoneGeometry = useZoneStore((s) => s.setNewSafeZoneGeometry)
    const powerZoneList = useZoneStore((s) => s.powerZoneList)
    const setPowerZoneList = useZoneStore((s) => s.setPowerZoneList)
    const canDragPowerZone = useZoneStore((s) => s.canDragPowerZone)
    const setCanDragPowerZone = useZoneStore((s) => s.setCanDragPowerZone)
    const setZoneID = useZoneStore((s) => s.setZoneID)
    const zoneID = useZoneStore((s) => s.zoneID)
    const zoneName = useZoneStore((s) => s.zoneName)

    const zoneLists = usePolygonStore()
    let zoneListData = zoneLists.useSafeZoneList

    const mapRef = useRef(null)

    function handleLoad(map) {
        mapRef.current = map
    }


    useEffect(() => {
        if (zoneListData.length != 0) {
            let zoneCoords
            if (zoneListData.length !== 0) {
                zoneCoords = zoneListData.reduce((zoneCoords, data) => {
                    zoneCoords.push(data.zone_coordinates)
                    return zoneCoords
                }, [])
            }

            setMapCenter(getCenterOfSafeZone(zoneCoords[0]))
        }
    }, [zoneListData])


    const [searchCount, setSearchCount] = useState(0)
    const onLoad = (map) => {
        setGoogleMap(map)
        console.log('map loaded')
    }

    const onUnmount = (map) => {
        setGoogleMap(null)
        useMapStore.getState().setBlockFitBounds(true)
        console.log('map unmount')
    }

    const MapChild = data => {
        const mapData = useGoogleMap()
        // console.log('google', mapData)
        return null
    }

    const onMapClick = (evt) => {
        console.log('onMapClick', evt)
        // getPlaceholderZoneForCreation()
    }

    const onZoomChanged = () => {
        if (mapInstance) {
            const zoom = mapInstance.getZoom()
            // console.log('zoom changed', zoom)
            setMapZoom(zoom)
        }
    }

    // when an address is selected we create a placeholder zone shape
    // at the zoneCoords location
    useEffect(() => {
        if (zoneCoords.lat !== undefined && zoneCoords.lng !== undefined) {
            getPlaceholderZoneForCreation()
        }
    }, [zoneCoords])
    const [searchZoneList,setSearchZoneList] =useState([]);
    const [polygonType,setPolygonType] =useState("old");
    const [searchPowerList,setSearchPowerList] =useState([]);
    const [circleType,setCircleType] =useState("old");

    const getPlaceholderZoneForCreation = (ref) => {
        console.log(zoneCoords)
        console.log(ref)
        console.log(searchCount)
        if (buttonType.length !== 0 && buttonType[0] === 'safe') {
            if (zoneCoords.lat !== undefined && zoneCoords.lng !== undefined) {
                setSearchCount(searchCount + 1)

                // const lat = searchCount === 0 ? zoneCoords.lat : ref?.latLng.lat()
                // const lng = searchCount === 0 ? zoneCoords.lng : ref?.latLng.lng()
                const lat = zoneCoords.lat  
                const lng = zoneCoords.lng  

                const safeZoneCoords = getPlaceholderSafeZone(lat, lng)
                const zone = {
                    _id: `new-safe-zone`,
                    zone_type: 'safe',
                    zone_name: '',
                    zone_coordinates: safeZoneCoords
                }
                console.log(zone);
                setZoneID('new-safe-zone')
                setNewSafeZoneGeometry(safeZoneCoords)
                // setSafeZoneList([zone])
                setSearchZoneList([zone]);
                setPolygonType("new");
                setCanDragSafeZone(true)
                setCanEditSafeZone(true)
            }
        }
        else {
            // new power zone
            if (zoneCoords.lat !== undefined && zoneCoords.lng !== undefined) {
                const zone = {
                    _id: `new-power-zone`,
                    zone_type: 'power',
                    zone_name: '',
                    zone_coordinates: [zoneCoords]
                }
                setZoneID('new-power-zone')
                setNewPowerZoneLocation(zoneCoords)
                // setPowerZoneList([zone])
                setSearchPowerList([zone])
                setCanDragPowerZone(true)
                setCircleType("new");
            }
        }
    }

    const searchBoxProps = {
        setMapCenter,
        setSearchCount,
        setCanDragPowerZone,
        showSearchBox,
        setShowSearchBox,
        setZoneCoords,
        buttonType,
        newPowerZoneLocation,
        newSafeZoneGeometry,
        setCanDragSafeZone,
        setCanEditSafeZone,
        setNewSafeZoneGeometry,
        setNewPowerZoneLocation,
        zoneID,
        zoneName,
        setPolygonType,
        setCircleType
    }

    console.log(mapCenter, mapZoom)
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            // options={options}
            // mapContainerClassName="App-map"
            // defaultOptions={defaultOptions}
            zoomControl="false"
            center={mapCenter}
            zoom={mapZoom}
            version="weekly"
            onClick={onMapClick}
            defaultCenter={mapCenter}
            onZoomChanged={onZoomChanged}
        >
            {/*<UserContext.Provider value={{searchBoxProps}}>*/}
                <SafeZoneSearchBox {...searchBoxProps} />
            {/*</UserContext.Provider>*/}
            <MapChild
                options={{
                    zoomControl: false, // zoomControlOptions: {
                    //   position: mapData.maps.ControlPosition.TOP_LEFT
                    // }
                }}
            />

            <Markers/>

            <SafeZonePolygons canDragSafeZone={canDragSafeZone}
                              canEditSafeZone={canEditSafeZone}
                              zoneList={safeZoneList}
                              zoneId={zoneID}
                              searchZoneList={searchZoneList}
                              polygonType={polygonType}
                              setPolygonType={setPolygonType}
                              setNewSafeZoneGeometry={setNewSafeZoneGeometry}
                              />

            <PowerZoneCircles canDragPowerZone={canDragPowerZone}
                              zoneList={powerZoneList}
                              zoneId={zoneID}
                              setNewPowerZoneLocation={setNewPowerZoneLocation}
                              searchPowerList={searchPowerList}
                              circleType={circleType}
                              setCircleType={setCircleType}
            />

            <LocationHighlight/>
            {/*<ReferencePoint coords={referencePoint}/>*/}

            <PetHistorySection/>

        </GoogleMap>
    )
}
