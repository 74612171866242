import { post } from "./apiHelper";

export const checkEmailExistorNot = async (data) => {
  const response = await post("/api/v1/check_email_exist", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const checkDeviceExistorNot = async (data) => {
  const response = await post("/api/v1/checkDeviceExist", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const checkMobileExistorNot = async (data) => {
  const response = await post("/api/v1/checkMobileExist", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const CityStateLookupRequest = async (data) => {
  const response = await post("/api/v1/CityStateLookupRequest", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const userRegisteration = async (data) => {
  const response = await post("/api/v1/user-registeration", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getUserPaymentData = async (data, userToken) => {
  const response = await post("/api/v1/getUserPaymentDetails", data, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const resetPassword = async (data) => {
  const response = await post("/api/v1/resetPassword", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};


export const sendForgetMail = async (data) => {
  const response = await post("/api/v1/forgetPassword", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const checkTokenValid = async (data) => {
  const response = await post("/api/v1/checkTokenValid", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const userRegisterationMail = async (data) => {
  const response = await post("/api/v1/user-registeration-mail", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const updatePaymentStatus = async (data) => {
  const response = await post("/api/v1/updatePaymentStatus", data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

