import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar.js'
import Header from "../Header";
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import useAuthStore from '../../store/useAuthStore'
import { getDevices, gethAccountDetail, getTimeZone, } from '../../helpers/accountHelper'
import ChangePassModal from './MyAccountModal/ChangePassModal.js'
import EditAccountInfoModal from './MyAccountModal/EditAccountInfoModal.js'
import AddressModel from './MyAccountModal/AddressModel.js'
import { DateTime, Interval, Settings } from 'luxon'
import useGibiConfigStore from '../../store/useGibiConfigStore'
import MenuSidebar from '../MenuSidebar';
import useUIStore from '../../store/useUIStore'

const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        }
    },
}

function MyAccount({updateLoader, updateSidebar}) {
    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        mode: 'all',
        // resolver: yupResolver(schema),
        // defaultValues: {form_type:true}
    })

    const navigate = useNavigate()

    const [userBillInfo, setUserBillInfo] = useState({})
    const [userAccountInfo, setUserAccountInfo] = useState({})
    const [showEditAccountInfo, setShowEditAccountInfo] = useState(false)
    const [showAddGibi, setShowAddGibi] = useState(false)
    const [auxToFetch, setAuxToFetch] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [deviceList, setDeviceList] = useState([])
    const [deviceData, setDeviceData] = useState([])
    const [planRenewal, setPlanRenewal] = useState('')
    const [planStatus, setPlanStatus] = useState('')

    const [options, setOption] = React.useState([
        {value: 'NA', label: 'No timezone avaialbe'},
    ])

    const userToken = useAuthStore((s) => s.token)

    const toastConfig = {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    const fetchAccountDetails = async (userToken) => {
        updateLoader(true)
        const accountData = await gethAccountDetail(userToken)

        if (accountData.status) {
            setUserAccountInfo(accountData.account_info)
            setUserBillInfo(accountData.account_info)
        }
        else {
            setUserAccountInfo({})
            setUserBillInfo({})
        }
        updateLoader(false)
    }

    const fetchTimeZone = async () => {
        const timeZoneData = await getTimeZone(userToken)
        if (timeZoneData.status) {
            const timezone_type = timeZoneData.result
            useGibiConfigStore.getState().loadTimezones(timezone_type)
            setOption(
                timezone_type.map((item) => ({
                    value: item._id,
                    label: item.timezone_name,
                })).sort((a, b) => (a.offset > b.offset) ? -11 : 1)
            )
        }
        else {
            setOption([])
        }
    }

    const [deviceValue, setDeviceValue] = useState({})
    const [planDetail, setPlanDetail] = useState({})
    const [billAddressDetails, setBillAddressDetails] = useState({})

    const calcPlanRenewal = (plan) => {
        if (plan && plan.plan_start) {
            const now = DateTime.now()
            const start = DateTime.fromISO(plan.plan_start)
            const isMonthly = plan.description.toLowerCase().includes('month')
            let endDate
            if (isMonthly) {
                const months = Math.ceil(Interval.fromDateTimes(start, now).length('months'))
                endDate = start.plus({months})
            }
            else {
                const years = Math.ceil(Interval.fromDateTimes(start, now).length('years'))
                endDate = start.plus({years})
            }
            setPlanRenewal(endDate.setZone(useAuthStore.getState().timezone.value).toLocaleString(DateTime.DATE_FULL))
        }
    }

    const fetchDevices = async () => {
        const deviceResult = await getDevices(userToken)

        if ((deviceResult.status = true)) {
            let devices = deviceResult.result
            setDeviceList(
                devices.map((item) => ({value: item._id, label: item.deviceName}))
            )
            setDeviceData(devices)
            setValue('deviceList', devices[0]?._id || '')
            let deviceId = devices[0]?._id || ''
            const planDetails = devices.filter(
                (deviceData) => deviceData._id === deviceId
            )
            setPlanDetail(planDetails[0]?.planId || '')
            const billAddressDetails = devices.filter(
                (deviceData) => deviceData._id === deviceId
            )
            setBillAddressDetails(billAddressDetails[0]?.billInfoId || '')
        }

    }

    useEffect(() => {
        // console.log(auxToFetch)
        if (userToken === '' || userToken === null) {
            navigate('/login')
        }
        else {
            fetchAccountDetails(userToken)
            fetchTimeZone()
            fetchDevices(userToken)
        }
        setIsSubmitting(false)
    }, [auxToFetch])

    useEffect(() => {
        // console.log(planDetail)

        // let day2 = planDetail?.plan_end?.split('T')[0]

        // const ednDate = DateTime.fromISO(day2, {zone: useAuthStore.getState().timezone.value})
        // const diff = Math.floor(ednDate.diffNow().as('day'))
        // if (diff > 0) {
        //     setDaysRemains(<span style={{color: '#139dcc'}}>{diff} Days Remains</span>)
        // }
        // else {
        //     setDaysRemains(<span style={{color: '#e50f0f'}}>Plan Renews</span>)
        // }
        if (planDetail) {
            calcPlanRenewal(planDetail)
            if (planDetail.status) {
                setPlanStatus(planDetail.status.toUpperCase())
            }
        }

    }, [planDetail])

    const [userData, setUserData] = useState({})

    const editAccountinfo = (userValues, address_values) => {
        setUserData(userValues)
        setShowEditAccountInfo(true)
    }

    const [showOldPassword, setShowOldPassword] = useState(false)
    const toggleOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }
    const [showNewPassword, setShowNewPassword] = useState(false)
    const toggleNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const [statusValue, setStatusValue] = useState(0)
    const handleStatusChange = (e) => {
        setStatusValue(e.value)
        let deviceId = e.target.value
        const planDetails = deviceData.filter(
            (deviceData) => deviceData._id === deviceId
        )
        setPlanDetail(planDetails[0].planId)
    }

    let changePassProps = {
        showChangePassword: showChangePassword,
        setShowChangePassword: setShowChangePassword,
        showOldPassword: showOldPassword,
        toggleOldPassword: toggleOldPassword,
        showNewPassword: showNewPassword,
        toggleNewPassword: toggleNewPassword,
        isSubmitting: isSubmitting,
        toastConfig: toastConfig,
        setAuxToFetch: setAuxToFetch,
        auxToFetch: auxToFetch,
        setIsSubmitting: setIsSubmitting,
    }
    let EditAccountInfoProps = {
        userData: userData,
        showEditAccountInfo: showEditAccountInfo,
        setShowEditAccountInfo: setShowEditAccountInfo,
        isSubmitting: isSubmitting,
        toastConfig: toastConfig,
        setIsSubmitting: setIsSubmitting,
        customStyles: customStyles,
        setShowAddGibi: setShowAddGibi,
        options: options,
        setAuxToFetch: setAuxToFetch,
        auxToFetch: auxToFetch,
    }

    let addressProps = {
        showAddGibi: showAddGibi,
        setShowAddGibi: setShowAddGibi,
        updateLoader: updateLoader,
        userBillInfo: userBillInfo,
        setUserBillInfo: setUserBillInfo,
        toastConfig: toastConfig,
        setAuxToFetch: setAuxToFetch,
        auxToFetch: auxToFetch,
    }
    const [width, setWidth] = useState(window.innerWidth)
    const [daysRemains, setDaysRemains] = useState('active')
    const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
    const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)

    console.log(planDetail)

    return (
        <>
        <Header/>
            <main id="mainContent" className={!isOpenSidebar ? 'show' : ''}>
               <MenuSidebar/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xx-12">
                            <div className="card parent mt-2">
                                <div className="card-header"> Account & Billing</div>
                                <div className="card-body scrollbar">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-sm-12 my-account mt-0">
                                            <h4 className="section-title mb-2">Account</h4>
                                            <div className="card mb-2 h-90">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-sm-2 offset-sm-10">
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                    <Tooltip id="tooltip-disabled">
                                                                        Click to update account details.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    className="btn btn-link float-end"
                                                                    style={{color: '#139dcc'}}
                                                                    onClick={() =>
                                                                        editAccountinfo(
                                                                            userAccountInfo,
                                                                            userBillInfo
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fa fa-pencil-square-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Name :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {userAccountInfo.first_name}{' '}
                                                                {userAccountInfo.last_name}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Email :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {userAccountInfo.email}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Mobile :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {userAccountInfo.mobile}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Country :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {userBillInfo?.homeAddress?.country}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Address :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {userBillInfo?.homeAddress?.street_addr}
                                                                <br/>
                                                                {userBillInfo?.homeAddress?.usa_city}, {userBillInfo?.homeAddress?.state}
                                                                <br/>
                                                                {userBillInfo?.homeAddress?.zip_code}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Time Zone :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            {userAccountInfo.timeZone && (
                                                                <label className="form-label value">
                                                                    {userAccountInfo.timeZone.timezone_name}
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                SMS Alerts :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {userAccountInfo.smsOptIn == 'Opt-in' ?
                                                                    <span style={{color: '#41bd41'}}>Opted in</span> :
                                                                    userAccountInfo.smsOptIn == 'Opt-out' ?
                                                                        <span style={{color: '#e50f0f'}}>Opted out</span> :
                                                                        <span style={{color: '#0f73e5'}}>Pending</span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <button
                                                        className="btn btn-outline-primary min-width-120 float-end"
                                                        onClick={() => setShowChangePassword(true)}
                                                    >
                                                        Change Password
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-sm-12 my-account">
                                            <h4 className="section-title mb-2">Billing Info</h4>
                                            <div className="card mb-2 h-90">
                                                <div className="card-body">
                                                    <div className="row mb-3 d-flex align-items-center">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Gibi :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <div className="custom-dropdown device-icon">
                                                                <Controller
                                                                    name="deviceList"
                                                                    control={control}
                                                                    render={({field: {onChange, value}}) => (
                                                                        <Select
                                                                            options={deviceList}
                                                                            styles={customStyles}
                                                                            className="react-dropdown"
                                                                            classNamePrefix="dropdown"
                                                                            value={deviceList.find(
                                                                                (c) => c.value === value
                                                                            )}
                                                                            onChange={(val) => {
                                                                                onChange(val.value)
                                                                                setStatusValue(val.value)
                                                                                let deviceId = val.value
                                                                                const planDetails = deviceData.filter(
                                                                                    (deviceData) =>
                                                                                        deviceData._id === deviceId
                                                                                )

                                                                                setPlanDetail(planDetails[0].planId)

                                                                                // console.log(planDetail?.plan_start?.split('T')[0])

                                                                                const billAddressDetails = deviceData.filter(
                                                                                    (deviceData) => deviceData._id === deviceId
                                                                                )
                                                                                setBillAddressDetails(billAddressDetails[0].billInfoId)

                                                                            }}
                                                                            placeholder={
                                                                                <div className="Select Species">
                                                                                    Select Gibi Tracker
                                                                                </div>
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Plan :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {planDetail.description} {planDetail ? '$' : null} {planDetail.plan_amount}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Plan Start :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            {planDetail.plan_start && (
                                                                <label className="form-label value">
                                                                    {DateTime.fromISO(planDetail.plan_start).toLocaleString(DateTime.DATE_FULL)}
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                            Plan Renews :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            {/*planDetail.plan_end && (
                                                                <label className="form-label value">
                                                                    {planDetail.plan_end.split('T')[0]}
                                                                </label>

                                                            )}*/}
                                                            <label className="form-label value">
                                                                {planRenewal}
                                                            </label>

                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Plan Status :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {planStatus}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Name :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                            {billAddressDetails?.first_name} {billAddressDetails?.last_name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label caption">
                                                                Billing Address :
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <label className="form-label value">
                                                                {billAddressDetails?.apartment}{billAddressDetails?.street_addr}
                                                                <br/>
                                                                {billAddressDetails?.address1} {billAddressDetails?.address2} {billAddressDetails?.address3}
                                                                <br/>
                                                                {billAddressDetails?.usa_city}{billAddressDetails?.canada_city}, {billAddressDetails?.state}{billAddressDetails?.province}
                                                                <br/>
                                                                {billAddressDetails?.zip_code}{billAddressDetails?.postal_code}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ChangePassModal {...changePassProps} />
            <EditAccountInfoModal {...EditAccountInfoProps} />
            <AddressModel {...addressProps} />
        </>
    )
}

export default MyAccount
