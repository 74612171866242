import React from 'react';

export const ConfirmForm = (props) => {
    const {closeModal, sendGibiCommand, message} = props
    const sendCommand = () => {
        sendGibiCommand()
    }
    const cancelCommand = () => {
        closeModal()
    }

    const form = (
        <div className="custom-ui">
            <div className="fa-3x">
                <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {message} ?</h3>

            <button className="btn btn-outline-primary min-width-120" onClick={sendCommand}>
                Yes
            </button>
            <button className="btn btn-outline-primary min-width-120" onClick={closeModal}>
                No
            </button>
        </div>
    )

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    {form}
                </div>
            </div>
        </>
    )
}
