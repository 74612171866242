import React, { useState, useEffect } from "react";

const containerStyles = {
  border: "1px solid #fff",
  padding: "8px 10px 8px 10px",
  borderRadius: "4px",
  width: "100%",
  marginBottom: "15px",
  backgroundColor: "#fff",
};

const DevicePaymentFailed = ({ updateStep, updateLoader }) => {
  return (
    <>
      <form method="POST">
        <h3 className="panel-title text-center mb-3">Payment Failed</h3>
        <div className="view-data mb-3 px-3"></div>
        <div style={containerStyles}>
          <div className="  text-center">
            <p className="fw-bold">Oops! Something Went Wrong </p>
            <p className="fw-bold">Try Again </p>
          </div>
        </div>
      </form>
    </>
  );
};
export default DevicePaymentFailed;
