import {React,useState} from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';

const AdminDashboard = ({updateSidebar,isOpenSidebar}) => {
   
    const [pageTitle,setPageTitle] = useState("Dashboard");
  
    return (

        <>
            <div className="page-wrapper chiller-theme toggled">
                <AdminSidebar isOpenSidebar={isOpenSidebar} updateSidebar={updateSidebar}/>
                <main className={"page-content " + (isOpenSidebar != true ? 'show' : '')}>
                    <AdminHeader pageTitle={pageTitle} isOpenSidebar={isOpenSidebar}/>

                    <main className="content-holder">
                        <div className="container-fluid dashboard">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-wrapper">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="top-action clearfix">
                                                        <h2>Product</h2>
                                                    </div>
                                                    <div className="skiptranslate goog-te-gadget" dir="ltr">
                                                        <div id=":0.targetLanguage">
                                                            <select className="goog-te-combo">
                                                                <option value=""> Select by Product</option>
                                                                <option value="c1">Product 1</option>
                                                                <option value="c2">Product 2</option>
                                                                <option value="c3">Product 3</option>
                                                                <option value="c4">Product 4</option>
                                                                <option value="c5">Product 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div id="productgraph"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="top-action clearfix">
                                                        <h2>Users</h2>
                                                    </div>
                                                    <div className="skiptranslate goog-te-gadget" dir="ltr">
                                                        <div id=":0.targetLanguage">
                                                            <select className="goog-te-combo">
                                                                <option value=""> Select by product</option>
                                                                <option value="c1">Product 1</option>
                                                                <option value="c2">Product 2</option>
                                                                <option value="c3">Product 3</option>
                                                                <option value="c4">Product 4</option>
                                                                <option value="c5">Product 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div id="usergraph"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="top-action clearfix">
                                                        <h2>Alerts</h2>
                                                    </div>
                                                    <div className="skiptranslate goog-te-gadget" dir="ltr">
                                                        <div id=":0.targetLanguage">
                                                            <select className="goog-te-combo">
                                                                <option value=""> Select by Month</option>
                                                                <option value="c1">Jan</option>
                                                                <option value="c2">Feb</option>
                                                                <option value="c3">March</option>
                                                                <option value="c4">April</option>
                                                                <option value="c5">May</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" >
                                                    <div className="card-body">
                                                        <div id="alertgraph"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="top-action clearfix">
                                                        <h2>Notifications</h2>
                                                    </div>
                                                    <div className="skiptranslate goog-te-gadget" dir="ltr">
                                                        <div id=":0.targetLanguage">
                                                            <select className="goog-te-combo">
                                                                <option value=""> Monthly</option>
                                                                <option value="c1">Yearly</option>
                                                                <option value="c2">Weekly</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="notification">
                                                            <p className="notification-title">Alert 1</p>
                                                            <p className="notification-content">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                        <div className="notification">
                                                            <p className="notification-title">Battery Status</p>
                                                            <p className="notification-content">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                        <div className="notification">
                                                            <p className="notification-title">Plans</p>
                                                            <p className="notification-content">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </main>

                </main>
            </div>


        </>
    );
}
export default AdminDashboard;