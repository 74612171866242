import usePetsDevicesStore from '../store/usePetsDevicesStore'
import { getLastKnownPosition, getLastTenLocations, getPets } from './petHelpers'
import { getCheckInRateOptions } from '../components/SettingsModal/helpers/settingHelpers'
import { getAlertOptions } from './alertHelpers'
import usePetHistoryStore from '../store/usePetHistoryStore'
import useGibiConfigStore from '../store/useGibiConfigStore'
import { fetchAllPetZones } from './zoneHelper'
import useMapStore from '../store/useMapStore'
import useZoneStore from '../store/useZoneStore'

export const fetchAllZones = async (token) => {
    const zones = await fetchAllPetZones(token)
    if (zones) {
        console.log('zones', zones)
        useZoneStore.getState().setSafeZoneList(zones.filter(z => z.zone_type === 'safe'))
        useZoneStore.getState().setPowerZoneList(zones.filter(z => z.zone_type === 'power'))
    }
}

async function fetchPetsAndDevices(token) {
    const {devices, pets} = await getPets(token)
    if (devices) {
        usePetsDevicesStore.getState().loadDeviceMap(devices)
        const iccids = Object.keys(devices)
        let lk = []
        for (let iccid of iccids) {
            const locations = await getLastTenLocations(iccid, token)
            usePetHistoryStore.getState().loadLastTen(iccid, locations)
            const last = await getLastKnownPosition(iccid, token)
            usePetHistoryStore.getState().updateLastKnownPosition(iccid, last)
            lk.push(last)
        }
        if (!usePetHistoryStore.getState().showHistory) {
            useMapStore.getState().setMarkers(lk)
        }
    }
    if (pets) {
        usePetsDevicesStore.getState().loadPetMap(pets)
    }
}

async function fetchBGIOptions(token) {
    const options = await getCheckInRateOptions(token)
    if (options) {
        useGibiConfigStore.getState().loadBgiOptions(options)
    }
}

async function fetchAlertOptions(token) {
    const options = await getAlertOptions(token)
    if (options) {
        useGibiConfigStore.getState().loadAlertOptions(options)
    }
}

export const reloadAllStoresAndResetMap = (token) => {
    if (token) {
        console.log('reloadAllStoresAndResetMap')
        fetchAllZones(token).then(() => console.log('zones loaded'))
        fetchPetsAndDevices(token).then(() => {
            console.log('pets & devices loaded, map reset')
            const map = useMapStore.getState().googleMap
            if (map) {
                useMapStore.getState().setBlockFitBounds(false)
            }
        })
        fetchBGIOptions(token).then(() => console.log('bgi options loaded'))
        fetchAlertOptions(token).then(() => console.log('alert options loaded'))
    }
}
