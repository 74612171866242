import ReactAvatarEditor from "../../components/Croppers/ReactAvatarEditor/ReactAvatarEditor";

const ModalWrapper = ({
	modal,
	showModal,
	onModalClose,
	imgURL,
	onSaveHandler,
}) => {
	switch (modal) {
		
		case "React Avatar Editor":
			return (
				<ReactAvatarEditor
					showModal={showModal}
					onModalClose={onModalClose}
					imgURL={imgURL}
					onSaveHandler={onSaveHandler}
				/>
			);
		default:
			return "";
	}
};

export default ModalWrapper;
