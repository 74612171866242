import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Base64 } from "js-base64";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { checkTokenValid, resetPassword } from "../helpers/registerHelper";
const toastConfig = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const schema = yup
  .object()
  .shape({
    newPassword: yup
      .string()
      .min(6, "Password is Too Short!")
      .max(16, "Password is Too Long!")
      .required("Password is required."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null])
      .required("Confirm Password Should Match!"),
  })
  .required();
// http://localhost:3000/reset-password?email=dXNlcjAzQHlvcG1haWwuY29t&token=123654
function ResetPassword({ updateLoader }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });
  const [searchParams] = useSearchParams();
  const emailer = searchParams.get("email");
  const token = searchParams.get("token");
  const checkTokenExits = async () => {
    let email = Base64.decode(emailer);
    const data = { email: email, token: token };
    const response = await checkTokenValid(data);
  
    if (!response.status) {
      navigate("/tokenInvalid");
    }
  };
  useEffect(() => {
    checkTokenExits(emailer, token);
  }, [token]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const resetPass = async (data) => {
    const response = await resetPassword(data);
    if (response.status) {
      updateLoader(false);
      toast.success(response.message, {...toastConfig, containerId: 'api'});
     setTimeout(() => {
      navigate("/login");
      window.location.reload();
      reset();
     }, 300);
    } else {
      updateLoader(false);
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
    setIsSubmitting(false);
  };
  const formSubmit = (data) => {
    let email = Base64.decode(emailer);
    let resetData = { ...data, email, token };
  
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to reset password ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                setIsSubmitting(true);
                resetPass(resetData);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };
  const actionBtn = (
    <ul className="list-inline w-100 d-flex align-items-center justify-content-center">
      <li className="list-inline-item m-0  ps-2">
        <button
          type="submit"
          className="btn btn-outline-primary white w-100"
          disabled={isSubmitting}
        >
          {isSubmitting === true && (
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            // <span className="spinner-grow spinner-grow-sm"></span>
          )}
          Reset Password
        </button>
      </li>
    </ul>
  );

  const newPass = (
    <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
      <div className="form-group">
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-lock" aria-hidden="true"></i>
          </span>
          <input
            type="password"
            className={
              "form-control" + (errors.newPassword ? " is-invalid" : "")
            }
            placeholder="Enter New Password"
            {...register("newPassword")}
          />
        </div>
        {errors.newPassword && (
          <div className="errors">{errors.newPassword?.message}</div>
        )}
      </div>
    </div>
  );
  const conNewPass = (
    <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
      <div className="form-group">
        <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-lock" aria-hidden="true"></i>
          </span>
          <input
            type="password"
            name="confirmPassword"
            className={
              "form-control" + (errors.confirmPassword ? " is-invalid" : "")
            }
            placeholder="Re-enter Password"
            // {...register("confirmPassword")}
            {...register("confirmPassword", { required: true })}
          />
        </div>
        {errors.confirmPassword && (
          <p className="errors">
            {errors.confirmPassword && "Confirm Password Should Match!"}
          </p>
        )}
      </div>
    </div>
  );
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 p-0 login-img-holder">
          <div className="login-bg"></div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 p-0">
          <div className="login-holder d-flex align-items-center">
            <div className="login-container">
              <div className="logo">
                <img src={require("../images/logo-white_.png")} alt="gibi" />
              </div>
              <h3 className="panel-title text-center text-white">
                Reset Password
              </h3>
              <p className="text-center text-white">
                You can reset your password here.
              </p>
              <form onSubmit={handleSubmit(formSubmit)}>
                <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
                    {newPass}
                    {conNewPass}
                    <div className="form-group my-5">{actionBtn}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
