import React, { useEffect, useState } from 'react'
import './Markers.css'
import { Circle, InfoWindow, Marker } from '@react-google-maps/api'
import useMapStore from '../../../store/useMapStore'
import { DateTime, Settings } from 'luxon'
import usePetsDevicesStore from '../../../store/usePetsDevicesStore'
import useUIStore from '../../../store/useUIStore'
import { estimateDeviationFromReport } from '../mapHelpers'
import usePetHistoryStore from '../../../store/usePetHistoryStore'
import useAuthStore from '../../../store/useAuthStore'

const gibiPaw = `M333.525 472.262c30.24 67.085 21.003 136.657-20.628 155.437-41.633 18.78-99.897-20.362-130.134-87.434-30.24-67.072-21.005-136.651 20.628-155.428 41.633-18.772 99.894 20.372 130.134 87.425zM746.472 472.262c-30.241 67.085-21.004 136.657 20.631 155.437 41.633 18.78 99.891-20.362 130.135-87.434 30.237-67.072 21-136.651-20.632-155.428-41.63-18.772-99.894 20.372-130.134 87.425zM510.912 337.433c15.5 71.912-8.128 138.005-52.773 147.634-44.642 9.616-93.4-40.884-108.898-112.79-15.498-71.91 8.13-138.005 52.774-147.625 44.644-9.624 93.398 40.87 108.897 112.781zM567.96 337.501c-15.488 71.91 8.124 138.01 52.766 147.626 44.644 9.63 93.4-40.87 108.897-112.78 15.5-71.912-8.125-138.007-52.772-147.627-44.645-9.622-93.4 40.87-108.892 112.781zM455.321 578.712c-10.896 18.82-61.388 81.116-96.311 102.37-34.92 21.253-78.193 52.375-70.932 123.836 7.258 71.474 80.565 102.064 128.824 99.351 48.261-2.7 139.25-11.584 204.212-2.58 64.959 9.004 146.947 2.181 166.147-66.247 19.2-68.429-23.74-112.026-57.249-136.764-33.506-24.725-89.462-98.686-106.702-132.641-17.236-33.942-104.972-96.106-167.989 12.675z`
const otherPaw = 'M 281.78125 0 C 280.89861 0.011256107 279.99321 0.05451875 279.09375 0.125 C 243.27015 6.3085 223.57295 44.18935 220.71875 77.59375 C 216.54805 107.90515 229.9058 146.86425 263.1875 153.65625 C 268.015 154.57535 273.0311 154.1553 277.75 152.875 C 317.8269 139.4366 335.76175 91.97195 329.96875 52.65625 C 328.28477 27.263859 309.14316 -0.3489393 281.78125 0 z M 129.90625 1.625 C 107.63131 2.1717807 90.279662 24.7634 86.75 46 C 79.3091 88.0743 98.44834 140.35185 142.28125 153.65625 C 146.38695 154.54915 150.63375 154.6411 154.78125 154 C 184.40765 149.0625 196.95825 115.84615 195.71875 89.03125 C 194.82705 53.65875 176.45285 12.7581 139.71875 2.8125 C 136.36096 1.9220875 133.08838 1.5468885 129.90625 1.625 z M 378.84375 121.125 C 340.30945 123.4304 313.8911 161.87875 310.125 197.78125 C 305.0254 223.67495 318.8299 258.3078 348.375 260.375 C 389.5717 259.8672 417.67605 215.84645 418.84375 177.96875 C 421.44955 152.91745 406.69275 122.5082 378.84375 121.125 z M 41.5625 129.65625 C 25.168497 129.5152 9.0449188 139.33506 3.6875 156 C -10.6052 200.579 18.0964 257.03815 65.3125 266.40625 C 85.0181 269.77835 102.33285 254.6454 107.21875 236.4375 C 117.57335 197.4927 96.3054 152.27225 60.3125 134.59375 C 54.448578 131.30284 47.977544 129.71144 41.5625 129.65625 z M 214.3125 209.59375 C 182.17313 209.66229 149.52974 225.97193 128.71875 250.25 C 106.24135 278.5472 87.8285 311.4765 80.625 347.1875 C 71.8736 372.894 91.70839 402.47795 119.1875 402.65625 C 152.2464 403.57455 180.65825 380.8733 213.53125 379.1875 C 241.42135 374.9358 266.39435 389.4427 291.46875 398.9375 C 312.81805 408.0688 342.31905 404.56945 353.21875 381.59375 C 361.79235 358.17505 349.17285 333.20375 338.71875 312.40625 C 317.39655 278.64925 294.55365 243.17045 259.59375 222.09375 C 245.58084 213.40805 230.00847 209.56028 214.3125 209.59375 z'

const Markers = (props) => {

    const {} = props

    const showAccuracy = useUIStore((s) => s.showAccuracy)
    const showHistory = usePetHistoryStore(s => s.showHistory)

    const googleMap = useMapStore((s) => s.googleMap)
    const markers = useMapStore((s) => s.markers)
    const pets = usePetsDevicesStore((s) => s.pets)


    const [markerList, setMarkerList] = useState([])
    const [accuracyList, setAccuracyList] = useState([])
    const [activeMarker, setActiveMarker] = useState(null)

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return
        }
        setActiveMarker(marker)
    }

    const getMarkerColor = (loc) => {
        let color
        let petId
        if (loc.properties.itemId) {
            petId = loc.properties.itemId
        }
        else {
            Object.values(pets).forEach(pet => {
                const device = pet.pet_devices.find(pd => pd.iccid === loc.properties.deviceId)
                if (device) {
                    petId = pet._id
                }
            })
        }
        color = petId ? pets[petId]?.iconColorName[0]?.iconColorName : 'black'
        return color
    }

    useEffect(() => {
        if (googleMap && markers?.length) {
            const bounds = new window.google.maps.LatLngBounds()
            let updatedBound = false
            for (let i = 0; i < markers.length; i++) {
                if (markers[i]?.properties?.status) {
                    const lat = markers[i].properties.latitude
                    const lon = markers[i].properties.longitude
                    bounds.extend(new window.google.maps.LatLng(lat, lon))
                    updatedBound = true
                }
            }
            if (updatedBound) {
                // save it first, fitBounds overwrites it
                const userZoom = useMapStore.getState().mapZoom
                const blockFitBounds = useMapStore.getState().blockFitBounds
                if (!blockFitBounds) {
                    googleMap.fitBounds(bounds)
                }
                const boundsZoom = googleMap.getZoom()
                // console.log('zoom mgmt', boundsZoom, userZoom)
                googleMap.setZoom(Math.min(boundsZoom, userZoom))
            }
        }
    }, [markers, googleMap])

    const locationIcon = (loc, number) => {
        if (loc?.properties?.status && loc?.properties?.latitude && loc?.properties?.longitude) {
            // console.log('marker',number,loc.properties.latitude,loc.properties.longitude)
            const color = getMarkerColor(loc)
            const markerText = (number + 1).toString()
            const markerLabel = {
                text: !showHistory ? '+' : (number + 1).toString(),
                color: color,
                className: showHistory ? 'marker-label' : 'location-label',
                fontWeight: 'bold',
                fontSize: !showHistory ? '16px' : '14px',
            }
            const curZoom = googleMap?.getZoom() || 16
            const scale = curZoom > 12 ? 0.07 : 0.03
            // console.log('marker scale', scale)
            const petName = pets[loc.properties.itemId]?.petName || pets[loc.properties.itemId]?.pet_name
            return (
                <div key={number}>
                    <Marker
                        key={number}
                        onClick={() => handleActiveMarker(number)}
                        position={{lat: loc.properties.latitude, lng: loc.properties.longitude}}
                        // label={`${number.toString()}`}
                        label={markerLabel}
                        icon={{
                            // path: 'M443.827 195.5c-4.383-11.73-11.753-20.212-21.317-24.524-4.389-1.975-8.995-2.976-13.688-2.976-20.025 0-40.372 18.667-50.632 46.454-12.698 34.42-5.315 68.135 17.173 78.423 4.522 2.071 9.365 3.123 14.394 3.123 20.396 0 41.126-17.119 51.581-42.596 7.9-19.283 8.83-40.927 2.489-57.904zM153.811 214.454C143.551 186.667 123.204 168 103.179 168c-4.693 0-9.3 1.001-13.689 2.976-9.565 4.312-16.934 12.794-21.317 24.524-6.341 16.977-5.411 38.621 2.49 57.904C81.118 278.881 101.848 296 122.244 296c5.028 0 9.871-1.052 14.394-3.123 22.488-10.288 29.871-44.003 17.173-78.423zM198.347 210.601c1.855.081 3.727.03 5.563-.151 10.787-1.059 20.54-6.594 28.207-16.008 12.371-15.191 15.806-38.974 13.201-63.439C241.336 93.3 221.469 65.161 194.776 64c0 0-3.811.008-5.75.193-11.776 1.164-22.481 7.283-30.957 17.695-12.291 15.101-18.198 37.57-15.803 60.104 3.936 37.277 28.57 67.412 56.081 68.609zM256 240c-64 0-128 76.074-128 149.128 0 21.798 10.932 39.331 21.667 46.517C162.925 444.516 172.269 448 191.704 448c23.093 0 29.325-8.078 40.136-15.205 7.819-5.152 14.572-9.605 24.161-9.605s16.342 4.453 24.16 9.605C290.972 439.922 297.203 448 320.297 448c19.434 0 28.778-3.484 42.036-12.355C373.068 428.459 384 410.926 384 389.128 384 316.074 320 240 256 240zM308.281 210.265c1.836.182 3.709.232 5.563.151 27.511-1.196 52.146-31.332 56.081-68.607 2.395-22.534-3.514-45.004-15.804-60.104-8.476-10.412-18.783-16.228-30.56-17.392-1.939-.186-6.146-.312-6.146-.312-26.693 1.161-46.561 29.115-50.542 66.817-2.604 24.466.83 48.248 13.2 63.439 7.668 9.414 17.421 14.949 28.208 16.008z',
                            path: otherPaw,
                            fillColor: color,
                            fillOpacity: 0.9,
                            scale: 0.07,
                            strokeColor: 'black',
                            strokeWeight: 1,
                            rotation: loc.properties.heading,
                            // offset: { x: 150, y: 150 },
                        }}
                    >
                        {activeMarker === number ? (
                            <InfoWindow
                                onCloseClick={() => setActiveMarker(null)}
                                position={{lat: loc.properties.latitude, lng: loc.properties.longitude}}
                            >
                                <div>
                                    <div>{`${DateTime.fromISO(loc?.properties?.receivedTime, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}`}</div>
                                    <div>{`${petName}`}</div>
                                </div>
                            </InfoWindow>
                        ) : null}
                    </Marker>
                </div>
            )
        }
        return null
    }

    const accuracyStyle = {
        strokeColor: 'blue',
        strokeOpacity: 0,
        strokeWeight: 1,
        fillColor: 'blue',
        fillOpacity: 0.1,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 1
    }

    useEffect(() => {
        if (markers && markers.length) {
            const update = markers.map((loc, i) => {
                return locationIcon(loc, i)
            }).filter(n => n)

            const accuracy = showAccuracy ? markers.map((loc, i) => {
                const radius = estimateDeviationFromReport(loc)
                return <div key={`accuracy-${i}`}>
                    {radius ? <Circle
                        center={{lat: loc.properties.latitude, lng: loc.properties.longitude}}
                        radius={radius}
                        options={accuracyStyle}
                    /> : null}
                </div>
            }).filter(n => n) : []

            if (!update.length) {
                console.log('no valid markers')
                setMarkerList([])
                setAccuracyList([])
            }
            else {
                setMarkerList(update)
                setAccuracyList(accuracy)
            }
        }
        else {
            console.log('no markers')
            setMarkerList([])
            setAccuracyList([])
        }

    }, [markers, activeMarker, showAccuracy])

    // console.log('show history', showHistory)
    return (
        <>
            {markerList}
            {accuracyList}
        </>
    )
}

export default Markers
