import { get, post,remove } from "./apiHelper";

export const checkDeviceExist = async (userToken,data) => {
  const response = await post("/api/v1/checkDeviceExist",data,userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getSubscriptionPlanList = async (userToken) => {
  const response = await get("/api/v1/getSubscriptionPlanList",userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getDevices = async (userToken) => {
  const response = await get("/api/v1/new_devices",userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getbillingInfo = async (userToken) => {
  const response = await get("/api/v1/billing_info",userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getHomeAddress = async (userToken) => {
  const response = await get("/api/v1/homeAddress",userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const addNewDevice =async (userToken,data) => {
  const response = await post("/api/v1/new_device",data,userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const makePayment =async (data) => {
    const response = await post("/api/v1/makePayment",data);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const checkPromoCode =async (data,userToken) => {
  const response = await post("/api/v1/checkPromoCode",data,userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};



export const removeDeviceDetails =async (userToken,deletedID) => {
  try {
    const response = await remove("/api/v1/new_device/"+deletedID, userToken);
    console.log(response);
    if (response.data.status) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) { console.log(error);}
};


  export const assignDevie =async (userToken,data) => {
    const response = await post("/api/v1/assign_device",data,userToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };
  export const activateDevice =async (userToken,data) => {
    const response = await post("/api/v1/activateDevice",data,userToken);
    if (response.status) {
      return response.data;
    } else {
      return [];
    }
  };