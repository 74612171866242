import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getSubscriptionPlanList,
  getbillingInfo,
  getHomeAddress
} from "../../../helpers/deviceHelper";

const schema = yup
  .object()
  .shape({
    plan: yup.string().required("Please select plan "),
  })
  .required();

const DeviceSubscription = (props) => {
  const {
    userToken,
    updateStep,
    planInfo,
    updatesetPlanInfo,
    updateLoader,
    updatesetBillInfo,
    updatehomeAddress,
    updatesetUserInfo,
  } = props;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: planInfo,
  });

  const [plan_list, setPlanList] = React.useState([]);

  const formSubmit = (data) => {
    const selected_plan = plan_list.filter(function (item) {
      return item.value === data.plan;
    });

    updatesetPlanInfo(selected_plan[0]);
    updateStep(3);
  };

  const previous_step = () => {
    updateStep(1);
  };

  const fetchPlanData = async () => {
    updateLoader(true);
    const response = await getSubscriptionPlanList(userToken);
    updateLoader(false);

    if (response.status) {
      let plan_list = response.products;

      setPlanList(
        plan_list.map((item) => ({
          value: item.priceID,
          label: item.productName,
          amount: item.productPrice,
          priceID: item.priceID,
          productID: item.productID,
          description: item.productDescription,
          no_months: 1, //item.intervalCount,
          intervalCount: item.interval_count,
          interval: item.interval,
          currency: item.currency,
        }))
      );
    }
  };

  const fetchAccountDetails = async (userToken) => {
    const response = await getbillingInfo(userToken);
    if (response.status) {
      // console.log(response.billing_info);
      updatesetBillInfo(response.billing_info);
      updatesetUserInfo(response.account_info);
    }
    const homeData = await getHomeAddress(userToken);
    if (homeData.status) {
      // console.log(homeData);
      updatehomeAddress(homeData.result);
      // updatesetUserInfo(response.account_info);
    }
  };
  useEffect(() => {
    fetchPlanData();

    if (userToken === "" || userToken === null) {
    } else {
      fetchAccountDetails(userToken);
    }
  }, []);
  const actionButtons = (
    <ul className="list-inline w-100 d-flex align-items-center mb-0">
      <li className="list-inline-item m-0 pe-2 ms-auto">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={previous_step}
        >
          Previous
        </button>
      </li>
      <li className="list-inline-item m-0 ps-2">
        <button type="submit" className="btn btn-outline-primary">
          Next
        </button>
      </li>
    </ul>
  );
  return plan_list.length ? (
    <>
      <h5 className="panel-title">Subscription Plan</h5>

      <p className="mb-4 text-align-justify">
        Note: Please select a subscription plan. Subscription service fees are
        automatically charged to your credit card based on the plan selected
        unless your account is paused or canceled. Subscription service fees are
        not refundable per the&nbsp;
        <a
          href="http://getgibi.com/legal"
          className="text-decoration-none"
          target="_blank"
        >Terms of Service</a>.
      </p>
      <form method="POST" onSubmit={handleSubmit(formSubmit)}>
        {Object.values(plan_list).map((key) => {
          let str = key.label.replace(/([A-Z])/g, " $1").trim();
          const capitalizeFirst = (str) => {
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div className="form-group device-plan" key={key.value}>
              <div className="form-check custom-check white">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("plan")}
                  id={key.value}
                  value={key.value}
                  defaultChecked={key.value === planInfo.value}
                />
                <label
                  className="form-check-label clearfix"
                  htmlFor={key.value}
                >
                  <span className="plan-name">{capitalizeFirst(str)}</span>
                  <span className="plan-price"> $ {key.amount}</span>
                </label>
              </div>
            </div>
          );
        })}
        {actionButtons}
      </form>
    </>
  ) : null;
};

export default DeviceSubscription;
