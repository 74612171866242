import { Accordion, Tab, Tabs } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import React, { useContext, useEffect, useState } from 'react'
import useAuthStore from '../../store/useAuthStore'
import { deleteZone } from '../../helpers/zoneHelper'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import UserContext from '../../contextApi/UserContext'
import HelperSafeZoneModal from './helper/HelperSafeZoneModal'
import HelperPowerZoneModal from './helper/HelperPowerZoneModal'
import usePolygonStore from '../../store/usePolygonStore'
import { getCenterOfSafeZone } from '../GibiMap/mapHelpers'
import './SafeZoneSection.css'
import useUIStore from '../../store/useUIStore'
import useZoneStore from '../../store/useZoneStore'
import useMapStore from '../../store/useMapStore'
import { useNavigate } from 'react-router-dom'

export  const SafeZoneSection = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [previewSafe, setPreviewSafe] = useState(false)
    const [previewPower, setPreviewPower] = useState(false)
    const zoneContext = useContext(UserContext)
    const setSafeZonePolygonStoreList = usePolygonStore((s) => s.setSafeZoneStoreList)
    const setPowerZonePolygonStoreList = usePolygonStore((s) => s.setPowerZoneStoreList)

    const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
    const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)
    const setShowMenu = useUIStore((s) => s.setShowMenu)

    const setShowSearchBox = useZoneStore((s) => s.setShowSearchBox)
    const setButtonType = useZoneStore((s) => s.setButtonType)
    const fetchZonesToggle = useZoneStore((s) => s.fetchZonesToggle)
    const setFetchZones = useZoneStore((s) => s.setFetchZones)
    const setNewSafeZoneGeometry = useZoneStore((s) => s.setNewSafeZoneGeometry)
    const setNewPowerZoneLocation = useZoneStore((s) => s.setNewPowerZoneLocation)
    const setCanDragSafeZone = useZoneStore((s) => s.setCanDragSafeZone)
    const setCanEditSafeZone = useZoneStore((s) => s.setCanEditSafeZone)
    const setZoneID = useZoneStore((s) => s.setZoneID)
    const setZoneName = useZoneStore((s) => s.setZoneName)
    const setCanDragPowerZone = useZoneStore((s) => s.setCanDragPowerZone)
    const safeZoneList = useZoneStore((s) => s.safeZoneList)
    const powerZoneList = useZoneStore((s) => s.powerZoneList)

    const setMapCenter = useMapStore((s) => s.setMapCenter)
    const setMapZoom = useMapStore((s) => s.setMapZoom)

    const navigate = useNavigate()

    const userToken = useAuthStore((s) => s.token)
    const messageContent = {
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'api',
    }
    const types = ['safe', 'power']

    useEffect(() => {
        setSafeZonePolygonStoreList(safeZoneList)
        setPowerZonePolygonStoreList(powerZoneList)
    }, [safeZoneList, powerZoneList])

    const performZoneAction = (
        zoneCoordinates,
        zoneType,
        viewType,
        zoneID,
        zoneName,
    ) => {

        let single_lat_lng
        if (zoneType === 'power') {
            single_lat_lng = zoneCoordinates[0]
        }
        else {
            single_lat_lng = getCenterOfSafeZone(zoneCoordinates)
        }

        if (single_lat_lng !== undefined) {

            setMapCenter(single_lat_lng)

            if (zoneType === 'safe') {
                setNewSafeZoneGeometry(zoneCoordinates)
                setNewPowerZoneLocation({})

                if (viewType === 'view') {
                    // For View
                    navigate('/dashboard')
                    setMapZoom(25)
                    setCanDragSafeZone(false)
                    setCanEditSafeZone(false)
                    setShowSearchBox(false)
                }
                else {
                    navigate('/dashboard')
                    setButtonType([zoneType, 'Update']) // For Udpate
                    setCanDragSafeZone(true)
                    setCanEditSafeZone(true)
                    setShowSearchBox(true)
                    setZoneID(zoneID)
                    setZoneName(zoneName)
                }
            }
            else {
                setNewSafeZoneGeometry([])
                setNewPowerZoneLocation(zoneCoordinates[0])
                if (viewType === 'view') {
                    navigate('/dashboard')
                    // For View
                    setMapZoom(20)
                    setShowSearchBox(false)
                    setCanDragPowerZone(false)
                }
                else {
                    navigate('/dashboard')
                    setButtonType([zoneType, 'Update']) // For Udpate
                    setCanDragPowerZone(true)
                    setShowSearchBox(true)
                    setZoneID(zoneID)
                    setZoneName(zoneName)
                }
            }
            const mediaQuery = window.matchMedia('(max-width: 768px)')
            if (mediaQuery.matches) {
                navigate('/dashboard')
                setShowMenu(false)
            }
        }
    }

    const handleConfirm = async (onClose, zoneID) => {
        const response = await deleteZone(userToken, zoneID)
        // console.log(response)
        if (response.status) {
            toast.success(response.message, messageContent)
            setFetchZones(!fetchZonesToggle)
        }
        else {
            toast.error(response.message, messageContent)
        }

        onClose()
    }

    const removeZone = async (zoneID) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <div className="fa-3x">
                            <i className="fa-solid fa-paw fa-bounce"></i>
                        </div>
                        <h3>Are you sure want remove Zone ?</h3>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={() => {
                                handleConfirm(onClose, zoneID)
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={onClose}
                        >
                            No
                        </button>
                    </div>
                )
            },
            closeOnClickOutside: false, // setPets
        })
    }

    function capFirst(str) {
        return str[0].toUpperCase() + str.slice(1)
    }

    function ZoneContentDetails(props) {
        const {activeData} = props

        let type = ''
        let zonetypeList = []
        let label = ''
        if (activeData === types[0]) {
            // Safe or Power
            type = types[0] // Safe Zone
            zonetypeList = safeZoneList
            label = capFirst(type)
            // console.log(type);
            // console.log(zonetypeList);
            // console.log(label);
        }
        else {
            type = types[1] // Power Zone
            zonetypeList = powerZoneList
            label = capFirst(type)
        }

        const createButtonMediaQuery = window.matchMedia('(min-width: 450px)')
        const zoneCreationTip = `${type === 'safe' ? 'Safe' : 'Power'} Zone creation tutorial`
        return (
            <div>
                {createButtonMediaQuery.matches ? <div className={'zone-creation-section'}>
                    <div className={'zone-button'}>
                        <button
                            type="button"
                            className="btn btn-outline-primary white w-100 mb-3"
                            id="createSafeZoneModalBtn"
                            onClick={() => {
                                setShowSearchBox(true)
                                setButtonType([type, 'Create'])
                                setNewPowerZoneLocation({})
                                setNewSafeZoneGeometry([])
                                navigate('/dashboard')
                                const mediaQuery = window.matchMedia('(max-width: 768px)')
                                if (mediaQuery.matches) {
                                    // navigate('/dashboard')
                                    setShowMenu(false)
                                }
                            }}
                        >
                            Create {label} Zone
                        </button>
                    </div>
                    <div>
                        <div onClick={() => type === 'safe' ? setPreviewSafe(true) : setPreviewPower(true)}
                             className={'zone-help'}>
                            <OverlayTrigger placement="auto"
                                            overlay={<Tooltip id="tooltip-disabled">{zoneCreationTip}</Tooltip>}>
                                <i className="fa-regular fa-circle-question"></i>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div> : null}


                <ul className="zones-list ps-0 w-100">
                    {Object.values(zonetypeList).map((values) => {

                        let zoneName = values.zone_name
                        let zoneID = values._id
                        let zoneCoordinates = values.zone_coordinates
                        return (
                            <li
                                className="d-flex align-items-center justify-content-between w-100"
                                key={zoneID}
                            >
                                <h5 className="zone-name">{zoneName}</h5>
                                <div className="d-flex align-items-center">
                                    {/* center on zone */}
                                    <button
                                        className="icon-btn"
                                        type="button"
                                        onClick={() => {
                                            performZoneAction(
                                                zoneCoordinates,
                                                type,
                                                'view',
                                                zoneID,
                                                zoneName
                                            )
                                        }}
                                    >
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                    {/* Edit Zone */}
                                    <button
                                        className="icon-btn ms-3"
                                        type="button"
                                        onClick={() => {
                                            performZoneAction(
                                                zoneCoordinates,
                                                type,
                                                'edit',
                                                zoneID,
                                                zoneName
                                            )
                                        }}
                                    >
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>
                                    {/* remove zone */}
                                    <button
                                        className="icon-btn ms-3"
                                        onClick={() => {
                                            removeZone(zoneID)
                                        }}
                                    >
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const [active, setActive] = useState(types[0])

    const zoneTabs = (
        /*<Tabs
            defaultActiveKey="safe"
            id="zoneKey"
            className="nav nav-tabs mb-3 w-100"
            onSelect={(eventKey) => {
                setActive(eventKey)
                setShowSearchBox(false)
                setNewPowerZoneLocation({})
                setNewSafeZoneGeometry([])
            }}
        >*/
                <ZoneContentDetails activeData={active}/>
       /* </Tabs>*/
    )

    const zoneContent = (
        <Accordion flush>
            <Accordion.Item
                eventKey="1"
                className={
                    width < 767 ? "" : isOpenSidebar === true ? "mini-sidebar sub-accordian-item" : "sub-accordian-item"
                  }
            >
                <OverlayTrigger
                    placement="auto"
                    overlay={<div/>}
                    defaultShow={false}
                >
                    <Accordion.Header
                        onClick={() => {
                            setIsOpenSidebar(false)
                        }}
                    >
                        <i
                            className={
                                "fa fa-shield " +
                                (width < 767 ? "me-2" : isOpenSidebar ? "mx-auto " : "me-3")
                              }
                            aria-hidden="true"
                        />
                        <span>Safe Zones</span>
                    </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body style={{backgroundColor: '#139ecd '}}>
                    {/*<ToggleGroup/>*/}
                    <div style={{backgroundColor: '#139ecd '}}>
                        {zoneTabs}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )

    return (
        <>
            {zoneContent}

            <HelperSafeZoneModal
                previewSafe={previewSafe}
                setPreviewSafe={setPreviewSafe}
            />
            <HelperPowerZoneModal
                previewPower={previewPower}
                setPreviewPower={setPreviewPower}
            />
        </>
    )
}
