import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast, Zoom } from "react-toastify";
import { assignDevie } from "../../../helpers/deviceHelper";
import { toastConfig } from '../../Admin/adminConfig'

function AssignedModal(props) {
  const {
    userToken,
    updateGibi,
    setUpdateGibi,
    petList,
    setselectPet,
    selectPet,
    selectedDevice,
    toastConfig,
    setAuxToFetch,
    auxToFetch,
  } = props;
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        cursor: "pointer",
        padding: "5px",
        fontSize: "14px",
        borderColor: "#fff!important",
        "&:hover": {
          backgroundColor: "#139dcc ",
          color: "#fff",
        },
      };
    },
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    // resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });

  const assignDevieToPet = async (data,onClose) => {
    const response = await assignDevie(userToken, data);
    // console.log(response);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      setUpdateGibi(false);
      onClose();
      reset();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };
  const Update = () => {
    let pet_id = selectPet;
    let device_id = selectedDevice;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to assign the tracker ?</h3>
            {/* <p>You want to delete this file?</p> */}
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={async () => {
                const data = { pet_id: pet_id, device_id: device_id };
                assignDevieToPet(data,onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  return (
    <div>
      <Modal
        className="add-new-gibi"
        show={updateGibi}
        onHide={() => setUpdateGibi(false)}
        aria-labelledby="modal-sizes-title-lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-sizes-title-lg">Update Gibi Tracker</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-device">
          <p>Assign Gibi Tracker : </p>
          <div className="form-group mb-0 assign-value-1">
            <div className="custom-dropdown mb-3">
              <Controller
                name="species"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    maxMenuHeight={150}
                    styles={customStyles}
                    isClearable={false}
                    isSearchable={false}
                    value={petList.find((c) => c.value === value)}
                    // value="USA"
                    onChange={(val) => {
                      onChange(val.value);
                      setselectPet(val.value);
                    }}
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    id="assigned1"
                    options={petList}
                    placeholder={<div className="Select">Select Pet</div>}
                  />
                )}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              Update();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AssignedModal;
