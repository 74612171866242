import React, { useEffect, useState } from 'react'
import { LoadScript } from '@react-google-maps/api'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from './Header.js'
import SettingsModals from './SettingsModal/SettingsModals.js'
import 'react-confirm-alert/src/react-confirm-alert.css'
import useAuthStore from '../store/useAuthStore'
import SleepModeModal from './SleepModeModal/SleepModeModal'
import { SafeZoneSection } from './SafeZone/SafeZoneSection'
import { GibiMap } from './GibiMap/GibiMap'
import AlertsModal from './AlertsModal/AlertsModals'
import DashboardAccordion from './DashboardAccordion'
import useUIStore from '../store/useUIStore'
import ErrorDeviceModal from '../components/ErrorDeviceModal'
import { getDeviceList } from '../helpers/petHelpers' 
import AccountMenuSidebar from './AccountMenuSidebar'
import MenuSidebar from './MenuSidebar';

//Map reference Link : https://react-google-maps-api-docs.netlify.app/

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const lib = ['places']

const Dashboard = ({updateLoader}) => {

    // Let's do this first
    // we can't use zustand state in the login form handler
    // the form handler is a callback and zustand state is a hook, so that's a no go
    // so instead we pass the token in the route and save it here
    //
    const location = useLocation()
    const userToken = useAuthStore((state) => state.token)
    // console.log(userToken);
    const navigate = useNavigate()

    if (location.state?.data) {
        setTimeout(() => {
            const userDetails = location.state?.data?.userDetails
            const token = location.state?.data?.token
            useAuthStore.getState().setToken(token, userDetails.isSuperUser)
            useAuthStore.getState().setUserDetails(userDetails)
            console.log('browser timezone', Intl.DateTimeFormat().resolvedOptions().timeZone, 'configured timezone', userDetails.timezone.value)
        })
    }
    else if (!userToken) {
        navigate('/login')
    }

    const isOpenSidebar = useUIStore((s) => s.isOpenSidebar)
    const setIsOpenSidebar = useUIStore((s) => s.setIsOpenSidebar)

    const toggleSidbar = () => {
        setIsOpenSidebar(!isOpenSidebar)
    }

    const asideButton = (
        <div className={`sidebar-footer p-2`}>
            <button
                className="btn btn-link toggle-btn d-flex justify-content-center align-items-center"
                onClick={toggleSidbar}>
                <img
                    src={isOpenSidebar === true ? require('../images/maximize_.png') : require('../images/minimize_.png')}
                    alt="" style={{'height': '25px'}}/>
            </button>
        </div>
    )

    const [previewSafe, setPreviewSafe] = useState(false)
    const [errorDevices, setErrorDevices] = useState([])

    const fetchAllDevices = async (token) => {
      const deviceLists = await getDeviceList(token);

      if (deviceLists.status === true) {
        let devicesList = {};
        let errorDevices = [];
        for (const p of deviceLists.result) {
          let { iccid, imsi, mdn } = p.val;
          devicesList[iccid] = [iccid, mdn, imsi];
          if (imsi === "" || mdn === "") {
            errorDevices.push(iccid);
          }
        }
        if(errorDevices.length!=0){

            setErrorDevices(errorDevices);
            setPreviewSafe(true);
        }
    }
    };

    useEffect(() => { 
        if(userToken!==""){
            fetchAllDevices(userToken).then(() => console.log('zones loaded'))
        }
    }, [userToken])


    return (
      <>
        <LoadScript
          id="script-loader"
          googleMapsApiKey={GOOGLE_API_KEY}
          libraries={lib}
        >
          <>
            <Header />
            <main id="mainContent" className={!isOpenSidebar ? "show" : ""}>
              
              < MenuSidebar />
              <GibiMap />

              
            </main>
          </>
        </LoadScript>
        <ErrorDeviceModal
          previewSafe={previewSafe}
          setPreviewSafe={setPreviewSafe}
          errorDevices={errorDevices}
        />
      </>
    );
}

export default Dashboard
