import Select from 'react-select';

const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        };
    },
};

export const GibiSelect = (props) => {
    const {devices, iccid, onChange} = props

    const gibiOptions = Object.keys(devices).map((id) => ({value: devices[id].iccid, label: devices[id].deviceName}))
    const curGibi = gibiOptions.find(g => g.value === iccid)

    const changeGibi = (val) => {
        const updatedIccid = val.value
        onChange('device', updatedIccid)
    }

    return !iccid ? null : (
        <div className="setting-device form-group">
            <label htmlFor="exampleInputEmail1" className="form-label">
                Assigned Gibi
            </label>
            <div className="custom-dropdown">
                <Select
                    options={gibiOptions}
                    styles={customStyles}
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    value={curGibi}
                    onChange={changeGibi}
                    placeholder={
                        <div className="Select Species">
                            Select Gibi Tracker
                        </div>
                    }
                />
            </div>
        </div>
    )
}
