import React from 'react'
import logo from '../assets/local-image/gibi-logo.png' 
function NoDataFound() {
  return (
    <div className='no-data-found'>
        <div>
            <img src={logo} />
            <p>No Data Found!</p>
        </div>
    </div>
  )
}

export default NoDataFound