import create from 'zustand';

let store = (set,get) => ({
    googleMapPolygon: [],
    useSafeZoneList: [],
    usePowerZoneList: [],
    // markers: [],
    setGoogleMapPolygon: (list) =>
        set((state) => {
            return {
                ...state,
                usePolygonStore: list,
            }
        }),

        setSafeZoneStoreList: (list) =>
        set((state) => {
            return {
                ...state,
                useSafeZoneList: list,
            }
        }),
        setPowerZoneStoreList: (list) =>
        set((state) => {
            return {
                ...state,
                usePowerZoneList: list,
            }
        }),
   
});

const usePolygonStore = create(store);

export default usePolygonStore;
