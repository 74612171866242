import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { checkDeviceExist } from "../../../helpers/deviceHelper";

const schema = yup
  .object()
  .shape({
    device_id: yup
      .string()
      .required("Tracker ID is required.")
      .min(19, "Devcie ID must be at least 19 digits.")
      .max(20, "Devcie ID must be at most 20 digits."),
  })
  .required();
const DeviceCheck = (props) => {
  const {
    userToken,
    updateStep,
    registerInfo,
    updatesetRegisterInfo,
    updateLoader,
  } = props;
  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    formState: { errors },
      clearErrors,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: registerInfo,
  });
  const [trackerIdValid, setTrackerIdValid] = useState(false);

  React.useEffect(() => {
    if (registerInfo.device_id) {
      const check = async () => {
        return await checkDeviceExist(userToken, {deviceID: registerInfo.device_id})
      }
      check().then((response) => {
        if (response.status === false) {
          setTrackerIdValid(false)
          // console.log(response.message);
          if (registerInfo.device_id.length > 18) {
            setError(
                'device_id',
                {type: 'custom', message: response.message},
                {shouldFocus: true}
            )
          } else {
            setError(
                'device_id',
                {type: 'custom', message: 'Tracker ID is at least 19 digits'},
                {shouldFocus: true}
            )
          }
        }
        else {
          setTrackerIdValid(true)
          clearErrors()
        }
      })
    }
  },[])

  React.useEffect(() => {
    console.log('trackerIsValid', trackerIdValid)
  }, [trackerIdValid])

  const handleDeviceChange = async (deviceID) => {
      const data = {deviceID: deviceID}
      const response = await checkDeviceExist(userToken, data)
      // console.log(response);

      if (response.status === false) {
        setTrackerIdValid(false)
        // console.log(response.message);
        if (deviceID.length > 18) {
          setError(
              'device_id',
              {type: 'custom', message: response.message},
              {shouldFocus: true}
          )
        } else {
          setError(
              'device_id',
              {type: 'custom', message: 'Tracker ID is at least 19 digits'},
              {shouldFocus: true}
          )
        }
      }
      else {
        setTrackerIdValid(true)
        clearErrors()
      }
  };
  const formSubmit = (data) => {
    if (trackerIdValid === true) {
      // console.log(data);
      updatesetRegisterInfo(data);
      updateStep(2);
    } else {
      setError(
        "device_id",
        { type: "custom", message: "Tracker not exist or already assigned" },
        { shouldFocus: true }
      );
    }
  };

  return (
    <>
      <form method="POST" onSubmit={handleSubmit(formSubmit)}>
        <h5 className="panel-title">Tracker ID</h5>
        <div className="form-group">
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-microchip" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.device_id ? " is-invalid" : "")
              }
              {...register("device_id")}
              placeholder="Tracker ID"
              autoComplete="off"
              onChange={(evt) => handleDeviceChange(evt.target.value)}
            />
          </div>
          {errors.device_id && (
            <div className="errors">{errors.device_id?.message}</div>
          )}
        </div>

        <ul className="list-inline w-100 d-flex align-items-center mb-0">
          <li className="list-inline-item ms-auto">
            <button type="submit" className="btn btn-outline-primary" disabled={!trackerIdValid}>
              Next
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

export default DeviceCheck;
