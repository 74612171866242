import Flatpickr from 'react-flatpickr'
import React, { useEffect, useState } from 'react'
import { DateTime, Settings } from 'luxon'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Accordion } from 'react-bootstrap'
import useAuthStore from '../../store/useAuthStore'
import { getEventIcon, getEventName, getLastTenEvents, getPetEvents } from '../../helpers/alertHelpers'
import useEventStore from '../../store/useEventStore'
import Select from 'react-select'
import './EventSection.css'

export const camel2title = (camelCase) => camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

const customStyles = {

    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer', padding: '5px', fontSize: '14px', '&:hover': {
                backgroundColor: '#139dcc ', color: '#fff',
            },
        }
    },
}

const noFilter = {label: 'No filter', value: 'none'}

export const EventsSection = (props) => {
    const {pet, iccid, isOpen} = props

    const [eventList, setEventList] = useState([])
    const [selectedDate, setSelectedDate] = useState('')
    const [listItems, setListItems] = useState(null)
    const [chooseDate, setChooseDate] = useState(false)
    const [listFilters, setListFilters] = useState([noFilter])
    const [curFilter, setCurFilter] = useState(noFilter)

    const userToken = useAuthStore((s) => s.token)

    const loadLastTenEvents = useEventStore((s) => s.loadLastTen)
    const allLastTenEvents = useEventStore((s) => s.lastTen)

    const allEvents = useEventStore((s) => s.events)
    const loadEvents = useEventStore((s) => s.loadEvents)

    async function fetchLastTenEvents(iccid) {
        const events = await getLastTenEvents(iccid, userToken)
        loadLastTenEvents(iccid, events)
        setEventList(events || [])
    }

    useEffect(() => {
        // populate the stores
        fetchLastTenEvents(iccid).then(() => console.log('mount loaded events'))
    }, [])

    useEffect(() => {
        if (iccid) {
            if (!selectedDate) {
                setEventList(allLastTenEvents[iccid] || [])
            }
        }
    }, [allLastTenEvents])

    useEffect(() => {
        if (iccid) {
            if (selectedDate) {
                setEventList(allEvents[iccid] || [])
            }
        }
    }, [allEvents])

    useEffect(() => {
        if (isOpen) {
            fetchLastTenEvents(iccid).then(() => console.log('open loaded events'))
        }
    }, [isOpen])

    useEffect(() => {
        console.log('eventList', eventList)
        const listItems = generateEventList(eventList)
        const listFilters = generateListFilters(eventList)
        setListItems(listItems)
        setListFilters(listFilters)
        setCurFilter(noFilter)
    }, [eventList])

    useEffect(() => {
        if (iccid) {
            if (!selectedDate) {
                setEventList(allLastTenEvents[iccid] || [])
            }
            else {
                setEventList(allEvents[iccid] || [])
            }
        }
    }, [selectedDate])

    const applyFilter = (list) => {
        if (curFilter.value !== 'none') {
            return list.filter(e => e.deviceUpdate?.subType === curFilter.value)
        }
        else {
            return list && Array.isArray(list) ? list.slice(0) : []
        }
    }

    useEffect(() => {
        const filteredEvents = applyFilter(eventList)
        const filteredList = generateEventList(filteredEvents)
        setListItems(filteredList)
    }, [curFilter])

    const onDaySearch = async (day) => {
        // const isoDate = DateTime.fromJSDate(day[0]).toISODate()
        // setSelectedDate(day[0])//
        const date = DateTime.fromJSDate(day[0]).toISODate()
        const isoDate = DateTime.fromISO(`${date}T00:00`, {zone: useAuthStore.getState().timezone.value}) // .setZone('UTC')
        const epoch = isoDate.toSeconds()
        setSelectedDate(day[0])
        const events = await getPetEvents(iccid, epoch, userToken)
        loadEvents(iccid, events.data)
    }

    const onTodaySearch = async () => {
        setSelectedDate(new Date())
        setChooseDate(true)
        const events = await getPetEvents(iccid, undefined, userToken)
        loadEvents(iccid, events.data)
    }

    const closeDateSearch = () => {
        setChooseDate(!chooseDate)
        setEventList(allLastTenEvents[iccid])
    }

    const generateEventList = (locations) => {
        return locations && locations.length ? Object.values(locations).map((message, index) => {
            const dateTime = DateTime.fromISO(message.createdAt, {zone: useAuthStore.getState().timezone.value}).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
            const subType = message.deviceUpdate?.subType || message.commandResponse?.command
            const error = message.commandResponse?.errorMessage ? 'Please wait' : ''
            const eventType = getEventName(message, subType)
            const eventIcon = getEventIcon(message, subType)
            return (
                <li className="d-flex align-items-center justify-content-between"
                    key={`${message.createdAt}--${index}`}>
                    <div>
                        <h6 className="place-name" style={{marginBottom: '2px'}}>{eventType}</h6>
                        {error ? <div className={'command-error'}>{error}</div> : null}
                        <p className="date-no-indent">
                            {dateTime}
                        </p>
                    </div>
                    <i className={eventIcon}/>
                </li>
            )
        }) : (
            <p className="text-center text-white">
                No Events Found
            </p>
        )
    }

    const generateListFilters = (locations) => {
        let filters = eventList && eventList.length ? Object.values(eventList).map((message, index) => {
            let subType
            if (message.deviceUpdate?.subType) {
                subType = message.deviceUpdate.subType
            } else if (message.eventType === 'SAFE_ZONE') {
                subType = 'safeZone'
            }
            return subType ? {
                value: subType,
                label: camel2title(subType)
            } : null
        }).filter(n=>n) : []
        filters = filters.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.value === value.value && t.label === value.label
                ))
        )
        filters.unshift({label: 'No filter', value: 'none'})
        return filters
    }

    const lastTenEventsLabel = (
        <div className="label-row last-ten-label" key={`${iccid}-last-ten-label`}>
            <div>
                <OverlayTrigger
                    key={`tooltip-ev-${iccid}-left`}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-ev-${iccid}-right`}>
                            Choose a day
                        </Tooltip>
                    }
                >
                    <div className={'cal-icon-left'} onClick={() => setChooseDate(!chooseDate)}>
                        <i className="fa-regular fa-calendar-days"/>
                    </div>
                </OverlayTrigger>
            </div>

            <div className={'ph-label-text'}>
                <h6 className="place-name">Last 10 Events</h6>
            </div>

            <div style={{width: '22px'}}/>
            {/*<div>*/}
            {/*    <OverlayTrigger*/}
            {/*        key={`tooltip-ev-${iccid}-right`}*/}
            {/*        placement={'top'}*/}
            {/*        overlay={*/}
            {/*            <Tooltip id={`tooltip-ev-${iccid}-right`}>*/}
            {/*                Todays Events*/}
            {/*            </Tooltip>*/}
            {/*        }*/}
            {/*    >*/}
            {/*        <div className={'label-icon-right'}>*/}
            {/*            <i className="fa-solid fa-calendar-day"/>*/}
            {/*        </div>*/}
            {/*    </OverlayTrigger>*/}
            {/*</div>*/}
        </div>
    )

    const searchForm = (
        <div>
            <div className="input-group white input-style">
                <span className="input-group-text">
                    <i className="fa-regular fa-calendar-days"/>
                </span>
                    <Flatpickr
                        className="form-control white"
                        placeholder="Select Date"
                        id="petHistoryDate"
                        value={selectedDate}
                        options={{
                            maxDate: new Date(),
                            dateFormat: 'l, M j Y'
                        }}
                        onChange={(date) => onDaySearch(date)}
                    />
                    <span className="input-group-text" onClick={closeDateSearch}>
                    <i className="fa fa-close" aria-hidden="true"/>
                </span>
            </div>
            <div className="form-group">
                <div className="custom-dropdown">
                    <Select options={listFilters}
                            placeholder="No filter"
                            value={curFilter}
                            styles={customStyles}
                            onChange={(f) => setCurFilter(f)}
                    />
                </div>
            </div>
        </div>
    )

    const sectionTop = chooseDate ? searchForm : lastTenEventsLabel
    return (
        <Accordion.Item eventKey={`${iccid}-pet-event`} className="sub-accordian-item"
                        key={`${iccid}-pet-event`}>
            <div className={'event-section'}>
                <OverlayTrigger placement="auto"
                                overlay={<Tooltip id="tooltip-disabled">{`View ${pet.petName}'s event`}</Tooltip>}>
                    <Accordion.Header>
                        <div className={'header-row'}>
                            <div>
                                <i className="fa-solid fa-triangle-exclamation" style={{color: 'white'}}/>
                            </div>
                            <div>
                                <h4 className="dog-name ms-3" style={{color: 'white'}}>Pet Events</h4>
                            </div>
                        </div>
                    </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body style={{padding: '4px'}}>
                    <div className="tab-content" id="ph-tabContent">
                        <div className="tab-pane fade show active"
                             id="ph-home" role="tabpanel"
                             aria-labelledby="ph-home-tab">
                            <div className="form-group">
                                {sectionTop}
                            </div>
                            <div className="place-list-scroll scrollbar">
                                <ul className="place-list ps-0">
                                    {listItems}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </div>
        </Accordion.Item>
    )
}
