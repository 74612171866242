import React, { useState,useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import {CityStateLookupRequest} from '../../helpers/registerHelper'
const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL;
const state = [
  { value: " California", label: " California" },
  { value: "Florida", label: "Florida" },
];
const country = [
  { value: "USA", label: "USA" },
  { value: "Canada", label: "Canada" },
];

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      cursor: "pointer",
      padding: "5px",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#139dcc ",
        color: "#fff",
      },
    };
  },
};

const schema = yup
  .object()
  .shape({
    country: yup.mixed()
    .required()
    .oneOf(["USA", "Canada"])
    .label("Selected Country"),    

    // COMMON FIELD VALIDATION
  //   first_name: yup
  //   .string()
  //   .min(2, "First name at least 2 characters.")
  //   .max(20, "First name at most 20 characters.")
  //   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
  //   .required("First name is required."),
  // last_name: yup
  //   .string()
  //   .min(2, "Last name at least 2 characters.")
  //   .max(20, "Last name at most 20 characters.")
  //   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
  //   .required("Last Name is required."),
    // CANADA FIELD VALIDATION
    address1: yup.string()
    .when('country', {
      is: 'Canada',
      then: yup.string()
        .required('Address is required.'),
    }),
    address2: yup.string()
    .when('country', {
      is: 'Canada',
      then: yup.string()
        .required('Address is required.'),
    }),
    address3: yup.string()
    .when('country', {
      is: 'Canada',
      then: yup.string()
        .required('Address is required.'),
    }),
    province: yup.string()
    .when('country', {
      is: 'Canada',
      then: yup.string()
        .required('Province is required.'),
    }),
    canada_city: yup.string()
    .when('country', {
      is: 'Canada',
      then: yup.string()
        .required('City is required.'),
    }),
    postal_code: yup.string()
    .when('country', {
      is: 'Canada',
      then: yup.string()
        .required('Postal code is required.'),
    }),
        
// USA FIELD VALIDATION
zip_code: yup.string()
    .when('country', {
      is: 'USA',
      then: yup.string()
      .required('Zipcode is required')
      .typeError('Zipcode can only be a number')
      .test('len', 'Zipcode needs to be excatly 5 digits', val => val.toString().length === 5)
    }),
    street_addr: yup.string()
    .when('country', {
      is: 'USA',
      then: yup.string()
      .min(3, "Street address at least 5 characters.")
      .max(20, "Street address at most 20 characters.")
       .required("Street address is required."),
    }),
  
    usa_city: yup.string()
    .when('country', {
      is: 'USA',
      then: yup.string()
      .min(3, "City  at least 3 characters.")
      .max(20, "City at most 20 characters.")
     .required("City is required."),
    }),
    state: yup.string()
    .when('country', {
      is: 'USA',
      then: yup.string()
   
          .required("State is required "),
    }),   
  })
  .required();
 
const UserAddress = (props) => {
const { updateStep, homeAddress ,updatesetHomeAddress,updateLoader}=props
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([])

  const {
    handleSubmit,
    register,
    getValues ,
    setFocus,
    setError,
    setValue ,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: homeAddress
  });
  const formSubmit = async(data) => {
    updateLoader(true);
    const status = await  cityStateLookUp();
    updateLoader(false);
  if(status){
    updatesetHomeAddress(data);
    updateStep(3);
  }    
  };
  const cityStateLookUp = async () => {
    let status = true;
    let country = getValues("country");
    let zip_id = 0;
    if (country === "USA") {
      let zip_code = getValues("zip_code");
      let State_data = getValues("state");
      let State = State_data.value;
      const param = { zip_code: zip_code, zip_id: zip_id };      
      if (zip_code != "") {
        let city = getValues("city");
        const response = await CityStateLookupRequest(param);
        let ZipCode = response.result.ZipCode[0];
        let zipCodeStatus = "Error" in ZipCode ? true : false;

        if (zipCodeStatus === false) {
          let CityName = response.result.ZipCode[0].City[0];
          let StateName = response.result.ZipCode[0].State[0];

          if (CityName !== city) {
            setError(
              "usa_city",
              {
                type: "custom",
                message: "City Name Is not correct as per the zipcode",
              },
              { shouldFocus: true }
            );
            setValue("usa_city", CityName);
          }
          if (StateName != State) {
            // alert("State Name Is not correct as per the zipcode");
          }
        } else {
          setError(
            "zip_code",
            {
              type: "manual",
              message: "ZipCode is invalid",
            },
            { shouldFocus: true }
          );
          status=false;
        }
      }
    } else {
      let postal_code = getValues("postal_code");
      // if (postal_code != "") {
      //   let canada_city = getValues("canada_city");
      //   const param = { zip_code: postal_code, zip_id: 0 };
      //   const response = await CityStateLookupRequest(param);
      //   let ZipCode = response.result.ZipCode[0];
      //   let zipCodeStatus = "Error" in ZipCode ? true : false;
      //   if (zipCodeStatus === false) {
      //     let CityName = response.result.ZipCode[0].City[0];
      //     let StateName = response.result.ZipCode[0].State[0];

      //     if (CityName !== canada_city) {
      //       // setError(
      //       //   "canada_city",
      //       //   {
      //       //     type: "custom",
      //       //     message: "City Name Is not correct as per the zipcode",
      //       //   },
      //       //   { shouldFocus: true }
      //       // );
      //       setValue("canada_city", CityName);
      //     }
      //   } else {
      //     setError(
      //       "postal_code",
      //       {
      //         type: "manual",
      //         message: "Postal code is invalid",
      //       },
      //       { shouldFocus: true }
      //     );
      //     status=false;
      //   }
      // }
    }
    return status;
  };
  
  const [Selected_Country, setCountry_As] = useState(null);
  const selectCountry = (value) => {
    setCountry_As(value);
    
  };

  useEffect(() => {

    if (Object.keys(homeAddress).length != 0) {
      
      setValue('country',homeAddress.country);
      setCountry_As(homeAddress.country);
    }

}, []);

  const previous_step=()=>{
    updateStep(1);
 }



const CountryDropDown = () => {
  return (
    <div className="col-xl-8 col-lg-8 col-md-8 offset-sm-2 col-12">
      <div className="form-group ">
        <label className="text-white">Select Country</label>
        <div className="custom-dropdown country">
          <Controller
            name="country"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                options={country}
                styles={customStyles}
                className="react-dropdown"
                classNamePrefix="dropdown"
                value={country.find((c) => c.value === value)}
                onChange={(val) => {
                  onChange(val.value);
                  selectCountry(val.value);
                }}
                placeholder={
                  <div className="Select country">Select country</div>
                }
              />
            )}
          />
          <p className="errors">
            {errors.country?.message || errors.country?.label.message}
          </p>
        </div>
      </div>
    </div>
  );
};

const commonAddressData =   (
    <>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">First Name</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.first_name ? " is-invalid" : "")
              }
              {...register("first_name")}
              placeholder="Enter Your First Name"
            />
            {errors.first_name && (
              <div className="invalid-feedback">
                {errors.first_name?.message}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Last Name</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.last_name ? " is-invalid" : "")
              }
              {...register("last_name")}
              placeholder="Enter Your Last Name"
            />
            {errors.last_name && (
              <div className="invalid-feedback">
                {errors.last_name?.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
   
);

const usaAddressData =  (
    <>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Street Address 1</label>

          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>

            <input
              type="text"
              className={
                "form-control" + (errors.street_addr ? " is-invalid" : "")
              }
              {...register("street_addr")}
              placeholder="Street Address*"
            />
            {errors.street_addr && (
              <div className="invalid-feedback">
                {errors.street_addr?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Street Address 2</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>

            <input
              type="text"
              className={
                "form-control" + (errors.apartment ? " is-invalid" : "")
              }
              {...register("apartment")}
              placeholder="Street Address 2"
            />
            {errors.apartment && (
              <div className="invalid-feedback">
                {errors.apartment?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">City</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>

            <input
              type="text"
              className={
                "form-control" + (errors.usa_city ? " is-invalid" : "")
              }
              {...register("usa_city")}
              placeholder="Enter Your City*"
            />

            {errors.usa_city && (
              <div className="invalid-feedback">{errors.usa_city?.message}</div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">State</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>

            <input
              type="text"
              className={
                "form-control" + (errors.state ? " is-invalid" : "")
              }
              {...register("state")}
              placeholder="Enter Your State*"
            />

            {errors.state && (
              <div className="invalid-feedback">{errors.state?.message}</div>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Zipcode</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>

            <input
              type="text"
              className={
                "form-control" + (errors.zip_code ? " is-invalid" : "")
              }
              {...register("zip_code")}
              placeholder="Enter Your Zipcode*"
            />
            {errors.zip_code && (
              <div className="invalid-feedback">{errors.zip_code?.message}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
 

const canadaAddressData =  (
    <>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Address 1</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.address1 ? " is-invalid" : "")
              }
              {...register("address1")}
              placeholder="Enter Address1*"
            />
            {errors.address1 && (
              <div className="invalid-feedback">{errors.address1?.message}</div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Address 2</label>
          <div className="custom-dropdown country">
            <div className="input-group white">
              <span className="input-group-text">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
              </span>

              <input
                type="text"
                className={
                  "form-control" + (errors.address2 ? " is-invalid" : "")
                }
                {...register("address2")}
                placeholder="Enter Address2*"
              />
              {errors.address2 && (
                <div className="invalid-feedback">
                  {errors.address2?.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Address 3</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.address3 ? " is-invalid" : "")
              }
              {...register("address3")}
              placeholder="Enter Address3*"
            />
            {errors.address3 && (
              <div className="invalid-feedback">{errors.address3?.message}</div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Province</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.province ? " is-invalid" : "")
              }
              {...register("province")}
              placeholder="Enter Province"
            />
            {errors.province && (
              <div className="invalid-feedback">{errors.province?.message}</div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">City</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.canada_city ? " is-invalid" : "")
              }
              {...register("canada_city")}
              placeholder="City"
            />
            {errors.canada_city && (
              <div className="invalid-feedback">
                {errors.canada_city?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <label className="text-white">Postal Code</label>
          <div className="input-group white">
            <span className="input-group-text">
              <i className="fa fa-hashtag" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              className={
                "form-control" + (errors.postal_code ? " is-invalid" : "")
              }
              {...register("postal_code")}
              placeholder="Enter Postal Code"
            />
            {errors.postal_code && (
              <div className="invalid-feedback">
                {errors.postal_code?.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );


const actionButton = (
  <ul className="list-inline w-100 d-flex align-items-center mb-0">
    <li className="list-inline-item m-0 w-50 pe-2">
      <button
        type="button"
        className="btn btn-outline-primary white w-100"
        onClick={previous_step}
      >
        Previous
      </button>
    </li>
    <li className="list-inline-item m-0 w-50 ps-2">
      <button type="submit" className="btn btn-outline-primary white w-100">
        Next
      </button>
    </li>
  </ul>
);
  return (
    <>
      <div className="login-holder d-flex align-items-center">
        <div className="login-container">
          <div className="logo">
            <img src={require("../../images/logo-white_.png")} alt="gibi" />
          </div>
          <h3 className="panel-title text-center text-white">  Home Address</h3>
          <form method="POST" onSubmit={handleSubmit(formSubmit)}>
            <div className="row">
              <CountryDropDown />
              {/* {(Selected_Country === "USA" ||
                Selected_Country === "Canada") && <>{commonAddressData}</>} */}
              {Selected_Country === "USA" && <>{usaAddressData}</>}
              {Selected_Country === "Canada" && <>{canadaAddressData} </>}
              {actionButton}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserAddress;
