import React from 'react'
import useMapStore from '../../../store/useMapStore'
import { Circle } from '@react-google-maps/api'
import { estimateDeviationFromReport, getMeanAndStd } from '../mapHelpers'

const highlightSettings = {
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 4,
    fillColor: 'transparent',
    fillOpacity: 0,
}

const accuracyStyle = {
    strokeColor: 'blue',
    strokeOpacity: 0,
    strokeWeight: 1,
    fillColor: 'blue',
    fillOpacity: 0.1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
}

const SIZE_OF_ICON_IN_PX = 28
const ZOOM_THRESHOLD = 12

export const LocationHighlight = (props) => {
    const {} = props

    const feature = useMapStore((s) => s.highlightedFeature)
    const zoom = useMapStore((s) => s.mapZoom)

    const getEndPoint = (point, angle, distance) => {
        const endPoint = {
            lng: point.lng + (distance * Math.cos(angle)),
            lat: point.lat + (distance * Math.sin(angle))
        }
        return endPoint
    }

    const getHighlight = (highlightFeature) => {
        const loc = {
            lng: highlightFeature.properties.longitude,
            lat: highlightFeature.properties.latitude,
        }
        // https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM
        const metersPerPx = 156543.03392 * Math.cos(highlightFeature.properties.longitude * Math.PI / 180) / Math.pow(2, zoom)
        const radius = SIZE_OF_ICON_IN_PX * metersPerPx

        return (
            <Circle
                center={loc}
                radius={radius}
                options={highlightSettings}
            />
        )
    }

    const getAccuracyHighlight = (feature) => {
        const loc = {
            lng: feature.properties.longitude,
            lat: feature.properties.latitude,
        }
        let stats = getMeanAndStd(feature?.properties?.accuracy?.toFixed(1))
        if (!stats) {
            stats = {
                mean: estimateDeviationFromReport(feature),
                std: estimateDeviationFromReport(feature)
            }
        }
        return (
            <div>
                <Circle
                    center={loc}
                    radius={stats.std}
                    options={accuracyStyle}
                />
                <Circle
                    center={loc}
                    radius={stats.mean}
                    options={accuracyStyle}
                />
                <Circle>
                    center={loc}
                    radius={1}
                    options={{...accuracyStyle, fillOpacity: 1}}
                </Circle>
            </div>
        )
    }

    const show = feature && feature.properties.status && zoom > ZOOM_THRESHOLD
    const highLightCircle = show ? getAccuracyHighlight(feature) : null

    return (
        <div>
            {highLightCircle}
        </div>
    )
}
