import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { set, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { addContact, updateContact } from '../../helpers/contactHelper'
import { toastConfig } from '../Admin/adminConfig'

const schema = yup
    .object()
    .shape(
        {
            first_name: yup
                .string()
                .min(2, 'First name must be at least 2 characters.')
                .max(20, 'First name must be at most 20 characters.')
                .required('First name is required.')
                .matches(/^[aA-zZ\s]+$/, 'Only alphabetic characters are allowed for this field '),
            last_name: yup
                .string()
                .min(2, 'Last name must be at least 2 characters.')
                .max(20, 'Last name must be at most 20 characters.')
                .required('Last Name is required.')
                .matches(/^[aA-zZ\s]+$/, 'Only alphabetic characters are allowed for this field '),
            email: yup.string().when('email', (value) => {
                if (value) {
                    return yup.string().matches(/^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/, 'Invalid Email address.')

                }
                else {
                    return yup
                        .string()
                        .transform((value, originalValue) => {
                            // Convert empty values to null
                            if (!value) {
                                return null
                            }
                            return originalValue
                        })
                        .nullable()
                        .optional()
                }
            }),
            // mobile_no: yup.string().when('mobile_no', (value) => {
            //     if (value) {
            //         return yup.string()
            //             .matches(/^[0-9]+$/, 'Must be only numeric characters')
            //             .min(10, 'Mobile No. must be exactly 10 digits')
            //             .max(10, 'Mobile No. must be exactly 10 digits');
            //     }
            //     else {
            //         return yup
            //             .string()
            //             .transform((value, originalValue) => {
            //                 // Convert empty values to null
            //                 if (!value) {
            //                     return null
            //                 }
            //                 return originalValue
            //             })
            //             .nullable()
            //             .optional()
            //     }
            // }),

            mobile_no: yup
                .string()
                .matches(/^[0-9]+$/, 'Must be only numeric characters')
                .min(10, 'Mobile No. must be exactly 10 digits')
                .max(10, 'Mobile No. must be exactly 10 digits'),

            pet_list: yup
                .array()
                .min(1, 'Select atleast one pet')
                .of(yup.string().required('Pet is required.'))
                .required('Pet is required.')
                .nullable(),
            // pet_list: yup.array().min(1, 'Select atleast one pet').of(yup.string().required("Pet is required.")).nullable(),
            // pet_list: yup.array().when(petInfo, (petInfo, schema) => {
            //   return petInfo.length >1  ? schema.required( ) : schema.optional();
            // }),
        },
        [['email', 'email']
        // ,['mobile_no','mobile_no']
    ]
    )
    .required()

function ContactModal(props) {
    const {
        showAddNewContact,
        setShowAddNewContact,
        petInfo,
        isSubmitting,
        setIsSubmitting,
        form_type,
        setform_type,
        petContactID,
        updateLoader,
        modalTitle,
        btnName,
        userToken,
        toastConfig,
        auxToFetch,
        setAuxToFetch,
        contactInfo,
        checkDisable,
        setCheckDisable,
        origSms,
    } = props
    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        // defaultValues: {form_type:true}
    })

    useEffect(() => {
        // console.log(contactInfo)
        // console.log(form_type)
        if (form_type === 'edit') {
            if (JSON.stringify(contactInfo) !== '{}') {
                // console.log(contactInfo.smsOptIn)
                setValue('first_name', contactInfo.first_name)
                setValue('last_name', contactInfo.last_name)
                setValue('email', contactInfo.email)
                setValue('mobile_no', contactInfo.mobile_no)
                setValue('smsOptIn', contactInfo.smsOptIn === 'Opt-in' ? true : false)
                // setValue('smsOptIn', contactInfo.smsOptIn)

                let pet_list = contactInfo.pet_list
                let selected_pet_list = []
                pet_list.map((item, index) => selected_pet_list.push(item._id))
                setValue('pet_list', selected_pet_list)
                  setShowAddNewContact(true)

            }
        }
    }, [contactInfo,form_type])




    const addContactDetails = async (onClose, data) => {
        const result = await addContact(userToken, data)
        if (result.status) {
            toast.success(result.message, {...toastConfig, containerId: 'api'})
            reset()
            onClose()
            setShowAddNewContact(false)
            setIsSubmitting(false)
            setAuxToFetch(!auxToFetch)
        }
        else {
            toast.error(result.message, {...toastConfig, containerId: 'api'})
            setIsSubmitting(false)
        }
    }
    const updateContactDetails = async (onClose, petContactID, data) => {
        const result = await updateContact(userToken, petContactID, data)
        if (result.status) {
            toast.success(result.message, {...toastConfig, containerId: 'api'})
            onClose()
            reset()
            setShowAddNewContact(false)
            setIsSubmitting(false)
            setAuxToFetch(!auxToFetch)
        }
        else {
            toast.error(result.message, {...toastConfig, containerId: 'api'})
            setIsSubmitting(false)
        }
    }

    const handleForm = (onClose, data, form_type) => {
        // console.log(form_type)
        // console.log(data)

        form_type === 'add'
            ? addContactDetails(onClose, data)
            : updateContactDetails(onClose, petContactID, data)
    }
    const formSubmit = (data) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <div className="fa-3x">
                            <i className="fa-solid fa-paw fa-bounce"></i>
                        </div>
                        <h3>Are you sure want to {form_type} ?</h3>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={() => {
                                if (origSms === 'Opt-in') {
                                    if (!data.smsOptIn) {
                                        data.smsOptIn = 'Opt-out'
                                    } else {
                                        data.smsOptIn = origSms
                                    }
                                } else {
                                    if (data.smsOptIn) {
                                        data.smsOptIn = 'pending'
                                    }
                                    else {
                                        data.smsOptIn = 'Opt-out'
                                    }
                                }
                                handleForm(onClose, data, form_type)
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-outline-primary min-width-120"
                            onClick={() => {
                                onClose()
                            }}
                        >
                            No
                        </button>
                    </div>
                )
            },
            closeOnClickOutside: false,
        })
    }
    const firstNameField = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group my-pets">
                <label className="form-label text-dark">First Name :</label>
                <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-user"></i>
          </span>
                    <input
                        type="text"
                        className={
                            'form-control' + (errors.first_name ? ' is-invalid' : '')
                        }
                        placeholder="Enter name"
                        {...register('first_name')}
                    />
                </div>
                {errors.first_name && (
                    <div className="errors">{errors.first_name?.message}</div>
                )}
            </div>
        </div>
    )
    const lastNameField = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group my-pets">
                <label className="form-label text-dark">Last Name :</label>
                <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-user"></i>
          </span>
                    <input
                        type="text"
                        className={'form-control' + (errors.last_name ? ' is-invalid' : '')}
                        placeholder="Enter name"
                        {...register('last_name')}
                    />
                </div>
                {errors.last_name && (
                    <div className="errors">{errors.last_name?.message}</div>
                )}
            </div>
        </div>
    )

    const emailField = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group my-pets">
                <label className="form-label text-dark">Email : (Optional)</label>
                <div className="input-group white">
          <span className="input-group-text">
            <i className="fa fa-envelope"></i>
          </span>
                    <input
                        type="text"
                        className={'form-control' + (errors.email ? ' is-invalid' : '')}
                        placeholder="Enter email"
                        {...register('email')}
                    />
                </div>
                {errors.email && <div className="errors">{errors.email?.message}</div>}
            </div>
        </div>
    )
    const mobileNoField = (
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="form-group my-pets">
                <label className="form-label text-dark">Mobile :   </label>
                <div className="input-group white">
          <span className="input-group-text" style={{fontSize: '20px'}}>
            <i className="fa fa-mobile"></i>
          </span>
                    <input
                        type="text"
                        className={'form-control' + (errors.mobile_no ? ' is-invalid' : '')}
                        placeholder="Enter mobile"
                        {...register('mobile_no')}
                       onChange={(e)=>{
                        // console.log(e.target.value)
                         e.target.value!==""?setCheckDisable(false):setCheckDisable(true);

                       }}
                    />
                </div>
                {errors.mobile_no && (
                    <div className="errors">{errors.mobile_no?.message}</div>
                )}
            </div>
        </div>
    )
    const alertSectionField = (
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="form-group my-pets">
                <label className="text-blue mb-3 fw-bold">Alert Notification</label>
                <br/>

                {Object.values(petInfo).map((values) => {
                    if (petInfo.length > 1) {
                        return (
                            <div
                                className="form-check form-check-inline custom-check"
                                key={values._id}
                            >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={values._id}
                                    id={values._id}
                                    {...register('pet_list')}
                                />
                                <label
                                    className="form-check-label clearfix text-dark"
                                    htmlFor={values._id}
                                >
                                    {values.petName}
                                </label>
                            </div>
                        )
                        {
                            errors.pet_list && (
                                <div className="errors">{errors.pet_list?.message}</div>
                            )
                        }
                    }
                    else {
                        setValue('pet_list', [values._id])
                        return (
                            <label
                                className="form-check-label clearfix text-dark"
                                htmlFor={values._id}
                            >
                                {values.petName}
                            </label>
                        )
                    }
                })}
            </div>
        </div>
    )

    const btnList = (
        <>
            <button
                type="submit"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                disabled={isSubmitting}
            >
                {isSubmitting === true && (
                    <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    // <span className="spinner-grow spinner-grow-sm"></span>
                )}
                {btnName}
            </button>
            <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                onClick={() => { onCloseContact()}}
            >
                Cancel
            </button>
        </>
    )

    const checkbox = (
        <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6">
             <div className="form-group my-pets">
            <label className="text-blue mb-3 fw-bold">SMS OPT-IN / OUT</label>
            <br/>
            <label className="toggle">
                <input
                    type="checkbox"
                    name="notificationStatus"
                    autoComplete="off"
                    {...register('smsOptIn')}
                    defaultChecked={false}
                    disabled={checkDisable}
                />
                <span className="slider"></span>
                <span
                    className="labels"
                    data-on="IN"
                    data-off="OUT"
                ></span>
            </label>
            </div>
        </div>
    )
const onCloseContact=()=>{
    reset();
    setShowAddNewContact(false);
    setform_type('add');
    setCheckDisable(true);
}
    return (
        <Modal
            className="add-new-gibi"
            size="lg"
            show={showAddNewContact}
            onHide={() => onCloseContact()}
            aria-labelledby="modal-sizes-title-lg"
            centered
            backdrop="static"
        >
            <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {firstNameField}
                        {lastNameField}
                        {/* {emailField} */}
                        {mobileNoField}
                        {alertSectionField}
                        {checkbox}
                    </div>
                </Modal.Body>
                <Modal.Footer>{btnList}</Modal.Footer>
            </form>
        </Modal>
    )
}

export default ContactModal
