import { get, post, put, remove } from './apiHelper'

export const getUpdateZoneDetail = (
    setZoneFormtype,
    setZoneID,
    userToken,
    setPetSelected,
    setValue,
    setShowSearchUpdate,
    setMapCenter,
    setPath,
    setNewPowerZoneLocation
) => {
    const getUpdateZoneDetails = async (res, zone_coordinates, zone_type) => {
        setZoneFormtype('Update')
        setZoneID(res._id)
        var zone_pets = []
        const response = await get('/api/v1/get_assign_zone_pet_list/', userToken)
        const getzones = response.data.result
        zone_pets = getzones.map((pets) => ({
            value: pets.petID._id,
            label: pets.petID.petName,
        }))

        setPetSelected(zone_pets)
        setValue('select_pets', zone_pets)

        setShowSearchUpdate(true)
        setValue('zone_name', res.zone_name)

        let single_lat_lng = zone_coordinates[0]
        if (single_lat_lng !== undefined) {
            let res = []

            Object.keys(zone_coordinates).forEach((key) => {
                res[key] = {}
                Object.keys(zone_coordinates[key]).forEach((temp) => {
                    res[key][temp] = !isNaN(zone_coordinates[key][temp])
                        ? parseFloat(zone_coordinates[key][temp], 10)
                        : zone_coordinates[key][temp]
                })
                return res
            })

            if (res[0]) {
                setMapCenter(res[0])
            }
            if (zone_type === 'safe') {
                setPath(res)
                setNewPowerZoneLocation({})
            }
            else {
                setPath([])
                setNewPowerZoneLocation(res[0])
            }
        }
    }
    return getUpdateZoneDetails
}

// export const getAllSafeZones = (updateLoader, setSafeZoneList, setPowerZoneList, setPPath) => {
export const fetchAllPetZones = async (userToken) => {
    // updateLoader(true);

    const response = await get('/api/v1/zones', userToken)
    if (response.data.status) {
        return response.data.result
    }
    else {
        return []
        // updateLoader(false);
    }
}
//     return fetchAllPetZones;
// }

export function removeSafeZone(userToken, fetchAllPetZones) {
    // const removeZone = (deletedID) => {
    //   confirmAlert({
    //     customUI: ({ onClose }) => {
    //       return (
    //         <div className="custom-ui">
    //           <div className="fa-3x">
    //             <i className="fa-solid fa-paw fa-bounce"></i>
    //           </div>
    //           <h3>Are you sure want to remove ?</h3>
    //           <button
    //             className="btn btn-outline-primary min-width-120"
    //             onClick={() => {
    //               // axios({
    //               //     method: 'delete',
    //               //     url: REACT_APP_BE_URL + '/api/v1/zone/' + deletedID,
    //               //     headers: {Authorization: `Bearer ${userToken}`}
    //               // }).then(function (response) {
    //               //     if (response.data.status) {
    //               //         onClose();
    //               //         toast.success(response.data.message, {
    //               //             position: 'top-center',
    //               //             autoClose: 5000,
    //               //             hideProgressBar: false,
    //               //             closeOnClick: true,
    //               //             pauseOnHover: true,
    //               //             draggable: true,
    //               //             progress: undefined,
    //               //         });
    //               //         fetchAllPetZones(userToken);
    //               //         // setAuxToFetch(!auxToFetch);
    //               //     }
    //               // }).catch(function (response) {
    //               //     console.log(response);
    //               // });
    //             }}
    //           >
    //             Yes
    //           </button>
    //           <button
    //             className="btn btn-outline-primary min-width-120"
    //             onClick={onClose}
    //           >
    //             No
    //           </button>
    //         </div>
    //       );
    //     },
    //     closeOnClickOutside: false,
    //   });
    // };
}

export const zonePost = async (userToken, data) => {
    try {
        const response = await post('/api/v1/createZone', data, userToken)
        if (response.data.status) {
            return response.data
        }
        else {
            return []
        }
    } catch (error) {
        console.log(error)
    }
}

export const zonePut = async (userToken, id, data) => {
    try {
        const response = await put('/api/v1/updateZone/' + id, data, userToken)
        if (response.data.status) {
            return response.data
        }
        else {
            return []
        }
    } catch (error) {
        console.log(error)
    }
}

export const deleteZone = async (userToken, id) => {
    try {
        const response = await remove('/api/v1/zone/' + id, userToken)
        console.log(response)
        if (response.data.status) {
            return response.data
        }
        else {
            return []
        }
    } catch (error) {
        console.log(error)
    }
}
