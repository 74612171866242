import React, {useState,useEffect} from 'react';
import {Modal} from 'react-bootstrap';

// let selectedAlerts = [];
export const AlertsPanel = (props) => {
    const {alerts, alertTypes, onChange, onSave,selectedAlerts,setSelectedAlerts} = props;
    

 
    // let selectedAlerts = alertTypes.filter(a => alerts?.includes(a._id)).map(a => a._id)

    const [alertsChanged, setAlertsChanged] = useState(false)

    const changeAlert = (e) => {
        let value = e.target.value;
       
        if (e.target.checked === true) {
            setSelectedAlerts(selectedAlerts => selectedAlerts.concat(value))
            // selectedAlerts.push(value)
        }
        else {
            // selectedAlerts = selectedAlerts.filter(v => v !== value)
            setSelectedAlerts(selectedAlerts => selectedAlerts.filter(v => v !== value))
        }
        setAlertsChanged(true)
        // onChange('alerts', selectedAlerts)
        
    }

    useEffect(() => {
        onChange('alerts', selectedAlerts)
    }, [selectedAlerts])
    

    const label = (
        <div className="row ps-4">
            <div>
                <label className="form-label">
                    Alerts
                </label>
            </div>
        </div>
    )

    const saveButton = (
        <button
            className="btn btn-outline-primary min-width-120 float-end"
            disabled={!alertsChanged}
            onClick={() => onSave('alerts', selectedAlerts)}
        >
            Save
        </button>
    )

    const alertBoxes =
    <div className='modal-body'>
            <div className="alert-container">
                {Object.values(alertTypes).map((alert, index, row) => {
                    const isOn = selectedAlerts.includes(alert._id)

                    const label = (
                        <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label text-dark"
                            >
                                {alert.alertType}
                            </label>
                        </div>
                    )
                    const checkbox = (
                        <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6">
                            <label className="toggle">
                                <input
                                    type="checkbox"
                                    name={alert.alertType}
                                    id={`on-${index}`}
                                    autoComplete="off"
                                    value={alert._id}
                                    defaultChecked={isOn}
                                    onChange={changeAlert}
                                />
                                <span className="slider"></span>
                                <span
                                    className="labels"
                                    data-on="ON"
                                    data-off="OFF"
                                ></span>
                            </label>
                        </div>
                    )

                    return (
                        <div className="alert-item" key={index}>
                            {label}
                            {checkbox}
                        </div>
                    )
                })}
            </div>
            </div>

    return (
        <div>
            {/* {label} */}
            {alertBoxes}
            <Modal.Footer>
                {saveButton}
            </Modal.Footer>
        </div>
    )
}
