import create from 'zustand';

let store = (set) => ({
    token: '',
    superUser: false,
    userIP: '',
    firstName: '',
    lastName: '',
    email: '',
    timezone: {},
    userSince: '',

    setToken: (token, adminRole) =>
        set((state) => ({
            ...state,
            token: token,
            superUser: Boolean(adminRole),
        })),
    clearToken: () =>
        set((state) => ({
            ...state,
            token: '',
            superUser: false,
        })),
    setUserIP: (userIP) =>
        set((state) => ({
            ...state,
            userIP
        })),
    setUserDetails: (u) =>
        set((state) => ({
            ...state,
            timezone: u.timezone,
            firstName: u.first_name || u.firstName,
            lastName: u.last_name || u.lastName,
            email: u.email,
            userSince: u.createdAt,
        })),
    setTimeZone: (tz) =>
        set((state) => ({
            ...state,
            timezone: tz,
        })),
});

const useAuthStore = create(store);

export default useAuthStore
