import React, { useState } from 'react'
import usePetsDevicesStore from '../store/usePetsDevicesStore'
import DashboardAccordion from './DashboardAccordion'
import { SafeZoneSection } from './SafeZone/SafeZoneSection'

function MobileDashboardTopMenu() {
    const [isOpenSidebar, setIsOpenSidebar] = useState(true)
    const petMap = usePetsDevicesStore((s) => s.pets)

    return (

        <main id="mainContent" className="sidebar show">
            <DashboardAccordion/>
            <SafeZoneSection/>
        </main>


    )
}

export default MobileDashboardTopMenu
