import React,{useState}from 'react';
import Header from "../Header";
// import Footer from "./Footer.js";
// import Select from "react-select";
import { NavLink  } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Sidebar(props) {
  const {updateSidebar,isOpenSidebar,width}=props;
  return(
    <>
     {
      //  <Header updateSidebar={updateSidebar} />
     }
        <aside className={"sidebar scrollbar " + (isOpenSidebar  ? 'show' : '')}>
          <ul  className={"nav-list ps-0 "+(!isOpenSidebar  ? 'mini-sidebar' : ' ')}>
            <li>
            <OverlayTrigger placement='auto' overlay={<Tooltip id="tooltip-disabled">Click to view Dashboard.</Tooltip>}>
              <NavLink  to="/dashboard" className="nav-link" activeclassname="active">
                <i className={"fa fa-tachometer  " + (!isOpenSidebar ? 'mx-auto' : '')} aria-hidden="true"></i><span>Dashboard</span>
              </NavLink>
            </OverlayTrigger>
            </li>
              <li>
            <OverlayTrigger placement='auto' overlay={<Tooltip id="tooltip-disabled">Click to view Pets.</Tooltip>}>
              <NavLink  to="/my-pet" className="nav-link" activeclassname="active">
                <i className={"fa fa-paw " + (!isOpenSidebar ? 'mx-auto' : '')} aria-hidden="true"></i><span>Pets</span>
              </NavLink>
            </OverlayTrigger>
            </li>
            <li>
            <OverlayTrigger placement='auto' overlay={<Tooltip id="tooltip-disabled">Click to view Trackers.</Tooltip>}>
              <NavLink  to="/my-device" className="nav-link" activeclassname="active">
                <img className={`${(!isOpenSidebar ? 'mx-auto' : '')}`}
                     src={require('../../images/deviceVertIcon.png')}
                     style={{width:'15px', marginRight:'16px',transform: 'rotateX(180deg)'}}
                /><span>Gibi Trackers</span>
              </NavLink>
            </OverlayTrigger>
            </li>

            <li>
            <OverlayTrigger placement='auto' overlay={<Tooltip id="tooltip-disabled">Click to view Contacts.</Tooltip>}>
              <NavLink  to="/my-contact" className="nav-link" activeclassname="active">
                <i  className={"fa fa-users " + (!isOpenSidebar ? 'mx-auto' : '')} aria-hidden="true"></i> <span>Contacts</span>
              </NavLink>
            </OverlayTrigger>
            </li>
            <li>
            <OverlayTrigger placement='auto' overlay={<Tooltip id="tooltip-disabled">Click to view Account & Billing.</Tooltip>}>
              <NavLink  to="/my-account" className="nav-link" activeclassname="active">
                <i  className={"fa fa-file-text " + (!isOpenSidebar ? 'mx-auto' : '')} aria-hidden="true"></i> <span> Account & Billing</span>
              </NavLink>
            </OverlayTrigger>
            </li>
          </ul>
          <div className="sidebar-footer p-3">
                  <div>
                      <button id="show-sidebar" className="btn btn-sm border-0 d-flex align-items-center justify-content-center" onClick={updateSidebar}>
                      <img src={isOpenSidebar === true ? require("../../images/minimize_.png"):require("../../images/maximize_.png")} alt=""   style={{'height': '25px'}}/>
                          </button>
                  </div>
          </div>
        </aside>
   </>
  );
}
export default Sidebar;
