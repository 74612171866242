import React, { useEffect, useRef, useState } from 'react'
import { Circle, OverlayView } from '@react-google-maps/api'

const POWER_ZONE_RADIUS_IN_METERS = 20

const FILL_COLOR = '#73a246'
const STROKE_COLOR = '#73a246'
const EDIT_FILL_COLOR = '#e98585'
const EDIT_STROKE_COLOR = '#d92424'

const PowerZone = (props) => {
    const {
        canDrag,
        zone,
        index,
        setNewPowerZoneLocation,
        searchPowerList,
        circleType

    } = props

    const [center, setCenter] = useState({})
    const [strokeColor, setStrokeColor] = useState(STROKE_COLOR)
    const [fillColor, setFillColor] = useState(FILL_COLOR)

    const mapRef = useRef(null)
console.log("***************************************searchPowerList************************************");
console.log(searchPowerList);
console.log(circleType);
    useEffect(() => {
        if (zone ) {
            const p = zone.zone_coordinates[0]
            if (p && p.lat !== undefined && p.lng !== undefined) {
                console.log(p)
                setCenter(p)
            }
        }
    }, [zone])
    useEffect(() => {
        if (searchPowerList.length>0 && circleType!=="old") {
            const p = searchPowerList[0].zone_coordinates[0]
            if (p && p.lat !== undefined && p.lng !== undefined) {
                console.log(p)
                setCenter(p)
            }
        }
    }, [searchPowerList])

    useEffect(() => {
        if (canDrag) {
            setFillColor(EDIT_FILL_COLOR)
            setStrokeColor(EDIT_STROKE_COLOR)
        }
        else {
            setFillColor(FILL_COLOR)
            setStrokeColor(STROKE_COLOR)
        }
    }, [canDrag])

    const onDragEnd = () => {
        if (!mapRef.current) {
            return
        }
        let newPos = mapRef.current.getCenter().toJSON()
        if (newPos && newPos.lat !== undefined && newPos.lng !== undefined) {
            setCenter(newPos)
            setNewPowerZoneLocation(newPos)
        }
    }

    const onDrag = () => {
        if (!mapRef.current) {
            return
        }
        let newPos = mapRef.current.getCenter().toJSON()
        if (newPos && newPos.lat !== undefined && newPos.lng !== undefined) {
            setCenter(newPos)
        }
    }

    let circleSettings = {
        strokeColor: strokeColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: fillColor,
        fillOpacity: 0.45,
    }

    let centerSettings = {
        strokeColor: strokeColor,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: fillColor,
        fillOpacity: 1,
    }

    const getTextWidth = (text, font) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = font || getComputedStyle(document.body).font
        return context.measureText(text).width
    }

    const handleLoad = (map) => {
        mapRef.current = map
    }

    if (!(center && center.lat !== undefined && center.lng !== undefined )) {
        return null
    }

    const labelOffset = getTextWidth(zone.zone_name) / 2

    const divStyle = {
        color: '#0a570a',
        fontSize: '12px',
        marginLeft: `-${labelOffset}px`,
        paddingTop: '8px'
    }

    return (<div key={`power-zone-${index}`}>
        <Circle
            editable={false}
            onLoad={handleLoad}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            draggable={canDrag}
            center={center}
            radius={POWER_ZONE_RADIUS_IN_METERS}
            options={circleSettings}
        />
        <Circle
            center={center}
            radius={0.25}
            options={centerSettings}
        />
        <OverlayView
            position={center}
            mapPaneName={OverlayView.FLOAT_PANE}
        >
            <div style={divStyle}>
                {zone.zone_name}
            </div>
        </OverlayView>
    </div>)
}

export default PowerZone
