import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  savePetType,
  updatePetType,
} from "../../../helpers/adminHelper/petHelpers";

import { toastConfig } from "../adminConfig";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const schema = yup
  .object()
  .shape({
    species_name: yup
      .string()
      .min(3, "Pet Type must be at least 3 characters.")
      .max(20, "Pet Type must be at most 20 characters.")
      .required("Pet Type is required."),
  })
  .required();

function PetTypeModal(props) {
  const {
    adminToken,
    show,
    setShow,
    setAuxToFetch,
    auxToFetch,
    form_type,
    setform_type,
    showEdit,
    setShowEdit,
    speciesName,
  } = props;
  const [modalButtonName, setModalButtonName] = useState("Save");
  const [modalTitle, setModalTitle] = useState("Add New Pet Type");

  const [petTypeID, setPetTypeID] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    // defaultValues: {form_type:true}
  });

  useEffect(() => {
    if (showEdit) {
      setPetTypeID(speciesName[0]);
      setValue("species_name", speciesName[1]);
      setModalTitle("Edit Pet Type");
      setModalButtonName("Update");
      setShow(true);
      setform_type("edit");
    }
  }, [showEdit]);

  const saveORupdate = async (form_type, data, onClose) => {
    const response =
      form_type === "add"
        ? await savePetType(data, adminToken)
        : await updatePetType(data, petTypeID, adminToken);

    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      reset();
      setAuxToFetch(!auxToFetch);
      setShow(false);
      setIsSubmitting(false);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setError(
        "petTypeError",
        { type: "custom", message: response.message },
        { shouldFocus: true }
      );
      setIsSubmitting(false);
    }
  };

  const savePetTypeDetails = (data) => {
    data.status = "active";
    const type = form_type === "add" ? "Add" : "Update";
    setIsSubmitting(true);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {type} ?</h3>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                saveORupdate(form_type, data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                setIsSubmitting(false);
                onClose();
              }}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  return (
    <div>
      <Modal
        size="md"
        show={show}
        onHide={() => {
          setShow(false);
          setShowEdit(false);
        }}
        aria-labelledby="modal-sizes-title-lg"
        centered
      >
        <form onSubmit={handleSubmit(savePetTypeDetails)}>
          <Modal.Header closeButton>
            <Modal.Title id="modal-sizes-title-lg">{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="scrollbar text-align-justify">
            <div className="form-group my-pets">
              <label for="exampleInputEmail1" className="form-label">
                Pet Type{" "}
              </label>
              <div className="input-group white">
                <span className="input-group-text">
                  <i className="fa fa-paw"></i>
                </span>
                <input
                  type="text"
                  className={
                    "form-control " + (errors.species_name ? " is-invalid" : "")
                  }
                  {...register("species_name")}
                  placeholder="Enter pet type "
                />
              </div>
              <p className="errors">
                {" "}
                {errors.species_name?.message ||
                  errors.species_name?.label.message}{" "}
              </p>
              <p className="errors">
                {" "}
                {errors.petTypeError?.message ||
                  errors.petTypeError?.label.message}{" "}
              </p>
            </div>

            <ul className="list-inline float-end mb-0">
              <li className="list-inline-item ">
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting === true && (
                    <div className="spinner-border text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {modalButtonName}
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    reset();
                    setShow(false);
                    setShowEdit(false);
                  }}
                >
                  Cancel
                </button>
              </li>
            </ul>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
}

export default PetTypeModal;
