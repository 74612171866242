import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import pethistory from '../../images/pethistory_w.png';
import React from 'react';
import settings from '../../images/settings_w.png'
import useUIStore from '../../store/useUIStore'
import usePetHistoryStore from '../../store/usePetHistoryStore'

export const PetHistoryButton = (props) => {
    const {iccid} = props

    return (
        <div className="nav-item" role="presentation" style={{cursor: 'pointer'}} onClick={() => {
            useUIStore.getState().setCurrentIccid(iccid)
            usePetHistoryStore.getState().toggleShowHistory()
        }}>
            <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip-disabled">Pet History</Tooltip>}>
                <div className="sleep-button"
                     id="historyBtn"
                >
                    <i className={'fa fa-clock-rotate-left'} style={{color: 'white', fontSize: '20px'}}/>
                </div>
            </OverlayTrigger>

        </div>

    )
}
