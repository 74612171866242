import { DateTime, Settings } from 'luxon'
import useAuthStore from '../../../store/useAuthStore'

const hmsToSecond = (str) => {
    let p = str.split(':'),
        s = 0, m = 1;
    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }
    return s;
}

export const convertGibiSettingsToFormValues = (gibi) => {
    const zone = useAuthStore.getState().timezone.value
    const startDate = DateTime.fromSeconds(gibi.sleepStart, {zone}).toJSDate()
    const endDate = DateTime.fromSeconds(gibi.sleepStart + (60 * gibi.sleepDuration), {zone}).toJSDate()

    const startTime = DateTime.fromSeconds(gibi.sleepStart, {zone}).toJSDate()
    const endTime = DateTime.fromSeconds(gibi.sleepStart + (60 * gibi.sleepDuration), {zone}).toJSDate()

    return {startDate, endDate, startTime, endTime}
}

export const convertFormValuesToGibiSettings = (sleepStartDate, sleepEndDate, sleepStartTime, sleepEndTime, repeat) => {
    const zone = useAuthStore.getState().timezone.value
    let unixStart = 0
    let duration = 0
    if (!repeat) {
        const start = DateTime.fromJSDate(sleepStartDate).toSeconds() + hmsToSecond(`${DateTime.fromJSDate(sleepStartTime, {zone}).toLocaleString(DateTime.TIME_24_SIMPLE)}:00`)
        const end = DateTime.fromJSDate(sleepEndDate).toSeconds() + hmsToSecond(`${DateTime.fromJSDate(sleepEndTime, {zone}).toLocaleString(DateTime.TIME_24_SIMPLE)}:00`);
        duration = (end - start) / 60
        unixStart = start
    }
    else {
        const startTime = DateTime.fromJSDate(sleepStartTime, {zone: useAuthStore.getState().timezone.value}).toSeconds()
        const endTime = DateTime.fromJSDate(sleepEndTime, {zone: useAuthStore.getState().timezone.value}).toSeconds()
        duration = (endTime - startTime) / 60
        unixStart = startTime
    }
    return {startEpoch: unixStart, duration}
}
