import React from 'react';

 function Footer() {
  return(
   <>
   <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <p>Terms & Conditions | Privacy & Policy</p>
          </div>
        </div>
      </div>
    </footer>
   </>
  );
}
export default Footer;