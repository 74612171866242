import { get, post, put } from "./apiHelper";

export const gethAccountDetail = async (userToken) => {
  const response = await get("/api/v1/billing_info", userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getTimeZone = async (userToken) => {
  const response = await get("/api/v1/get_all_timezone/1", userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getDevices = async (userToken) => {
  const response = await post("/api/v1/getAccountDevices", {}, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const changePass = async (userToken, data) => {
  const response = await post("/api/v1/changeUserPass", data, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const updateAccountDetails = async (userToken, accountId, data) => {
  const response = await put("/api/v1/userInfo/" + accountId, data, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const updateAddress = async (userToken, accountId, data) => {
  const response = await put(
    "/api/v1/homeAddress/"+accountId,
    data,
    userToken
  );
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
