import { get, post, put, remove } from "./apiHelper";

export const getPets = async (userToken) => {
  const response = await get("/api/v1/pets", userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
export const getAllContacts = async (userToken) => {
  const response = await get("/api/v1/contactpets", userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const addContact = async (userToken, data) => {
  const response = await post("/api/v1/contactpet", data, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const updateContact = async (userToken, petContactID, data) => {
  const response = await put(
    "/api/v1/contactpet" + petContactID,
    data,
    userToken
  );
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};

export const removeContact = async (userToken, deletedID) => {
  const response = await remove("/api/v1/contactpet" + deletedID, userToken);
  if (response.status) {
    return response.data;
  } else {
    return [];
  }
};
