import create from 'zustand';

let store = (set) => ({
    showSearchBox: false,

    buttonType: [],
    fetchZonesToggle: false,

    safeZoneList: [],
    powerZoneList: [],

    newSafeZoneGeometry: [],
    newPowerZoneLocation: {},
    zoneCoords: {},

    canDragSafeZone: false,
    canEditSafeZone: false,
    canDragPowerZone: false,
    zoneID: '',
    zoneName: '',

    setShowSearchBox: (v) =>
        set((state) => {
            return {
                ...state,
                showSearchBox: v,
            }
        }),
    setButtonType: (v) =>
        set((state) => {
            return {
                ...state,
                buttonType: v,
            }
        }),
    setFetchZones: (v) =>
        set((state) => {
            return {
                ...state,
                fetchZonesToggle: v,
            }
        }),
    setSafeZoneList: (v) =>
        set((state) => {
            return {
                ...state,
                safeZoneList: v,
            }
        }),
    setPowerZoneList: (v) =>
        set((state) => {
            return {
                ...state,
                powerZoneList: v,
            }
        }),
    setNewSafeZoneGeometry: (v) =>
        set((state) => {
            return {
                ...state,
                newSafeZoneGeometry: v,
            }
        }),
    setNewPowerZoneLocation: (v) =>
        set((state) => {
            return {
                ...state,
                newPowerZoneLocation: v,
            }
        }),
    setZoneCoords: (v) =>
        set((state) => {
            return {
                ...state,
                zoneCoords: v,
            }
        }),
    setCanDragSafeZone: (v) =>
        set((state) => {
            return {
                ...state,
                canDragSafeZone: v,
            }
        }),
    setCanEditSafeZone: (v) =>
        set((state) => {
            return {
                ...state,
                canEditSafeZone: v,
            }
        }),
    setCanDragPowerZone: (v) =>
        set((state) => {
            return {
                ...state,
                canDragPowerZone: v,
            }
        }),
    setZoneID: (v) =>
        set((state) => {
            return {
                ...state,
                zoneID: v,
            }
        }),
    setZoneName: (v) =>
        set((state) => {
            return {
                ...state,
                zoneName: v,
            }
        }),
});

const useZoneStore = create(store);

export default useZoneStore;
