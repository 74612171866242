import { React, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExcelTemplate from '../../../assets/ExcelTemplate.xlsx'
import useAdminAuthStore from '../../../store/useAdminAuthStore'
import { toastConfig } from '../adminConfig'
import { addIccIdData, uploadExcelFile, } from '../../../helpers/adminHelper/deviceHelper'


const excelSchema = yup
    .object()
    .shape({
        excelFile: yup
            .mixed()
            .test('fileRequired', 'Excel file is required', (value) => {
                // file_require = 1;//true
                // if(value.length===0 && file_require===1){
                if (value.length === 0) {
                    return false
                }
                else {
                    return true
                }
            })
            .test(
                'fileType',
                'Only the following formats are accepted: .xls and xlsx',
                (value) => {
                    if (value.length !== 0) {
                        // return value && (value[0].type === "xls" ||value[0].type === "xlsx")
                        return (
                            value &&
                            (value[0].type ===
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                value[0].type ===
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                        )
                    }
                    else {
                        return true
                    }
                }
            )
            .test('fileSize', 'File too big, can\'t exceed 3MB', (value) => {
                if (value.length !== 0) {
                    return value[0].size <= 3000000
                }
                else {
                    return true
                }
            }),
    })
    .required()

const schema = yup
    .object()
    .shape(
        {
            iccid: yup
                .string()
                .min(19, 'ICCID must be at least 19 digits.')
                .max(20, 'ICCID must be at most 20 digits.')
                .required('ICCID is required.'),
            mdn: yup.string().when('mdn', (value) => {
                if (value) {
                    return yup
                        .string()
                        .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
                }
                else {
                    return yup
                        .string()
                        .transform((value, originalValue) => {
                            // Convert empty values to null
                            if (!value) {
                                return null
                            }
                            return originalValue
                        })
                        .nullable()
                        .optional()
                }
            }),
            imsi: yup.string().when('imsi', (value) => {
                if (value) {
                    return yup
                        .string()
                        .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
                }
                else {
                    return yup
                        .string()
                        .transform((value, originalValue) => {
                            // Convert empty values to null
                            if (!value) {
                                return null
                            }
                            return originalValue
                        })
                        .nullable()
                        .optional()
                }
            }),

            carrier: yup
                .string()
                .nullable()
                .notRequired()
                .when('carrier', {
                    is: (value) => value?.length,
                    then: (rule) =>
                        rule
                            .min(3, 'Carrier must be at least 3 characters.')
                            .max(30, 'Carrier must be at most 30 characters.')
                            .matches(
                                /^[aA-zZ\s]+$/,
                                'Only alphabets are allowed for this field '
                            ),
                }),
        },
        [
            // Add Cyclic deps here because when require itself
            ['carrier', 'carrier'],
            ['mdn', 'mdn'],
            ['imsi', 'imsi'],
        ]
    )
    .required()
const AddICCID = (props) => {
    const {showModal, setShowModal, ModalData, auxToFetch, setAuxToFetch} = props
    const [adminToken, setAdminToken] = useState(useAdminAuthStore((s) => s.token))
    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        setError,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        // defaultValues: {form_type:true}
    })

    const {
        control: excelControl,
        register: excelRegister,
        handleSubmit: excelSubmit,
        setValue: excelValue,
        reset: excelReset,
        formState: {errors: excelErrors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(excelSchema),
        // defaultValues: planInfo
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUploadSubmitting, setIsUploadSubmitting] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)

    const exSubmit = async (data) => {
        console.log(selectedFile)
        const formData = new FormData()
        formData.append('excelFile', selectedFile)
        setIsUploadSubmitting(!isUploadSubmitting)

        const response = await uploadExcelFile(formData, adminToken)

        if (response.status) {
            toast.success(response.message, {...toastConfig, containerId: 'api'})

            setIsUploadSubmitting(false)
            setAuxToFetch(!auxToFetch)
            setShowModal(false)
        }
        else {
            toast.error(response.message, {...toastConfig, containerId: 'api'})
            setError(
                'iccid_error',
                {type: 'manual', message: response.message},
                {shouldFocus: true}
            )
            setIsUploadSubmitting(false)
        }
    }

    const fileUpdate = (e) => {
        const file = e.target.files[0]
        setSelectedFile(file)
    }
    const singleSubmit = async (data) => {
        console.log(data)
        setIsSubmitting(true)
        const response = await addIccIdData(data, adminToken)
        if (response.status) {
            toast.success(response.message, {...toastConfig, containerId: 'api'})
            setIsSubmitting(false)
            setAuxToFetch(!auxToFetch)
            setShowModal(false)
            reset()
        }
        else {
            toast.error(response.message, {...toastConfig, containerId: 'api'})
            setError(
                'iccid_error',
                {type: 'manual', message: response.message},
                {shouldFocus: true}
            )
            setIsSubmitting(false)
        }
    }

    // Function will execute on click of button
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('ExcelGibi.xlsx').then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob)
                // Setting various property values
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'ExcelGibi.xlsx'
                alink.click()
            })
        })
    }

    return (
        <>
            <Modal
                className="add-new-gibi"
                show={showModal}
                size="lg"
                onHide={() => {
                    setShowModal(false)
                    reset()
                }}
                aria-labelledby="modal-sizes-title-lg"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">
                        {ModalData.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="edit-device">
                    <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                        <li className="nav-item me-2" role="presentation">
                            <a
                                className="nav-link active"
                                id="single-tab"
                                data-bs-toggle="tab"
                                href="#singleUpload"
                                role="tab"
                                aria-controls="single"
                                aria-selected="true"
                            >
                                Single Import
                            </a>
                        </li>
                        <li className="nav-item me-2" role="presentation">
                            <a
                                className="nav-link"
                                id="bulk-tab"
                                data-bs-toggle="tab"
                                href="#bulkUpload"
                                role="tab"
                                aria-controls="bulk"
                                aria-selected="false"
                            >
                                Bulk Import
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active p-3 border"
                            id="singleUpload"
                            role="tabpanel"
                            aria-labelledby="single-tab"
                        >
                            <form onSubmit={handleSubmit(singleSubmit)}>
                                <div>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="form-group my-pets">
                                                <label className="form-label text-dark">ICCID *:</label>
                                                <div className="input-group white">
                          <span className="input-group-text">
                            <i className="fa fa-hashtag" aria-hidden="true"></i>
                              {/* <i className="fa fa-input-numeric"></i> */}
                          </span>
                                                    <input
                                                        type="text"
                                                        {...register('iccid')}
                                                        className="form-control"
                                                        placeholder="Enter ICCID"
                                                        onKeyPress={(e) => {
                                                            if (new RegExp(/[0-9]/).test(e.key)) {
                                                            }
                                                            else {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {errors.iccid && (
                                                    <p className="errors">{errors.iccid.message}</p>
                                                )}
                                                {errors.iccid_error && (
                                                    <p className="errors">{errors.iccid_error.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="form-group my-pets">
                                                <label className="form-label text-dark">
                                                    MDN (Optional):
                                                </label>
                                                <div className="input-group white">
                          <span className="input-group-text">
                            <i className="fa fa-hashtag" aria-hidden="true"></i>
                          </span>
                                                    <input
                                                        type="text"
                                                        {...register('mdn')}
                                                        className="form-control"
                                                        placeholder="Enter MDN"
                                                    />
                                                </div>
                                                {errors.mdn && (
                                                    <p className="errors">{errors.mdn.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="form-group my-pets">
                                                <label className="form-label text-dark">
                                                    IMSI (Optional):
                                                </label>
                                                <div className="input-group white">
                          <span className="input-group-text">
                            <i className="fa fa-window-maximize"></i>
                              {/* <i className="fa fa-hashtag" aria-hidden="true"></i> */}
                          </span>
                                                    <input
                                                        type="text"
                                                        {...register('imsi')}
                                                        className="form-control"
                                                        placeholder="Enter IMSI"
                                                    />
                                                </div>
                                                {errors.imsi && (
                                                    <p className="errors">{errors.imsi.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="form-group my-pets">
                                                <label className="form-label text-dark">
                                                    Carrier (Optional):
                                                </label>
                                                <div className="input-group white">
                          <span className="input-group-text">
                            <i
                                className="fa fa-circle-user"
                                aria-hidden="true"
                            ></i>
                          </span>
                                                    <input
                                                        type="text"
                                                        {...register('carrier')}
                                                        className="form-control"
                                                        placeholder="Enter Carrier"
                                                    />
                                                </div>
                                                {errors.carrier && (
                                                    <p className="errors">{errors.carrier.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="list-inline float-end mb-0 mt-5">
                                        <li className="list-inline-item ">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting === true && (
                                                    <div
                                                        className="spinner-border text-info"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )}
                                                Save
                                            </button>
                                        </li>
                                        <li className="list-inline-item">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                    reset()
                                                    setShowModal(false)
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                        <div
                            className="tab-pane fade p-3 border"
                            id="bulkUpload"
                            role="tabpanel"
                            aria-labelledby="bulk-tab"
                        >
                            <form
                                onSubmit={excelSubmit(exSubmit)}
                                encType="multipart/form-data"
                            >
                                <div className="file-upload">
                                    <div className="text-center">
                                        <p>
                                            Please download the XLS template below and upload with all
                                            the necessary information.
                                        </p>
                                        <a
                                            href={ExcelTemplate}
                                            download="ExcelTemplate"
                                            className="btn btn-outline-primary"
                                        >
                                            <i className="fa fa-download"></i> Download
                                        </a>
                                    </div>

                                    <div className="form-group my-pets">
                                        <div className="file-control w-75 mt-3">
                                            <input
                                                name="excelFile"
                                                className="filestyle form-control"
                                                id="picture"
                                                {...excelRegister('excelFile')}
                                                type="file"
                                                onChange={(e) => {
                                                    fileUpdate(e)
                                                }}
                                            />
                                        </div>
                                        {excelErrors.excelFile && (
                                            <p className="errors text-center">
                                                {excelErrors.excelFile.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <ul className="list-inline float-end mb-0 mt-5">
                                    <li className="list-inline-item ">
                                        <button
                                            type="submit"
                                            className="btn btn-outline-primary"
                                            disabled={isUploadSubmitting}
                                        >
                                            {isUploadSubmitting === true && (
                                                <div className="spinner-border text-info" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                // <span className="spinner-grow spinner-grow-sm"></span>
                                            )}
                                            Upload
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={() => {
                                                reset()
                                                setShowModal(false)
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddICCID;
