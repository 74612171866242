import { React, useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import {
  getAllAlertTypes,
  removeAlertType,
} from "../../../helpers/adminHelper/settigHelper";
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import DataTableSetup from "../DataTableSetup";
import AddAlertModal from "./AddAlertModal";
import { toastConfig } from "../adminConfig";

const AlertSetting = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [alertTypes, setAlertTypes] = useState([]);
  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );

  const columns = [
    {
      name: "Alert Type",
      selector: (row) => row.alertType,
      sortable: true,
    },
    {
      name: "Alert Code",
      selector: (row) => row.alertCode,
      sortable: true,
    },
    // {
    //     name: 'Created Date',
    //     // selector: row => row.createdAt.substring(0, 10),
    //     selector: row => row.createdAt.split("T")[0],
    //     sortable: true,

    // },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.status === true ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>InActive</span>
          )}
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-disabled">Click to change status.</Tooltip>
            }
          >
            <button className="icon-button">
              <i
                className={
                  row.status === true ? "fa fa-trash" : "fa fa-undo"
                }
                onClick={() => {
                  deleteID(row._id, row.status);
                }}
              >
                {" "}
              </i>
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const fetchAlertType = async () => {
    const response = await getAllAlertTypes(adminToken);
    if (response.status) {
      let alertTypes = response.result;
      console.log(alertTypes);
      setAlertTypes(alertTypes);
    }
  };

  useEffect(() => {
    fetchAlertType();
  }, [auxToFetch]);

  const deleteAlert = async (data, onClose) => {
    const response = await removeAlertType(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };
  const deleteID = (id, status) => {
    const statusValue = status === true ? "In-Active" : "Active";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {statusValue} ?</h3>
            {/* <p>You want to delete this file?</p> */}
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                const data = { id: id, status: status };
                deleteAlert(data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };
  //-----Search Filter Starts-----//
  const [filterText, setFilterText] = useState("");
  const filteredItems = alertTypes.filter(
    (item) =>
      item.alertType &&
      item.alertType.toLowerCase().includes(filterText.toLowerCase())
  );

  const onFilterData = (e) => {
    setFilterText(e.target.value);
  };
  const onFilterClear = () => {
    setFilterText("");
  };
  //-----Search Filter Ends-----//
  const btnStatus = true;
  const BtnContent = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">Click to add Alert Type</Tooltip>
        }
      >
        <button
          className="btn btn-primary mb-2 "
          onClick={() => setShowAlert(true)}
        >
          Add Alert Type
        </button>
      </OverlayTrigger>
    );
  };
  const datatableProps = {
    filterText: filterText,
    onFilterData: onFilterData,
    onFilterClear: onFilterClear,
    columns: columns,
    filteredItems: filteredItems,
    btnStatus: btnStatus,
    BtnContent: BtnContent,
  };
  const alertModalProps = {
    adminToken: adminToken,
    showAlert: showAlert,
    setShowAlert: setShowAlert,
    auxToFetch: auxToFetch,
    setAuxToFetch: setAuxToFetch,
  };
  return (
    <>
      <DataTableSetup {...datatableProps} />
      <AddAlertModal {...alertModalProps} />
    </>
  );
};
export default AlertSetting;
