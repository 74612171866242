import React from "react";
import { useNavigate } from "react-router-dom";
 
function TokenInvalid() {
    let navigate = useNavigate();

    const homePage = () => {
      let path = `/login`;
      navigate(path);
    };
  const actionBtn = (
    <ul className="list-inline w-100 d-flex align-items-center justify-content-center">
      <li className="list-inline-item m-0  ps-2">
        <button
          type="button"
          className="btn btn-outline-primary white w-100"
          onClick={homePage}
        >
         
          Go to Home Page
        </button>
      </li>
    </ul>
  );


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 p-0 login-img-holder">
          <div className="login-bg"></div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 p-0">
          <div className="login-holder d-flex align-items-center">
            <div className="login-container">
              <div className="logo">
                <img src={require("../images/logo-white.png")} alt="gibi" />
              </div>
              <h3 className="panel-title text-center text-white">
               Link Expired..!
              </h3>
              <p className="text-center text-white">
             Oops.! This invite URL is not valid anymore. 
              </p>
             
                <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-10 col-12 offset-sm-1">
                
                    <div className="form-group my-5">{actionBtn}</div>
                  </div>
                </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TokenInvalid;
