import React, { useState,useEffect } from "react";
import { Link} from "react-router-dom";
import useAuthStore from '../../store/useAuthStore';
import { useLocation, useNavigate } from 'react-router-dom'

const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL;
const containerStyles = {
  border: '1px solid #fff',
  padding: '8px 10px 8px 10px',
  borderRadius: '4px',
  width: '100%',
  marginBottom:'15px',
  backgroundColor:'#149dcd',
 };


  const DeviceInactive = ({ updateStep,registerInfo,planInfo,petInfo,billInfo,selectedFile,updateLoader}) => {

const [userToken, setuserToken] = useState(useAuthStore((s) => s.token));

const location = useLocation()
console.log(location);
const deviceID = location.state?.data?.errDeviceID[0].errorDevice;
console.log(deviceID);

return (

    <div>

      <div className="container-fluid">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0 login-img-holder">
          <div className="login-bg"></div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 p-0">
          <div className="login-holder d-flex align-items-center">
            <div className="login-container">
              <div className="logo">
                <img src={require('../../images/logo-white.png')} alt="gibi"/>
              </div>

    <>
     <form method="POST"  >
      <h3 className="panel-title text-center text-white">Gibi Tracker Inactive</h3>
      <div className="view-data mb-4">
        <div className="row mb-3 text-white">
        {/* <div className="logo">
                <img src={require('../../images/payment_success.png')} alt="gibi" style={{width:"240px",height:"228px"}}/>
              </div> */}

       <h5 className="text-center text-white">
            An error occurs as your device ID :{deviceID} is not valid.
        </h5>
          <p className="text-center text-white">
            Please contact to admin to get more help.
         </p>
        </div>

        </div>

            <div className="text-center">

            {/* <Link
                to="/"
                target="_blank"
                className="btn btn-outline-primary white w-25 m-auto"
                >
            Login
            </Link> */}
                </div>

        </form>

</>
        </div>
          </div>
        </div>
      </div>
    </div>





      </div>





  );
};
export default DeviceInactive;
