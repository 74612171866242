import { React, useState, useEffect } from "react";
import AddPetBreed from "./AddPetBreed";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DataTableSetup from "../DataTableSetup";
import {getBreedList,removePetBreed} from "../../../helpers/adminHelper/petHelpers";
import useAdminAuthStore from "../../../store/useAdminAuthStore";
import { toastConfig } from "../adminConfig";

const PetBreeds = ({ updateLoader, updateLoaderMessage }) => {
  const [showModal, setShowModal] = useState(false);
  const [ModalData, setModalData] = useState({
    title: "Add New Pet Breed",
    btnName: "Save",
  });
  const [AddExcel, setAddExcel] = useState(true);
  const [breedlist, setBreedlist] = useState([]);
  const [auxToFetch, setAuxToFetch] = useState(false);
  const [adminToken, setAdminToken] = useState(
    useAdminAuthStore((s) => s.token)
  );

  const columns = [
    {
      name: "Breed Name",
      selector: (row) => row.breed_name,
      sortable: true,
    },
    {
      name: "Pet Type",
      selector: (row) => row.species_type.species_name,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) =>
        row.createdAt != undefined ? row.createdAt.split("T")[0] : "-",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div>
          {row.status === "active" ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>InActive</span>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      cell: (row) => (
        <div>
          {/* <button className='icon-button me-3' id="productList"><i className="fa fa-eye"></i> </button> */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-disabled">Click to change status.</Tooltip>
            }
          >
            <button className="icon-button">
              <i
                className={
                  row.status === "active" ? "fa fa-trash" : "fa fa-undo"
                }
                onClick={() => {
                  deleteID(row._id, row.status);
                }}
              >
              </i>
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchAllBreeds();
  }, [auxToFetch]);

  const deleteBreed = async (data, onClose) => {
    const response = await removePetBreed(data, adminToken);
    if (response.status) {
      toast.success(response.message, {...toastConfig, containerId: 'api'});
      onClose();
      setAuxToFetch(!auxToFetch);
    } else {
      toast.error(response.message, {...toastConfig, containerId: 'api'});
    }
  };
  const deleteID = (id, status) => {
    const statusValue = status === "active" ? "In-Active" : "Active";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="fa-3x">
              <i className="fa-solid fa-paw fa-bounce"></i>
            </div>
            <h3>Are you sure want to {statusValue} ?</h3>
            {/* <p>You want to delete this file?</p> */}
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                const data = { id: id, status: status };
                deleteBreed(data, onClose);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary min-width-120"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  const fetchAllBreeds = async () => {
    updateLoader(true);
    updateLoaderMessage("Please wait..");
    const response = await getBreedList(adminToken);
    if (response.status) {
      let result = response.data;
      setBreedlist(result);
      updateLoader(false);
    } else {
      updateLoader(false);
    }
  };
  //-----Search Filter Starts-----//
  const [filterText, setFilterText] = useState("");
  const filteredItems = breedlist.filter(
    (item) =>
      (item.breed_name &&
        item.breed_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.species_type.species_name &&
        item.species_type.species_name
          .toLowerCase()
          .includes(filterText.toLowerCase()))
  );

  const onFilterData = (e) => {
    setFilterText(e.target.value);
  };
  const onFilterClear = () => {
    setFilterText("");
  };
  //-----Search Filter Ends-----//
  const btnStatus = true;
  const BtnContent = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">Click to Add New Breed</Tooltip>
        }
      >
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add New Breed
        </button>
      </OverlayTrigger>
    );
  };
  const datatableProps = {
    filterText: filterText,
    onFilterData: onFilterData,
    onFilterClear: onFilterClear,
    columns: columns,
    filteredItems: filteredItems,
    btnStatus: btnStatus,
    BtnContent: BtnContent,
  };
  const addBreedProps = {
    showModal: showModal,
    setShowModal: setShowModal,
    ModalData: ModalData,
    auxToFetch: auxToFetch,
    setAuxToFetch: setAuxToFetch,
  };
  return (
    <>
      <DataTableSetup {...datatableProps} />
      <AddPetBreed {...addBreedProps} />
    </>
  );
};
export default PetBreeds;
