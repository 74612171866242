import React from 'react'
import DataTable from 'react-data-table-component';

function DataTableSetup(props) {
const {filterText,onFilterData,onFilterClear,columns,filteredItems,btnStatus,BtnContent}= props
    return (
        <div className="card">
        <div className="card-body p-0">
            {btnStatus===true &&<BtnContent/>}
            <div className='search-box float-end'>
                <span className="deleteicon">
                    <input type="text" value={filterText} placeholder="Search"
                        onChange={onFilterData} />
                    {filterText && <span onClick={onFilterClear}>x</span>}
                </span>
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId={1}
                searchable
                pagination
                responsive
            />
        </div>
    </div>
  )
}

export default DataTableSetup