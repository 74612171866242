import { React, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useForm, Controller } from "react-hook-form";

import {  toast } from 'react-toastify';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {addNewTimeZone} from "../../../helpers/adminHelper/settigHelper"
import useAdminAuthStore from "../../../store/useAdminAuthStore";


import { toastConfig } from "../adminConfig";
const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        cursor: "pointer",
        padding: "5px",
        fontSize: "14px",
        borderColor: "#fff!important",
        "&:hover": {
          backgroundColor: "#139dcc ",
          color: "#fff",
        },
      };
    },
  };
const schema = yup
  .object()
  .shape({
    timeZone: yup
      .string()
      .min(6, "Time Zone must be at least 6 characters long.")
      .max(100, "Time Zone must be upto 100 characters long.")
    //   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Time Zone is required."),

  })
  .required();

function AddTimeZoneModal(props) {
    const {showZone,setShowZone,auxToFetch,setAuxToFetch,}=props
    const [adminToken, setAdminToken] = useState(
        useAdminAuthStore((s) => s.token)
      );
    const {
        control,
        register,
        handleSubmit,
        setValue,
        setError,
        reset,
        formState: { errors },
      } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        // defaultValues: {form_type:true}
      });

    const [selectRate, setselectRate] = useState("");

const addTimeZone = async(data,onClose)=>{
const response = await addNewTimeZone(data,adminToken)
console.log(response);
if(response.status){
    toast.success(response.message, {...toastConfig, containerId: 'api'});
    onClose();
    reset();
    setAuxToFetch(!auxToFetch);
    setShowZone(false);
}else{
    toast.error(response.message, {...toastConfig, containerId: 'api'});
    onClose();
    setError("checkInRateData", { type:"custom",message: response.message},{ shouldFocus: true });
 }

}

    const saveTimeZone=(data)=>{
          console.log(data);
    confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <div className="fa-3x">
          <i className="fa-solid fa-paw fa-bounce"  ></i>
          </div>
          <h3>Are you sure want to add ?</h3>
          <button className="btn btn-outline-primary min-width-120"
            onClick={() => { addTimeZone(data,onClose) }}
          >
            Yes
          </button>
            <button className="btn btn-outline-primary min-width-120" onClick={onClose}>No</button>
        </div>
      );
    },
    closeOnClickOutside: false,

    });
    }

  return (
    <Modal
                size="md"
                show={showZone}
                onHide={() => {reset();setShowZone(false); }}
                aria-labelledby="modal-sizes-title-lg"
                centered
            >
            <form onSubmit={handleSubmit(saveTimeZone)}>
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">
                       Time Zone
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='scrollbar text-align-justify'>



                          <div className=" my-pets">
                          <div className="mb-3 ">
                            <label for="exampleInputEmail1" className="form-label"> Time Zone</label>
                            <input
                                type="text"
                                className={"form-control" + (errors.timeZone ? " is-invalid" : "")}
                                {...register("timeZone")}
                                placeholder="Enter Time Zone" />
                        </div>
                        <p className="errors">{errors.timeZone?.message || errors.timeZone?.label.message}</p>
                        </div>

                </Modal.Body>
                <Modal.Footer>
                    <button  type="submit"  className="btn btn-outline-primary"> Save </button>
                    <button type="button" className="btn btn-outline-primary" onClick={()=>{reset();setShowZone(false)}}>
                                    Cancel
                                </button>
                </Modal.Footer>
                </form>
            </Modal>
  )
}

export default AddTimeZoneModal
