import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { checkDeviceExistorNot, checkEmailExistorNot, checkMobileExistorNot, } from '../../helpers/registerHelper'
import { getTimeZone } from '../../helpers/accountHelper'

const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            cursor: 'pointer',
            padding: '5px',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#139dcc ',
                color: '#fff',
            },
        }
    },
}

const schema = yup
    .object()
    .shape({
        first_name: yup
            .string()
            .min(2, 'First name must be at least 2 characters.')
            .max(20, 'First name must be at most 20 characters.')
            .required('First name is required.')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        last_name: yup
            .string()
            .min(2, 'Last name must be at least 2 characters.')
            .max(20, 'Last name must be at most 20 characters.')
            .required('Last Name is required.')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        email: yup
            .string()
            .matches(/^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/, 'Invalid Email address.')
            .required('Email address is required.'),
        password: yup
            .string()
            .min(6, 'Password is Too Short!')
            .max(16, 'Password is Too Long!')
            .required('Password is required.'),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null])
            .required('Confirm Password Should Match'),
        mobile: yup
            .string()
            .matches(/^[0-9]+$/, 'Must be only numeric characters')
            .min(10, 'Mobile No. must be exactly 10 digits')
            .max(10, 'Mobile No. must be exactly 10 digits'),
        device_id: yup
            .string()
            .min(19, 'Tracker ID must be at least 19 digits.')
            .max(20, 'Tracker ID must be no more than 20 digits')
            .required('Tracker ID is required.'),
        // recaptcha: yup.string().required(),
        timeZone: yup.string().required('TimeZone is required '),
    })
    .required()
const Register = ({
                      updateStep,
                      registerInfo,
                      updatesetRegisterInfo,
                      updateLoader,
                      width
                  }) => {

    const {
        handleSubmit,
        register,
        control,
        setError,
        setValue,
        formState: {errors},
        clearErrors,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: registerInfo,
    })

    const [captchaResponse, setCaptchaResponse] = useState(null)
    const [emailCheck, setEmailCheck] = useState(true)
    const [deviceCheck, setDeviceCheck] = useState(true)
    const [mobileCheck, setMobileCheck] = useState(true)

    function onChange(value) {
        setCaptchaResponse(value)
    }

    // const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";


    const formSubmit = async (data) => {

        const email = data.email
        const deviceID = data.device_id
        const mobileNo = data.mobile
        const emailCheckRespoonse = await handleEmailChange(email)
        console.log(emailCheckRespoonse)
        if (emailCheckRespoonse === true) {
            setEmailCheck(true)
            const deviceCheckResponse = await handleDeviceChange(deviceID)
            if (deviceCheckResponse === true) {
                setDeviceCheck(true)
                // const mobileCheckResponse = await handleMobileChange(mobileNo);
                // if (mobileCheckResponse === true) {
                setMobileCheck(true)
                updatesetRegisterInfo(data)
                updateStep(2)
                // } else {
                //   setMobileCheck(false);
                // }
            }
            else {
              console.log(deviceCheckResponse)
              setDeviceCheck(false)
            }
        }
        else {
            setEmailCheck(false)
        }

    }

    let navigate = useNavigate()

    const previous_step = () => {
        let path = `/login`
        navigate(path)
    }

    const [options, setOption] = React.useState([
        {value: 'Assigned Gibi', label: 'Assigned Gibi'},
        {value: 'Alpha Time Zone UTC +1', label: 'Alpha Time Zone UTC +1'},
        {
            value: 'Central Africa Time UTC +2',
            label: 'Central Africa Time UTC +2',
        },
        {value: 'India Time Zone UTC +9', label: 'India Time Zone UTC +9'},
        {
            value: 'Greenwich Mean Time UTC +0',
            label: 'Greenwich Mean Time UTC +0',
        },
    ])
    const selectTimeZone = (val) => {
        setValue('timeZone', val)
    }
    const fetchTimeZone = async () => {
        const response = await getTimeZone()
        if (response.status) {
            let timezone_type = response.result
            setOption(
                timezone_type.map((item) => ({
                    value: item._id,
                    label: item.timezone_name,
                }))
            )
            updateLoader(false)
        }
        else {
        }
    }

    useEffect(() => {
        updateLoader(true)
        setTimeout(() => {
            fetchTimeZone()
        }, 2000)
    }, [])

    const handleEmailChange = async (email) => {
        const data = {email: email}
        const response = await checkEmailExistorNot(data)
        console.log(response)
        if (response.status) {
            if (response.result === 1) {
                setEmailCheck(false)
                setError(
                    'email',
                    {type: 'custom', message: response.message},
                    {shouldFocus: true}
                )
                return false
            }
            else {
                setEmailCheck(true)
                return true
            }
        }
    }

    const handleDeviceChange = async (deviceID) => {
        const data = {deviceID: deviceID}
        const response = await checkDeviceExistorNot(data)
        if (response.status) {
            setDeviceCheck(true)
            clearErrors('device_id')
            return true
        }
        else {
            setDeviceCheck(false)
            setError(
                'device_id',
                {type: 'custom', message: response.message},
                {shouldFocus: true}
            )
            return false
        }
    }

    const handleMobileChange = async (mobileNo) => {
        const data = {mobileNo: mobileNo}
        const response = await checkMobileExistorNot(data)
        if (response.status) {
            setMobileCheck(true)
            return true
        }
        else {
            setMobileCheck(false)
            setError(
                'mobile',
                {type: 'custom', message: response.message},
                {shouldFocus: true}
            )
            return false
        }
    }

    return (
        <>
            <div className={'login-holder d-flex align-items-center ' + (width < 768 ? 'h-100' : '')}>
                <div className={'login-container ' + (width < 768 ? 'h-100' : '')}>
                    <div className="logo">
                        <img src={require('../../images/logo-white_.png')} alt="gibi"/>
                    </div>

                    <form method="POST" onSubmit={handleSubmit(formSubmit)}>
                        <h3 className="panel-title text-center text-white">Account Info</h3>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">First Name</label>
                                    <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="text"
                                            className={
                                                'form-control' +
                                                (errors.first_name ? ' is-invalid' : '')
                                            }
                                            {...register('first_name')}
                                            placeholder="Enter Your First Name"
                                            autoComplete="off"
                                        />
                                        {errors.first_name && (
                                            <div className="invalid-feedback">
                                                {errors.first_name?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Last Name</label>
                                    <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="text"
                                            className={
                                                'form-control' + (errors.last_name ? ' is-invalid' : '')
                                            }
                                            {...register('last_name')}
                                            placeholder="Enter Your Last Name"
                                            autoComplete="off"
                                        />
                                        {errors.last_name && (
                                            <div className="invalid-feedback">
                                                {errors.last_name?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Email</label>
                                    <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="email"
                                            className={
                                                'form-control' + (errors.email ? ' is-invalid' : '')
                                            }
                                            {...register('email')}
                                            // onChange={(evt) => handleEmailChange(evt.target.value)}
                                            placeholder="Enter Your Email"
                                            autoComplete="off"
                                        />
                                        {errors.email && (
                                            <div className="invalid-feedback">
                                                {errors.email?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Mobile Number</label>
                                    <div className="input-group white">
                    <span
                        className="input-group-text"
                        style={{fontSize: '20px'}}
                    >
                      <i className="fa fa-mobile" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="text"
                                            className={
                                                'form-control' + (errors.mobile ? ' is-invalid' : '')
                                            }
                                            {...register('mobile')}
                                            // onChange={(evt) => handleMobileChange(evt.target.value)}
                                            placeholder="Enter Your Mobile No."
                                            autoComplete="off"
                                        />
                                        {errors.mobile && (
                                            <div className="invalid-feedback">
                                                {errors.mobile?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Password</label>
                                    <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="password"
                                            className={
                                                'form-control' + (errors.password ? ' is-invalid' : '')
                                            }
                                            {...register('password')}
                                            placeholder="Enter Your Password"
                                            autoComplete="off"
                                        />
                                        {errors.password && (
                                            <div className="invalid-feedback">
                                                {errors.password?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Re-Enter Password</label>
                                    <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            {...register('confirmPassword', {required: true})}
                                            className="form-control"
                                            placeholder="Re-enter Password"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.confirmPassword && (
                                        <p className="errors">
                                            {errors.confirmPassword &&
                                                'Confirm Password Should Match!'}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Time Zone</label>
                                    <div className="custom-dropdown timezone">
                                        <Controller
                                            name="timeZone"
                                            control={control}
                                            render={({field: {onChange, value}}) => (
                                                <Select
                                                    options={options}
                                                    styles={customStyles}
                                                    className="react-dropdown"
                                                    classNamePrefix="dropdown"
                                                    value={options.find((c) => c.value === value)}
                                                    // value="USA"
                                                    onChange={(val) => {
                                                        onChange(val.value)
                                                        selectTimeZone(val.value)
                                                    }}
                                                    placeholder={
                                                        <div className="Select Species">
                                                            Select Time Zone
                                                        </div>
                                                    }
                                                />
                                            )}
                                        />

                                        <p className="errors">
                                            {errors.timeZone?.message ||
                                                errors.timeZone?.label.message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                    <label className="text-white">Tracker ID</label>
                                    <div className="input-group white">
                    <span className="input-group-text">
                      <i className="fa fa-microchip" aria-hidden="true"></i>
                    </span>
                                        <input
                                            type="text"
                                            className={
                                                'form-control ' +
                                                (errors.device_id ? ' is-invalid' : '')
                                            }
                                            {...register('device_id')}
                                            placeholder="Please Enter Tracker ID"
                                            autoComplete="off"
                                            // onChange={(evt) => handleDeviceChange(evt.target.value)}
                                        />
                                        {errors.device_id && (
                                            <div className="invalid-feedback">
                                                {errors.device_id?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group clearfix">
                            {/* <img
            src={require("../images/captcha.png")}
            alt="Gibi"
            className="img-fluid"
            style={{ width: "100%" }}
          /> */}

                            {/* <ReCAPTCHA
        // ref={recaptchaRef}
        sitekey={TEST_SITE_KEY}
        onChange={onChange}
        // {...register("recaptcha")}
      /> */}

                            {/* {errors.recaptcha && (
              <div className="invalid-feedback">
                {errors.recaptcha?.message}
              </div>
            )} */}

                        </div>
                        <p className="version-detials">
                            If you already created a Gibi account,
                            <a href="/login" className="ms-2 text-white">
                                Click HERE
                            </a>
                        </p>

                        <ul className="list-inline w-100 d-flex align-items-center mb-0">
                            <li className="list-inline-item m-0 w-50 pe-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary white w-100"
                                    onClick={previous_step}
                                >
                                    Previous
                                </button>
                            </li>
                            <li className="list-inline-item m-0 w-50 ps-2">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary white w-100"
                                    // disabled={!captchaResponse}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Register
