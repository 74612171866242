import { StandaloneSearchBox } from '@react-google-maps/api'
import React, { useContext, useState } from 'react'
import SafeZoneModal from '../SafeZone/SafeZoneModal'

const SafeZoneSearchBox = (props) => {
    const {
        setMapCenter,
        setSearchCount,
        setCanDragPowerZone,
        showSearchBox,
        setShowSearchBox,
        setZoneCoords,
        buttonType,
        newPowerZoneLocation,
        newSafeZoneGeometry,
        setCanDragSafeZone,
        setCanEditSafeZone,
        setNewSafeZoneGeometry,
        setNewPowerZoneLocation,
        zoneID,
        zoneName,
        setPolygonType,
        setCircleType
    } = props

    const [searchBox, setSearchBox] = useState(null)
    const [zoneModalData, setZoneModalData] = useState([])
    const [btnActive, setBtnActive] = useState(false)

    const onPlacesChanged = () => {
        let places = searchBox.getPlaces()
        const placeCenter = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng()
        }
        setZoneCoords(placeCenter)
        setMapCenter(placeCenter)
        setSearchCount(0)
        setBtnActive(true)
    }

    const onSBLoad = ref => {
        setSearchBox(ref)
    }

    const clearZoneDetails = () => {
        setShowSearchBox(false)
        setBtnActive(false)
        setCanDragPowerZone(false)
        setCanDragSafeZone(false)
        setCanEditSafeZone(false)
        setNewSafeZoneGeometry([])
        setPolygonType("old");        
        setCircleType("old");
        setSearchCount(0);
    }

    const zoneModalProps = {
        setShowSearchBox,
        zoneModalData,
        setZoneModalData,
        zoneID,
        zoneName,

        // power zone creation
        setCanDragPowerZone,
        newPowerZoneLocation,
        setNewPowerZoneLocation,
        setCircleType,

        // safeZoneCreation
        newSafeZoneGeometry,
        setNewSafeZoneGeometry,
        setCanDragSafeZone,
        setCanEditSafeZone,
        setPolygonType
    }

    const searchInput = (
        <>

        <input
            type="text"
            placeholder="Enter Location"
            style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `350px`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                marginRight: '10px',
                top: '15px',
            }}
            />
            <br />
        <br />
        </>

    )
    return (<div id={'safe-zone-search-box'}>

        {showSearchBox && (<StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
        >
            <div className="fadeIn search-block" style={{
                position: 'absolute',
                top: '10px',
                left: '200px',
                right: '0',
                width: '100%',
                display: 'flex',
                alignItems: 'center', // justifyContent: "center",
            }}>
                {searchInput}

                {buttonType[1] === 'Create' && btnActive === true ?
                    <>
                        <button type="button"
                                onClick={() => {
                                    setZoneModalData([buttonType, true])
                                }}
                                className="btn btn-outline-primary min-width-120 btn-bg"
                                id="advanceSettingModalBtn"
                                data-dismiss="modal"
                                data-target="#settingModal">
                            Create {buttonType[0]} Zone
                        </button>
                        <button className="btn btn-outline-primary ms-2 btn-bg" onClick={clearZoneDetails}><i
                            className="fa-solid fa-xmark"></i></button>
                    </> : null}
                {buttonType[1] === 'Update' ?
                    <>
                        <button type="button"
                                onClick={() => {
                                    setZoneModalData([buttonType, true])
                                }}
                                className="btn btn-outline-primary min-width-120 btn-bg"
                                id="advanceSettingModalBtn"
                                data-dismiss="modal"
                                data-target="#settingModal">
                            Udpate {buttonType[0]} Zone
                        </button>
                        <button className="btn btn-outline-primary ms-2 btn-bg" onClick={clearZoneDetails}><i
                            className="fa-solid fa-xmark"></i></button>
                    </> : null}

            </div>
        </StandaloneSearchBox>)}

        <SafeZoneModal {...zoneModalProps}/>
    </div>)

}

export default SafeZoneSearchBox
